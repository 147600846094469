import React, { ForwardRefRenderFunction, Ref } from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import {
  NumericFormat,
  NumericFormatProps,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format'
import {
  InputAdornment,
  InputBaseProps,
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'
import cx from 'classnames'

import {
  useCurrencyFormat,
  useDateFormat,
  usePhoneNumberFormat,
  useSSNFormat,
} from '@common/react-lib-cms-context'
import { makeStyles, OldTheme } from '@modules/core-ui'

import { Box } from './content-elements'

type NumberValues = {
  floatValue: number | undefined
  formattedValue: string
  value: string
}

export type TextFieldProps = Readonly<{
  name?: string
  value?: MuiTextFieldProps['value']
  disabled?: MuiTextFieldProps['disabled']
  defaultValue?: MuiTextFieldProps['defaultValue']
  label?: string // Narrowing type so content can come from CMS
  helperText?: MuiTextFieldProps['helperText']
  error?: MuiTextFieldProps['error']
  className?: MuiTextFieldProps['className']
  rootClassName?: MuiTextFieldProps['className']
  autoFocus?: MuiTextFieldProps['autoFocus']
  id?: MuiTextFieldProps['id']
  placeholder?: MuiTextFieldProps['placeholder']
  variant?: MuiOutlinedTextFieldProps['variant']
  size?: MuiTextFieldProps['size']
  onChange?: MuiTextFieldProps['onChange']
  onKeyDown?: MuiTextFieldProps['onKeyDown']
  onBlur?: MuiTextFieldProps['onBlur']
  onFocus?: MuiTextFieldProps['onFocus']
  inputBaseProps?: InputBaseProps
  InputLabelProps?: MuiTextFieldProps['InputLabelProps']
}>

const useLabelStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))

/**
 *
 * Base TextField component wrapping the Mui TextField.
 * Component to be exported/forwarded with Refs below
 * This is needed in order for the component to have a name
 *
 */
const TextFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    name,
    label,
    size = 'medium',
    variant = 'outlined',
    inputBaseProps,
    rootClassName,
    ...props
  },
  ref,
) => {
  const labelClasses = useLabelStyles(props)

  return (
    <Box className={rootClassName}>
      <MuiTextField
        inputRef={ref}
        InputProps={{
          notched: false,
          ...inputBaseProps,
        }}
        inputProps={{
          'aria-label': label,
        }}
        name={name}
        label={
          !!label && <Box className={cx(labelClasses.container)}>{label}</Box>
        }
        sx={(theme: OldTheme) => ({
          '& .MuiOutlinedInput-root': {
            '& label.Mui-focused': {
              color: theme.mrExtensions.palette.accentOne.main,
            },
            '&:focus-within': {
              borderColor: theme.mrExtensions.palette.accentOne.main,
            },
          },
        })}
        size={size}
        variant={variant}
        error={props.error}
        helperText={props.helperText}
        {...props}
      />
    </Box>
  )
}

export const TextField = React.forwardRef(TextFieldToForward)

/** Text field with mask */
export type TextMaskFieldProps = Readonly<
  Omit<TextFieldProps & InputMaskProps, 'size' | 'mask'> & {
    mask?: InputMaskProps['mask']
  }
>

const TextMaskFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  TextMaskFieldProps
> = (props, ref) => (
  <InputMask {...props} mask={props.mask} value={props.value ?? undefined}>
    {
      ((inputProps) => (
        <TextField {...inputProps} ref={ref} />
      )) as unknown as JSX.Element
    }
  </InputMask>
)

export const TextMaskField = React.forwardRef(TextMaskFieldToForward)

/*
 * Omit the MUI `size` and `value` props here, because they conflict with the
 * react-number-format properties of the same name with different types.
 */
export type NumberFormatInputProps = Readonly<
  Omit<TextFieldProps, 'size' | 'value' | 'defaultValue'> & {
    separator?: string
    format?: string
    value?: NumericFormatProps['value']
    mask?: string
    onValueChange?: (values: NumberValues) => void
  }
>

/**
 * Currency text field.
 */
const CurrencyFormatInputToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  NumberFormatInputProps
> = ({ separator = ',', ...props }, ref) => (
  <NumericFormat
    getInputRef={ref}
    customInput={TextField}
    thousandSeparator={separator}
    allowNegative={false}
    {...props}
  />
)

export const CurrencyFormatInput = React.forwardRef(
  CurrencyFormatInputToForward,
)

export type CurrencyTextFieldProps = Readonly<
  TextFieldProps & {
    onValueChange?: (values: NumberValues) => void
    thousandSeparator?: boolean
    prefix?: string
  } & NumberFormatInputProps
>

const CurrencyTextFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  CurrencyTextFieldProps
> = ({ ...props }, ref) => {
  const format = useCurrencyFormat()

  return (
    <CurrencyFormatInput
      inputBaseProps={{
        startAdornment: format.position === 'prefix' && (
          <InputAdornment position="start">{format.symbol}</InputAdornment>
        ),
      }}
      separator={format.separator}
      {...props}
      ref={ref}
    />
  )
}

export const CurrencyTextField = React.forwardRef(CurrencyTextFieldToForward)

/**
 *
 * Phone number text field.
 */
const PhoneNumberFormatInputToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  PatternFormatProps
> = ({ format = '', ...props }, ref) => (
  <PatternFormat
    getInputRef={ref}
    format={format}
    type="tel"
    mask="_"
    {...props}
  />
)

const PhoneNumberFormatInput = React.forwardRef(PhoneNumberFormatInputToForward)

export type PhoneNumberTextFieldProps = Readonly<
  NumberFormatInputProps & {
    onValueChange?: (values: NumberValues) => void
  }
>

export const PhoneNumberTextFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  PhoneNumberTextFieldProps
> = ({ name, rootClassName, ...props }, ref) => {
  const format = usePhoneNumberFormat()
  return (
    <Box className={rootClassName}>
      <PhoneNumberFormatInput
        name={`${name}-format`}
        format={format}
        error={props.error}
        helperText={props.helperText}
        {...props}
        ref={ref}
      />
    </Box>
  )
}

export const PhoneNumberTextField = React.forwardRef(
  PhoneNumberTextFieldToForward,
)

/**
 *
 * Date number text field.
 */
const DateFormatInputToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  NumberFormatInputProps
> = ({ format = '', ...props }, ref) => (
  <PatternFormat
    aria-label={props.label}
    getInputRef={ref}
    customInput={TextField}
    format={format}
    type="tel"
    mask="_"
    {...props}
  />
)

const DateFormatInput = React.forwardRef(DateFormatInputToForward)

export type DateTextFieldProps = Readonly<
  NumberFormatInputProps & {
    onValueChange?: (values: NumberValues) => void
  }
>

const DateTextFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  DateTextFieldProps
> = ({ name, label, rootClassName, ...props }, ref) => {
  const format = useDateFormat()

  return (
    <Box className={rootClassName}>
      <DateFormatInput
        ref={ref}
        name={`${name}-format`}
        label={label}
        format={format.numberFormat}
        error={props.error}
        helperText={props.helperText}
        {...props}
      />
    </Box>
  )
}

export const DateTextField = React.forwardRef(DateTextFieldToForward)

/**
 *
 * SSN number text field.
 */
const SSNFormatInputToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  NumberFormatInputProps
> = ({ format = '', ...props }, ref) => (
  <PatternFormat
    aria-label={props.label}
    getInputRef={ref}
    customInput={TextField}
    format={format}
    type="tel"
    mask="_"
    {...props}
  />
)

const SSNFormatInput = React.forwardRef(SSNFormatInputToForward)

export type SSNTextFieldProps = Readonly<
  NumberFormatInputProps & {
    onValueChange?: (values: NumberValues) => void
    ref: Ref<HTMLInputElement>
  }
>

const SSNTextFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  SSNTextFieldProps
> = ({ name, label, rootClassName, ...props }, ref) => {
  const format = useSSNFormat()

  return (
    <Box className={rootClassName}>
      <SSNFormatInput
        ref={ref}
        id={'ssn'}
        name={`${name}-format`}
        label={label}
        format={format.numberFormat}
        error={props.error}
        helperText={props.helperText}
        {...props}
      />
    </Box>
  )
}

export const SSNTextField = React.forwardRef(SSNTextFieldToForward)

/**
 *
 * General numeric text field input.
 */
const NumberFormatInputToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  NumberFormatInputProps
> = ({ ...props }, ref) => (
  <NumericFormat customInput={TextField} {...props} getInputRef={ref} />
)

export const NumberFormatInput = React.forwardRef(NumberFormatInputToForward)
