import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PartyDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M12.327 20.8716L8.40857 31.1069V31.1077C9.36744 34.8591 11.4751 38.1753 14.4586 40.6767L19.375 38.8713C14.153 34.291 11.5755 27.554 12.327 20.8716Z"
      fill="currentColor"
    />
    <path
      d="M7.08188 34.5717L4.07842 42.4141C3.71944 43.3508 4.65518 44.2773 5.61884 43.9226L11.9552 41.5962C9.82991 39.5762 8.18124 37.1688 7.08188 34.5717Z"
      fill="currentColor"
    />
    <path
      d="M23.0637 22.2052C23.2472 27.1775 25.4585 31.8765 29.1999 35.263H29.2007L32.6947 33.9803C33.5108 33.6803 33.7342 32.6546 33.1207 32.0538L23.0637 22.2052Z"
      fill="currentColor"
    />
    <path
      d="M31.8033 25.8866C32.2706 26.3442 33.0282 26.3442 33.4955 25.8866C37.9973 21.4781 42.3807 22.841 42.4245 22.8552C43.0514 23.0598 43.7291 22.7281 43.9381 22.1141C44.1472 21.5003 43.8083 20.8365 43.1813 20.6319C42.9453 20.5549 37.3394 18.808 31.8033 24.2294C31.336 24.6869 31.3359 25.429 31.8033 25.8866Z"
      fill="currentColor"
    />
    <path
      d="M26.7269 19.2579C26.2596 19.7156 26.2596 20.4575 26.7269 20.9151C27.19 21.3686 27.9423 21.3745 28.4121 20.922C29.0302 20.6204 30.9489 21.7518 32.0153 20.7079C33.0701 19.6749 31.9494 17.8341 32.2293 17.189C32.8889 16.9142 34.7679 18.0122 35.8228 16.9793C36.8778 15.9463 35.757 14.1055 36.037 13.4603C36.6976 13.1854 38.5755 14.2836 39.6303 13.2506C40.6854 12.2176 39.5646 10.3768 39.8445 9.73164C40.5012 9.45814 42.3839 10.5539 43.4381 9.52188C44.5039 8.47796 43.3508 6.59566 43.6566 5.99343C44.1169 5.53533 44.1144 4.79858 43.6495 4.34322C43.1822 3.88559 42.4245 3.88559 41.9572 4.34322C41.0141 5.26684 41.1986 6.57074 41.3762 7.50295C40.4243 7.32897 39.0929 7.14828 38.1497 8.07182C37.2066 8.99543 37.3911 10.2993 37.5688 11.2315C36.6168 11.0577 35.2852 10.8768 34.3422 11.8005C33.399 12.7241 33.5836 14.028 33.7613 14.9602C32.8092 14.7862 31.4778 14.6055 30.5347 15.5291C29.5915 16.4527 29.776 17.7566 29.9537 18.6888C29.0017 18.515 27.67 18.3343 26.7269 19.2579Z"
      fill="currentColor"
    />
    <path
      d="M33.496 9.31477C33.9633 8.85715 33.9633 8.11524 33.496 7.65761C33.0286 7.19999 32.271 7.19999 31.8037 7.65761C31.3364 8.11524 31.3364 8.85715 31.8037 9.31477C32.271 9.7724 33.0286 9.7724 33.496 9.31477Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M38.5724 27.5438C38.1051 28.0015 38.1051 28.7434 38.5724 29.201C39.0397 29.6586 39.7973 29.6586 40.2646 29.201C40.7319 28.7434 40.7319 28.0015 40.2646 27.5438C39.7974 27.0862 39.0397 27.0862 38.5724 27.5438Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8622 5.62959C27.9404 5.86083 29.7249 11.3511 24.1886 16.7719C23.7226 17.2283 22.9656 17.2318 22.4959 16.7719C22.0268 16.3111 22.0315 15.5697 22.4959 15.1149C26.9983 10.7066 25.6062 6.41392 25.5919 6.37095C25.3828 5.75693 25.7219 5.09369 26.3481 4.88901C26.9751 4.68434 27.6532 5.01557 27.8622 5.62959ZM16.2611 15.5431L20.7276 19.9171C20.264 26.0321 22.5285 31.9421 26.7153 36.1762L21.9481 37.9269C15.1154 32.6863 12.6397 23.5428 16.2611 15.5431ZM41.1111 17.9439C41.772 17.9439 42.3077 17.4193 42.3077 16.7721C42.3077 16.1249 41.772 15.6003 41.1111 15.6003C40.4503 15.6003 39.9145 16.1249 39.9145 16.7721C39.9145 17.4193 40.4503 17.9439 41.1111 17.9439ZM21.6498 9.31472C22.1172 9.77235 22.1172 10.5143 21.6498 10.9719C21.1826 11.4295 20.4249 11.4295 19.9576 10.9719C19.4903 10.5143 19.4903 9.77227 19.9576 9.31472C20.4249 8.85709 21.1825 8.85709 21.6498 9.31472Z"
      fill="currentColor"
    />
  </SvgIcon>
)
