import { styled } from '@mui/material'

import { Button } from '../../reskin'

export const ContinueButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 56,
  [theme.breakpoints.up('sm')]: {
    width: 264,
  },
}))

export const ContinueButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(6),
  },
}))
