import React, { FunctionComponent } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import cx from 'classnames'

import { H3, P } from '@common/react-lib-base'
import { CoborrowerInformationFormModule } from '@common/types'

import { ButtonFineText, makeNewStyles } from '../../reskin'

const useStyles = makeNewStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
    lineHeight: '56px',
    fontSize: '48px',
  },
  headingCosignerForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  coTitle: {
    fontSize: '18px',
    lineHeight: '32px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  coText: {
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    lineHeight: '36px',
    marginBottom: theme.spacing(0),
  },
  cosigner: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',

    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  boxMain: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
    },
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: theme.spacing(3),
    columnGap: '30px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  form2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: theme.spacing(3),
    columnGap: '30px',
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: theme.spacing(2),
    },
  },
  yearMonthRow: {
    gridTemplateColumns: '1fr 1fr !important',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      columnGap: theme.spacing(1),
    },
  },
}))

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1, 0),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

const TitleSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}))

const RadioSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}))

type Props = {
  module: CoborrowerInformationFormModule
  sameAddress: boolean
  vehicle: {
    make: string
    model: string
    year: number
  }
}

export const CoborrowerForm: FunctionComponent<Props> = ({
  sameAddress,
  vehicle: { make, model, year },
  module: {
    SameAddress,
    Submit,
    FirstName,
    LastName,
    Email,
    EmailExistError,
    ConfirmEmail,
    Cellphone,
    Birthdate,
    Citizenship,
    Relationship,
    Address,
    Apartment,
    City,
    State,
    Zip,
    MovedInYear,
    MovedInMonth,
    RentOrOwn,
    MonthlyPayment,
    EmploymentStatus,
    YearlyIncome,
  },
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <H3 className={cx(classes.heading, classes.headingCosignerForm)}>
        {make && model && year
          ? `We found a co-borrower on your loan for your ${year} ${make} ${model}`
          : 'We found a co-borrower on your loan'}
      </H3>

      <Section>
        <Box className={cx(classes.boxMain)}>
          <P className={cx(classes.coText)}>
            {make && model && year
              ? `Tell us about your co-borrower for your ${year} ${make} ${model}`
              : 'Tell us about your co-borrower'}
          </P>
          <P className={cx(classes.coTitle)}>
            {'This information is required'}
          </P>
          <Box className={cx(classes.form)}>
            <FirstName />

            <LastName />

            <Email />
            {EmailExistError && isMobileView && (
              <EmailExistError
                sx={{
                  gridColumn: '1 / -1',
                  display: { xs: 'inline', sm: 'none' },
                }}
              />
            )}

            <ConfirmEmail />

            {EmailExistError && !isMobileView && (
              <EmailExistError
                sx={{
                  gridColumn: '1 / -1',
                  display: { xs: 'none', sm: 'initial' },
                }}
              />
            )}

            <Birthdate />

            <Citizenship />

            <Cellphone />

            <Relationship />
          </Box>

          <>
            <Section>
              <P className={cx(classes.coText)}>
                {'Does your co-borrower have the same address as you?'}
              </P>
              <RadioSection>
                <SameAddress />
              </RadioSection>
            </Section>
            {!sameAddress && (
              <>
                <TitleSection>
                  <P className={cx(classes.coText)}>
                    {"What is your co-borrower's address?"}
                  </P>
                </TitleSection>
                <Box className={cx(classes.form)}>
                  <Address />

                  <Apartment />

                  <City />

                  <State />

                  <Zip />
                </Box>
              </>
            )}
            {!sameAddress ? (
              <>
                <Section>
                  <P className={cx(classes.coText)}>
                    {'How long have they lived at this current residence?'}
                  </P>
                </Section>
                <Box className={cx(classes.form2, classes.yearMonthRow)}>
                  <MovedInYear />

                  <MovedInMonth />
                </Box>
                <Box className={cx(classes.form2)}>
                  <RentOrOwn />

                  <MonthlyPayment />

                  <EmploymentStatus />

                  <YearlyIncome />
                </Box>
              </>
            ) : (
              <>
                <Section>
                  <P className={cx(classes.coText)}>
                    {`Your Co-Borrower’s Information`}
                  </P>
                </Section>
                <Box className={cx(classes.form2)}>
                  <EmploymentStatus />

                  <YearlyIncome />
                </Box>
              </>
            )}
          </>
        </Box>
      </Section>

      <Section>
        <ButtonFineText button={Submit} />
      </Section>
    </>
  )
}
