import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { NewResidenceAndEmploymentFormModule as ResidenceAndEmploymentFormModule } from '@common/types'
import {
  useDirectMailUserAddressInfo,
  useEffectIf,
  useLogger,
} from '@src/hooks'
import { safeWindow } from '@src/utils'

import { trackResidenceAndEmploymentFormSubmitted } from '../../../tracking'
import { ResidenceEmploymentForm } from '../../schemas'
import { genericErrorMessage } from '../../utils'

import { useResidenceForm } from './form'
import { useResidenceAndEmploymentFormModule } from './module'
import { useDirectMailCodePrefillResidenceAndEmploymentInfo } from './prefill'

type FormModel = ResidenceEmploymentForm

type UseResidentialAndEmploymentForm = {
  coborrowerHasSameAddress: boolean
  module: ResidenceAndEmploymentFormModule
}

export const useResidentialAndEmploymentForm = (
  hasCoborrower: boolean,
): UseResidentialAndEmploymentForm => {
  const { form, submitForm } = useResidenceForm(hasCoborrower)
  const logger = useLogger()

  const { setValue, watch } = form
  const { userInfo, userInfoLoaded } = useDirectMailUserAddressInfo()
  useDirectMailCodePrefillResidenceAndEmploymentInfo(setValue, {
    userInfo,
    userInfoLoaded,
  })

  // Default values aren't tracked
  useEffectIf(hasCoborrower, () => {
    setValue('hasCoborrower', hasCoborrower, { shouldValidate: true })
    setValue('coborrowerResidence', hasCoborrower, {
      shouldValidate: true,
    })
  })

  const router = useRouter()

  const [submitError, setSubmitError] = useState('')
  const nextURL = `/review-info`
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(nextURL)
    }
    prefetch().catch(console.error)
  }, [])
  const submit = async (formValues: FormModel): Promise<void> => {
    // formValues is a LIE. It is not of type FormModel because FormModel says
    // income and livedInAddress are numbers but they are actually strings!
    try {
      // trigger segment event using the values
      void trackResidenceAndEmploymentFormSubmitted(formValues)
      setSubmitError('')
      await submitForm(formValues)
      void router.push(nextURL)
    } catch (error) {
      setSubmitError(genericErrorMessage)
      logger.error('Error submitting residence and employment form: ', {
        error,
      })
    }
  }

  useEffectIf(!!submitError, () => {
    safeWindow.scroll({ top: 0, behavior: 'smooth' })
  })

  const mod = useResidenceAndEmploymentFormModule({
    form,
    submit,
    submitError,
  })

  return {
    module: mod,
    coborrowerHasSameAddress: watch('coborrowerResidence') ?? true,
  }
}
