/**
 * @TODO: Needs to decide on where this page props should be stored
 * This setup is intermittent since we are in phase or removing CMS and upgrading to newer design.
 * One possible solution could be of having it at different libs at all or
 * Just duplicate the type at cms lib types. However these are not show stoppers
 * hence keeping it for now and we'll revisit soon on this.
 */
import React, { ChangeEvent, FC, MouseEventHandler, ReactNode } from 'react'
import { Props as InputMaskProps } from 'react-input-mask'
import { NumberFormatValues, NumericFormatProps } from 'react-number-format'
import { DialogProps as MuiDialogProps, InputBaseProps } from '@mui/material'

import { OmitSafe } from '@common/lib-types'
import { TextFieldProps as LegacyTextFieldProps } from '@common/react-lib-base'
import { MaterialProps } from '@lib/mui'

import { AddonProduct } from './api/offers'

export type SelectFieldProps = MaterialProps &
  OmitSafe<React.HTMLProps<HTMLSelectElement>, 'as'> & {
    menuItems: ReadonlyArray<{ displayName: string; value: string }>
    error?: string
    label?: string
    disabled?: boolean
    className?: string
    ref?: React.LegacyRef<HTMLSelectElement>
    /**
     * @deprecated Use styled or sx props
     */
    rootClassName?: string
    loading?: boolean
    endIcon?: JSX.Element
    variant?: string
    'data-testid'?: string
  }

type OmitedInputBaseProps = OmitSafe<InputBaseProps, 'error'>
export type TextFieldProps = OmitedInputBaseProps & {
  error?: string | boolean
  label?: string
  placeholder?: string
  value?: string
  // onChange?: React.HTMLProps<HTMLInputElement>['onChange']
  loading?: boolean
  endIcon?: JSX.Element
  hideErrorIcon?: boolean
  'data-testid'?: string
}

/*
 * Omit the MUI `size` and `value` props here, because they conflict with the
 * react-number-format properties of the same name with different types.
 */
export type NumberFormatInputProps = Readonly<
  Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'>
> & {
  separator?: string
  format?: string
  value?: NumericFormatProps['value']
  onValueChange?: (values: NumberFormatValues) => void
  mask?: string
  prefix?: string
  thousandSeparator?: boolean
}

export type PatternFormatInputProps = Readonly<
  Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'>
> & {
  separator?: string
  format: string
  value?: NumericFormatProps['value']
  onValueChange?: (values: NumberFormatValues) => void
  mask?: string
  prefix?: string
  thousandSeparator?: boolean
}

export type CurrencyTextFieldProps = Readonly<
  Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'> & {
    onValueChange?: (values: NumberFormatValues) => void
  }
>

export type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'text' | 'card'
export type IconPosition = 'start' | 'middle' | 'end'
export type ButtonProps = MaterialProps & {
  children?: ReactNode
  icon?: ReactNode
  iconPosition?: IconPosition
  loading?: boolean
  selected?: boolean
  disabled?: boolean
  type?: ButtonTypes
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  'data-testid'?: string
}

export type ButtonFineTextProps = {
  button: FC<ButtonProps>
  fineText?: string
  className?: string
}

export type ContinueButtonProps = {
  button: FC<ButtonProps>
  className?: string
}

export type DialogProps = Pick<
  MuiDialogProps,
  | 'className'
  | 'open'
  | 'disableEscapeKeyDown'
  | 'onClose'
  | 'children'
  | 'maxWidth'
> & {
  headerContent?: MuiDialogProps['children']
}

export type ConfirmationDialogProps = Omit<
  DialogProps,
  'footerContent' | 'content' | 'headerContent'
> & {
  title?: string
  content: string
  submitButton?: {
    text: string
    onClick: ButtonProps['onClick']
  }
  cancelButton?: {
    text: string
    onClick: ButtonProps['onClick']
  }
}

type NumberTextFieldProps = Readonly<
  NumberFormatInputProps & {
    onValueChange?: (values: NumberFormatValues) => void
  }
>

export type PhoneNumberTextFieldProps = NumberTextFieldProps

export type DateTextFieldProps = NumberTextFieldProps

export type SSNTextFieldProps = NumberTextFieldProps

export type TextMaskFieldProps = Readonly<
  Omit<
    TextFieldProps & InputMaskProps,
    'size' | 'mask' | 'ref' | 'defaultValue' | 'type'
  > & {
    mask?: InputMaskProps['mask']
  }
>

type NotificationType = 'warning' | 'error' | 'success'
export type NotificationBaseProps = MaterialProps & {
  type: NotificationType
  title?: string
  body?: ReactNode
  className?: string
  onClose?: () => void
}

export type NotificationProps = NotificationBaseProps & {
  scrollIntoView?: boolean
  shake?: boolean
}

export type SelectablePanel = {
  active?: boolean
  children: ReactNode
  className?: string
  name?: string
  onClick?: (name: string) => void
  tag?: string
}

export type SSNHashedFieldProps = LegacyTextFieldProps & {
  onValueChange?: (values: { value: string; unformatted: string }) => void
}

export type SelectedLoanInfoProps = {
  make: string
  model: string
  trim: string
  year: string | number
  amountFinanced: string
  combinedAgreementApr: string
  term: string
  monthlyPayment: number
  monthlySavings: number
  totalSavings?: number
  selectedProducts: ReadonlyArray<AddonProduct>
}

type ValueType = string | number | boolean
export type CheckboxProps<T extends ValueType = ValueType> = {
  name?: string
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  error?: string
  value?: T
  label?: string
  'data-testid'?: string
}

export type ErrorMessageProps =
  | ({
      phoneNumber: string
    } & MaterialProps)
  | ({ customMessage: React.ReactNode } & MaterialProps)

export type SelectButtonProps = MaterialProps & {
  selected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  inactiveLabel?: string
  activeLabel?: string
}
