import {
  CitizenshipKeys,
  EmploymentStatusKeys,
  ERelationship,
  ResidenceStatusKeys,
  StateKeys,
} from '@common/types'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

type CoborrowerSubmissionInfo = {
  relationship: ERelationship
  firstName: string
  lastName: string
  phoneNumber: string
  address: string
  apartment: string
  city: string
  state: StateKeys
  zip: string
  citizenship: CitizenshipKeys
  dob: string
  emailAddress: string
  employmentStatus: EmploymentStatusKeys
  livedInAddress: {
    years: number
    months: number
  }
  sameAddress: boolean
  yearlyIncome: number
  monthlyPayment: number
  rentOrOwn: ResidenceStatusKeys
}

type SubmitCoborrower = (coborrower: CoborrowerSubmissionInfo) => Promise<void>

export const submitCoborrower: SubmitCoborrower = async (coborrower) => {
  const body = JSON.stringify({ ...coborrower, requestId: getRequestId() })
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-coborrower${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body,
    },
  )

  if (!response.ok) {
    const text = await response.text()
    const resText = JSON.parse(text) as
      | { error: string }
      | { errors: Array<string> }

    if ('error' in resText || 'errors' in resText) {
      throw new Error(
        `${response.status}: ${
          'error' in resText ? resText.error : resText.errors[0]
        }`,
      )
    }
    throw new Error(`Unknown error: ${text}`)
  }
}
