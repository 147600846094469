import parseInt from 'lodash/fp/parseInt'
import * as vinValidator from 'vin-validator'
import { TestConfig } from 'yup'

import { parseInteger } from '@src/utils'

const getDefaultValue = (val: number, defaultValue = 0): number =>
  !Number.isNaN(val) ? val : defaultValue

const toNumber = (val: string): number =>
  parseInt(10, (val ?? '').replaceAll(/[^\d.?-]/g, ''))

export const noDecimal = (message?: string): TestConfig<string> => ({
  name: 'no-decimal',
  message: message ?? `This value can't have decimals.`,
  test: (val) => Number.isInteger(Number.parseFloat(val)),
})

export const getMinTest = (
  min: number,
  message?: string,
): TestConfig<string> => ({
  name: 'min',
  message: message ?? `This value can't be less than ${min}`,
  test: (val) => toNumber(val) >= min,
})

export const notLessThanAMonth = (message?: string): TestConfig<string> => ({
  name: 'no-lt-a-month',
  message: message ?? `Time at residence can't be less than a month`,
  test: (val, context) =>
    getDefaultValue(toNumber(context.parent.years)) > 0 ||
    getDefaultValue(toNumber(val)) > 0,
})

export const notMoreThanMaxYrs = (
  maxYrs: number,
  message?: string,
): TestConfig<string> => ({
  name: `no-gt-${maxYrs}-yrs`,
  message: message ?? `Time at residence can't be greater than ${maxYrs} years`,
  test: (val, context) =>
    getDefaultValue(toNumber(context.parent.years)) * 12 +
      getDefaultValue(toNumber(context.parent.months)) <=
    12 * maxYrs,
})

export const notMoreThan120Yrs = (message?: string): TestConfig<string> =>
  notMoreThanMaxYrs(120, message)

export const getMaxTest = (
  max: number,
  message?: string,
): TestConfig<string> => ({
  name: 'max',
  message: message ?? `This value can't be greater than ${max}`,
  test: (val) => toNumber(val) <= max,
})

export const validVinTest = (message?: string): TestConfig<string> => ({
  name: 'valid-vin',
  message:
    message ??
    "The vin you entered isn't valid. Please check that you entered it correctly",
  test: (val) => vinValidator.validate(val ?? '') as boolean,
})

export const positiveCurrency = (message?: string): TestConfig<string> => ({
  name: 'currency',
  message: message ?? 'This value should be more than 0',
  test: (val) => parseInteger(val || 0) > 0,
})

export const positiveCurrencyIfPresent = (
  message?: string,
): TestConfig<string> => ({
  name: 'currency',
  message: message ?? 'This value should be more than 0',
  test: (val) => {
    if (val === 'null' || val === '') {
      return true
    }

    return parseInteger(val || 0) > 0
  },
})

export const positiveOrZeroNumber = (
  message?: string,
): TestConfig<string | number> => ({
  name: 'positive-number',
  message: message ?? 'This value should be more or equal to 0',
  test: (val) => parseInteger(val || 0) >= 0,
})

export const zipCode = (message?: string): TestConfig<string> => ({
  name: 'zip',
  message: message ?? 'Please enter a valid zip code',
  test: (val) => (val ?? '').replace('_', '').length === 5,
})

export enum EErrorType {
  emailExists = 'emailExists',
  accountCreated = 'accountCreated',
}
