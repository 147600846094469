import React, { FC, useCallback } from 'react'
import { styled } from '@mui/material'
import isString from 'lodash/isString'

import { Format } from '../../format'
import { Text } from '../../reskin'

import { SelectButton as BaseButton } from './select-button'

type TFormatFunc = (num: number) => string

const SelectButton = styled(BaseButton)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const details: ReadonlyArray<[string, string, string | TFormatFunc]> = [
  ['originalTotal', 'Original total', 'currencyWithCents'],
  ['currentLoanBalance', 'Current Loan Balance', 'currencyWithCents'],
  ['term', 'Term', (term) => `${term} months`],
  ['rate', 'APR', (rate) => `${rate}%`],
  ['monthlyPayment', 'Monthly payment', 'newCurrency'],
]

const DetailLine: FC<{ label: string; value: string }> = ({
  label,
  value,
  ...props
}) => {
  return (
    <div {...props}>
      <Text use={['div', { xs: 'subheadings.16' }]} color="text.softBlack">
        {label}
      </Text>
      <Text
        use={['div', { xs: 'subheadings.16m', md: 'subheadings.18m' }]}
        color="text.softBlack"
      >
        {value}
      </Text>
    </div>
  )
}

const Detail = styled(DetailLine)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up('sm')]: {
    flexFlow: 'column',
  },
}))

const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  margin: theme.spacing(2, 0),
  [theme.breakpoints.up('sm')]: {
    flexFlow: 'row',
  },
}))

type Props = {
  selected?: number
  tradelineId: number
  handleSelect: (id: number) => void
}

export const CardBody: FC<Props> = (props) => {
  const { selected, tradelineId, handleSelect } = props
  const onClick = useCallback(() => {
    handleSelect(tradelineId)
  }, [handleSelect, tradelineId])
  return (
    <>
      <Details>
        {details.map(([key, label, format]) => {
          const formatter = isString(format) ? Format[format] : format
          return (
            <Detail key={key} label={label} value={formatter(props[key])} />
          )
        })}
      </Details>
      <SelectButton onClick={onClick} selected={selected === tradelineId} />
    </>
  )
}
