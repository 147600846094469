import React from 'react'

const originalWidth = 156
const originalHeight = 48
const aspectRatio = originalWidth / originalHeight

export const Logo = ({
  height = originalHeight,
}: {
  height?: number
}): JSX.Element => {
  const width = aspectRatio * height
  return (
    <svg
      {...{ width, height }}
      viewBox="0 0 156 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.0919 44C44.6119 44 41.7266 42.854 39.436 40.562C37.1453 38.2479 36 35.3939 36 32C36 28.6061 37.1453 25.7631 39.436 23.4711C41.7266 21.157 44.6119 20 48.0919 20C50.1843 20 52.1115 20.4959 53.8736 21.4876C55.6576 22.4793 57.0452 23.8237 58.0364 25.5207L54.7326 27.438C54.1158 26.292 53.2128 25.3884 52.0234 24.7273C50.8561 24.0441 49.5456 23.7025 48.0919 23.7025C45.6251 23.7025 43.6208 24.4848 42.079 26.0496C40.5592 27.6143 39.7994 29.5978 39.7994 32C39.7994 34.4022 40.5592 36.3857 42.079 37.9504C43.6208 39.5152 45.6251 40.2975 48.0919 40.2975C49.5456 40.2975 50.8671 39.9669 52.0565 39.3058C53.2458 38.6226 54.1379 37.708 54.7326 36.562L58.0364 38.4463C57.0672 40.1433 55.6907 41.4986 53.9066 42.5124C52.1446 43.5041 50.2063 44 48.0919 44Z"
        fill="white"
      />
      <path
        d="M74.0123 27.0413H77.5804V43.5702H74.0123V41.1901C72.6688 43.0634 70.7415 44 68.2306 44C65.962 44 64.0238 43.1625 62.416 41.4876C60.8081 39.7906 60.0042 37.73 60.0042 35.3058C60.0042 32.8595 60.8081 30.7989 62.416 29.124C64.0238 27.449 65.962 26.6116 68.2306 26.6116C70.7415 26.6116 72.6688 27.5372 74.0123 29.3884V27.0413ZM65.059 39.1074C66.0501 40.0992 67.2946 40.595 68.7923 40.595C70.29 40.595 71.5344 40.0992 72.5256 39.1074C73.5167 38.0937 74.0123 36.8264 74.0123 35.3058C74.0123 33.7851 73.5167 32.5289 72.5256 31.5372C71.5344 30.5234 70.29 30.0165 68.7923 30.0165C67.2946 30.0165 66.0501 30.5234 65.059 31.5372C64.0679 32.5289 63.5723 33.7851 63.5723 35.3058C63.5723 36.8264 64.0679 38.0937 65.059 39.1074Z"
        fill="white"
      />
      <path
        d="M85.3074 29.8182C86.2105 27.7686 87.9064 26.7438 90.3953 26.7438V30.6116C89.0297 30.5234 87.8403 30.854 86.8272 31.6033C85.814 32.3306 85.3074 33.5427 85.3074 35.2397V43.5702H81.7393V27.0413H85.3074V29.8182Z"
        fill="white"
      />
      <path
        d="M96.4015 23.9008C95.961 24.3416 95.4324 24.562 94.8157 24.562C94.1989 24.562 93.6593 24.3416 93.1968 23.9008C92.7563 23.438 92.536 22.8981 92.536 22.281C92.536 21.6639 92.7563 21.135 93.1968 20.6942C93.6373 20.2314 94.1769 20 94.8157 20C95.4544 20 95.994 20.2314 96.4345 20.6942C96.875 21.135 97.0953 21.6639 97.0953 22.281C97.0953 22.8981 96.864 23.438 96.4015 23.9008ZM93.0316 43.5702V27.0413H96.5997V43.5702H93.0316Z"
        fill="white"
      />
      <path
        d="M110.092 26.6116C112.361 26.6116 114.299 27.449 115.907 29.124C117.515 30.7989 118.319 32.8595 118.319 35.3058C118.319 37.73 117.515 39.7906 115.907 41.4876C114.299 43.1625 112.361 44 110.092 44C107.581 44 105.654 43.0634 104.311 41.1901V43.5702H100.743V20.4298H104.311V29.3884C105.654 27.5372 107.581 26.6116 110.092 26.6116ZM105.797 39.1074C106.789 40.0992 108.033 40.595 109.531 40.595C111.028 40.595 112.273 40.0992 113.264 39.1074C114.255 38.0937 114.751 36.8264 114.751 35.3058C114.751 33.7851 114.255 32.5289 113.264 31.5372C112.273 30.5234 111.028 30.0165 109.531 30.0165C108.033 30.0165 106.789 30.5234 105.797 31.5372C104.806 32.5289 104.311 33.7851 104.311 35.3058C104.311 36.8264 104.806 38.0937 105.797 39.1074Z"
        fill="white"
      />
      <path
        d="M129.35 44C126.927 44 124.868 43.1625 123.172 41.4876C121.476 39.8127 120.628 37.7521 120.628 35.3058C120.628 32.8595 121.476 30.7989 123.172 29.124C124.868 27.449 126.927 26.6116 129.35 26.6116C131.794 26.6116 133.854 27.449 135.528 29.124C137.224 30.7989 138.072 32.8595 138.072 35.3058C138.072 37.7521 137.224 39.8127 135.528 41.4876C133.854 43.1625 131.794 44 129.35 44ZM125.682 39.0413C126.674 40.0331 127.896 40.5289 129.35 40.5289C130.803 40.5289 132.026 40.0331 133.017 39.0413C134.008 38.0496 134.504 36.8044 134.504 35.3058C134.504 33.8072 134.008 32.562 133.017 31.5702C132.026 30.5785 130.803 30.0826 129.35 30.0826C127.896 30.0826 126.674 30.5785 125.682 31.5702C124.691 32.562 124.196 33.8072 124.196 35.3058C124.196 36.8044 124.691 38.0496 125.682 39.0413Z"
        fill="white"
      />
      <path
        d="M152.432 27.0413H156V43.5702H152.432V41.4545C151.353 43.1515 149.646 44 147.311 44C145.417 44 143.886 43.3939 142.719 42.1818C141.551 40.9697 140.968 39.3058 140.968 37.1901V27.0413H144.536V36.8264C144.536 38.0606 144.866 39.0083 145.527 39.6694C146.188 40.3306 147.091 40.6612 148.236 40.6612C149.492 40.6612 150.505 40.2755 151.276 39.5041C152.046 38.7107 152.432 37.5096 152.432 35.9008V27.0413Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8294 5.90623C13.0589 9.43091 12.9645 13.4795 14.5225 16.9328C12.7284 14.6346 11.0878 12.384 10.5212 9.39519C10.0373 6.88266 9.91926 3.89382 10.8163 0C7.20452 6.25155 7.38157 11.61 10.3914 15.9087C13.4484 20.2669 18.3113 23.1248 24.8031 26.0779C24.4861 25.7755 24.1215 25.4529 23.7216 25.099C21.9327 23.5162 19.439 21.3097 17.3553 17.5043C15.6438 14.3726 15.0301 10.8956 14.8294 5.90623ZM14.0015 22.0889L14.0019 22.0887C8.81919 19.2347 6.60906 16.5777 5.37451 15.0935C5.32135 15.0296 5.27 14.9679 5.2203 14.9084C3.7449 21.4814 8.08848 22.9223 10.9567 23.1128H10.9768L10.9765 23.1129H10.9562C6.87224 24.8752 4.31094 26.9591 2.30439 30.3051C-0.752638 35.3778 -0.10346 39.6765 0.533913 41.8913C1.15948 44.0466 3.08341 47.0474 4.27553 48C0.722765 39.7956 2.10374 34.9015 4.29914 31.5554C6.22306 28.6142 8.47748 26.6971 12.3607 25.0896C12.762 24.9228 13.1988 24.7561 13.6709 24.5775C13.7366 24.6107 13.8074 24.6438 13.8834 24.6794C13.971 24.7204 14.0654 24.7646 14.1666 24.8157C17.082 26.1851 20.4695 27.614 22.9482 28.65L23.0663 28.6976L22.1338 29.6621C21.8977 29.9122 21.5554 30.0193 21.225 29.9598C19.9502 29.7336 18.2623 29.6859 17.4479 29.6859C9.82304 29.6859 5.9988 32.6033 4.8775 36.8663C4.1457 39.6527 5.11356 42.5582 6.70699 44.4872C8.70174 46.9045 11.9594 47.9881 15.0755 47.9881H21.1069C18.6164 46.7021 16.2322 45.1541 14.7922 43.5227C13.2342 41.7604 11.9948 39.3312 12.4434 36.6162C12.8211 34.2823 14.5089 32.2223 17.1646 32.0556H17.4597C17.9673 32.0556 18.4866 32.0794 18.9823 32.1151H19.0178C20.021 32.1985 21.0243 32.3652 21.9213 32.6033C22.842 32.8415 23.8217 32.5676 24.4826 31.877L28 28.2332L27.4098 28.0189C27.4 28.0139 27.2469 27.9497 26.9781 27.8369C25.3041 27.1346 19.143 24.5498 15.1463 22.6723C14.7568 22.4937 14.3674 22.2913 14.0015 22.0889Z"
        fill="white"
      />
    </svg>
  )
}
