import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const GoogleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M22.0002 12.222C22.0002 11.3998 21.9321 10.7997 21.7848 10.1775H12.2042V13.8887H17.8278C17.7145 14.811 17.1022 16.2 15.7416 17.1333L15.7226 17.2576L18.7518 19.5574L18.9616 19.5779C20.889 17.8334 22.0002 15.2666 22.0002 12.222Z"
      fill="#4285F4"
    />
    <path
      d="M12.2051 22.0009C14.9601 22.0009 17.273 21.1119 18.9624 19.5786L15.7424 17.1339C14.8808 17.7228 13.7243 18.134 12.2051 18.134C9.50665 18.134 7.21641 16.3895 6.4 13.9782L6.28034 13.9882L3.13055 16.3772L3.08936 16.4894C4.76735 19.7563 8.21408 22.0009 12.2051 22.0009Z"
      fill="#34A853"
    />
    <path
      d="M6.39907 13.9782C6.18366 13.356 6.05899 12.6892 6.05899 12.0003C6.05899 11.3114 6.18366 10.6447 6.38774 10.0224L6.38203 9.88992L3.19276 7.46252L3.08842 7.51117C2.39683 8.86681 2 10.3891 2 12.0003C2 13.6115 2.39683 15.1338 3.08842 16.4894L6.39907 13.9782Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2051 5.86682C14.1212 5.86682 15.4137 6.67797 16.1507 7.35582L19.0305 4.60012C17.2619 2.98894 14.9602 2 12.2051 2C8.21411 2 4.76736 4.24453 3.08936 7.51133L6.38869 10.0226C7.21644 7.61137 9.50669 5.86682 12.2051 5.86682Z"
      fill="#EB4335"
    />
  </SvgIcon>
)
