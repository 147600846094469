import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderCheckIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7H13C12.0557 7 11.1666 6.55542 10.6 5.8L9.55 4.4C9.36115 4.14819 9.06476 4 8.75 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.75C9.69427 2 10.5834 2.44458 11.15 3.2L12.2 4.6C12.3889 4.85181 12.6852 5 13 5H18C20.2091 5 22 6.79086 22 9V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.684 11.2705C16.0869 11.6482 16.1073 12.281 15.7296 12.6839L11.9796 16.6839C11.6213 17.0661 11.0289 17.107 10.6215 16.7778L8.37151 14.9596C7.94195 14.6125 7.87512 13.9829 8.22224 13.5533C8.56936 13.1237 9.19899 13.0569 9.62855 13.404L11.1559 14.6383L14.2705 11.3161C14.6482 10.9131 15.2811 10.8927 15.684 11.2705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
