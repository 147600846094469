import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MoreHorizontalRectangleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99995 13.3C8.71792 13.3 9.29995 12.718 9.29995 12C9.29995 11.282 8.71792 10.7 7.99995 10.7C7.28198 10.7 6.69995 11.282 6.69995 12C6.69995 12.718 7.28198 13.3 7.99995 13.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13.3C16.7179 13.3 17.3 12.718 17.3 12C17.3 11.282 16.7179 10.7 16 10.7C15.282 10.7 14.7 11.282 14.7 12C14.7 12.718 15.282 13.3 16 13.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13.3C12.7179 13.3 13.3 12.718 13.3 12C13.3 11.282 12.7179 10.7 12 10.7C11.282 10.7 10.7 11.282 10.7 12C10.7 12.718 11.282 13.3 12 13.3Z"
      fill="currentColor"
    />
  </SvgIcon>
)
