import {
  DirectMailUserAddressInfo,
  DirectMailUserNameInfo,
} from '@common/types/api/direct-mail'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

type GetCustomerEmailExistsResponse = {
  emailExists: boolean
  accountCreated: boolean
  createdAt: string
}

export const getCustomerEmailExists = async ({
  email,
}: {
  email: string
}): Promise<GetCustomerEmailExistsResponse> => {
  const response = await fetch(
    `${refiApiUrl}/services/v2/customer/email_exists?email=${encodeURIComponent(
      email,
    )}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  return response.json() as unknown as GetCustomerEmailExistsResponse
}

type SendReturningCustomerEmailResponse = {
  response?: string
  error?: string
}

export const sendReturningCustomerEmail = async ({
  email,
}: {
  email: string
}): Promise<SendReturningCustomerEmailResponse> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/customer/send_returning_customer_email`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  )

  return response.json() as unknown as SendReturningCustomerEmailResponse
}

export const getResumeUrlByEmail = async ({
  email,
}: {
  email: string
}): Promise<{ resumeUrl: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/email-resume-url?email=${encodeURIComponent(
      email,
    )}`,
    {
      method: 'GET',
    },
  )

  return response.json() as unknown as { resumeUrl: string }
}

/*
 * Direct Mail API Calls
 */

// Prefill First & Last name from Direct Mail Code
export const getCustomerNameByDirectMailCode = async ({
  code,
  zip,
}: {
  code: string
  zip: string
}): Promise<DirectMailUserNameInfo> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/direct-mail/recipient/name?code=${code}&zip=${zip}`,
  )
  return response.json()
}

// Prefill Residential Address from Direct Mail Code
export const getCustomerAddressByDirectMailCode = async ({
  code,
  zip,
}: {
  code: string
  zip: string
}): Promise<DirectMailUserAddressInfo> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/direct-mail/recipient/address?code=${code}&zip=${zip}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  return response.json()
}

// Tracks when a customer has lookup a Direct Mail Code.
export const saveDirectMailLookupCode = async (
  code: string,
  zip: string,
): Promise<{ id: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/direct-mail/lookup`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify({ code, zip }),
    },
  )
  return response.json()
}

// Converts Customer from a Direct Mail Recipient to a Customer
export const convertDirectMailRecipientToCustomer = async ({
  code,
  zip,
}: {
  code: string
  zip: string
}): Promise<{ status: string }> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/direct-mail/${getRequestId()}/convert${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify({ code, zip }),
    },
  )

  return response.json() as unknown as { status: string }
}
