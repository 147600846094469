import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderDeleteIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7H13C12.0557 7 11.1666 6.55542 10.6 5.8L9.55 4.4C9.36115 4.14819 9.06476 4 8.75 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.75C9.69427 2 10.5834 2.44458 11.15 3.2L12.2 4.6C12.3889 4.85181 12.6852 5 13 5H18C20.2091 5 22 6.79086 22 9V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.79289 11.2929C9.18342 10.9024 9.81658 10.9024 10.2071 11.2929L12 13.0858L13.7929 11.2929C14.1834 10.9024 14.8166 10.9024 15.2071 11.2929C15.5976 11.6834 15.5976 12.3166 15.2071 12.7071L13.4142 14.5L15.2071 16.2929C15.5976 16.6834 15.5976 17.3166 15.2071 17.7071C14.8166 18.0976 14.1834 18.0976 13.7929 17.7071L12 15.9142L10.2071 17.7071C9.81658 18.0976 9.18342 18.0976 8.79289 17.7071C8.40237 17.3166 8.40237 16.6834 8.79289 16.2929L10.5858 14.5L8.79289 12.7071C8.40237 12.3166 8.40237 11.6834 8.79289 11.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
