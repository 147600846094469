import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const TrashV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H5C4.44772 6 4 5.55228 4 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77778 3.33333C8.77778 2.59695 9.37473 2 10.1111 2L13.8889 2C14.6253 2 15.2222 2.59695 15.2222 3.33333C15.2222 3.70152 15.5207 4 15.8889 4H16V6H15.8889C14.6463 6 13.6023 5.15015 13.3062 4L10.6938 4C10.3977 5.15015 9.35367 6 8.11111 6H8V4L8.11111 4C8.4793 4 8.77778 3.70152 8.77778 3.33333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.91701 8.00345C6.46739 7.95758 6.95074 8.36657 6.9966 8.91695L7.76736 18.1661C7.85374 19.2027 8.72028 20 9.76045 20H14.2397C15.2798 20 16.1464 19.2027 16.2327 18.1661L17.0035 8.91695C17.0494 8.36657 17.5327 7.95758 18.0831 8.00345C18.6335 8.04931 19.0425 8.53266 18.9966 9.08304L18.2258 18.3322C18.0531 20.4053 16.32 22 14.2397 22H9.76045C7.6801 22 5.94704 20.4053 5.77427 18.3322L5.00351 9.08304C4.95765 8.53266 5.36663 8.04931 5.91701 8.00345Z"
      fill="currentColor"
    />
  </SvgIcon>
)
