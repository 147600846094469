/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useState } from 'react'
import { UnpackNestedValue, useController, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import {
  Button,
  NumberFormatInput,
  PageLevelError,
} from '@common/react-lib-consumer-pres'
import {
  NewVehicleInformationFormModule,
  PageRoute,
  SubmittedVehicle,
  VehicleFindBy,
} from '@common/types'

import { residenceAndEmploymentAdapters, vehicleAdapters } from '../../../api'
import { useResolver } from '../../../hooks'
import {
  trackEstimatedMonthlyPayment,
  trackVehicleInfoFormSubmitted,
} from '../../../tracking'
import { parseInteger } from '../../../utils'
import { VehicleFormValues, VehicleInfo } from '../../schemas'
import { Element, useModule } from '../../utils'
import { CurrencyInput, RadioGroup } from '../controlled-fields'

import { useLicensePlate } from './use-license-plate'
import { useMakeModel } from './use-make-model'
import { useVin } from './use-vin'

const mapValuesToSubmit = (
  values: UnpackNestedValue<VehicleFormValues>,
  getNadaUid: (trim: string) => string,
): SubmittedVehicle => {
  return {
    estimatedMonthlyPayment: parseInteger(values.estimatedMonthlyPayment),
    estimatedMileage: parseInteger(values.estimatedMileage),
    loanValue: parseInteger(values.loanValue),
    ...(values.findBy === VehicleFindBy.VIN
      ? {
          vin: values.dynamic.vin,
        }
      : values.findBy === VehicleFindBy.LICENSEPLATE
      ? {
          licensePlateNumber: values.dynamic.licensePlateNumber,
          licensePlateState: values.dynamic.state,
        }
      : {
          year: Number(values.dynamic.year),
          make: values.dynamic.make,
          model: values.dynamic.model,
          trim: values.dynamic.trim,
          nadaUid: getNadaUid(values.dynamic.trim),
        }),
  }
}

export const useVehicleForm = (): {
  module: NewVehicleInformationFormModule
} => {
  const form = useForm<VehicleFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      findBy: VehicleFindBy.VIN,
      loanValue: '',
      estimatedMonthlyPayment: '',
      dynamic: {
        year: '',
        make: '',
        model: '',
        trim: '',
        vin: '',
        licensePlateNumber: '',
        state: '',
      } as VehicleFormValues['dynamic'],
    } as VehicleFormValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: useResolver(VehicleInfo as any),
  })

  const {
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    control,
    handleSubmit,
  } = form

  const findBy = watch('findBy')
  const makeModel = useMakeModel(form)
  const vin = useVin(form)
  const licensePlate = useLicensePlate(form)

  const router = useRouter()
  const { query } = router
  const [showSubmitError, setShowSubmitError] = useState(false)
  const hideSubmitError = useCallback(() => setShowSubmitError(false), [])

  const estimatedMileage = useController({
    control,
    name: 'estimatedMileage',
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.ResidenceAndEmployment)
    }
    prefetch().catch(console.error)
  }, [])
  const onSubmit = handleSubmit(async (values): Promise<void> => {
    try {
      const data = mapValuesToSubmit(values, makeModel.getNadaUid)
      setShowSubmitError(false)

      await vehicleAdapters.submitVehicle(data)

      void trackVehicleInfoFormSubmitted({ formName: values.findBy })

      const nextPagePathname =
        (await residenceAndEmploymentAdapters.residenceAndEmploymentExists())
          ? `/review-info`
          : `/residence-and-employment`
      void router.push({
        pathname: nextPagePathname,
        query,
      })
    } catch (error) {
      setShowSubmitError(true)
      throw error
    }
  })

  return {
    module: useModule({
      Notification: showSubmitError
        ? Element(PageLevelError, {
            onClose: hideSubmitError,
          })
        : findBy === VehicleFindBy.VIN
        ? vin.Warning
        : findBy === VehicleFindBy.MAKEMODEL
        ? makeModel.Warning
        : findBy === VehicleFindBy.LICENSEPLATE
        ? licensePlate.Warning
        : undefined,
      FindBy: Element(RadioGroup, {
        control,
        name: 'findBy',
        items: [
          { value: VehicleFindBy.VIN, label: 'VIN', dataTestId: 'radio-vin' },
          {
            value: VehicleFindBy.LICENSEPLATE,
            label: 'License Plate',
            dataTestId: 'radio-license-plate',
          },
          {
            value: VehicleFindBy.MAKEMODEL,
            label: 'Make/Model',
            dataTestId: 'radio-ymmt',
          },
        ],
      }),

      Dynamic:
        findBy === VehicleFindBy.VIN
          ? vin.fields
          : findBy === VehicleFindBy.LICENSEPLATE
          ? licensePlate.fields
          : findBy === VehicleFindBy.MAKEMODEL
          ? makeModel.fields
          : null,

      Mileage: Element(NumberFormatInput, {
        control,
        ref: estimatedMileage.field.ref,
        onBlur: estimatedMileage.field.onBlur,
        onValueChange: ({ floatValue }) =>
          estimatedMileage.field.onChange(floatValue),
        value: estimatedMileage.field.value?.toString(),
        error: estimatedMileage.fieldState.error?.message,
        label: 'How many miles are on your vehicle?',
        name: 'estimatedMileage',
        disabled: isSubmitting,
        thousandSeparator: true,
        'data-testid': 'input-estimated-mileage',
      }),

      LoanBalance: Element(CurrencyInput, {
        label: 'How much do you owe?',
        control,
        name: 'loanValue',
        'data-testid': 'input-loan-value',
      }),
      MonthlyPayment: Element(CurrencyInput, {
        label: 'Current  monthly car payment (Optional)',
        control,
        name: 'estimatedMonthlyPayment',
        onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
          return (
            event.target.value !== '' && void trackEstimatedMonthlyPayment()
          )
        },
        'data-testid': 'input-estimated-monthly-payment',
      }),

      Submit: Element(Button, {
        onClick: onSubmit,
        disabled: isSubmitting,
        loading: isSubmitting || isSubmitSuccessful,
        children: 'Continue',
        'data-testid': 'vehicle-info-submit',
      }),
    }),
  }
}
