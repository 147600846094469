import React, { RefObject } from 'react'
import { PatternFormat } from 'react-number-format'

import { useDateFormat } from '@common/react-lib-cms-context'
import {
  DateTextFieldProps,
  NumberFormatInputProps,
  TextFieldProps,
} from '@common/types'

import { TextField } from './text-field'

export const DateFormatInput = React.forwardRef<
  RefObject<TextFieldProps>,
  NumberFormatInputProps
>(({ format = '', ...props }, ref) => {
  return (
    <PatternFormat
      aria-label={props.label}
      getInputRef={ref}
      inputProps={{ 'data-testid': props['data-testid'] }}
      customInput={TextField}
      format={format}
      type="tel"
      mask="_"
      {...props}
    />
  )
})

export const DateTextField = React.forwardRef<
  RefObject<TextFieldProps>,
  DateTextFieldProps
>(({ name, label, ...props }, ref) => {
  const format = useDateFormat()
  return (
    <DateFormatInput
      ref={ref}
      name={`${name}-format`}
      inputProps={{ 'data-testid': props['data-testid'] }}
      label={label}
      format={format.numberFormat}
      error={props.error}
      {...props}
      //Add data-testid prop for testing
    />
  )
})
