import React from 'react'

import { NewReviewVehicleFormModule, ReviewInfoData } from '@common/types'

import { Format } from '../../format'
import {
  CarIcon,
  DollarMoneyIcon,
  LocationIcon,
  SpeedIcon,
  StackFields,
  WalletIcon,
} from '../../reskin'
import {
  Container,
  FormRowContainer,
  FormRowName,
  FormRowValue,
  SaveCancelContainer,
  StyledIcon,
} from '../verify-form'

type VehicleInfo = ReviewInfoData['vehicle']

const hasValues =
  (keys: ReadonlyArray<keyof VehicleInfo>) =>
  (
    data: Omit<
      VehicleInfo,
      'estimatedMileage' | 'loanValue' | 'estimatedMonthlyPayment'
    >,
  ): boolean =>
    keys.every((key) => key in data && !!data[key])
const isVin = hasValues(['vin'])
const isLicensePlate = hasValues(['licensePlateState', 'licensePlateNumber'])

export const NewVerifyVehicleInfo = React.forwardRef<
  HTMLDivElement,
  {
    data: VehicleInfo
  } & NewReviewVehicleFormModule
>(
  (
    {
      controls: { Save, Edit, Cancel, Popup },
      data,
      submitError,
      isEditing,
      module: {
        VehicleVin,
        LicensePlateNumber,
        LicensePlateState,
        VehicleYear,
        VehicleMake,
        VehicleModel,
        VehicleTrim,
        Mileage,
        LoanValue,
        MonthlyPayment,
      },
    },
    ref,
  ): JSX.Element => {
    const { estimatedMileage, loanValue, estimatedMonthlyPayment, ...vehicle } =
      data

    return (
      <Container
        title={!isEditing ? 'Vehicle Information' : 'Edit Vehicle Information'}
        error={submitError}
        actions={!isEditing && <Edit />}
        ref={ref}
      >
        <>
          <Popup />
          {!isEditing && (
            <>
              {isVin(vehicle) ? (
                <FormRowContainer>
                  <FormRowName>
                    <StyledIcon use={CarIcon} />
                    {'Vehicle identification number'}
                  </FormRowName>
                  <FormRowValue>
                    <span>{`${vehicle.vin}`}</span>
                  </FormRowValue>
                </FormRowContainer>
              ) : isLicensePlate(vehicle) ? (
                <>
                  <FormRowContainer>
                    <FormRowName>
                      <StyledIcon use={CarIcon} />
                      {'License plate number'}
                    </FormRowName>
                    <FormRowValue>
                      <span>{`${vehicle.licensePlateNumber}`}</span>
                    </FormRowValue>
                  </FormRowContainer>

                  <FormRowContainer>
                    <FormRowName>
                      <StyledIcon use={LocationIcon} />
                      {'State'}
                    </FormRowName>
                    <FormRowValue>{vehicle.licensePlateState}</FormRowValue>
                  </FormRowContainer>
                </>
              ) : (
                <FormRowContainer>
                  <FormRowName>
                    <StyledIcon use={CarIcon} />
                    {'Vehicle information'}
                  </FormRowName>
                  <FormRowValue>{`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}</FormRowValue>
                </FormRowContainer>
              )}
              {/* Vehicle Mileage */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={SpeedIcon} />
                  {'Vehicle mileage'}
                </FormRowName>
                <FormRowValue>{Format.number(estimatedMileage)}</FormRowValue>
              </FormRowContainer>

              {/* Vehicle Est Loan Balance */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={DollarMoneyIcon} />
                  {'Estimated loan balance'}
                </FormRowName>
                <FormRowValue>{Format.newCurrency(loanValue)}</FormRowValue>
              </FormRowContainer>

              {estimatedMonthlyPayment && (
                // Vehicle Est Monthly Payment
                <FormRowContainer>
                  <FormRowName>
                    <StyledIcon use={WalletIcon} />
                    {'Estimated monthly payment'}
                  </FormRowName>
                  <FormRowValue>
                    {Format.newCurrency(estimatedMonthlyPayment)}
                  </FormRowValue>
                </FormRowContainer>
              )}
              <Edit mobile />
            </>
          )}
          {isEditing && (
            <>
              <StackFields>
                {isVin(vehicle) ? (
                  <VehicleVin />
                ) : isLicensePlate(vehicle) ? (
                  <>
                    <LicensePlateNumber />
                    <LicensePlateState />
                  </>
                ) : (
                  <>
                    <VehicleYear />
                    <VehicleMake />
                    <VehicleModel />
                    <VehicleTrim />
                  </>
                )}
                <Mileage />
                <LoanValue />
                <MonthlyPayment />
              </StackFields>
              <SaveCancelContainer>
                <Cancel sx={{ width: '100%' }} />
                <Save sx={{ width: '100%' }} />
              </SaveCancelContainer>
            </>
          )}
        </>
      </Container>
    )
  },
)
