import React from 'react'
import {
  SxProps,
  Theme,
  ThemeOptions,
  Typography,
  TypographyProps,
} from '@mui/material'
import get from 'lodash/get'
import isString from 'lodash/isString'
import reduce from 'lodash/reduce'

import { OmitSafe } from '@common/lib-types'
import { sxToArray } from '@lib/mui'

import { Breakpoints, ColorPath, TypographyPath } from './types'

type ExtendedTheme = Theme & ThemeOptions

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getTypographyStyle = (
  theme: ExtendedTheme,
  typographyPath: TypographyPath,
) => {
  if (isString(typographyPath)) {
    return get(theme.extension.typography, typographyPath)
  }

  const { xs, ...otherSizes } = typographyPath
  const baseStyles = get(theme.extension.typography, xs)

  const breakpointStyles = reduce(
    otherSizes,
    (acc, path, size: Breakpoints) => {
      return {
        ...acc,
        [theme.breakpoints.up(size)]: get(theme.extension.typography, path),
      }
    },
    {},
  )

  return {
    ...baseStyles,
    ...breakpointStyles,
  }
}

type TextProps = OmitSafe<TypographyProps, 'variant' | 'sx' | 'color'> & {
  use: [React.ElementType, TypographyPath]
  sx?: SxProps<Theme>

  // utility props
  color?: ColorPath
}
/**
 * Flexible Text component for all your text/typography needs.
 * You can specify typography based on breakpoint.
 * It also supports color property
 *
 * Property "use" accepts 2 parameters: type of the component
 * and typography path or a record (object) of breakpoints mapped to typogarphy pathes
 *
 * @example
    <Text
      use={['p', 'subheadings.14b']}
      color="semantic.warning"
      p={{xs: 1, sm: 2}}
    >
      {'Hello world'}
    </Text>

    <Text use={['span', { xs: 'subheadings.16b', md: 'headings.64' }]}>
      {'Hi'}
    </Text>
 */
export const Text = ({
  use,
  color = 'text.bodyGray',
  sx,
  ...props
}: TextProps): JSX.Element => {
  const [component, typographyPath] = use

  return (
    <Typography
      sx={[
        (theme) => ({
          ...getTypographyStyle(theme, typographyPath),
          ...(color && { color: get(theme.extension.colors, color) }),
        }),
        ...sxToArray(sx),
      ]}
      component={component}
      {...props}
    />
  )
}
