import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldValues, UseFormReturn, UseFormWatch } from 'react-hook-form'

import {
  NewVerifyEditFormText,
  NewVerifyFormButton,
  NewVerifySubmitFormButton,
} from '@common/react-lib-consumer-pres'
import { GenericReviewFormModule } from '@common/types'

import { useLogger } from '../../../hooks'
import { genericErrorMessage } from '../../utils'
// eslint-disable-next-line import/no-internal-modules
import { Element, useModule } from '../../utils/module'

import { ReviewInfoPopup } from './forms-context'

const useTrackSubmitError = <T>(
  watch: UseFormWatch<T>,
): [string, (error: string) => void] => {
  const [submitError, setSubmitError] = useState('')

  // Remove submit error on form update
  useEffect(() => {
    if (!submitError) {
      return
    }
    const subscription = watch(() => setSubmitError(''))
    return () => subscription.unsubscribe()
  }, [submitError, watch])

  return [submitError, setSubmitError]
}

export const useFormControls = <T extends FieldValues>(
  data: T,
  submit: (data: T) => Promise<void>,
  {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  }: UseFormReturn<T>,
  getOpenForm: () => HTMLDivElement | void,
  overrideEditing = false,
): GenericReviewFormModule => {
  const [submitError, setSubmitError] = useTrackSubmitError(watch)
  const logger = useLogger()
  const [isEditing, setIsEditing] = useState(false)

  const onSubmit = useCallback(
    handleSubmit(
      async (newData) => {
        try {
          if (isDirty) {
            await submit(newData)
          }
          setIsEditing(false)
        } catch (error) {
          logger.error('Error submitting form control: ', { error })
          setSubmitError(genericErrorMessage)
        }
      },
      () => {
        const openForm = getOpenForm() as HTMLDivElement | undefined
        openForm?.scrollIntoView({
          behavior: 'smooth',
        })
      },
    ),
    [handleSubmit, submit],
  )

  const onCancel = useCallback(() => {
    reset(data)
    setSubmitError('')
    setIsEditing(false)
  }, [reset, data, setIsEditing])

  // reset form on data change
  useEffect(() => {
    reset(data)
  }, [data])

  const [open, setOpen] = useState(false)
  const openFormRef = useRef<HTMLDivElement>()

  const editClick = useCallback(() => {
    const openForm = getOpenForm()
    if (openForm) {
      setOpen(true)
      openFormRef.current = openForm
    } else {
      setIsEditing(true)
    }
  }, [])

  const handleCloseModal = useCallback(() => {
    setOpen(false)
    // if setTimeout is not used the scroll doesn't work
    // not sure why, probably has something to do with Modal overlay
    setTimeout(() =>
      openFormRef.current?.scrollIntoView({ behavior: 'smooth' }),
    )
  }, [])

  return {
    controls: useModule({
      Save: Element(NewVerifySubmitFormButton, {
        loading: isSubmitting,
        onClick: onSubmit,
        children: 'Save Changes',
      }),
      Cancel: Element(NewVerifyFormButton, {
        disabled: false,
        onClick: onCancel,
        variant: 'text',
        children: 'Cancel',
      }),
      Edit: Element(NewVerifyEditFormText, {
        onClick: editClick,
        children: 'Edit info',
      }),
      Popup: Element(ReviewInfoPopup, {
        open: open,
        onClick: handleCloseModal,
      }),
    }),
    submitError,
    isEditing: isEditing || overrideEditing,
  }
}
