import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NumberOneDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62397 37.2828L10.4092 29.7181C13.2615 33.3179 17.5133 35.7618 22.3358 36.2226L18.7689 43.3524C18.5906 43.7095 18.2068 44 17.7197 44C17.353 44 17.0046 43.8282 16.7826 43.5312L13.3663 38.9789H7.67226C7.26583 38.9789 6.88902 38.7687 6.67497 38.4235C6.46152 38.0781 6.44198 37.6462 6.62397 37.2828ZM37.5909 29.7181L41.3761 37.2828C41.5581 37.6462 41.5386 38.0781 41.3251 38.4235C41.1111 38.7687 40.7343 38.9789 40.3279 38.9789H34.6339L31.2178 43.5312C30.9958 43.8282 30.6471 44 30.2804 44C29.7933 44 29.4095 43.7095 29.2315 43.3524L25.6643 36.2226C30.4868 35.7618 34.7386 33.3179 37.5909 29.7181Z"
      fill="currentColor"
    />
    <path
      d="M38.9907 18.9792C38.9907 10.7197 32.267 4 24.0025 4C15.7381 4 9.0144 10.7197 9.0144 18.9792C9.0144 27.2385 15.7381 33.9585 24.0025 33.9585C32.267 33.9585 38.9907 27.2388 38.9907 18.9792ZM25.7761 25.2552C25.7761 25.9025 25.2511 26.4271 24.6035 26.4271C23.9561 26.4271 23.4309 25.9025 23.4309 25.2552V15.5466L21.6947 17.2944C21.2385 17.7537 20.4961 17.7564 20.0366 17.3002C19.577 16.8442 19.5743 16.1024 20.0305 15.6431L23.7714 11.8775C24.1061 11.5406 24.6111 11.4387 25.0502 11.6196C25.4893 11.8006 25.7761 12.2285 25.7761 12.703V25.2552Z"
      fill="currentColor"
    />
  </SvgIcon>
)
