import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MailNewIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 18.2091 3.79086 20 6 20H12C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18H6C4.89543 18 4 17.1046 4 16V8C4 6.89543 4.89543 6 6 6H18C19.1046 6 20 6.89543 20 8V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V8C22 5.79086 20.2091 4 18 4H6C3.79086 4 2 5.79086 2 8V16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21917 8.37531C6.56418 7.94405 7.19347 7.87413 7.62473 8.21914L12 11.7194L16.3753 8.21914C16.8066 7.87413 17.4359 7.94405 17.7809 8.37531C18.1259 8.80657 18.056 9.43587 17.6247 9.78088L12 14.2806L6.37534 9.78088C5.94408 9.43587 5.87416 8.80657 6.21917 8.37531Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 15C17.8954 15 17 15.8954 17 17C17 18.1046 17.8954 19 19 19C20.1046 19 21 18.1046 21 17C21 15.8954 20.1046 15 19 15ZM15 17C15 14.7909 16.7909 13 19 13C21.2091 13 23 14.7909 23 17C23 19.2091 21.2091 21 19 21C16.7909 21 15 19.2091 15 17Z"
      fill="currentColor"
    />
  </SvgIcon>
)
