import React, { useEffect, useRef } from 'react'
import { useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import isFunction from 'lodash/isFunction'

import { NotificationProps } from '@common/types'
import { safeWindow } from '@src/utils'

import { NotificationBase } from './notification-base'

const useStyles = makeStyles({
  shake: {
    animation: '$shake 500ms ease-in-out',
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(0)' },
    '100%': { transform: 'translate(0)' },
    '20%': { transform: 'translate(-6px)' },
    '40%': { transform: 'translate(6px)' },
    '60%': { transform: 'translate(-6px)' },
    '80%': { transform: 'translate(6px)' },
  },
})

export const useScrollIntoView = (): ((el: HTMLDivElement) => boolean) => {
  const theme = useTheme()

  return (el) => {
    const yOffset = -theme.spacing(2)
    const rect = el.getBoundingClientRect()
    const y = rect.top + safeWindow.scrollY + yOffset
    const isInView =
      y >= safeWindow.scrollY &&
      rect.bottom < safeWindow.scrollY + safeWindow.innerHeight

    if (isInView) {
      return false
    }

    safeWindow.scrollTo({ top: y, behavior: 'smooth' })
    return true
  }
}

export const useShake = (): ((el: HTMLDivElement) => void) => {
  const styles = useStyles()
  return (el) => {
    el.classList.add(styles.shake)
    // 500 ms is how long the above animation should last
    // Removing the class so it can be used againd during submit.
    setTimeout(() => el.classList.remove(styles.shake), 500)
  }
}

type Unsubscribe = () => void
export const onScrollFinish = (callback: () => void): Unsubscribe => {
  let timeoutId = -1

  const debouncedCallback = (): void => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      callback()
      safeWindow.removeEventListener('scroll', debouncedCallback)
    }, 100) as unknown as number
  }
  safeWindow.addEventListener('scroll', debouncedCallback)
  return () => {
    safeWindow.removeEventListener('scroll', debouncedCallback)
    clearTimeout(timeoutId)
  }
}

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  (
    { scrollIntoView: shouldScroll, shake: shouldShake, ...props },
    forwardedRef,
  ) => {
    const ref = useRef<HTMLDivElement>()
    const shake = useShake()
    const scrollIntoView = useScrollIntoView()

    useEffect(() => {
      const scrolling = shouldScroll && scrollIntoView(ref.current)

      if (shouldShake) {
        return scrolling
          ? onScrollFinish(() => shake(ref.current))
          : shake(ref.current)
      }
    }, [])

    return (
      <NotificationBase
        {...props}
        ref={(el) => {
          ref.current = el

          if (!forwardedRef) {
            return
          }
          if (isFunction(forwardedRef)) {
            forwardedRef(el)
          } else {
            forwardedRef.current = el
          }
        }}
      />
    )
  },
)
