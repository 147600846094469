import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9684 3.21808C8.38659 2.4115 10.8302 3.92625 11.509 6.3787C11.9715 8.04984 11.5335 9.80216 10.4654 11.0021C10.6841 11.2854 10.9232 11.5578 11.1827 11.8175C11.4409 12.0759 11.7117 12.3141 11.9932 12.5321C13.1927 11.4555 14.9503 11.0126 16.6263 11.4771C19.0782 12.1567 20.5902 14.6023 19.7848 17.0204C19.2565 18.607 18.2712 19.5709 16.9571 19.8882C15.7361 20.183 14.4197 19.8696 13.2585 19.387C10.92 18.4148 8.6108 16.4999 7.5562 15.4445C6.4767 14.3642 4.57009 12.0517 3.60677 9.71757C3.1284 8.55847 2.82039 7.24688 3.1159 6.03148C3.43384 4.72389 4.3929 3.74355 5.9684 3.21808ZM9.58147 6.91218C9.16878 5.42109 7.79755 4.71629 6.60122 5.11532C5.54962 5.46606 5.18488 5.98744 5.05928 6.504C4.91127 7.11276 5.03998 7.94774 5.45551 8.95458C6.27998 10.9523 7.98986 13.0491 8.97094 14.0309C9.92827 14.9889 12.0228 16.7073 14.0263 17.5402C15.0361 17.96 15.8752 18.092 16.4877 17.9441C17.0071 17.8187 17.5332 17.4518 17.8873 16.3884C18.2867 15.1893 17.5807 13.8171 16.0921 13.4044C14.9842 13.0974 13.8451 13.4531 13.1672 14.1791C12.7295 14.648 11.8951 14.9152 11.1851 14.4197C10.6856 14.071 10.2112 13.6748 9.76793 13.2312C9.32396 12.7869 8.92757 12.3113 8.57884 11.8107C8.08641 11.1038 8.34917 10.2726 8.81458 9.83381C9.53521 9.15443 9.88748 8.01787 9.58147 6.91218Z"
      fill="currentColor"
    />
  </SvgIcon>
)
