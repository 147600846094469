import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SaleDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M32.3416 11.5581C28.6184 7.21061 24.9432 4.38492 24.7888 4.26696C23.9498 3.62612 22.72 4.21173 22.72 5.2513V10.748C22.72 13.5863 18.8735 14.5852 17.432 12.1183C16.9838 11.3531 15.8938 11.2725 15.3296 11.9456C11.4559 16.562 8 22.3827 8 28.3755C8 36.9909 15.1776 44 24 44C32.8224 44 40 36.9909 40 28.3755C40 22.2276 36.3649 16.2566 32.3416 11.5581V11.5581ZM16.96 24.0007C16.96 22.6218 18.1088 21.5008 19.52 21.5008C20.9312 21.5008 22.08 22.6218 22.08 24.0007C22.08 25.3795 20.9312 26.5006 19.52 26.5006C18.1088 26.5006 16.96 25.3795 16.96 24.0007ZM20.5304 34.7675C20.0987 35.3108 19.2942 35.412 18.7344 34.987C18.176 34.5628 18.076 33.7777 18.5096 33.2332L27.4696 21.9836C27.904 21.4383 28.708 21.3406 29.2656 21.764C29.824 22.1882 29.924 22.9734 29.4904 23.5179L20.5304 34.7675ZM28.48 35.2503C27.0688 35.2503 25.92 34.1292 25.92 32.7504C25.92 31.3715 27.0688 30.2505 28.48 30.2505C29.8912 30.2505 31.04 31.3715 31.04 32.7504C31.04 34.1292 29.8912 35.2503 28.48 35.2503Z"
      fill="currentColor"
    />
  </SvgIcon>
)
