import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MicOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.01876 3.33309C8.74976 2.51635 9.81524 2 11 2H13C15.2091 2 17 3.79086 17 6V11.5C17 12.0523 16.5523 12.5 16 12.5C15.4477 12.5 15 12.0523 15 11.5V6C15 4.89543 14.1046 4 13 4H11C10.4078 4 9.87667 4.25615 9.50903 4.66691C9.1407 5.07844 8.50851 5.11346 8.09698 4.74513C7.68545 4.37681 7.65043 3.74461 8.01876 3.33309ZM9 8V12C9 13.1046 9.89543 14 11 14H13C13.3246 14 13.6284 13.9234 13.897 13.7883L14.7958 15.575C14.2546 15.8472 13.6437 16 13 16H11C8.79086 16 7 14.2091 7 12V8H9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C5.55228 10 6 10.4477 6 11V12C6 14.7614 8.23858 17 11 17H13C14.0897 17 15.0954 16.6525 15.916 16.0622C16.3644 15.7397 16.9893 15.8417 17.3118 16.29C17.6343 16.7383 17.5323 17.3632 17.084 17.6857C15.9344 18.5127 14.5226 19 13 19H11C7.13401 19 4 15.866 4 12V11C4 10.4477 4.44772 10 5 10ZM19 10C19.5523 10 20 10.4477 20 11V12C20 12.8164 19.8598 13.6021 19.6014 14.3332C19.4174 14.854 18.8461 15.1269 18.3253 14.9428C17.8046 14.7588 17.5317 14.1875 17.7157 13.6668C17.8995 13.1467 18 12.5862 18 12V11C18 10.4477 18.4477 10 19 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17C12.5523 17 13 17.4477 13 18V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21L11 18C11 17.4477 11.4477 17 12 17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
