import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FileTextDownSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.5C12 2.22386 11.7761 2 11.5 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H16C18.2091 22 20 20.2091 20 18V10.5C20 10.2239 19.7761 10 19.5 10H17C14.2386 10 12 7.76142 12 5V2.5ZM19.2195 8C19.552 8 19.7909 7.67893 19.6312 7.3873C19.4956 7.13969 19.3245 6.91032 19.1213 6.70711L15.2929 2.87868C15.0897 2.67546 14.8603 2.50441 14.6127 2.3688C14.3211 2.20909 14 2.44805 14 2.78055V5C14 6.65685 15.3431 8 17 8H19.2195ZM12 11C12.5523 11 13 11.4477 13 12V15.5858L14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071L12.7071 18.7071C12.3166 19.0976 11.6834 19.0976 11.2929 18.7071L8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929L11 15.5858V12C11 11.4477 11.4477 11 12 11Z"
      fill="currentColor"
    />
  </SvgIcon>
)
