import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderV1DuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M34.8001 7.80295C34.464 7.11134 33.5818 6.78594 32.8257 7.15206L11.4854 16.9156H39.2109L34.8001 7.80295Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8545 16.4691L23.7099 11.506H20.5173L15.6863 7.51926C15.4762 7.31587 15.1822 7.2345 14.8881 7.2345H6.94844C6.23427 7.2345 5.68822 7.76337 5.68822 8.4549V16.4691H5.26825C4.93215 16.4691 4.59613 16.5912 4.34405 16.8353C4.09197 17.0794 3.96598 17.4048 4.00795 17.7303L5.26817 39.8608C5.31014 40.5117 5.85626 41 6.52838 41H41.4795C42.1516 41 42.6977 40.5118 42.7397 39.8608L44 17.7303C44 17.4048 43.874 17.0793 43.664 16.8353C43.4119 16.5912 43.0759 16.4691 42.7398 16.4691H42.3199V12.7264C42.3199 12.0348 41.7738 11.506 41.0597 11.506H36.3547L38.776 16.4691H12.8545Z"
      fill="currentColor"
    />
  </SvgIcon>
)
