import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HomeV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9036 4.85667C12.7971 3.94825 11.2029 3.94825 10.0964 4.85667L4.73093 9.26165C4.2682 9.64155 4 10.2087 4 10.8074V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V10.8074C20 10.2087 19.7318 9.64155 19.2691 9.26165L13.9036 4.85667ZM8.82732 3.31089C10.6715 1.79684 13.3285 1.79684 15.1727 3.31089L20.5381 7.71587C21.4636 8.47565 22 9.61006 22 10.8074V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V10.8074C2 9.61006 2.53641 8.47565 3.46186 7.71587L8.82732 3.31089Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C8 14.3431 9.34315 13 11 13H13C14.6569 13 16 14.3431 16 16V21H14V16C14 15.4477 13.5523 15 13 15H11C10.4477 15 10 15.4477 10 16V21H8V16Z"
      fill="currentColor"
    />
  </SvgIcon>
)
