import get from 'lodash/get'

import {
  ColorByCoordsSelector,
  isExtensionPaletteName,
  Theme,
  ThemeOptions,
} from './theme-types'

interface SelectorInputs {
  readonly theme: Omit<Theme, 'select'>
  readonly themeOptions: ThemeOptions
}

/**
 * The type param should be the type of the selector function
 */
type SelectorCreator<T> = (inputs: SelectorInputs) => T

/**
 * Given a palette and optional color return the color string
 * in the given theme at the palette color coordinate.
 */
export const colorByCoords: SelectorCreator<ColorByCoordsSelector> =
  ({ theme, themeOptions }): ColorByCoordsSelector =>
  ({ colorName = 'main', paletteName }) =>
    isExtensionPaletteName(paletteName)
      ? get(themeOptions, ['mrExtensions', 'palette', paletteName, colorName])
      : get(theme, ['palette', paletteName, colorName])
