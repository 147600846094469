/* eslint-disable react/jsx-no-bind */
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  useController,
  UseControllerReturn,
  UseFormReturn,
} from 'react-hook-form'
import { Box, CircularProgress, styled } from '@mui/material'

// import { memoize } from 'lodash'
import { DeepMapNumbersToStrings } from '@common/lib-types'
import { Notification, TextField } from '@common/react-lib-consumer-pres'
import { NotificationProps, TextFieldProps } from '@common/types'
// import { Logger, useLogger } from '@src/hooks'
import { getResumeUrlByEmail } from '@src/api'

import {
  trackCoBorrowerFoundEmailWasUsed,
  trackReturnUserLoginEmailRequested,
} from '../../../tracking'
import { CoborrowerInformationForm, EErrorType } from '../../schemas'
import { Element, genericErrorMessage, TElement } from '../../utils'

type WrappedCoborrrowerInformationForm =
  DeepMapNumbersToStrings<CoborrowerInformationForm>

// Email Exist Error Component
const StyledLink = styled('a')({
  fontWeight: 'bold',
  textDecoration: 'underline',
  cursor: 'pointer',
})

const LoadingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const LoadingText = styled('span')({
  fontWeight: 500,
  marginLeft: 5,
})

type EmailExistErrorProps = NotificationProps & {
  accountCreated: boolean
  email: string
  errorRef: MutableRefObject<HTMLDivElement>
}
const EmailExistsError: React.FC<EmailExistErrorProps> = ({
  email,
  accountCreated,
  errorRef,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  const handleLoginClickAccountCreated = useCallback(async () => {
    void trackReturnUserLoginEmailRequested()
    try {
      setLoading(true)
      setError(null)
      const { resumeUrl } = await getResumeUrlByEmail({ email })
      void window.open(resumeUrl, '_self')
    } catch {
      setError(genericErrorMessage)
      setLoading(false)
    }
  }, [email])

  useEffect(() => {
    void trackCoBorrowerFoundEmailWasUsed({ email })
  }, [email])

  const body = loading ? (
    <LoadingContainer>
      <CircularProgress
        sx={{
          color: (theme) => theme.extension.colors.base.deepGreen,
        }}
        size={20}
        thickness={4}
      />{' '}
      <LoadingText>{'Generating link'}</LoadingText>
    </LoadingContainer>
  ) : (
    error || (
      <>
        {`You’ve been here before! Click `}
        <StyledLink
          data-testid={'resumeLink'}
          onClick={handleLoginClickAccountCreated}
        >
          {'here'}
        </StyledLink>
        {` to resume your application.`}
      </>
    )
  )
  return (
    <Notification
      {...props}
      type={loading ? 'warning' : 'error'}
      ref={errorRef}
      body={body}
    />
  )
}

// Email Text Field Component
type EmailTextFieldProps = Pick<TextFieldProps, 'label' | 'className'> &
  FormProps & {
    form: UseControllerReturn<WrappedCoborrrowerInformationForm, 'emailAddress'>
    showCustomError: boolean
  }

const EmailTextField = ({
  form,
  trigger,
  showCustomError,
  ...props
}: EmailTextFieldProps): JSX.Element => {
  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { error },
    formState: { dirtyFields },
  } = form

  return (
    <TextField
      {...props}
      onChange={(e) => {
        onChange(e)
        if (dirtyFields.confirmEmailAddress) {
          void trigger('confirmEmailAddress')
        }
      }}
      onBlur={(e) => {
        onChange(e.target.value.trim())
        onBlur()

        if (dirtyFields.confirmEmailAddress) {
          void trigger('confirmEmailAddress')
        }
      }}
      error={showCustomError || error?.message}
      {...{ name, value, ref }}
    />
  )
}

type FormProps = Pick<
  UseFormReturn<WrappedCoborrrowerInformationForm>,
  'trigger' | 'control'
>

export const useBorrowerEmailField = ({
  trigger,
  control,
  staticProps,
}: {
  staticProps: Pick<TextFieldProps, 'label'>
} & FormProps): {
  element: TElement<Pick<TextFieldProps, 'className'>>
  errorComponent?: TElement<EmailExistErrorProps>
  errorRef: MutableRefObject<HTMLDivElement>
  showCustomError: boolean
} => {
  const errorRef = useRef<HTMLDivElement>()
  const form = useController<WrappedCoborrrowerInformationForm, 'emailAddress'>(
    {
      control,
      name: 'emailAddress',
    },
  )

  const {
    field: { value },
    fieldState: { error },
  } = form

  const showCustomError = [
    EErrorType.emailExists,
    EErrorType.accountCreated,
  ].includes(error?.type as EErrorType)

  useEffect(() => {
    if (showCustomError) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errorRef, showCustomError])

  return {
    errorRef,
    showCustomError,
    element: Element(EmailTextField, {
      ...staticProps,
      form,
      trigger,
      showCustomError,
    }),
    ...(showCustomError && {
      errorComponent: Element(EmailExistsError, {
        email: value,
        errorRef,
        accountCreated: error?.type === EErrorType.accountCreated,
      }),
    }),
  }
}
