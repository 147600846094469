import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FileTextDeleteSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.5C12 2.22386 11.7761 2 11.5 2H8C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H16C18.2091 22 20 20.2091 20 18V10.5C20 10.2239 19.7761 10 19.5 10H17C14.2386 10 12 7.76142 12 5V2.5ZM19.2195 8C19.552 8 19.7909 7.67893 19.6312 7.3873C19.4956 7.13969 19.3245 6.91032 19.1213 6.70711L15.2929 2.87868C15.0897 2.67546 14.8603 2.50441 14.6127 2.3688C14.3211 2.20909 14 2.44805 14 2.78055V5C14 6.65685 15.3431 8 17 8H19.2195ZM8.79289 12.2929C9.18342 11.9024 9.81658 11.9024 10.2071 12.2929L12 14.0858L13.7929 12.2929C14.1834 11.9024 14.8166 11.9024 15.2071 12.2929C15.5976 12.6834 15.5976 13.3166 15.2071 13.7071L13.4142 15.5L15.2071 17.2929C15.5976 17.6834 15.5976 18.3166 15.2071 18.7071C14.8166 19.0976 14.1834 19.0976 13.7929 18.7071L12 16.9142L10.2071 18.7071C9.81658 19.0976 9.18342 19.0976 8.79289 18.7071C8.40237 18.3166 8.40237 17.6834 8.79289 17.2929L10.5858 15.5L8.79289 13.7071C8.40237 13.3166 8.40237 12.6834 8.79289 12.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
