import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BagIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49921 10.5657C2.15496 8.15595 4.02481 6 6.459 6H17.5409C19.9751 6 21.845 8.15595 21.5007 10.5657L20.4805 17.7071C20.1286 20.1704 18.019 22 15.5308 22H8.46916C5.9809 22 3.8713 20.1704 3.51941 17.7071L2.49921 10.5657ZM6.459 8C5.2419 8 4.30698 9.07798 4.4791 10.2828L5.49931 17.4243C5.71044 18.9022 6.9762 20 8.46916 20H15.5308C17.0237 20 18.2895 18.9022 18.5006 17.4243L19.5208 10.2828C19.693 9.07798 18.758 8 17.5409 8H6.459Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44716 2.10557C9.94114 2.35256 10.1414 2.95323 9.89438 3.44721L8.10552 7.02492C7.85853 7.5189 7.25786 7.71912 6.76388 7.47213C6.2699 7.22514 6.06968 6.62447 6.31667 6.13049L8.10552 2.55278C8.35251 2.0588 8.95318 1.85858 9.44716 2.10557Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5529 2.10557C15.0469 1.85858 15.6475 2.05881 15.8945 2.55279L17.6834 6.1305C17.9304 6.62447 17.7301 7.22515 17.2362 7.47214C16.7422 7.71913 16.1415 7.5189 15.8945 7.02492L14.1057 3.44721C13.8587 2.95324 14.0589 2.35256 14.5529 2.10557Z"
      fill="currentColor"
    />
  </SvgIcon>
)
