import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M42.6729 10.532H29.339L25.7084 4.60349C25.4787 4.22856 25.0708 4 24.6311 4H14.5897C12.8128 4 11.3672 5.44564 11.3672 7.22259V12.7395V32.0403C11.3672 33.8173 12.8128 35.2629 14.5897 35.2629H36.6318H42.6729C44.4499 35.2629 45.8955 33.8173 45.8955 32.0403V13.7547C45.8955 11.9776 44.4499 10.532 42.6729 10.532Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M8.8427 32.0393V12.7385H5.32805C3.55111 12.7385 2.10547 14.1841 2.10547 15.961V40.7787C2.10547 42.5557 3.55111 44.0013 5.32805 44.0013H33.4113C35.1882 44.0013 36.6339 42.5557 36.6339 40.7787V37.7882H14.5917C11.4217 37.7883 8.8427 35.2093 8.8427 32.0393Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1667 16C27.5223 16 27 16.5223 27 17.1667C27 17.811 27.5223 18.3333 28.1667 18.3333H28.1783C28.8227 18.3333 29.345 17.811 29.345 17.1667C29.345 16.5223 28.8227 16 28.1783 16H28.1667ZM29.3333 21.8307C29.3333 21.1864 28.811 20.6641 28.1667 20.6641C27.5223 20.6641 27 21.1864 27 21.8307V26.4974C27 27.1417 27.5223 27.6641 28.1667 27.6641C28.811 27.6641 29.3333 27.1417 29.3333 26.4974V21.8307Z"
      fill="white"
    />
  </SvgIcon>
)
