import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CaseIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09016 2.55C8.37882 2.55 7.76538 3.10697 7.76538 3.83559V5.10552H2.9577C1.92519 5.10552 1.05005 5.91241 1.05005 6.94782V19.6077C1.05005 20.6431 1.92519 21.45 2.9577 21.45H21.0424C22.0749 21.45 22.9501 20.6431 22.9501 19.6077V6.94782C22.9501 5.9124 22.0749 5.10552 21.0424 5.10552H16.2358V3.83559C16.2358 3.10696 15.6223 2.55 14.911 2.55H9.09016ZM14.3948 12.606H21.1753V19.6077C21.1753 19.6469 21.135 19.7144 21.0424 19.7144H2.9577C2.86508 19.7144 2.82483 19.647 2.82483 19.6077V12.606H9.60505V13.1806C9.60505 13.9475 10.2513 14.5356 11.0027 14.5356H12.9974C13.7487 14.5356 14.3948 13.9474 14.3948 13.1806V12.606ZM9.54016 5.10552V4.28559H14.461V5.10552H9.54016ZM2.82483 6.94782C2.82483 6.90857 2.86508 6.84111 2.9577 6.84111H21.0424C21.135 6.84111 21.1753 6.90857 21.1753 6.94782V10.8704H14.3334C14.1552 10.3081 13.6114 9.91526 12.9974 9.91526H11.0027C10.3885 9.91526 9.8447 10.3081 9.66647 10.8704H2.82483V6.94782ZM11.3798 12.8V11.6508H12.62V12.8H11.3798Z"
      fill="currentColor"
    />
  </SvgIcon>
)
