import { Box, Button, styled } from '@mui/material'

import { UIAddonProduct } from '@src/index'

import { Text } from '../../reskin/components/text'

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const ButtonBox = styled(Box)({
  justifyContent: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
})

const ListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.extension.colors.divider.lightGray}`,

  '&:last-child': {
    borderBottom: 'none',
  },
}))

export type ProductAttachmentsItem = UIAddonProduct & {
  highlight: string
}

export type ProductAttachmentsListProps = {
  onSelect: (id: number | string) => void
  items: ReadonlyArray<ProductAttachmentsItem>
  selectedItems?: ReadonlyArray<number | string>
}

const SelectButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean
}>(({ isSelected, theme }) => ({
  width: 100,
  height: 40,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.extension.colors.base.blueSky,
  backgroundColor: theme.extension.colors.base.lightSky,
  border: `2px solid transparent`,
  textTransform: 'none',

  ...(isSelected && {
    backgroundColor: theme.extension.colors.text.white,
    color: theme.extension.colors.text.softBlack,
    border: `2px solid ${theme.extension.colors.neutral[600]}`,
  }),
}))

export const ProductAttachmentsList: React.FC<ProductAttachmentsListProps> = (
  props,
) => {
  const { items, onSelect, selectedItems } = props
  return (
    <Box>
      <Text
        use={['h1', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
        color="text.softBlack"
      >
        Customize it how you want
      </Text>
      <Container role="list">
        {items.map((item, i) => (
          <ListItem
            role="listitem"
            data-testid={`product-attachment-${i}`}
            key={i}
          >
            <Box flex={1} display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text
                  use={[
                    'span',
                    { xs: 'subheadings.16b', sm: 'subheadings.18b' },
                  ]}
                  color="text.softBlack"
                  data-testid={`addon-product-name`}
                >
                  {item.name}
                </Text>
                <Text
                  use={[
                    'span',
                    { xs: 'subheadings.16b', sm: 'subheadings.18b' },
                  ]}
                  color="base.blueSky"
                  data-testid={`addon-product-highlight`}
                >
                  ${item.highlight}
                </Text>
              </Box>

              <Text
                use={['span', { xs: 'subheadings.16', sm: 'subheadings.18' }]}
                color="text.softBlack"
              >
                {item.description}
              </Text>
            </Box>

            <ButtonBox ml={6}>
              <SelectButton
                data-testid={`product-attachment-select-button-${i}`}
                onClick={() => onSelect(item.id)}
                isSelected={selectedItems?.includes(item.id)}
              >
                {selectedItems?.includes(item.id) ? 'Remove' : 'Add'}
              </SelectButton>
            </ButtonBox>
          </ListItem>
        ))}
      </Container>
    </Box>
  )
}
