import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ReviewEditIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <path
      d="M17.743 4.283a4.072 4.072 0 0 0-2.81-1.188 4.048 4.048 0 0 0-2.967 1.179l-7.235 7.235a5.577 5.577 0 0 0-1.637 3.96v2.394c0 .591.477 1.07 1.068 1.07h2.394c1.494 0 2.9-.582 3.962-1.64l7.226-7.225a4.099 4.099 0 0 0-.001-5.785ZM9.008 15.782a3.453 3.453 0 0 1-2.452 1.013H5.231v-1.326c0-.924.36-1.796 1.012-2.45l4.77-4.77 2.764 2.764-4.769 4.769Zm7.224-7.224-.944.944-2.764-2.764.944-.944a1.956 1.956 0 0 1 2.764 0 1.958 1.958 0 0 1 0 2.764Z"
      fill="#0075E3"
    />
  </SvgIcon>
)
