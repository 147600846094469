import React, { FC } from 'react'
import { Box, styled } from '@mui/material'
import Image from 'next/legacy/image'

import { Url } from '@common/lib-types'
import { Text } from '@common/react-lib-consumer-pres'

const Heading = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  [theme.breakpoints.only('xs')]: {
    marginTop: theme.spacing(3),
    flexWrap: 'wrap',
  },
  '& p': {
    maxWidth: 608,
  },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.only('xs')]: {
    marginTop: theme.spacing(0),
  },
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    maxHeight: 80,
  },
}))

const SubHeading = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.only('xs')]: {
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
}))

export type GenericErrorProps = {
  title?: string
  message?: string
}

export const GenericError: FC<GenericErrorProps> = (props) => {
  const { message, title } = props
  return (
    <>
      <ImageContainer>
        <Image
          src={'/hero_avatars.png' as Url}
          alt="Avatars"
          width="150"
          height="80"
        />
      </ImageContainer>
      <Heading>
        <Text
          use={['p', 'subheadings.28m']}
          align="center"
          color="text.softBlack"
          mb={{ xs: 2, sm: 1 }}
        >
          {title ?? 'Oops! Something Went A Little Haywire.'}
        </Text>
      </Heading>
      <SubHeading>
        <Text
          use={['p', { xs: 'paragraphs.16', sm: 'paragraphs.18' }]}
          align="center"
          color="text.softBlack"
          mb={0.5}
        >
          {message ??
            "We apologize for the inconvenience. It looks like you've run into a weird system error that we can't solve online at the moment. Don't Worry, We're Here to Help! Please give us a call, and one of our support team members will be happy to assist you."}
        </Text>
      </SubHeading>
    </>
  )
}
