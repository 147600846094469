import React, { RefObject } from 'react'
import { PatternFormat } from 'react-number-format'

import { usePhoneNumberFormat } from '@common/react-lib-cms-context'
import {
  NumberFormatInputProps,
  PhoneNumberTextFieldProps,
  TextFieldProps,
} from '@common/types'

import { TextField } from './text-field'

const PhoneNumberFieldForward = React.forwardRef<
  RefObject<TextFieldProps>,
  NumberFormatInputProps
>(({ format = '', ...props }, ref) => {
  return (
    <PatternFormat
      getInputRef={ref}
      customInput={TextField}
      format={format}
      type="tel"
      mask="_"
      {...props}
    />
  )
})

export const PhoneNumberField = React.forwardRef<
  RefObject<TextFieldProps>,
  PhoneNumberTextFieldProps
>(({ name, label, ...props }, ref) => {
  const format = usePhoneNumberFormat()
  return (
    <PhoneNumberFieldForward
      ref={ref}
      name={`${name}-format`}
      label={label}
      format={format}
      error={props.error}
      {...props}
    />
  )
})
