import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
} from '@mui/material'

export const StyledStepLabel = styled(StepLabel)({
  flexDirection: 'column',

  [`&.${stepLabelClasses.iconContainer}`]: {
    paddingRight: 0,
  },

  [`&.${stepLabelClasses.label}`]: {
    '&$label': {
      marginTop: 8,
    },
  },
})

export const StyledStepConnector = styled(StepConnector)(({ theme }) => {
  const { grey, secondary } = theme.palette
  const { main: freshMint } = secondary
  return {
    marginTop: 15,
    alignSelf: 'self-start',
    marginRight: '-20px',
    marginLeft: '-20px',

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: freshMint,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: freshMint,
      },
    },

    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: grey['600'],
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      background: `linear-gradient(90deg, ${freshMint} 0%, ${freshMint} 25%, ${grey[600]} 60%, ${grey[600]} 100%)`,
    },
  }
})

export const StyledStepper = styled(Stepper)({
  padding: 0,
  justifyContent: 'space-between',
})

export const StyledStep = styled(Step)({
  padding: 0,
})

export const StepContainer = styled('div')<{
  active?: boolean
  completed?: boolean
}>(({ active, completed, theme }) => {
  const { common, grey, secondary } = theme.palette
  const { white } = common
  const { main: freshMint } = secondary
  return {
    backgroundColor: white,
    zIndex: 1,
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${grey['600']}`,

    ...(active && {
      borderColor: freshMint,
    }),

    ...(completed && {
      color: white,
      backgroundColor: freshMint,
      borderColor: freshMint,
    }),
  }
})
