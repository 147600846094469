import { styled } from '@mui/material'

import { SelectButton as BaseButton } from '../../reskin'

export const SelectButton = styled(BaseButton)(({ theme }) => ({
  height: 56,
  [theme.breakpoints.up('sm')]: {
    height: 64,
  },
}))
