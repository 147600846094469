import React, { CSSProperties, FunctionComponent } from 'react'
import { Box as MuiBox, Typography, TypographyProps } from '@mui/material'

type PProps = Readonly<{
  align?: TypographyProps['align']
  children?: TypographyProps['children']
  classes?: TypographyProps['classes']
  color?: TypographyProps['color']
  display?: TypographyProps['display']
  noWrap?: TypographyProps['noWrap']
  paragraph?: TypographyProps['paragraph']
  secondary?: boolean
  className?: TypographyProps['className']
  sx?: TypographyProps['sx']
}>

/**
 * Typography component for Paragraphs P
 *
 * @param props See https://material-ui.com/api/typography/
 *
 * @deprecated Import Typography from @mui/material
 */
export const P: FunctionComponent<PProps> = ({
  paragraph = true,
  secondary = false,
  ...props
}) => (
  <Typography
    variant={secondary ? 'body1' : 'body2'}
    paragraph={paragraph}
    {...props}
  />
)

type BoxProps = Readonly<{
  /**
   * Provide a css class name to set on the div.
   */
  className?: string
  style?: CSSProperties
  id?: string
  children?: React.ReactNode
}>

/**
 * The Box component serves as a wrapper component
 * upon which you can attach a className for styling purposes.
 *
 * See https://material-ui.com/components/box/ for more information.
 */
export const Box: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <MuiBox {...props}>{children}</MuiBox>
)
