import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VideoOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6C4.44772 6 4 6.44772 4 7V16C4 17.1046 4.89543 18 6 18H13C14.1046 18 15 17.1046 15 16H17C17 18.2091 15.2091 20 13 20H6C3.79086 20 2 18.2091 2 16V7C2 5.34315 3.34315 4 5 4V6ZM9 5C9 4.44772 9.44772 4 10 4H13C15.2091 4 17 5.79086 17 8V11.5C17 12.0523 16.5523 12.5 16 12.5C15.4477 12.5 15 12.0523 15 11.5V8C15 6.89543 14.1046 6 13 6H10C9.44772 6 9 5.55228 9 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6621 6.75878C19.9491 5.60044 22 6.51382 22 8.24537V16C22 16.5523 21.5523 17 21 17C20.4477 17 20 16.5523 20 16L20 8.24537L16.669 11.2433L15.3311 9.75671L18.6621 6.75878Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
