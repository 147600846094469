import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MailIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V8ZM6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89543 19.1046 6 18 6H6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21917 8.37531C6.56418 7.94405 7.19347 7.87413 7.62473 8.21914L12 11.7194L16.3753 8.21914C16.8066 7.87413 17.4359 7.94405 17.7809 8.37531C18.1259 8.80657 18.056 9.43587 17.6247 9.78088L12 14.2806L6.37534 9.78088C5.94408 9.43587 5.87416 8.80657 6.21917 8.37531Z"
      fill="currentColor"
    />
  </SvgIcon>
)
