import React from 'react'
import {
  FormControl,
  RadioGroup as RadioGroupBase,
  styled,
} from '@mui/material'

import { Text } from '../text'

import { Radio } from './radio'

const StyledRadioGroup = styled(RadioGroupBase)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const RadioContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2, 2, 0),
}))

const SimpleStyledRadioGroup = styled(RadioGroupBase)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  '& > *': {
    margin: theme.spacing(0, 8, 0, 0),
    width: '320px',
    [theme.breakpoints.down('md')]: {
      width: '100px',
    },
  },
}))

const mapRadioItems = <T,>(
  items: ReadonlyArray<{
    label: string
    value: T
    id?: string
    dataTestId?: string
  }>,
  onChange: (v: T) => void,
  disabled: boolean,
  error: string,
  value: T,
  variant?: string,
): Array<JSX.Element> => {
  return items.map(({ label, value: itemValue, id, dataTestId }) =>
    variant ? (
      <Radio
        key={label}
        {...{ label, onChange, disabled }}
        data-testid={dataTestId}
        error={!!error}
        value={itemValue}
        active={value === itemValue}
        id={id}
      />
    ) : (
      <RadioContainer key={label}>
        <Radio
          key={label}
          {...{ label, onChange, disabled }}
          data-testid={dataTestId}
          error={!!error}
          value={itemValue}
          active={value === itemValue}
          id={id}
        />
      </RadioContainer>
    ),
  )
}

type RadioGroupProps<T> = {
  name: string
  items: ReadonlyArray<{ label: string; value: T; dataTestId?: string }>
  onChange: (value: T) => void
  value: T | null
  disabled?: boolean
  error?: string
  variant?: string
}
export const RadioGroup = React.forwardRef(
  <T,>(
    {
      name,
      items,
      onChange,
      value,
      error,
      disabled,
      variant,
    }: RadioGroupProps<T>,
    ref,
  ): JSX.Element => {
    return (
      <FormControl component="fieldset" ref={ref}>
        {variant && variant === 'simple' ? (
          <SimpleStyledRadioGroup {...{ name, value }}>
            {mapRadioItems(items, onChange, disabled, error, value, variant)}
          </SimpleStyledRadioGroup>
        ) : (
          <StyledRadioGroup {...{ name, value }}>
            {mapRadioItems(items, onChange, disabled, error, value)}
          </StyledRadioGroup>
        )}
        {error && (
          <Text use={['span', 'subheadings.14m']} color="semantic.error">
            {error}
          </Text>
        )}
      </FormControl>
    )
  },
)
