import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HomeV4Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8186 4.6664C12.7439 3.77787 11.2561 3.77787 10.1815 4.6664L4.79539 9.11964C4.30425 9.52572 4 10.1673 4 10.8663V18.8739C4 19.5543 4.50443 20 5 20H7C7.55228 20 8 19.5523 8 19V15.6848C8 14.0168 9.28643 12.5587 11 12.5587H13C14.7136 12.5587 16 14.0168 16 15.6848V19C16 19.5523 16.4477 20 17 20H19C19.4956 20 20 19.5543 20 18.8739V10.8663C20 10.1673 19.6958 9.52572 19.2046 9.11965L13.8186 4.6664ZM15.093 3.12502L20.479 7.57827C21.4494 8.38059 22 9.59706 22 10.8663V18.8739C22 20.5419 20.7136 22 19 22H17C15.3431 22 14 20.6569 14 19V15.6848C14 15.0044 13.4956 14.5587 13 14.5587H11C10.5044 14.5587 10 15.0044 10 15.6848V19C10 20.6569 8.65685 22 7 22H5C3.28643 22 2 20.5419 2 18.8739V10.8663C2 9.59706 2.55059 8.38059 3.52097 7.57827L8.90703 3.12502C10.7213 1.62499 13.2787 1.62499 15.093 3.12502Z"
      fill="currentColor"
    />
  </SvgIcon>
)
