import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MoreHorizontalCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99995 13.3C8.71792 13.3 9.29995 12.718 9.29995 12C9.29995 11.282 8.71792 10.7 7.99995 10.7C7.28198 10.7 6.69995 11.282 6.69995 12C6.69995 12.718 7.28198 13.3 7.99995 13.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13.3C16.7179 13.3 17.3 12.718 17.3 12C17.3 11.282 16.7179 10.7 16 10.7C15.282 10.7 14.7 11.282 14.7 12C14.7 12.718 15.282 13.3 16 13.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13.3C12.7179 13.3 13.3 12.718 13.3 12C13.3 11.282 12.7179 10.7 12 10.7C11.282 10.7 10.7 11.282 10.7 12C10.7 12.718 11.282 13.3 12 13.3Z"
      fill="black"
    />
  </SvgIcon>
)
