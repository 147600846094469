import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const TwitterIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#03A9F4',
  ...props
}) => (
  <SvgIcon viewBox="0 0 22 18" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2.08324C21.1912 2.43695 20.3208 2.67515 19.4078 2.7827C20.3398 2.23266 21.0555 1.36212 21.3921 0.324827C20.521 0.834449 19.5538 1.20331 18.5264 1.40326C17.703 0.539939 16.529 0 15.2318 0C12.7393 0 10.7184 1.9894 10.7184 4.44295C10.7184 4.79088 10.7587 5.13014 10.8357 5.45569C7.08426 5.2709 3.75882 3.50166 1.53256 0.813517C1.14392 1.46967 0.921739 2.23267 0.921739 3.04691C0.921739 4.58805 1.71735 5.948 2.92947 6.74492C2.18885 6.72182 1.4937 6.52186 0.884339 6.18909V6.24467C0.884339 8.39793 2.4411 10.1932 4.50456 10.6024C4.12619 10.7035 3.72728 10.7584 3.31591 10.7584C3.0248 10.7584 2.74175 10.7302 2.46603 10.6782C3.0402 12.4431 4.70769 13.728 6.68242 13.7641C5.13812 14.9552 3.19199 15.6662 1.07719 15.6662C0.712018 15.6662 0.35271 15.6452 0 15.6034C1.99747 16.8637 4.36964 17.6 6.91854 17.6C15.2201 17.6 19.7598 10.8298 19.7598 4.95906C19.7598 4.76633 19.7562 4.57433 19.7466 4.38448C20.6295 3.75648 21.395 2.974 22 2.08324Z"
      fill="currentColor"
    />
  </SvgIcon>
)
