import { FC, useCallback, useEffect, useRef, useState } from 'react'
import last from 'lodash/last'

import {
  Button,
  LoanFlexibleOption,
  LoanLowestOption,
  Notification,
  PageLevelError,
  ProductAttachmentsList,
} from '@common/react-lib-consumer-pres'
import { OfferSelectData } from '@src/api'
import { getLeadSource, getRequestId } from '@src/utils/storage'

import { useEffectIf, useLogger } from '../../../hooks'
import {
  trackFlexibleTermClicked,
  trackFlexibleTermDisplayed,
  trackLowestPaymentDisplayed,
  trackOfferSelected,
  trackOffersPresentedPageSubmited,
  trackRecommendedTopOfferDisplayed,
} from '../../../tracking'
import { Element, useModule } from '../../utils'

import { useOfferSelectionsForm } from './form'
import { useAddonProducts } from './use-addon-products'

type OfferSelectionsForm = {
  states: {
    totalMonthlyCost: string
  }

  module: {
    LowestOption: FC
    FlexibleOptions: FC
    ProductAttachments: FC
    Submit: FC
    SubmitError?: FC<{
      className?: string
    }>
  }
}

export const useOfferSelectionsFormModule = (
  offerSelectionData: OfferSelectData & {
    hasRecommendedTopOffer?: boolean
  },
): OfferSelectionsForm => {
  const {
    lowestOption,
    flexibleOptions,
    currentLoanInfo,
    hasRecommendedTopOffer,
  } = offerSelectionData

  const {
    selectOffer,
    selectedOffer,
    submit,
    handleSubmit,
    handleProductSelect,
    resetSelectedAddonProducts,
    selectedProducts,
    formState,
  } = useOfferSelectionsForm()

  const { isSubmitting, isSubmitSuccessful } = formState
  const logger = useLogger()

  const cardErrorRef = useRef<HTMLDivElement>()

  const [showError, setShowError] = useState(false)
  const [showPageError, setShowPageError] = useState(false)

  const hidePageWarning = useCallback(() => setShowPageError(false), [])

  const { addonProducts, totalMonthlyCost } = useAddonProducts({
    offerSelectionData,
    offerSelectedId: selectedOffer,
    selectedProductIds: selectedProducts,
  })

  const onSubmit = handleSubmit(
    async (data) => {
      try {
        const selectedProducts = addonProducts.filter((product) =>
          data.selectedProducts.includes(product.id),
        )

        void trackOffersPresentedPageSubmited({
          lowestOption,
          flexibleOptions,
          addonProducts: selectedProducts,
          data,
        })

        await submit(data)
      } catch (error) {
        setShowPageError(true)
        logger.error('Error submitting offer selection: ', { error })
      }
    },
    () => {
      setShowError(true)
      cardErrorRef.current?.scrollIntoView()
    },
  )

  const [selectedFlexibleTerm, selectFlexibleTerm] = useState(
    last(flexibleOptions.options)?.id,
  )

  const isFlexibleOptionSelected = flexibleOptions.options
    .map((o) => o.id)
    .includes(selectedOffer)

  const onFlexibleTermClicked = (id: string | number): void => {
    const offer = flexibleOptions.options.find((opt) => opt.id === id)
    selectFlexibleTerm(offer?.id)

    void trackFlexibleTermClicked({ term: offer.term, offerUUID: id })
    if (isFlexibleOptionSelected) {
      selectOffer(offer?.id)
    }
  }

  useEffect(() => {
    if (flexibleOptions.options.length > 0) {
      selectFlexibleTerm(last(flexibleOptions.options)?.id)
    }
  }, [flexibleOptions.options])

  useEffect(() => {
    if (lowestOption.id && hasRecommendedTopOffer) {
      void trackRecommendedTopOfferDisplayed({
        requestId: getRequestId(),
        id: lowestOption.id,
        term: lowestOption.term,
        rate: lowestOption.rate,
        monthlyPayment: lowestOption.monthlyPayment,
        amountFinanced: lowestOption.amountFinanced,
        leadSource: getLeadSource(),
      })
    }
  }, [hasRecommendedTopOffer, lowestOption])

  useEffectIf(lowestOption, () => {
    void trackLowestPaymentDisplayed({
      ...lowestOption,
      offerUUID: lowestOption.id,
    })
  })

  useEffectIf(flexibleOptions?.options.length > 0, () => {
    void trackFlexibleTermDisplayed({
      amountFinanced: flexibleOptions.amountFinanced,
      offers: flexibleOptions.options.map(({ id, ...opt }) => ({
        ...opt,
        offerUUID: id,
      })),
    })
  })

  const selectCard = useCallback(
    (optionId: string) => {
      selectOffer(optionId)
      resetSelectedAddonProducts()
      setShowError(false)
    },
    [selectOffer, setShowError, resetSelectedAddonProducts],
  )

  return {
    states: { totalMonthlyCost },
    module: useModule({
      LowestOption: Element(LoanLowestOption, {
        ...lowestOption,
        hasRecommendedTopOffer,
        currentMonthlyPayment: currentLoanInfo.monthlyPayment,
        onClick: () => {
          selectCard(lowestOption.id)
          void trackOfferSelected({
            term: lowestOption.term,
            offerType: 'Lowest Payment',
            offerUUID: lowestOption.id,
            amountFinanced: lowestOption.amountFinanced,
            monthlyPayment: lowestOption.monthlyPayment,
            nominalInterestRate: lowestOption.rate,
            combinedAgreementApr: lowestOption.annualPercentageRate,
          })
        },
        selected: selectedOffer === lowestOption.id,
      }),
      FlexibleOptions: Element(LoanFlexibleOption, {
        ...flexibleOptions,
        hasRecommendedTopOffer,
        currentMonthlyPayment: currentLoanInfo.monthlyPayment,
        selected: isFlexibleOptionSelected,
        onTermChange: onFlexibleTermClicked,
        selectedTerm: selectedFlexibleTerm,
        onSelect: () => {
          selectCard(selectedFlexibleTerm)
          const flexibleOpt = flexibleOptions.options.find(
            (opt) => opt.id === selectedFlexibleTerm,
          )
          void trackOfferSelected({
            term: flexibleOpt?.term,
            offerType: 'Flexible Term',
            offerUUID: selectedFlexibleTerm,
            amountFinanced: flexibleOpt?.amountFinanced,
            monthlyPayment: flexibleOpt?.monthlyPayment,
            nominalInterestRate: flexibleOpt?.rate,
            combinedAgreementApr: flexibleOpt?.annualPercentageRate,
          })
        },
      }),
      ProductAttachments:
        !addonProducts || addonProducts?.length === 0
          ? null
          : Element(ProductAttachmentsList, {
              items: addonProducts.map((product) => ({
                ...product,
                highlight: `${product.monthlyCost.toFixed(2)}/mo`,
              })),
              onSelect: handleProductSelect,
              selectedItems: selectedProducts,
            }),
      Submit: Element(Button, {
        'data-testid': 'offer-selections-submit',
        onClick: onSubmit,
        loading: isSubmitting || isSubmitSuccessful,
        children: 'Continue',
      }),
      SubmitError: showError
        ? Element(Notification, {
            ref: cardErrorRef,
            type: 'error',
            title: 'Error!',
            body: 'Please choose an offer before continuing',
            scrollIntoView: true,
            shake: true,
          })
        : showPageError &&
          Element(PageLevelError, {
            onClose: hidePageWarning,
          }),
    }),
  }
}
