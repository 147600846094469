import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EditIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0176 5C17.7571 5 17.5072 5.1035 17.323 5.28773L6.42506 16.1856C6.04059 16.5701 5.76783 17.0519 5.63596 17.5794L5.37439 18.6256L6.42066 18.3641C6.94816 18.2322 7.4299 17.9594 7.81437 17.575L18.7123 6.67704C18.8035 6.58582 18.8759 6.47752 18.9252 6.35833C18.9746 6.23915 19 6.1114 19 5.98239C19 5.85338 18.9746 5.72563 18.9252 5.60645C18.8759 5.48726 18.8035 5.37896 18.7123 5.28773C18.6211 5.19651 18.5128 5.12415 18.3936 5.07478C18.2744 5.02541 18.1466 5 18.0176 5ZM15.9088 3.87352C16.4681 3.31421 17.2267 3 18.0176 3C18.4093 3 18.7971 3.07714 19.1589 3.22702C19.5208 3.3769 19.8496 3.59658 20.1265 3.87352C20.4034 4.15046 20.6231 4.47924 20.773 4.84108C20.9229 5.20292 21 5.59074 21 5.98239C21 6.37404 20.9229 6.76186 20.773 7.1237C20.6231 7.48554 20.4034 7.81432 20.1265 8.09126L9.22859 18.9892C8.58779 19.63 7.78489 20.0846 6.90573 20.3043L4.24256 20.9701C3.90178 21.0553 3.54129 20.9555 3.29291 20.7071C3.04453 20.4587 2.94468 20.0982 3.02988 19.7575L3.69567 17.0943C3.91546 16.2151 4.37006 15.4122 5.01085 14.7714L15.9088 3.87352Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.293 10.7071L13.293 7.70711L14.7072 6.29289L17.7072 9.29289L16.293 10.7071Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C12 19.4477 12.4477 19 13 19L20 19C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21L13 21C12.4477 21 12 20.5523 12 20Z"
      fill="currentColor"
    />
  </SvgIcon>
)
