import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const WarningSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path d="M12 17H12.3125L12 16.9994V17Z" fill="currentColor" />
    <path
      d="M21.5363 17.8462L13.7944 4.35874C13.6278 4.02552 13.3717 3.74529 13.0547 3.54944C12.7378 3.35359 12.3726 3.24985 12 3.24985C11.6275 3.24985 11.2623 3.35359 10.9454 3.54944C10.6284 3.74529 10.3723 4.02552 10.2057 4.35874L2.4638 17.8462C2.31064 18.1521 2.23823 18.492 2.25345 18.8336C2.26868 19.1753 2.37103 19.5074 2.55078 19.7984C2.73052 20.0894 2.9817 20.3296 3.28043 20.4961C3.57916 20.6627 3.91552 20.7501 4.25755 20.75H19.7425C20.0846 20.7501 20.4209 20.6627 20.7197 20.4961C21.0184 20.3296 21.2696 20.0894 21.4493 19.7984C21.6291 19.5074 21.7314 19.1753 21.7466 18.8336C21.7619 18.492 21.6895 18.1521 21.5363 17.8462ZM12 17.625C11.8764 17.625 11.7556 17.5883 11.6528 17.5197C11.55 17.451 11.4699 17.3534 11.4226 17.2392C11.3753 17.125 11.3629 16.9993 11.3871 16.8781C11.4112 16.7568 11.4707 16.6455 11.5581 16.558C11.6455 16.4706 11.7569 16.4111 11.8781 16.387C11.9994 16.3629 12.125 16.3753 12.2392 16.4226C12.3534 16.4699 12.451 16.55 12.5197 16.6528C12.5884 16.7555 12.625 16.8764 12.625 17C12.625 17.1657 12.5592 17.3247 12.442 17.4419C12.3248 17.5591 12.1658 17.625 12 17.625ZM12.625 14.5C12.625 14.6657 12.5592 14.8247 12.442 14.9419C12.3248 15.0591 12.1658 15.125 12 15.125C11.8343 15.125 11.6753 15.0591 11.5581 14.9419C11.4409 14.8247 11.375 14.6657 11.375 14.5V9.49999C11.375 9.33423 11.4409 9.17526 11.5581 9.05805C11.6753 8.94083 11.8343 8.87499 12 8.87499C12.1658 8.87499 12.3248 8.94083 12.442 9.05805C12.5592 9.17526 12.625 9.33423 12.625 9.49999V14.5Z"
      fill="currentColor"
    />
  </SvgIcon>
)
