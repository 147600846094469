import { getAuthToken, getLeadSource, getRequestId } from '@src/utils/storage'

import { refiApiUrl } from '../config'

export type ResourceUuidInfo = {
  addressUuid: string
  vehicleUuid: string
  primaryCustomerUuid: string
  residenceUuid: string
  loanApplicationUuid: string
  offerGroupUuid: string
}

export const getResourceUuids = async (): Promise<ResourceUuidInfo> => {
  const request_id = getRequestId()
  const leadSource = getLeadSource()
  const response = await fetch(
    `${refiApiUrl}/services/v3/partner_lead_source/source_name/${leadSource}/request_id/${request_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}
