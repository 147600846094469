import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PeopleDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7969 13.0103C30.7969 16.882 27.7538 20.0207 24 20.0207C20.2462 20.0207 17.2032 16.882 17.2032 13.0103C17.2032 9.13864 20.2462 6 24 6C27.7538 6 30.7969 9.13864 30.7969 13.0103ZM16.9143 24.9787C19.4753 22.7984 22.4444 22.5011 25.8604 22.795C29.7289 22.795 34.5471 26.7751 34.5471 32.5144C34.5471 38.7887 34.6133 39.4339 34.4208 39.9896C34.3916 40.0739 34.3565 40.1561 34.3143 40.2555C33.5272 42.0532 32.9484 42.0486 24.1543 41.9784C22.0222 41.9613 19.4072 41.9404 16.1893 41.9404C14.6705 41.9404 13.4534 40.6616 13.4534 39.1186C13.4534 38.286 13.4365 37.4751 13.4202 36.6864C13.322 31.9504 13.2404 28.0138 16.9143 24.9787Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.0469 15.5889C42.0469 18.0365 40.1231 20.0207 37.75 20.0207C35.3769 20.0207 33.4531 18.0365 33.4531 15.5889C33.4531 13.1412 35.3769 11.157 37.75 11.157C40.1231 11.157 42.0469 13.1412 42.0469 15.5889ZM14.5469 15.5889C14.5469 18.0365 12.6231 20.0207 10.25 20.0207C7.8769 20.0207 5.95313 18.0365 5.95313 15.5889C5.95313 13.1412 7.8769 11.157 10.25 11.157C12.6231 11.157 14.5469 13.1412 14.5469 15.5889ZM14.4836 23.9987C12.9728 22.722 11.5893 22.7367 9.91737 22.7543H9.91729C9.71745 22.7565 9.51348 22.7586 9.30468 22.7586C6.37968 22.7586 4 25.1985 4 28.1969V36.9968C4 38.299 5.03047 39.3578 6.29765 39.3578C7.58231 39.3578 8.52896 39.3634 9.22551 39.3676C10.8084 39.377 11.0997 39.3787 11.1311 39.2893C11.1382 39.2691 11.132 39.2443 11.1245 39.2138C11.1175 39.1857 11.1094 39.1529 11.1094 39.1144C11.1094 38.2793 11.0965 37.474 11.0841 36.6952L11.0841 36.6951C11.0038 31.6587 10.9411 27.729 14.4836 23.9987ZM38.0836 22.7542C38.283 22.7563 38.4866 22.7584 38.6953 22.7584C41.6203 22.7584 43.9999 25.1983 43.9999 28.1967V36.9128C43.9999 38.2609 42.9327 39.3576 41.6203 39.3576C37.5046 39.3576 36.9318 39.421 36.879 39.284C36.8641 39.2453 36.8906 39.1907 36.8906 39.1142C36.8906 38.2486 36.9024 37.4232 36.9138 36.6325C36.9888 31.4133 37.042 27.7113 33.5164 23.9985C35.0263 22.7226 36.4037 22.7369 38.0836 22.7542Z"
      fill="currentColor"
    />
  </SvgIcon>
)
