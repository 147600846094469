import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PercentPolygonSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.665 3.04046C13.147 1.68411 10.8526 1.68411 9.3346 3.04046L9.09292 3.25641C8.75513 3.55824 8.32487 3.73646 7.87259 3.76189L7.54899 3.78008C5.51656 3.89435 3.8941 5.5168 3.77983 7.54924L3.76164 7.87283C3.73621 8.32512 3.558 8.75537 3.25616 9.09316L3.04021 9.33485C1.68386 10.8528 1.68386 13.1473 3.04021 14.6652L3.25616 14.9069C3.558 15.2447 3.73621 15.675 3.76164 16.1273L3.77983 16.4508C3.8941 18.4833 5.51656 20.1057 7.54899 20.22L7.87259 20.2382C8.32487 20.2636 8.75513 20.4418 9.09292 20.7437L9.3346 20.9596C10.8526 22.316 13.147 22.316 14.665 20.9596L14.9067 20.7437C15.2445 20.4418 15.6747 20.2636 16.127 20.2382L16.4506 20.22C18.483 20.1057 20.1055 18.4833 20.2198 16.4509L20.238 16.1273C20.2634 15.675 20.4416 15.2447 20.7434 14.9069L20.9594 14.6652C22.3157 13.1473 22.3157 10.8528 20.9594 9.33484L20.7434 9.09316C20.4416 8.75537 20.2634 8.32512 20.238 7.87283L20.2198 7.54924C20.1055 5.5168 18.483 3.89435 16.4506 3.78008L16.127 3.76189C15.6747 3.73646 15.2445 3.55824 14.9067 3.25641L14.665 3.04046ZM15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289ZM16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5ZM9.5 11C10.3284 11 11 10.3284 11 9.5C11 8.67157 10.3284 8 9.5 8C8.67157 8 8 8.67157 8 9.5C8 10.3284 8.67157 11 9.5 11Z"
      fill="currentColor"
    />
  </SvgIcon>
)
