import { useCallback } from 'react'
import { UseControllerReturn } from 'react-hook-form'

import {
  Select as SelectBase,
  SelectProps,
  TextField as TextFieldBase,
  TextFieldProps,
  TextMaskField as TextMaskFieldBase,
} from '@common/react-lib-base'

import { UseMapProps, withController } from '../utils'

type MappedTextFieldProps = Pick<
  TextFieldProps,
  'name' | 'error' | 'label' | 'placeholder' | 'onBlur' | 'onChange'
>

const useDefaultMapProps = <T extends MappedTextFieldProps>(
  { field, fieldState: { error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: MappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [field.onBlur, props.onChange],
  )

  const mergedOnBlur: MappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onChange],
  )

  return {
    ...props,
    ...field,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
    error: error?.message,
  }
}

export const SelectInput = withController(
  SelectBase,
  useDefaultMapProps as UseMapProps<SelectProps>,
)

export const TextField = withController(TextFieldBase, useDefaultMapProps)

export const TextMaskField = withController(
  TextMaskFieldBase,
  useDefaultMapProps,
)
