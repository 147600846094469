import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BlogV2DuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <rect
      opacity="0.24"
      x="11.6842"
      y="13.5789"
      width="30.3158"
      height="26.5263"
      rx="4"
      fill="currentColor"
    />
    <rect
      x="5"
      y="6.89471"
      width="32.3158"
      height="28.5263"
      rx="5"
      fill="currentColor"
      stroke="white"
      strokeWidth="2"
    />
    <rect x="10" y="12" width="6" height="6" rx="1" fill="white" />
    <rect x="10" y="20" width="23" height="11" rx="1" fill="white" />
    <rect
      x="18"
      y="12"
      width="15.1579"
      height="1.89474"
      rx="0.947368"
      fill="white"
    />
    <rect
      x="18"
      y="16"
      width="8.52632"
      height="1.89474"
      rx="0.947368"
      fill="white"
    />
  </SvgIcon>
)
