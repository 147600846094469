import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CameraIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3271 4.85641C6.89571 3.15059 8.49207 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V9.42097C2 7.53928 3.28064 5.89906 5.10615 5.44269L5.91139 5.24138C6.10666 5.19256 6.26345 5.04736 6.3271 4.85641ZM10.2902 4C9.35293 4 8.52085 4.59973 8.22447 5.48886C7.94458 6.32853 7.25511 6.967 6.39646 7.18166L5.59123 7.38297C4.65605 7.61676 4 8.45702 4 9.42097V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V9.42097C20 8.45702 19.3439 7.61676 18.4088 7.38297L17.6035 7.18166C16.7449 6.967 16.0554 6.32853 15.7755 5.48886C15.4791 4.59973 14.6471 4 13.7098 4H10.2902Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11ZM8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13Z"
      fill="currentColor"
    />
  </SvgIcon>
)
