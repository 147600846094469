import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneCallIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9349 6.98742C13.1407 6.4749 13.723 6.22623 14.2355 6.43201C15.0144 6.74472 15.719 7.21738 16.3038 7.81944C16.8887 8.42149 17.3406 9.13954 17.6306 9.92719C17.8214 10.4455 17.5559 11.0203 17.0376 11.2111C16.5194 11.4019 15.9445 11.1364 15.7537 10.6181C15.5604 10.093 15.2591 9.61433 14.8692 9.21296C14.4794 8.81159 14.0096 8.49648 13.4904 8.288C12.9778 8.08223 12.7292 7.49994 12.9349 6.98742Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3634 3.04785C13.5064 2.51438 14.0547 2.1978 14.5882 2.34074C16.2837 2.79506 17.8298 3.6877 19.071 4.92893C20.3123 6.17017 21.2049 7.71626 21.6592 9.41181C21.8022 9.94528 21.4856 10.4936 20.9521 10.6366C20.4187 10.7795 19.8703 10.4629 19.7274 9.92945C19.3639 8.57301 18.6498 7.33613 17.6568 6.34315C16.6638 5.35016 15.427 4.63605 14.0705 4.27259C13.5371 4.12965 13.2205 3.58131 13.3634 3.04785Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9684 5.21548C7.38659 4.4089 9.83024 5.92366 10.509 8.3761C10.9715 10.0472 10.5335 11.7996 9.46537 12.9995C9.68413 13.2828 9.92317 13.5552 10.1827 13.8149C10.4409 14.0733 10.7117 14.3115 10.9932 14.5295C12.1927 13.4529 13.9503 13.01 15.6263 13.4745C18.0782 14.1542 19.5902 16.5997 18.7848 19.0178C18.2565 20.6044 17.2712 21.5683 15.9571 21.8856C14.7361 22.1805 13.4197 21.867 12.2585 21.3844C9.91998 20.4122 7.6108 18.4974 6.5562 17.442C5.4767 16.3617 3.57009 14.0491 2.60677 11.715C2.1284 10.5559 1.82039 9.24428 2.1159 8.02889C2.43384 6.7213 3.3929 5.74096 4.9684 5.21548ZM8.58147 8.90958C8.16878 7.4185 6.79755 6.71369 5.60122 7.11273C4.54962 7.46346 4.18488 7.98485 4.05928 8.50141C3.91127 9.11016 4.03998 9.94514 4.45551 10.952C5.27998 12.9497 6.98986 15.0465 7.97094 16.0283C8.92827 16.9863 11.0228 18.7048 13.0263 19.5376C14.0361 19.9574 14.8752 20.0894 15.4877 19.9415C16.0071 19.8161 16.5332 19.4492 16.8873 18.3858C17.2867 17.1867 16.5807 15.8145 15.0921 15.4018C13.9842 15.0948 12.8451 15.4505 12.1672 16.1766C11.7295 16.6454 10.8951 16.9126 10.1851 16.4171C9.68559 16.0684 9.2112 15.6722 8.76793 15.2286C8.32396 14.7843 7.92757 14.3087 7.57884 13.8081C7.08641 13.1012 7.34917 12.27 7.81458 11.8312C8.53521 11.1518 8.88748 10.0153 8.58147 8.90958Z"
      fill="currentColor"
    />
  </SvgIcon>
)
