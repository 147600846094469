import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NavigationRectangleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2893 8.11003C15.3049 7.65479 16.3452 8.69513 15.89 9.71065L13.4608 15.1295C12.9578 16.2517 11.3825 16.2998 10.8119 15.2105L10.2177 14.0761C10.1519 13.9505 10.0495 13.8481 9.92394 13.7823L8.78952 13.1881C7.70021 12.6175 7.74835 11.0422 8.87047 10.5392L14.2893 8.11003ZM14.0135 9.9865L9.76247 11.8921L10.6661 12.3655C11.0799 12.5822 11.4178 12.9201 11.6345 13.3339L12.1079 14.2375L14.0135 9.9865Z"
      fill="currentColor"
    />
  </SvgIcon>
)
