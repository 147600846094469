import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const WashingtonDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M23.9998 4L18.7046 11.0312H29.2951L23.9998 4Z"
      fill="currentColor"
    />
    <path
      d="M32.1854 41.6562L30.0037 13.375H25.1818V39.3125H22.8182V13.375H17.9963L15.8146 41.6562H11V44H37V41.6562H32.1854Z"
      fill="currentColor"
    />
  </SvgIcon>
)
