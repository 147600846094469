import React, { FC } from 'react'

import { H1, H2, H3, HComponentType } from '@common/react-lib-base'
import { MaterialProps, sxToArray } from '@lib/mui'

type Variant = 1 | 2 | 3
const headings: { [key in Variant]: HComponentType } = {
  1: H1,
  2: H2,
  3: H3,
}

type Props = MaterialProps & {
  children?: React.ReactNode
  variant?: Variant
}

export const Heading: FC<Props> = ({
  children,
  variant = 3,
  className,
  sx,
}) => {
  const Component = headings[variant]
  return (
    <Component
      className={className}
      sx={[{ my: 2, maxWidth: '900px', textAlign: 'center' }, ...sxToArray(sx)]}
    >
      {children}
    </Component>
  )
}
