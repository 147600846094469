/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { SimpleFunctionComponent } from '@common/lib-types'

type Provider = SimpleFunctionComponent<{ children: React.ReactNode }>
export const withProviders =
  (...Providers: ReadonlyArray<Provider>) =>
  <TProps extends {}>(
    Component: SimpleFunctionComponent<TProps>,
  ): SimpleFunctionComponent<TProps> => {
    return (
      [...Providers, Component] as ReadonlyArray<
        SimpleFunctionComponent<TProps>
      >
    ).reduce((Prev: any, Curr) => {
      // eslint-disable-next-line react/display-name
      return (props) => (
        <Prev>
          <Curr {...props} />
        </Prev>
      )
    })
  }
