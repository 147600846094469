import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EditSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9088 3.87352C16.4681 3.31421 17.2267 3 18.0176 3C18.4093 3 18.7971 3.07714 19.1589 3.22702C19.5208 3.3769 19.8496 3.59658 20.1265 3.87352C20.4034 4.15046 20.6231 4.47924 20.773 4.84108C20.9229 5.20292 21 5.59074 21 5.98239C21 6.37404 20.9229 6.76186 20.773 7.1237C20.6231 7.48554 20.4034 7.81432 20.1265 8.09126L19.0231 9.19466C18.6326 9.58519 17.9994 9.58519 17.6089 9.19467L14.8054 6.39114C14.4148 6.00062 14.4148 5.36745 14.8054 4.97693L15.9088 3.87352ZM13.3911 7.80536C13.0006 7.41483 12.3675 7.41483 11.9769 7.80536L5.01085 14.7714C4.37006 15.4122 3.91546 16.2151 3.69567 17.0943L3.02988 19.7575C2.94468 20.0982 3.04453 20.4587 3.29291 20.7071C3.54129 20.9555 3.90178 21.0553 4.24256 20.9701L6.90573 20.3043C7.78489 20.0846 8.58779 19.63 9.22859 18.9892L16.1947 12.0231C16.5852 11.6326 16.5852 10.9994 16.1947 10.6089L13.3911 7.80536Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C12 19.4477 12.4477 19 13 19L20 19C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21L13 21C12.4477 21 12 20.5523 12 20Z"
      fill="currentColor"
    />
  </SvgIcon>
)
