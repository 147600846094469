import deepFreeze from 'deep-freeze'
import join from 'lodash/join'

import {
  FontScaleMap,
  GutterBreakpointMap,
  SpacingScaleMap,
} from './theme-types'

/**
 * Given a scale returns the appropriate fontSize and lineHeight.
 * The user must define all other text decoration like weight and text spacing.
 *
 * See theme.ts for usage examples.
 *
 * @param scale The desired scale setting for your font. 0 is the smallest, 7 the largest.
 */
export const defaultFontScale: FontScaleMap = deepFreeze({
  0: { fontSize: '.75rem', lineHeight: '1.5rem' },
  1: { fontSize: '1rem', lineHeight: '1.8rem' },
  2: { fontSize: '1.25rem', lineHeight: '1.875rem' },
  3: { fontSize: '1.5rem', lineHeight: '2.25rem' },
  4: { fontSize: '1.75rem', lineHeight: '2.25rem' },
  5: { fontSize: '2rem', lineHeight: '2.2rem' },
  6: { fontSize: '2.5rem', lineHeight: '2.75rem' },
  7: { fontSize: '3rem', lineHeight: '3.3rem' },
  inherit: { fontSize: 'inherit', lineHeight: 'inherit' },
})

/**
 * Given a scale returns a value in rem proportional to the scale value.
 *
 * 1 is the smallest, 7 the largest.
 *
 * @param scale
 */
export const defaultSpacingScale: SpacingScaleMap = deepFreeze({
  1: '0.5rem',
  2: '1rem',
  3: '2rem',
  4: '3.75rem',
  5: '8rem',
  6: '12rem',
  7: '18rem',
})

export const defaultPaperBoxShadow = '0px 4px 4px rgba(147, 144, 145, 0.5)'
const plusBoxShadow = '0px 24px 10px -1px rgba(0,0,0,0.04)'
export const defaultPaperPlusBoxShadow = join(
  [defaultPaperBoxShadow, plusBoxShadow],
  ',',
)

/**
 * Given a breakpoint defines the global page margin.
 */
export const defaultPageGutterBreakpointMap: GutterBreakpointMap = deepFreeze({
  xl: '20rem',
  lg: '10rem',
  md: '5rem',
  sm: '1rem',
  xs: '0.5rem',
})
