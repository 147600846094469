import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LockDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M24.0034 27.5939C23.3666 27.5939 22.8492 28.1192 22.8492 28.7657C22.8492 29.4123 23.3666 29.9376 24.0034 29.9376C24.6401 29.9376 25.1575 29.4123 25.1575 28.7657C25.1575 28.1192 24.6402 27.5939 24.0034 27.5939Z"
      fill="currentColor"
    />
    <path
      d="M35.5453 20.5625H12.4624C10.6312 20.5625 9 22.1141 9 24.0781V28.7656C9 37.1656 15.731 44 24.0038 44C32.2767 44 38.9615 37.1656 38.9615 28.7656C38.9615 24.9633 39.614 20.5625 35.5453 20.5625ZM25.158 32.0656V35.7969C25.158 36.4445 24.6417 36.9688 24.0038 36.9688C23.366 36.9688 22.8497 36.4445 22.8497 35.7969V32.0656C21.5086 31.5805 20.5414 30.2914 20.5414 28.7656C20.5414 26.8273 22.0941 25.25 24.0038 25.25C25.9128 25.25 27.4663 26.8273 27.4663 28.7656C27.4663 30.2914 26.4983 31.5805 25.158 32.0656Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      d="M23.9961 4C16.9958 4 11.3159 9.78273 11.3159 16.8906V18.0625C15.3709 18.0625 14.1778 18.0625 18.2254 18.0625V16.8906C18.2254 13.6599 20.8143 11.0312 23.9961 11.0312C27.1779 11.0312 29.7668 13.6599 29.7668 16.8906V18.0625C33.8144 18.0625 32.6366 18.0625 36.6917 18.0625V16.8906C36.6917 9.78273 30.9964 4 23.9961 4Z"
      fill="currentColor"
    />
  </SvgIcon>
)
