import { object, string } from 'yup'

import { VehicleFindBy } from '@common/types'

import { validVinTest } from './tests'
import { updateYupErrorMessages } from './yup'

updateYupErrorMessages()

export type VehicleLookupFormValues =
  | {
      findBy: typeof VehicleFindBy.VIN
      dynamic: {
        vin: string
      }
    }
  | {
      findBy: typeof VehicleFindBy.LICENSEPLATE
      dynamic: {
        licensePlateNumber: string
        state: string
      }
    }

export const VehicleLookup = object({
  findBy: string()
    .required()
    .oneOf(Object.values(VehicleFindBy), 'This field is required'),

  dynamic: object().when('findBy', (findBy) =>
    findBy === VehicleFindBy.LICENSEPLATE
      ? object({
          licensePlateNumber: string().required(),
          state: string().required(),
        })
      : findBy === VehicleFindBy.VIN
      ? object({
          vin: string()
            .required()
            .test(
              validVinTest(
                'VIN number is invalid. Please check the VIN number again.',
              ),
            ),
        })
      : object(),
  ),
})
