import React, { RefObject } from 'react'
import { NumericFormat, PatternFormat } from 'react-number-format'

import {
  NumberFormatInputProps,
  PatternFormatInputProps,
  TextFieldProps,
} from '@common/types'

import { TextField } from './text-field'

export const NumberFormatInput = React.forwardRef<
  RefObject<TextFieldProps>,
  NumberFormatInputProps
>((props, ref) => {
  return (
    <NumericFormat
      getInputRef={ref}
      customInput={TextField}
      thousandSeparator
      {...props}
    />
  )
})

export const PatternFormatInput = React.forwardRef<
  RefObject<TextFieldProps>,
  PatternFormatInputProps
>((props, ref) => {
  return <PatternFormat getInputRef={ref} customInput={TextField} {...props} />
})
