import React from 'react'
import startCase from 'lodash/startCase'

import {
  citizenshipDisplay,
  CitizenshipKeys,
  ERelationship,
  NewReviewBorrowerFormModule,
  NewReviewCoborrowerFormModule,
  ReviewInfoData,
} from '@common/types'

import { Format } from '../../format'
import {
  CalendarIcon,
  GlobeV2Icon,
  MailIcon,
  PhoneIcon,
  StackFields,
  UserIcon,
} from '../../reskin'
import {
  Container,
  FormRowContainer,
  FormRowName,
  FormRowValue,
  SaveCancelContainer,
  StyledIcon,
} from '../verify-form'

import { createValuesMap } from './utils'

type PersonalInfo = ReviewInfoData['borrower']
type CoBorrowerInfo = ReviewInfoData['coborrower']

export const mapCitizenshipToDisplay =
  createValuesMap<CitizenshipKeys>(citizenshipDisplay)

export const mapRelationshipToDisplay = (relationship: ERelationship): string =>
  startCase(relationship?.toLowerCase?.())

export const NewVerifyPersonalInfo = React.forwardRef<
  HTMLDivElement,
  NewReviewBorrowerFormModule & {
    data: PersonalInfo
  }
>(
  (
    {
      data,
      module: {
        FirstName,
        LastName,
        Email,
        PhoneNumber,
        Birthdate,
        Citizenship,
        HasCoborrower,
      },
      controls: { Save, Edit, Cancel, Popup },
      isEditing,
      submitError,
    },
    ref,
  ) => {
    return (
      <Container
        title={
          !isEditing ? 'Personal Information' : 'Edit Personal Information'
        }
        error={submitError}
        actions={!isEditing && <Edit />}
        ref={ref}
      >
        <>
          <Popup />
          {!isEditing && (
            <>
              {/* Name */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={UserIcon} />
                  {'Name'}
                </FormRowName>
                <FormRowValue>
                  <span>{`${data.firstName} ${data.lastName}`}</span>
                </FormRowValue>
              </FormRowContainer>
              {/* Email */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={MailIcon} />
                  {'Email address'}
                </FormRowName>
                <FormRowValue>{data.email}</FormRowValue>
              </FormRowContainer>
              {/* Phone number */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={PhoneIcon} />
                  {'Phone number'}
                </FormRowName>
                <FormRowValue>{Format.phone(data.phoneNumber)}</FormRowValue>
              </FormRowContainer>
              {/* Birthdate */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={CalendarIcon} />
                  {'Date of birth'}
                </FormRowName>
                <FormRowValue>{data.birthdate}</FormRowValue>
              </FormRowContainer>
              {/* HasCoborrower */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={UserIcon} />
                  {'Co-borrower'}
                </FormRowName>
                <FormRowValue>{data.hasCoborrower ? 'Yes' : 'No'}</FormRowValue>
              </FormRowContainer>
              {/* Citizenship */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={GlobeV2Icon} />
                  {'Citizenship status'}
                </FormRowName>
                <FormRowValue>
                  {mapCitizenshipToDisplay(data.citizenship)}
                </FormRowValue>
              </FormRowContainer>
              <Edit mobile />
            </>
          )}
          {isEditing && (
            <>
              <StackFields>
                <FirstName />
                <LastName />
                <Email />
                <PhoneNumber />
                <Birthdate />
                <HasCoborrower />
                <Citizenship />
              </StackFields>
              <SaveCancelContainer>
                <Cancel sx={{ width: '100%' }} />
                <Save sx={{ width: '100%' }} />
              </SaveCancelContainer>
            </>
          )}
        </>
      </Container>
    )
  },
)

export const NewVerifyCoBorrowerInfo = React.forwardRef<
  HTMLDivElement,
  {
    data: CoBorrowerInfo
  } & NewReviewCoborrowerFormModule
>(
  (
    {
      data,
      module: {
        FirstName,
        LastName,
        Email,
        PhoneNumber,
        Birthdate,
        Citizenship,
        Relationship,
      },
      controls: { Save, Edit, Cancel, Popup },
      isEditing,
      submitError,
    },
    ref,
  ): JSX.Element => {
    return (
      <Container
        title={
          !isEditing ? 'Coborrower Information' : 'Edit Coborrower Information'
        }
        error={submitError}
        actions={!isEditing && <Edit />}
        ref={ref}
      >
        <>
          <Popup />
          {!isEditing && (
            <>
              {/* Name */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={UserIcon} />
                  {'Name'}
                </FormRowName>
                <FormRowValue>
                  <span>{`${data.firstName} ${data.lastName}`}</span>
                </FormRowValue>
              </FormRowContainer>
              {/* Email address */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={MailIcon} />
                  {'Email address'}
                </FormRowName>
                <FormRowValue>{data.email}</FormRowValue>
              </FormRowContainer>
              {/* Phone Number */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={PhoneIcon} />
                  {'Phone number'}
                </FormRowName>
                <FormRowValue>{Format.phone(data.phoneNumber)}</FormRowValue>
              </FormRowContainer>
              {/* Birthdate */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={CalendarIcon} />
                  {'Date of birth'}
                </FormRowName>
                <FormRowValue>{data.birthdate}</FormRowValue>
              </FormRowContainer>
              {/* Relationship */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={UserIcon} />
                  {'Relationship to primary borrower'}
                </FormRowName>
                <FormRowValue>
                  {mapRelationshipToDisplay(data.relationship)}
                </FormRowValue>
              </FormRowContainer>
              {/* Citizenship */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={GlobeV2Icon} />
                  {'Citizenship status'}
                </FormRowName>
                <FormRowValue>
                  {mapCitizenshipToDisplay(data.citizenship)}
                </FormRowValue>
              </FormRowContainer>
              <Edit mobile />
            </>
          )}
          {isEditing && (
            <>
              <StackFields>
                <FirstName />
                <LastName />
                <Email />
                <PhoneNumber />
                <Birthdate />
                <Citizenship />
                <Relationship />
              </StackFields>
              <SaveCancelContainer>
                <Cancel sx={{ width: '100%' }} />
                <Save sx={{ width: '100%' }} />
              </SaveCancelContainer>
            </>
          )}
        </>
      </Container>
    )
  },
)
