import { FC } from 'react'
import { Box, Grid, styled } from '@mui/material'

import { Link, Text } from '@common/react-lib-consumer-pres'

const FooterContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignSelf: 'center',
  border: `solid 1px ${theme.extension.colors.divider.lightGray}`,
  borderRadius: '12px',
  padding: theme.spacing(2),
  flexDirection: 'row',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const CallUsInfoContainer = styled(Grid)(({ theme }) => ({
  flex: '0',
  minWidth: 'max-content',
  marginRight: theme.spacing(1),
}))

const CallUsInfoItems = styled(Box)({
  lineHeight: '1.5rem',
  alignSelf: 'center',
})

const BusinessHoursContainer = styled(Grid)(({ theme }) => ({
  flex: '1',
  minWidth: 'max-content',
  borderLeft: `solid 1px ${theme.extension.colors.divider.lightGray}`,

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
    borderLeft: 'none',
    alignSelf: 'flex-start',
  },
}))

const BusinessHours = styled(Grid)(({ theme }) => ({
  color: theme.extension.colors.text.bodyGray,
  ...theme.extension.typography.subheadings['14m'],
  margin: theme.spacing(0, 2),
  display: 'flex',

  '& > div': {
    marginLeft: theme.spacing(1),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'block',

    '& > div': {
      marginLeft: 0,
    },
  },

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0),
  },
}))

export const CommonFooter: FC = () => {
  return (
    <FooterContainer item container>
      <CallUsInfoContainer item container>
        <CallUsInfoItems>
          <Text
            use={['p', { xs: 'subheadings.16b', sm: 'subheadings.18b' }]}
            color="text.softBlack"
          >
            Need help? Call us at{' '}
            <Link href={`tel:${'1-877-445-0070'}`}>{'1.877.445.0070'}</Link>
          </Text>
        </CallUsInfoItems>
      </CallUsInfoContainer>

      <BusinessHoursContainer item container>
        <BusinessHours item container>
          <div>Mon - Fri 9 AM - 8 PM EST</div>
          <div>Sat - Sun 9 AM - 4 PM EST</div>
        </BusinessHours>
      </BusinessHoursContainer>
    </FooterContainer>
  )
}
