import { useCallback, useEffect, useState } from 'react'
import { UseControllerReturn } from 'react-hook-form'

import { omitSafe, pickSafe } from '@common/lib-types'
import {
  Checkbox as CheckboxBase,
  CheckBoxGroup as CheckBoxGroupBase,
  CurrencyTextField,
  DateTextField as DateTextFieldBase,
  NumberFormatInput as NumberFormatInputBase,
  PatternFormatInput as PatternFormatInputBase,
  PhoneNumberField as PhoneNumberFieldBase,
  RadioGroup as RadioGroupBase,
  SelectField as SelectBase,
  TextField as TextFieldBase,
  VehicleLookupField as VehicleLookupFieldBase,
} from '@common/react-lib-consumer-pres'
import { SelectFieldProps, TextFieldProps } from '@common/types'

import { UseMapProps, withController } from '../utils'

type MappedTextFieldProps = Pick<
  TextFieldProps,
  'name' | 'error' | 'label' | 'placeholder' | 'onBlur' | 'onChange'
>

const useDefaultMapProps = <T extends MappedTextFieldProps>(
  { field, fieldState: { error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: MappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [field.onBlur, props.onChange],
  )

  const mergedOnBlur: MappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onChange],
  )

  return {
    ...props,
    ...field,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
    error: error?.message,
  }
}

const useForceRerender = (): (() => void) => {
  const [, setValue] = useState(0)
  return () => setValue((v) => v + 1)
}

const useFormattedMapProps = <T extends MappedTextFieldProps>(
  controller: UseControllerReturn,
  props: T,
): T => {
  // For some reason when passing ref from RHF to Formatted field it removes
  // initial formatting. This is a workaround
  const forceRerender = useForceRerender()
  useEffect(() => {
    forceRerender()
  }, [])

  const mappedProps = useDefaultMapProps(controller, props)
  return mappedProps
}

export const PhoneInput = withController(
  PhoneNumberFieldBase,
  useFormattedMapProps,
)

export const DateInput = withController(DateTextFieldBase, useDefaultMapProps)

export const TextField = withController(TextFieldBase, useDefaultMapProps)

export const NumberInput = withController(
  NumberFormatInputBase,
  useFormattedMapProps,
)

export const PatternInput = withController(
  PatternFormatInputBase,
  useFormattedMapProps,
)

export const CurrencyInput = withController(
  CurrencyTextField,
  useFormattedMapProps,
)

export const Select = withController(
  SelectBase,
  useDefaultMapProps as unknown as UseMapProps<SelectFieldProps>,
)

export const RadioGroup = withController(
  RadioGroupBase,
  (controller, props) => ({
    ...props,
    ...omitSafe(controller.field, ['onBlur']),
    error: controller.fieldState.error?.message,
  }),
)

export const VehicleLookupField = withController(
  VehicleLookupFieldBase,
  useDefaultMapProps,
)

export const CheckboxField = withController(
  CheckboxBase,
  ({ field, fieldState }, props) => {
    return {
      ...props,
      ...pickSafe(field, ['name', 'ref']),
      checked: field.value,
      error: fieldState.error?.message,
      onChange: (...args) => {
        field.onChange(...args)
        props.onChange?.(...args)
      },
    }
  },
)

export const CheckboxGroupField = withController(
  CheckBoxGroupBase,
  ({ field, fieldState }, props) => {
    return {
      ...props,
      ...pickSafe(field, ['name', 'ref']),
      selectedOptions: field.value,
      error: fieldState.error?.message,
      onChange: (...args) => {
        field.onChange(...args)
        props.onChange?.(...args)
      },
    }
  },
)
