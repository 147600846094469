import React, { FC } from 'react'
import { Box, styled } from '@mui/material'
import Image from 'next/legacy/image'

import { trackDirectMailAdditionalOffersClicked } from '@src/tracking'

import { Link, Text } from './reskin'

const Container = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
}))

const ImageContainer = styled(Box)({
  height: '100%',
})

const TextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '608px',
  justifyContent: 'center',
}))

const handleCtaClicked = async (): Promise<void> => {
  void (await trackDirectMailAdditionalOffersClicked({
    property: 'Call button',
    section_name: 'body',
  }))
}

export const DirectMailAdditionalOffersOptions: FC = () => {
  return (
    <Container>
      <ImageContainer>
        <Image
          src="/assets/dm-additional-icon.svg"
          alt="Direct Mail Icon"
          width="250"
          height="250"
        />
      </ImageContainer>
      <TextContainer>
        <Text
          use={['h2', 'subheadings.24m']}
          color="text.softBlack"
          mt={{ xs: 3, md: 4 }}
          mb={1}
          textAlign={'center'}
        >
          {
            'Based on the information you provided, our offer for you may not beat your current loan.'
          }
        </Text>
        <Text
          use={['p', { xs: 'subheadings.16', md: 'subheadings.18' }]}
          color="text.softBlack"
          textAlign={'center'}
        >
          {'Please give us a call to see if we can still help you.'}
        </Text>
        <Text
          use={['p', { xs: 'subheadings.16', md: 'subheadings.18' }]}
          color="text.softBlack"
          textAlign={'center'}
          maxWidth={{ xs: '230px', md: '100%' }}
          alignSelf={'center'}
        >
          {'Mon - Fri 9 AM - 8 PM EST, Sat - Sun 9 AM - 4 PM EST. at'}
        </Text>
        <Text
          use={['p', { xs: 'subheadings.16b', md: 'subheadings.18b' }]}
          color="base.blueSky"
          textAlign={'center'}
        >
          <Link href={`tel:${'1.877.275.0018'}`} onClick={handleCtaClicked}>
            {'1.877.275.0018'}
          </Link>
        </Text>
      </TextContainer>
    </Container>
  )
}
