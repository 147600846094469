import { DateTime } from 'luxon'

import { OmitSafe } from '@common/lib-types'
import { ResidenceStatus, ResidenceStatusKeys } from '@common/types'
import { parseInteger } from '@src/utils'
import { getAuthToken } from '@src/utils/storage'

import { refiApiUrl } from '../config'

type PrequalResidenceResponse = {
  addressUuid: string
  city: string | null
  county: string | null
  monthlyPayment: number | null
  moveInDate: string | null
  moveOutDate: string | null
  residenceStatus: string | null
  residenceUuid: string | null
  state: string | null
  streetAddress1: string
  streetAddress2: string | null
  streetAddress3: string | null
  streetAddress4: string | null
  zip: string | null
}

type RefiPrequalCustomerResidencesResponse = {
  residences: Array<PrequalResidenceResponse>
}

export type PrequalResidence = OmitSafe<
  PrequalResidenceResponse,
  'residenceStatus'
> & {
  residenceStatus?: ResidenceStatusKeys
}

const fromRefiResidenceStatusMap: Record<string, ResidenceStatusKeys> = {
  Own: ResidenceStatus.own,
  Rent: ResidenceStatus.rent,
  Other: ResidenceStatus.other,
  'Owns Free And Clear': ResidenceStatus.ownsFreeAndClear,
  'Lives With Family': ResidenceStatus.livesWithFamily,
  'Military Housing': ResidenceStatus.militaryHousing,
}

export const getCurrentResidence = async (
  primaryCustomerUuid: string,
): Promise<PrequalResidence> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/customer/${primaryCustomerUuid}/residences`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const residencesResponse = await response.json()

  if (!response.ok) {
    throw new Error(residencesResponse?.message)
  }

  if (residencesResponse.residences.length === 0) {
    return
  }

  // The residence that the customer currently lives in
  // is the one that moveOutDate is null
  const residence = (
    residencesResponse as RefiPrequalCustomerResidencesResponse
  ).residences.find((residence) => !residence.moveOutDate)

  return {
    ...residence,
    residenceStatus: fromRefiResidenceStatusMap[residence.residenceStatus],
  }
}

const toRefiResidenceStatusMap: Record<ResidenceStatusKeys, string> = {
  [ResidenceStatus.own]: 'Own',
  [ResidenceStatus.rent]: 'Rent',
  [ResidenceStatus.other]: 'Other',
  [ResidenceStatus.ownsFreeAndClear]: 'OwnsFreeAndClear',
  [ResidenceStatus.livesWithFamily]: 'LivesWithFamily',
  [ResidenceStatus.militaryHousing]: 'MilitaryHousing',
}

export const calculateDateFromDuration = (
  years: number,
  months: number,
): string => {
  const currentTime = DateTime.now()
  const moveInDate = currentTime.minus({ years, months }).toISODate()
  return moveInDate
}

export const updateCurrentResidence = async (
  uuid: string,
  customerUuid: string,
  addressUuid: string,
  livedInYears: number,
  livedInMonths: number,
  residenceStatus?: ResidenceStatusKeys,
  monthlyPayment?: string,
): Promise<void> => {
  const refiResidenceStatus = toRefiResidenceStatusMap[residenceStatus]
  const body = JSON.stringify({
    addressUuid,
    customerUuid,
    moveInDate: calculateDateFromDuration(livedInYears, livedInMonths),
    residenceStatus: refiResidenceStatus,
    monthlyPayment: parseInteger(monthlyPayment),
  })
  const response = await fetch(`${refiApiUrl}/services/v1/residence/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body,
  })
  if (!response.ok) {
    const data = await response.json()
    throw new Error(data?.errors)
  }
}
