export const EmploymentStatus = {
  Employed: 'EMPLOYED',
  Retired: 'RETIRED',
  SelfEmployed: 'SELF_EMPLOYED',
  Student: 'STUDENT',
  Unemployed: 'UNEMPLOYED',
} as const

export type EmploymentStatusKeys =
  (typeof EmploymentStatus)[keyof typeof EmploymentStatus]
