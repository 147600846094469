import { omitSafe, pickSafe } from '@common/lib-types'
import { CustomerInfo, ERelationship, VehicleInfo } from '@common/types'

import { customerAdapters, getNewResumeUrl, vehicleAdapters } from '../api'
import type { Logger } from '../hooks'

import { identify } from './tracking'

const trackPartnerUserInfo = (
  borrower: CustomerInfo,
  vehicle: VehicleInfo,
  coborrower?: CustomerInfo,
  relationship?: ERelationship,
  newResumeUrl?: string,
): void => {
  identify(borrower.segmentUserId, {
    ...borrower,
    newResumeUrl,
    phone: borrower.phoneNumber,
    vehicle: {
      ...pickSafe(vehicle, ['estimatedMonthlyPayment', 'vin']),
      loanBalance: vehicle.loanValue,
      mileage: vehicle.estimatedMileage,
      licensePlate: vehicle.licensePlateNumber,
      state: vehicle.licensePlateState,
      attributes: pickSafe(vehicle, ['year', 'make', 'model', 'trim']),
    },
    ...(coborrower && {
      coborrower: {
        ...omitSafe(coborrower, ['phoneNumber']),
        relationship,
        phone: coborrower.phoneNumber,
      },
    }),
  })
}

export const fetchAndTrackPartnerLead = async (
  logger: Logger,
): Promise<void> => {
  try {
    const [{ borrower, coborrower, relationship }, vehicle, { resumeUrl }] =
      await Promise.all([
        customerAdapters.getCustomers(),
        vehicleAdapters.getVehicle(),
        getNewResumeUrl(),
      ])

    trackPartnerUserInfo(borrower, vehicle, coborrower, relationship, resumeUrl)
  } catch (error) {
    logger.error(`failed to fetchAndTrackPartnerLead: ${error}`)
  }
}
