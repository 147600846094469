import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ImageIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8ZM6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9274 14.0745C15.4971 13.7159 14.8562 13.7806 14.5063 14.218L12.9072 16.217C11.946 17.4185 10.2257 17.6856 8.94541 16.8321C8.52834 16.554 7.96792 16.641 7.65479 17.0324L4.78098 20.6247L3.21924 19.3753L6.09305 15.783C7.05425 14.5816 8.77456 14.3145 10.0548 15.168C10.4719 15.446 11.0323 15.359 11.3454 14.9676L12.9446 12.9686C13.9943 11.6565 15.9169 11.4623 17.2078 12.538L21.6403 16.2318L20.3599 17.7682L15.9274 14.0745Z"
      fill="currentColor"
    />
  </SvgIcon>
)
