export type Image = {
  src: string
  title: string
  width: number
  height: number
}

export enum ERelationship {
  spouse = 'SPOUSE',
  parent = 'PARENT',
  child = 'CHILD',
  significantOther = 'SIGNIFICANT_OTHER',
  friend = 'FRIEND',
  // SELF not needed for UI
}
