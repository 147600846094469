import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { PrivacyPolicy } from '@common/react-lib-consumer-pres/privacy-policy'
import { NewPersonalFormModule as PersonalFormModule } from '@common/types'

import { ButtonFineText, ProgressBar, Text } from '../../reskin'

import { PersonalInformationForm } from './personal-information-form'

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  width: '100%',
}))

const CosignerContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',

  '& > *': {
    marginRight: theme.spacing(2),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    '& > *:not(last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))

type Props = {
  module: PersonalFormModule
  Banner?: FC
}
export const PersonalAndCoborrowerForm: FC<Props> = ({
  module: { SelectCosigner, Submit, borrower, coborrower },
  Banner,
}) => {
  return (
    <>
      <Box width="100%">
        <ProgressBar activeStep={0} />
      </Box>
      <Banner></Banner>
      <Text
        use={['h2', { xs: 'headings.32', md: 'headings.48' }]}
        color="text.softBlack"
        mt={{ xs: 4, md: 6 }}
        mb={2}
      >
        Share a bit about yourself
      </Text>

      <Section>
        <PersonalInformationForm module={borrower} />
      </Section>

      <Section>
        <Text use={['h3', 'subheadings.24b']} color="text.softBlack" mb={3}>
          {'Is there anyone else on your current loan?'}
        </Text>
        <CosignerContainer>
          <SelectCosigner />
        </CosignerContainer>
      </Section>

      {coborrower && (
        <>
          <Text
            use={['h2', { xs: 'headings.32', md: 'headings.48' }]}
            color="text.softBlack"
            mt={{ xs: 4, md: 6 }}
            mb={2}
          >
            {'Tell us about your co-borrower'}
          </Text>

          <Section>
            <PersonalInformationForm module={coborrower} />
          </Section>
        </>
      )}

      <Section>
        <PrivacyPolicy />
      </Section>

      <Section>
        <ButtonFineText button={Submit} />
      </Section>
    </>
  )
}
