import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShieldFailIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2747 4.11851C12.0952 4.06721 11.9048 4.06721 11.7253 4.11851L6.72528 5.54708C6.29598 5.66974 6 6.06212 6 6.5086V11.8831C6 14.1437 7.09176 16.2652 8.93133 17.5792L11.4188 19.3559C11.7665 19.6043 12.2335 19.6043 12.5812 19.3559L15.0687 17.5792C16.9082 16.2652 18 14.1437 18 11.8831V6.5086C18 6.06212 17.704 5.66974 17.2747 5.54708L12.2747 4.11851ZM11.1758 2.19546C11.7145 2.04156 12.2855 2.04156 12.8242 2.19546L17.8242 3.62403C19.1121 3.992 20 5.16916 20 6.5086V11.8831C20 14.7896 18.5963 17.5173 16.2311 19.2067L13.7437 20.9834C12.7006 21.7285 11.2994 21.7285 10.2563 20.9834L7.76886 19.2067C5.40369 17.5173 4 14.7896 4 11.8831V6.5086C4 5.16916 4.88793 3.992 6.17584 3.62403L11.1758 2.19546Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289L12 9.58579L13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711L13.4142 11L14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071C14.3166 14.0976 13.6834 14.0976 13.2929 13.7071L12 12.4142L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.90237 13.3166 8.90237 12.6834 9.29289 12.2929L10.5858 11L9.29289 9.70711C8.90237 9.31658 8.90237 8.68342 9.29289 8.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
