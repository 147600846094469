import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PieChartSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0304 5.9513C12.0304 4.88247 11.1352 3.86067 9.90188 4.01565C8.51526 4.18989 7.18259 4.68447 6.01205 5.4666C4.5275 6.45855 3.37044 7.86843 2.68717 9.51797C2.00391 11.1675 1.82514 12.9826 2.17346 14.7338C2.52179 16.4849 3.38156 18.0934 4.64407 19.3559C5.90657 20.6184 7.5151 21.4782 9.26624 21.8265C11.0174 22.1749 12.8325 21.9961 14.482 21.3128C16.1316 20.6296 17.5415 19.4725 18.5334 17.988C19.3155 16.8174 19.8101 15.4847 19.9844 14.0981C20.1393 12.8648 19.1175 11.9696 18.0487 11.9696H13.0335C12.4795 11.9696 12.0304 11.5205 12.0304 10.9665V5.9513Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1536 2.02043C13.8986 1.83992 13 2.88595 13 3.95207V9.89247C13 10.5041 13.4959 11 14.1075 11L20.0479 11C21.1141 11 22.1601 10.1014 21.9796 8.84637C21.8842 8.18302 21.7061 7.53266 21.4485 6.91073C21.0437 5.93348 20.4504 5.04552 19.7024 4.29757C18.9545 3.54962 18.0665 2.9563 17.0893 2.55151C16.4673 2.2939 15.817 2.11585 15.1536 2.02043Z"
      fill="currentColor"
    />
  </SvgIcon>
)
