import React, { FC, ReactElement } from 'react'
import { Checkbox, FormControlLabel, styled } from '@mui/material'

import { OldTheme } from '@modules/core-ui'

import { Error } from './error'

const ParentLabel = styled(FormControlLabel)({
  display: 'flex',
  alignItems: 'flex-start',
})
const ChildLabel = styled('div')(({ theme }: { theme: OldTheme }) => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  paddingTop: theme.spacing(1.125),
  color: theme.palette.grey['900'],
}))

const TermsTextContent: FC<{
  children: string | ReactElement
}> = ({ children }) => {
  return <ChildLabel>{children}</ChildLabel>
}

export interface CheckboxProps {
  name?: string
  value: boolean
  onChange: () => void
  errorMessage?: string
  children: string | ReactElement
  disabled?: boolean
}

export const TermsCheckBox: React.FC<CheckboxProps> = React.forwardRef(
  ({ errorMessage, children, ...props }, ref) => {
    return (
      <>
        <ParentLabel
          ref={ref}
          checked={props.value}
          control={<Checkbox {...props} />}
          label={<TermsTextContent>{children}</TermsTextContent>}
        />
        <Error>{errorMessage}</Error>
      </>
    )
  },
)
