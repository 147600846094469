import React, { FC } from 'react'

import { NotificationProps } from '@common/types'

import { Notification } from './notification'

export const PageLevelError: FC<
  Pick<NotificationProps, 'onClose' | 'className' | 'sx'>
> = (props) => {
  return (
    <Notification
      {...props}
      type="error"
      title="Something went wrong!"
      body="Please verify your information and try again or contact us at 1-877-445-0070."
      scrollIntoView
      shake
    />
  )
}
