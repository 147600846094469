/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Notification } from '@common/react-lib-consumer-pres'
import { VehicleFindBy } from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useFetchDataWithDependencies } from '../../../hooks'
import { VehicleFormValues } from '../../schemas'
import { Element } from '../../utils'
import { Select } from '../controlled-fields'

export const useMakeModel = ({
  control,
  watch,
  setValue,
  getValues,
}: UseFormReturn<VehicleFormValues>) => {
  const [findBy, year, make, model] = watch([
    'findBy',
    'dynamic.year',
    'dynamic.make',
    'dynamic.model',
  ])
  useEffect(() => {
    if (getValues('findBy') === VehicleFindBy.MAKEMODEL) {
      setValue('dynamic.make', '' as any as never)
      setValue('dynamic.model', '' as any as never)
      setValue('dynamic.trim', '' as any as never)
    }
  }, [year])
  useEffect(() => {
    if (getValues('findBy') === VehicleFindBy.MAKEMODEL) {
      setValue('dynamic.model', '' as any as never)
      setValue('dynamic.trim', '' as any as never)
    }
  }, [make])
  useEffect(() => {
    if (getValues('findBy') === VehicleFindBy.MAKEMODEL) {
      setValue('dynamic.trim', '' as any as never)
    }
  }, [model])

  // TODO: slice supported years ? Igor ? Do I need to slice ??
  const years = useFetchDataWithDependencies(
    () =>
      findBy === VehicleFindBy.MAKEMODEL
        ? vehicleAdapters.getAvailableYears()
        : null,
    [findBy],
  )

  const makes = useFetchDataWithDependencies(
    () => (year ? vehicleAdapters.getMakesByYear(year) : null),
    [year],
  )

  const models = useFetchDataWithDependencies(
    () => (year && make ? vehicleAdapters.getModels({ year, make }) : null),
    [year, make],
  )

  const trims = useFetchDataWithDependencies(
    () =>
      year && make && model
        ? vehicleAdapters.getTrims({ year, make, model })
        : null,
    [year, make, model],
  )

  const hasNetworkError =
    years.isError || makes.isError || models.isError || trims.isError

  const [showWarning, setShowWarning] = useState(false)
  const hideWarning = useCallback(() => setShowWarning(false), [])
  useEffect(() => {
    setShowWarning(hasNetworkError)
  }, [hasNetworkError])

  return {
    Warning:
      // TODO: error handling -> we should make another request in 30 seconds & show a different message
      // TODO: discuss the best UX to avoid refreshing the browser (all data will be lost)
      showWarning &&
      Element(Notification, {
        type: 'warning',
        title: 'Let’s give it a minute',
        body: 'We’re having trouble reaching the NADA service. Try using a different method of finding your car.',
        onClose: hideWarning,
        scrollIntoView: true,
        shake: true,
      }),
    fields: {
      Year: Element(Select, {
        label: 'Vehicle year',
        control,
        name: 'dynamic.year',
        loading: years.loading,
        menuItems: [
          { value: '', displayName: 'Select year' },
          ...(years.data ?? []).map((year) => ({
            value: year,
            displayName: year,
          })),
        ],
        'data-testid': 'select-vehicle-year',
      }),
      Make: Element(Select, {
        label: 'Vehicle make',
        control,
        name: 'dynamic.make',
        disabled: !year,
        loading: makes.loading,
        menuItems: [
          { value: '', displayName: 'Select make' },
          ...(makes.data ?? []),
        ],
        'data-testid': 'select-vehicle-make',
      }),
      Model: Element(Select, {
        label: 'Vehicle model',
        control,
        name: 'dynamic.model',
        disabled: !make,
        loading: models.loading,
        menuItems: [
          { value: '', displayName: 'Select model' },
          ...(models.data ?? []),
        ],
        'data-testid': 'select-vehicle-model',
      }),
      Trim: Element(Select, {
        label: 'Vehicle trim',
        control,
        name: 'dynamic.trim',
        disabled: !model,
        loading: trims.loading,
        menuItems: [
          { value: '', displayName: 'Select trim' },
          ...(trims.data ?? []),
        ],
        'data-testid': 'select-vehicle-trim',
      }),
    },
    getNadaUid: (trimValue: string) =>
      trims.data?.find((trim) => trim.value === trimValue)?.id,
  }
}
