import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BagCheckSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44716 2.10557C9.94114 2.35256 10.1414 2.95323 9.89438 3.44721L8.10552 7.02492C7.85853 7.5189 7.25786 7.71912 6.76388 7.47213C6.2699 7.22514 6.06968 6.62447 6.31667 6.13049L8.10552 2.55278C8.35251 2.0588 8.95318 1.85858 9.44716 2.10557Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5529 2.10558C15.0469 1.85859 15.6475 2.05882 15.8945 2.5528L17.6834 6.1305C17.9304 6.62448 17.7301 7.22516 17.2362 7.47214C16.7422 7.71913 16.1415 7.51891 15.8945 7.02493L14.1057 3.44722C13.8587 2.95324 14.0589 2.35257 14.5529 2.10558Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45925 6C4.02505 6 2.1552 8.15595 2.49945 10.5657L3.51965 17.7071C3.87155 20.1704 5.98115 22 8.4694 22H15.531C18.0193 22 20.1289 20.1704 20.4808 17.7071L21.501 10.5657C21.8452 8.15595 19.9754 6 17.5412 6H6.45925ZM15.6839 11.2705C16.0868 11.6482 16.1072 12.281 15.7295 12.6839L11.9795 16.6839C11.6213 17.0661 11.0289 17.107 10.6215 16.7778L8.37145 14.9596C7.94189 14.6125 7.87506 13.9829 8.22218 13.5533C8.5693 13.1237 9.19893 13.0569 9.62849 13.404L11.1559 14.6383L14.2704 11.3161C14.6482 10.9131 15.281 10.8927 15.6839 11.2705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
