import React, { FC } from 'react'

import { Box, Link } from '@common/react-lib-base'

export const TermsAndConditionsLabel: FC = () => {
  return (
    <Box>
      {'I have read and agree to the '}
      <Link href={'https://www.caribou.com/terms-of-use'} target={'_blank'}>
        {'Caribou Terms & Conditions'}
      </Link>
      {' and '}
      <Link href="https://www.caribou.com/creditreportauthorization">
        Credit Report Authorization
      </Link>
      <Box>
        *This estimated monthly payment is calculated using a 30 day period to
        first payment. This estimated monthly payment could vary with the final
        monthly payment displayed in your lender’s contract depending upon the
        number of days your lender allows until your first monthly payment.
      </Box>
    </Box>
  )
}
