import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { Box } from '@common/react-lib-base'
import { SelectablePanel as Props } from '@common/types'
import { makeStyles, Theme } from '@modules/core-ui'

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '60px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '6px',
    cursor: (props) => (props.onClick ? 'pointer' : 'initial'),
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: '0px 0px 5px 0 rgba(0,0,0,0.15)',
    padding: '0 5px',

    '&:hover': {
      backgroundColor: theme.select.colorByCoords({
        paletteName: 'tertiary',
        colorName: 'light',
      }),

      '& > div': {
        boxShadow: '0px 0px 5px 0 rgba(0,0,0,0.1)',
      },
    },
  },

  active: {
    backgroundColor: theme.select.colorByCoords({
      paletteName: 'tertiary',
      colorName: 'light',
    }),

    '& > div': {
      boxShadow: '0px 0px 5px 0 rgba(0,0,0,0.1)',
    },
  },

  tag: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.select.colorByCoords({
      paletteName: 'tertiary',
      colorName: 'contrast',
    }),
    borderRadius: '2px',
    padding: '5px 7px',
  },
}))

export const SelectablePanel: FC<Props> = (props) => {
  const { active, name, children, className, onClick, tag } = props

  const classes = useStyles(props)

  const handleOnClick = useCallback(() => onClick?.(name), [onClick, name])

  return (
    <div
      className={cx(classes.container, active && classes.active, className)}
      onClick={handleOnClick}
    >
      {tag && <Box className={cx(classes.tag)}>{tag}</Box>}
      {children}
    </div>
  )
}
