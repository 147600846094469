import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const GenderIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M18.6406 2.625H15.75C15.5221 2.625 15.3035 2.71554 15.1423 2.87671C14.9812 3.03787 14.8906 3.25645 14.8906 3.48438C14.8906 3.7123 14.9812 3.93088 15.1423 4.09204C15.3035 4.25321 15.5221 4.34375 15.75 4.34375H16.566L15.1168 5.79258C14.5384 5.11742 13.8206 4.5755 13.0129 4.20403C12.2051 3.83256 11.3266 3.64036 10.4375 3.64062C7.03437 3.64062 4.26562 6.40937 4.26562 9.8125C4.26562 12.9242 6.58047 15.5047 9.57812 15.9242V17.3906H8.48438C8.25645 17.3906 8.03787 17.4812 7.87671 17.6423C7.71554 17.8035 7.625 18.0221 7.625 18.25C7.625 18.4779 7.71554 18.6965 7.87671 18.8577C8.03787 19.0188 8.25645 19.1094 8.48438 19.1094H9.57812V20.5156C9.57812 20.7435 9.66867 20.9621 9.82983 21.1233C9.99099 21.2845 10.2096 21.375 10.4375 21.375C10.6654 21.375 10.884 21.2845 11.0452 21.1233C11.2063 20.9621 11.2969 20.7435 11.2969 20.5156V19.1094H12.3906C12.6185 19.1094 12.8371 19.0188 12.9983 18.8577C13.1595 18.6965 13.25 18.4779 13.25 18.25C13.25 18.0221 13.1595 17.8035 12.9983 17.6423C12.8371 17.4812 12.6185 17.3906 12.3906 17.3906H11.2969V15.9242C14.2945 15.5047 16.6094 12.9242 16.6094 9.8125C16.6098 8.93788 16.4234 8.07326 16.0625 7.27656L17.7812 5.55781V6.375C17.7812 6.60292 17.8718 6.82151 18.033 6.98267C18.1941 7.14383 18.4127 7.23438 18.6406 7.23438C18.8685 7.23438 19.0871 7.14383 19.2483 6.98267C19.4095 6.82151 19.5 6.60292 19.5 6.375V3.48438C19.5 3.25645 19.4095 3.03787 19.2483 2.87671C19.0871 2.71554 18.8685 2.625 18.6406 2.625ZM10.4375 14.2656C9.55676 14.2656 8.69579 14.0045 7.96348 13.5151C7.23116 13.0258 6.6604 12.3303 6.32335 11.5166C5.9863 10.7029 5.89812 9.80756 6.06994 8.94374C6.24177 8.07992 6.66588 7.28645 7.28867 6.66366C7.91145 6.04088 8.70492 5.61677 9.56874 5.44494C10.4326 5.27312 11.3279 5.3613 12.1416 5.69835C12.9553 6.0354 13.6508 6.60616 14.1401 7.33848C14.6295 8.07079 14.8906 8.93176 14.8906 9.8125C14.8893 10.9931 14.4197 12.125 13.5849 12.9599C12.75 13.7947 11.6181 14.2643 10.4375 14.2656Z"
      fill="currentColor"
    />
  </SvgIcon>
)
