import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShieldNewIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2747 4.11851C12.0952 4.06721 11.9048 4.06721 11.7253 4.11851L6.72528 5.54708C6.29598 5.66974 6 6.06212 6 6.5086V11.8831C6 14.1437 7.09176 16.2652 8.93133 17.5792L10.5812 18.7577C11.0307 19.0787 11.1347 19.7033 10.8137 20.1527C10.4927 20.6021 9.86817 20.7062 9.41876 20.3852L7.76886 19.2067C5.40369 17.5173 4 14.7896 4 11.8831V6.5086C4 5.16916 4.88793 3.992 6.17584 3.62403L11.1758 2.19546C11.7145 2.04156 12.2855 2.04156 12.8242 2.19546L17.8242 3.62403C19.1121 3.992 20 5.16916 20 6.5086V12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12V6.5086C18 6.06212 17.704 5.66974 17.2747 5.54708L12.2747 4.11851Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C14.8954 16 14 16.8954 14 18C14 19.1046 14.8954 20 16 20C17.1046 20 18 19.1046 18 18C18 16.8954 17.1046 16 16 16ZM12 18C12 15.7909 13.7909 14 16 14C18.2091 14 20 15.7909 20 18C20 20.2091 18.2091 22 16 22C13.7909 22 12 20.2091 12 18Z"
      fill="currentColor"
    />
  </SvgIcon>
)
