import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShieldDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M24.0306 16.3796C19.8281 16.3796 16.4092 19.7985 16.4092 24.0007C16.4092 28.2033 19.8281 31.6222 24.0306 31.6222C28.2329 31.6222 31.6518 28.2033 31.6518 24.0007C31.6518 19.7985 28.2329 16.3796 24.0306 16.3796ZM28.5555 22.5655L23.0956 28.0254C22.8664 28.2546 22.5658 28.3693 22.2655 28.3693C21.9652 28.3693 21.6649 28.2549 21.4357 28.0254L19.3492 25.9389C18.8909 25.4808 18.8909 24.7377 19.3492 24.2794C19.8073 23.821 20.5504 23.821 21.0088 24.2794L22.2655 25.5361L26.8959 20.9059C27.354 20.4476 28.0971 20.4476 28.5555 20.9059C29.0139 21.364 29.0139 22.1071 28.5555 22.5655Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M41.0224 14.853L41.0212 14.8219C41.0038 14.4377 40.9922 14.0312 40.9852 13.5789C40.9526 11.3734 39.1993 9.54908 36.9935 9.42579C32.3945 9.16913 28.8368 7.6695 25.7969 4.70656L25.771 4.68184C24.7795 3.77272 23.2838 3.77272 22.292 4.68184L22.2661 4.70656C19.2262 7.6695 15.6685 9.16913 11.0695 9.42609C8.864 9.54908 7.11046 11.3734 7.07781 13.5792C7.07109 14.0284 7.05919 14.4349 7.04179 14.8219L7.03996 14.8942C6.95055 19.5838 6.83946 25.4203 8.79197 30.7179C9.86558 33.6311 11.4916 36.1634 13.6244 38.245C16.0536 40.6156 19.2354 42.4976 23.0812 43.8386C23.2063 43.8822 23.3363 43.9176 23.4688 43.9442C23.6555 43.9814 23.8435 44 24.0315 44C24.2195 44 24.4078 43.9814 24.5943 43.9442C24.7267 43.9176 24.8576 43.8819 24.9833 43.838C28.8246 42.4946 32.003 40.6116 34.43 38.2413C36.562 36.1591 38.188 33.6262 39.2625 30.7124C41.2223 25.399 41.1116 19.5515 41.0224 14.853ZM24.0309 33.9692C18.5341 33.9692 14.0624 29.4975 14.0624 24.0006C14.0624 18.5038 18.5341 14.0321 24.0309 14.0321C29.5274 14.0321 33.9994 18.5038 33.9994 24.0006C33.9994 29.4975 29.5274 33.9692 24.0309 33.9692Z"
      fill="currentColor"
    />
  </SvgIcon>
)
