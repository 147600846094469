import { FC } from 'react'

import { NotificationProps } from '../../components'

export const VehicleFindBy = {
  VIN: 'vin',
  MAKEMODEL: 'make-model',
  LICENSEPLATE: 'license plate',
} as const

export type VehicleFindByKeys =
  (typeof VehicleFindBy)[keyof typeof VehicleFindBy]

export type NewVehicleInformationFormModule = {
  Notification?: FC<Partial<NotificationProps>>

  FindBy: FC
  Dynamic:
    | {
        Vin: FC
      }
    | { LicensePlate: FC; State: FC }
    | {
        Year: FC
        Make: FC
        Model: FC
        Trim: FC
      }
    | null

  Mileage: FC
  LoanBalance: FC
  MonthlyPayment: FC
  Submit: FC
}
