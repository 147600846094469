import { Card, styled } from '@mui/material'

export const CardContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => {
  return {
    boxShadow:
      '0px -4px 20px rgba(0, 0, 0, 0.04), 0px 32px 48px rgba(118, 124, 119, 0.08)',
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: highlight
      ? `4px solid ${theme.extension.colors.base.blueSky}`
      : `4px solid ${theme.extension.colors.text.white}`,

    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(4, 4, 2),
    },
  }
})
