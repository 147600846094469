import React, { FC, RefObject } from 'react'
import { styled } from '@mui/material'

import type { TTradeline } from '@common/types'

import { Notification as NotificationBase, Text } from '../../reskin'

import { ContinueButton, ContinueButtonContainer } from './continue-button'
import { NoneCard } from './none-card'
import { TradelineCards } from './tradeline-cards'

const Notification = styled(NotificationBase)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(5),
  },
}))

type Props = {
  tradelines: ReadonlyArray<TTradeline>
  selected?: number
  handleSubmit: () => void
  handleSelect: (id: number) => void
  showButtonLoader: boolean
  error: string
  errorRef: RefObject<HTMLDivElement>
  closeError: () => void
}

export const TradelinesSelection: FC<Props> = ({
  tradelines,
  handleSelect,
  selected,
  handleSubmit,
  showButtonLoader,
  error,
  errorRef,
  closeError,
}) => {
  const showContent = tradelines && tradelines.length > 0
  return (
    <div>
      {error && (
        <Notification
          title={error}
          type="error"
          ref={errorRef}
          scrollIntoView
          onClose={closeError}
          shake
        />
      )}
      {showContent && (
        <>
          <Text
            use={['h1', { xs: 'headings.32', sm: 'headings.48' }]}
            color="text.softBlack"
            mt={3}
            mb={{ xs: 4, sm: 3 }}
          >
            Select a loan to refinance
          </Text>
          <TradelineCards
            tradelines={tradelines}
            selected={selected}
            handleSelect={handleSelect}
          />
          <NoneCard selected={selected} handleSelect={handleSelect} />
          <ContinueButtonContainer>
            <ContinueButton onClick={handleSubmit} loading={showButtonLoader}>
              {'Continue'}
            </ContinueButton>
          </ContinueButtonContainer>
        </>
      )}
    </div>
  )
}
