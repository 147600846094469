import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6402 8.23177C17.0645 8.58534 17.1218 9.2159 16.7682 9.64018L11.7682 15.6402C11.5937 15.8497 11.3411 15.9788 11.0691 15.9976C10.797 16.0164 10.5291 15.9234 10.3273 15.7399L7.32733 13.0127C6.91868 12.6412 6.88856 12.0087 7.26007 11.6C7.63157 11.1914 8.26402 11.1613 8.67268 11.5328L10.9002 13.5578L15.2318 8.35981C15.5854 7.93553 16.2159 7.87821 16.6402 8.23177Z"
      fill="currentColor"
    />
  </SvgIcon>
)
