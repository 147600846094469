import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DeleteIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.28888 5.67505C7.03972 4.62386 8.25201 4 9.54381 4H18C20.2091 4 22 5.79086 22 8V16C22 18.2091 20.2091 20 18 20H9.54381C8.25201 20 7.03972 19.3761 6.28888 18.325L3.01656 13.7437C2.2715 12.7006 2.2715 11.2994 3.01656 10.2563L6.28888 5.67505ZM9.54381 6C8.89791 6 8.29177 6.31193 7.91634 6.83752L4.64403 11.4188C4.39567 11.7665 4.39567 12.2335 4.64403 12.5812L7.91634 17.1625C8.29177 17.6881 8.89791 18 9.54381 18H18C19.1045 18 20 17.1046 20 16V8C20 6.89543 19.1045 6 18 6H9.54381Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 8.29289C10.6834 7.90237 11.3166 7.90237 11.7071 8.29289L14 10.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L15.4142 12L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3166 16.0976 16.6834 16.0976 16.2929 15.7071L14 13.4142L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071C9.90237 15.3166 9.90237 14.6834 10.2929 14.2929L12.5858 12L10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
