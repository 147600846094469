import { useRef } from 'react'

import { SegmentSendTrackEvent } from '../../../tracking'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTrackChanges = <T extends {}>(
  initialValue: T,
  trackEvent: SegmentSendTrackEvent,
) => {
  const defaultValue = useRef(initialValue)

  return (updatedValue: T) => {
    const modifiedKeys = Object.keys(defaultValue.current).filter(
      (key) => defaultValue.current[key] !== updatedValue[key],
    )

    if (modifiedKeys.length > 0) {
      void trackEvent({ editedFields: modifiedKeys })
    }
  }
}
