import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BellSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C9.9695 2 8.34903 2.72578 7.24822 3.98079C6.16397 5.21692 5.66667 6.87057 5.66667 8.6V10.3333C5.66667 10.5774 5.55716 10.8709 5.27166 11.2498C4.99798 11.6129 4.6427 11.9534 4.25022 12.3296L4.18372 12.3934C3.49997 13.0494 3 13.9996 3 15.1333C3 16.67 4.19824 18 5.77778 18H18.2222C19.8018 18 21 16.67 21 15.1333C21 13.9996 20.5 13.0494 19.8163 12.3934L19.7498 12.3296L19.7497 12.3296C19.3573 11.9534 19.002 11.6129 18.7283 11.2498C18.4428 10.8709 18.3333 10.5774 18.3333 10.3333V8.6C18.3333 6.87057 17.836 5.21692 16.7518 3.98079C15.651 2.72578 14.0305 2 12 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44301 19.1695C9.9017 18.8619 10.5229 18.9844 10.8305 19.443C10.9299 19.5912 11.0843 19.731 11.2919 19.8345C11.4997 19.938 11.7444 19.9965 12 19.9965C12.2555 19.9965 12.5002 19.938 12.7081 19.8345C12.9157 19.731 13.0701 19.5912 13.1694 19.443C13.4771 18.9844 14.0983 18.8619 14.557 19.1695C15.0156 19.4771 15.1381 20.0983 14.8305 20.557C14.5234 21.0149 14.0943 21.3783 13.5999 21.6246C13.1058 21.8708 12.5545 21.9965 12 21.9965C11.4454 21.9965 10.8942 21.8708 10.4 21.6246C9.90562 21.3783 9.47656 21.0149 9.16945 20.557C8.86184 20.0983 8.98432 19.4771 9.44301 19.1695Z"
      fill="currentColor"
    />
  </SvgIcon>
)
