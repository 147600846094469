import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const WalletDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M35.2501 33.9048C31.5286 33.9048 28.5001 30.8282 28.5001 27.0476C28.5001 23.267 31.5286 20.1905 35.2501 20.1905H39.0001V17.1429C39.0001 15.4621 37.6546 14.0952 36.0001 14.0952H34.5001V11.0476C34.5001 9.36686 33.1546 8 31.5001 8H10.5C8.27551 8 6.4365 9.65181 6.0765 11.808C6.0435 11.933 6 12.0549 6 12.1905V35.4286C6 37.949 8.01901 40 10.5 40H36.0001C37.6546 40 39.0001 38.6331 39.0001 36.9524V33.9048H35.2501ZM9.00001 12.5714C9.00001 11.7318 9.67201 11.0476 10.5 11.0476H31.5001V14.0952H10.5C9.67201 14.0952 9.00001 13.411 9.00001 12.5714Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M40.8749 22.4776H35.2499C32.7689 22.4776 30.7499 24.5286 30.7499 27.049C30.7499 29.5694 32.7689 31.6205 35.2499 31.6205H40.8749C41.4959 31.6205 41.9999 31.1085 41.9999 30.4776V23.6205C41.9999 22.9896 41.4959 22.4776 40.8749 22.4776ZM35.2499 28.5728C34.4219 28.5728 33.7499 27.8902 33.7499 27.049C33.7499 26.2079 34.4219 25.5252 35.2499 25.5252C36.0779 25.5252 36.7499 26.2079 36.7499 27.049C36.7499 27.8902 36.0779 28.5728 35.2499 28.5728Z"
      fill="currentColor"
    />
  </SvgIcon>
)
