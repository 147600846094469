import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AustinDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  stroke = 'white',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M24.3528 9.14171L24.3525 9.12224L24.3507 9.10286C24.2771 8.3175 24.2826 7.39937 24.2893 6.29647C24.2913 5.95849 24.2934 5.60317 24.2934 5.22899C24.2934 4.29026 23.5535 3.5 22.6054 3.5H15.2715C14.3235 3.5 13.5835 4.29026 13.5835 5.22899V19.3864L6.25461 19.4401C6.254 19.4401 6.25338 19.4401 6.25276 19.4401C5.8338 19.4401 5.4052 19.4499 5.02553 19.4966C4.66023 19.5415 4.26745 19.6278 3.97107 19.829C3.81361 19.9358 3.6697 20.0843 3.58252 20.2858C3.49478 20.4887 3.48201 20.7048 3.52081 20.9167C3.59379 21.3152 3.85671 21.7421 4.24858 22.204C4.46549 22.4632 4.72518 22.7478 5.02206 23.0731C6.06382 24.2149 7.56366 25.8586 9.28111 28.6686L9.28123 28.6688C10.5116 30.6797 11.7155 31.6033 12.843 31.7903C13.9903 31.9805 14.878 31.3822 15.3988 30.7849C15.8635 30.2524 16.3091 29.783 16.7456 29.4358C17.1853 29.086 17.5735 28.8948 17.9224 28.8561C18.2462 28.8203 18.6012 28.9086 19.0121 29.2635C19.4375 29.6311 19.9098 30.2762 20.4166 31.3246C20.617 31.7412 20.8118 32.151 21.0032 32.5536C21.959 34.5639 22.8292 36.3943 23.878 37.9973L24.8231 39.442L24.7963 37.7159L24.4914 18.0519L24.3528 9.14171Z"
      fill="currentColor"
      stroke={stroke}
    />
    <path
      opacity="0.24"
      d="M43.0533 20.5763L42.9982 20.1388L30.7318 44.2505C31.37 44.5495 31.9969 44.6212 32.4827 44.2346C32.9138 43.8913 33.0635 43.3027 33.1252 42.7747C33.1904 42.2162 33.1732 41.5589 33.1381 40.9159C33.1235 40.6488 33.1061 40.3876 33.0891 40.1325C33.0637 39.752 33.0392 39.3848 33.0262 39.0308C33.0103 38.5962 33.235 37.9768 33.7215 37.2327C34.1967 36.5059 34.8778 35.7265 35.6641 34.9902C37.2537 33.5016 39.1747 32.2791 40.5514 31.9838C42.3165 31.6052 43.4496 29.9622 44.0191 28.3509C44.3096 27.529 44.471 26.6721 44.4964 25.9055C44.5211 25.159 44.4189 24.4326 44.1181 23.9133C43.5626 22.8813 43.2011 21.749 43.0533 20.5763Z"
      fill="currentColor"
      stroke={stroke}
    />
    <path
      d="M26.6804 11.6621L25.8714 11.0766L25.8873 12.0751L25.9823 18.0685L25.9901 18.5605H26.4822H42.3975V18.0605L42.8959 18.0204C42.8443 17.3796 42.8183 16.702 42.8183 15.9876C42.8183 15.0547 42.3351 14.3727 41.6199 13.9025C40.927 13.447 39.9965 13.1726 39.0007 12.9996C37.1433 12.677 34.8688 12.6793 33.0207 12.6812C32.8834 12.6813 32.7485 12.6814 32.6163 12.6814C30.9856 12.6814 29.732 12.5988 28.7626 12.4255C27.7927 12.2521 27.1378 11.9932 26.6804 11.6621Z"
      fill="currentColor"
      stroke={stroke}
    />
  </SvgIcon>
)
