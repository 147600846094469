import { Theme as MuiTheme } from '@mui/material'
import flow from 'lodash/flow'
import { mapKeys as fpMapKeys } from 'lodash/fp'
import mapValues from 'lodash/mapValues'

import {
  Breakpoint,
  CSSValueInRem,
  GutterBreakpointMap,
  GutterType,
  MediaQueryBlock,
} from './theme-types'

export const getPageGutterCreator = (
  theme: MuiTheme,
  gutterBreakpointMap: GutterBreakpointMap,
): ((gutterType: GutterType) => MediaQueryBlock) =>
  flow(
    (gutterType: GutterType) =>
      mapValues(gutterBreakpointMap, (gutterValue: CSSValueInRem) => ({
        [`${gutterType}Left`]: gutterValue,
        [`${gutterType}Right`]: gutterValue,
      })),
    fpMapKeys((breakpoint: string) =>
      theme.breakpoints.only(breakpoint as Breakpoint),
    ),
  )
