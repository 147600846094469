import { useEffect, useState } from 'react'

import { PartnerAuthCode, PartnerAuthToken, PartnerName } from '@common/types'

import {
  getCachedPartnerAuthToken,
  getPartnerAuthToken,
  setCachedPartnerAuthToken,
} from '../api'

type RewardsInfoHook = {
  token: PartnerAuthToken
  tokenLoaded: boolean
}

export const useAuthToken = (
  partner: PartnerName,
  code: PartnerAuthCode,
): RewardsInfoHook => {
  const [token, setToken] = useState<string>()
  const [tokenLoaded, setTokenLoaded] = useState(false)

  const fetchData = async (
    partner: PartnerName,
    code: PartnerAuthCode,
  ): Promise<void> => {
    try {
      setTokenLoaded(false)

      const { token } = await getPartnerAuthToken(partner, code)

      if (token) {
        setCachedPartnerAuthToken(token)
        setToken(token)
      } else {
        const cachedToken = getCachedPartnerAuthToken()
        setToken(cachedToken)
      }
    } catch {
      const cachedToken = getCachedPartnerAuthToken()
      setToken(cachedToken)
    } finally {
      setTokenLoaded(true)
    }
  }

  useEffect(() => {
    if (!partner || !code) {
      setTokenLoaded(true)
      return
    }

    void fetchData(partner, code)
  }, [partner, code])

  return {
    token,
    tokenLoaded,
  }
}
