/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createTheme as createMuiTheme } from '@mui/material'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import memoize from 'lodash/memoize'
import property from 'lodash/property'
import propertyOf from 'lodash/propertyOf'

import { isBrowser } from '@common/universal-toolkit'

import {
  defaultFontScale,
  defaultPageGutterBreakpointMap,
  defaultPaperBoxShadow,
  defaultPaperPlusBoxShadow,
  defaultSpacingScale,
} from './defaults'
import { getPageGutterCreator } from './gutter'
import { colorByCoords } from './selectors'
import { Theme, ThemeOptions } from './theme-types'

const doSideEffects = memoize(
  ({ mrExtensions: { fontLoaderConfig } }: ThemeOptions): void => {
    if (isBrowser && !isNil(fontLoaderConfig)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module
      require('webfontloader').load(fontLoaderConfig)
    }
  },
  // Run side effects for each theme only once
  property(['themeName']),
)

export const createTheme = (themeOptions: ThemeOptions): Theme => {
  const theme = createMuiTheme(themeOptions)

  // TODO: move side effects http://bit.ly/mr-ch3475
  doSideEffects(themeOptions)

  const fontScaleMap = themeOptions.mrExtensions.fontScale ?? defaultFontScale

  const spacingScaleMap =
    themeOptions.mrExtensions.spacingScale ?? defaultSpacingScale

  const paperShadow =
    themeOptions.mrExtensions.paper?.baseShadow ?? defaultPaperBoxShadow

  const paperPlusShadow =
    themeOptions.mrExtensions.paper?.plusShadow ?? defaultPaperPlusBoxShadow

  const pageGutterBreakpointMap =
    themeOptions.mrExtensions.pageGutterBreakpointMap ??
    defaultPageGutterBreakpointMap
  const pageGutterCreator = getPageGutterCreator(theme, pageGutterBreakpointMap)

  const select: Theme['select'] = {
    colorByCoords: colorByCoords({ theme, themeOptions }),
    fontScale: (scale) => get(fontScaleMap, scale ?? 'inherit'),
    gutters: {
      page: pageGutterCreator,
    },
    paperShadow,
    paperPlusShadow,
    spacingScale: propertyOf(spacingScaleMap),
  }

  return {
    ...theme,
    select,
  }
}

export {
  /**
   * @deprecated Import from @mui
   */
  withTheme,
  /**
   * @deprecated Import from @mui
   */
  useMediaQuery,
  /**
   * @deprecated Import from @mui
   */
  useTheme,
  /**
   * @deprecated Import from @mui
   */
  ThemeProvider,
} from '@mui/material'

export {
  /**
   * @deprecated Import from @mui and prefer styled
   */ makeStyles,
} from '@mui/styles'
