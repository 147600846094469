import { useEffect, useState } from 'react'

import { PartnerVehicleInfo } from '@common/types'

import { getCustomerVehicleInfo } from '../api'

type VehicleInfoHook = {
  vehicleInfo: PartnerVehicleInfo
  vehicleInfoLoaded: boolean
}

export const useVehicleInfo = (
  partner: string,
  token: string,
): VehicleInfoHook => {
  const [vehicleInfo, setVehicleInfo] = useState<PartnerVehicleInfo>([])
  const [vehicleInfoLoaded, setVehicleInfoLoaded] = useState(false)

  const fetchData = async (partner: string, token: string): Promise<void> => {
    try {
      setVehicleInfoLoaded(false)

      const vehicleInfoData = await getCustomerVehicleInfo(partner, token)

      setVehicleInfo(vehicleInfoData)
    } finally {
      setVehicleInfoLoaded(true)
    }
  }

  useEffect(() => {
    if (!partner || !token) {
      setVehicleInfoLoaded(true)
      return
    }

    void fetchData(partner, token)
  }, [partner, token])

  return {
    vehicleInfo,
    vehicleInfoLoaded,
  }
}
