// eslint-disable no-restricted-imports @typescript-eslint/ban-types */
import { Theme, useTheme } from '@mui/material'
import {
  ClassNameMap,
  makeStyles,
  Styles,
  WithStylesOptions,
} from '@mui/styles'

/**
 * @deprecated Import directly from @mui/material
 */
export const useNewTheme = (): Theme => useTheme()

type MakeStylesNew = {
  <ClassKey extends string = string>(
    style: Styles<Theme, {}, ClassKey>,
    options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): (props?: any) => ClassNameMap<ClassKey>

  <Props extends {} = {}, ClassKey extends string = string>(
    styles: Styles<Theme, Props, ClassKey>,
    options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
  ): (props: Props) => ClassNameMap<ClassKey>
}

/**
 * @deprecated Use styled instead
 */
export const makeNewStyles: MakeStylesNew = makeStyles

// export const styled
