import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SignOutIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5952 9.08333C18.2698 8.7579 17.7421 8.7579 17.4167 9.08334C17.0912 9.40877 17.0912 9.93641 17.4167 10.2618L18.3215 11.1667H11.1666C10.7064 11.1667 10.3333 11.5398 10.3333 12C10.3333 12.4602 10.7064 12.8333 11.1666 12.8333H18.3215L17.4167 13.7382C17.0912 14.0636 17.0913 14.5912 17.4167 14.9167C17.7421 15.2421 18.2698 15.2421 18.5952 14.9167L20.9226 12.5892C21.248 12.2638 21.248 11.7362 20.9226 11.4107L18.5952 9.08333ZM14.1583 8.81993C13.1859 7.46124 11.61 6.58333 9.83712 6.58333C6.89954 6.58333 4.50004 8.99836 4.50004 12C4.50004 15.0017 6.89954 17.4167 9.83712 17.4167C11.61 17.4167 13.1859 16.5388 14.1583 15.1801C14.4261 14.8058 14.9226 14.6362 15.3333 14.8437C15.7441 15.0513 15.9127 15.556 15.6584 15.9396C14.404 17.8317 12.2679 19.0833 9.83712 19.0833C5.95904 19.0833 2.83337 15.9019 2.83337 12C2.83337 8.09807 5.95904 4.91667 9.83712 4.91667C12.2679 4.91667 14.404 6.16835 15.6584 8.06039C15.9127 8.44399 15.7441 8.94871 15.3333 9.15628C14.9226 9.36385 14.4261 9.1942 14.1583 8.81993Z"
      fill="currentColor"
    />
  </SvgIcon>
)
