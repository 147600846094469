import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import isString from 'lodash/isString'
import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'

import { fetchPartnerCredentials } from '@src/api'
import {
  type LeadCampaign,
  LeadSource,
  LeadSourceKeys,
  removeAuthToken,
  removeLeadSource,
  removeLoanApplicationUuid,
  removePrimaryCustomerUuid,
  removeRequestId,
  setAuthToken,
  setLeadCampaign,
  setLeadSource,
  setRequestId,
} from '@src/utils/storage'

import { Logger } from '../hooks'
import { fetchAndTrackPartnerLead } from '../tracking'

export type PartnerTokenFetchStatus = 'idle' | 'fetching' | 'done'

type PartnerDataBase = {
  readonly requestId?: string
  readonly token?: string
  readonly leadSource?: LeadSourceKeys
  readonly cobrand?: boolean
}

export type PartnerData = PartnerDataBase & {
  readonly status: PartnerTokenFetchStatus
}

const shouldFetchAuthCredentialsForLead = (
  query: ParsedUrlQuery,
): query is {
  partnerToken: string
  leadSource: LeadSourceKeys
  leadCampaign: LeadCampaign | undefined
  cobrand: 'true' | 'false' | undefined
} => {
  const { partnerToken, leadSource: queryLeadSource } = query
  return (
    partnerToken &&
    isString(partnerToken) &&
    queryLeadSource &&
    queryLeadSource !== LeadSource.MR_CONSUMER_SITE &&
    Object.values(LeadSource).includes(queryLeadSource as LeadSourceKeys)
  )
}

export const PartnerContext = createContext<PartnerData>({
  status: 'idle',
})

// TODO: This probably needs a refactor. It's pretty hard to follow and everyone who has
// detail into the implementations is gone
export const PartnerDataProvider: React.FC<{
  readonly logger: Logger
  readonly children: React.ReactNode
}> = ({ logger, children }) => {
  const router = useRouter()
  const [baseData, setBaseData] = useState<PartnerDataBase>({})
  const [tokenStatus, setTokenStatus] =
    useState<PartnerTokenFetchStatus>('idle')

  const fetchToken = useCallback(async () => {
    if (!router.isReady || tokenStatus !== 'idle') {
      return
    }

    if (!shouldFetchAuthCredentialsForLead(router.query)) {
      setTokenStatus('done')
      return
    }

    const { partnerToken, leadSource, cobrand, leadCampaign } = router.query

    setTokenStatus('fetching')

    try {
      const { requestId, authToken: token } = await fetchPartnerCredentials(
        partnerToken,
        leadSource,
      )
      setRequestId(requestId)
      setAuthToken(token)
      setLeadSource(leadSource)
      setLeadCampaign(leadCampaign)
      setBaseData({ requestId, token, leadSource, cobrand: cobrand === 'true' })
      await fetchAndTrackPartnerLead(logger)
    } catch {
      removeRequestId()
      removeAuthToken()
      removeLeadSource()
      removeLoanApplicationUuid()
      removePrimaryCustomerUuid()
      setBaseData({})
      await router.replace('/personal-info')
    } finally {
      setTokenStatus('done')
    }
  }, [logger, router, tokenStatus])

  useEffect(() => {
    void fetchToken()
  }, [fetchToken])

  return (
    <PartnerContext.Provider value={{ ...baseData, status: tokenStatus }}>
      {children}
    </PartnerContext.Provider>
  )
}

export const usePartnerData = (): PartnerData => useContext(PartnerContext)
