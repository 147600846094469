import React from 'react'
import {
  FormControlLabel as FormControlLabelBase,
  Radio as RadioBase,
  styled,
} from '@mui/material'

import { OmitSafe } from '@common/lib-types'

import { useIsDense } from '../utils'

type RadioStyleProps = {
  disabled?: boolean
  error?: boolean
  active: boolean
  dense?: boolean
}
type RadioProps<T> = {
  onChange: (value: T) => void
  value: T | null
  label: string
  id?: string
  'data-testid'?: string
} & OmitSafe<RadioStyleProps, 'dense'>

const FormControlLabel = styled(FormControlLabelBase, {
  shouldForwardProp: (props) =>
    props !== 'active' && props !== 'error' && props !== 'dense',
})<RadioStyleProps>(({ theme, active, dense, error, disabled }) => ({
  '.MuiFormControlLabel-label	': {
    ...(active
      ? theme.extension.typography.subheadings[dense ? '16m' : '18m']
      : theme.extension.typography.paragraphs[dense ? 16 : 18]),
    color: disabled
      ? theme.extension.colors.neutral[700]
      : error
      ? theme.extension.colors.semantic.error
      : active
      ? theme.extension.colors.text.black
      : theme.extension.colors.text.bodyGray,
    textAlign: 'left',
  },

  margin: 0,
}))

const RadioControl = styled(RadioBase)<Partial<RadioStyleProps>>(
  ({ theme, dense, error, disabled }) => ({
    width: dense ? '24px' : '32px',
    height: dense ? '24px' : '32px',
    marginRight: theme.spacing(0.75),
    color: disabled
      ? theme.extension.colors.neutral[700]
      : error
      ? theme.extension.colors.semantic.error
      : theme.extension.colors.neutral[800],

    '&.Mui-checked': {
      color: disabled
        ? theme.extension.colors.neutral[700]
        : error
        ? theme.extension.colors.semantic.error
        : theme.extension.colors.base.blueSky,
    },
  }),
)

export const Radio = <T,>({
  onChange,
  value,
  label,
  disabled,
  active,
  error,
  id,
  'data-testid': dataTestId,
}: RadioProps<T>): JSX.Element => {
  const handleChecked = (): void => {
    if (!disabled) {
      onChange(value)
    }
  }

  return (
    <FormControlLabel
      active={active}
      error={error}
      dense={useIsDense()}
      onChange={handleChecked}
      control={<RadioControl id={id} data-testid={dataTestId} />}
      {...{ label, value, disabled }}
    />
  )
}
