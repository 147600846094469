import React from 'react'
import {
  Button as MuiButton,
  buttonClasses,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  styled,
} from '@mui/material'

import { OldTheme } from '@modules/core-ui'

export type ToolbarButtonProps = {
  onClick?: MuiButtonProps['onClick']
  children?: MuiButtonProps['children']
  startIcon?: MuiButtonProps['startIcon']
  variant?: 'text' | 'contained'
  disabled?: MuiButtonProps['disabled']
  size?: MuiButtonProps['size']
  loading?: boolean
  className?: string
}

const StyledButton = styled(MuiButton)(({ theme }: { theme: OldTheme }) => ({
  textTransform: 'none',
  fontSize: '0.825rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.4rem',
  height: '1.625rem',
  display: 'inline-flex',
  flexDirection: 'column',
  verticalAlign: 'top',

  [`& .${buttonClasses.text}`]: {
    color: theme.mrExtensions.palette.accentTwo.main,
    '&:hover': {
      color: theme.mrExtensions.palette.accentTwo.dark,
    },
  },

  [`& .${buttonClasses.contained}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export const ToolbarButton = ({
  loading,
  variant = 'contained',
  ...props
}: ToolbarButtonProps): JSX.Element => {
  return loading ? (
    <CircularProgress sx={{ margin: '0 50px' }} />
  ) : (
    <StyledButton
      {...{
        variant,
        ...props,
      }}
      color="primary"
    />
  )
}
