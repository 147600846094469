import React, { ChangeEvent, useCallback } from 'react'
import { styled } from '@mui/material'

import { Checkbox } from '../checkbox'
import { Text } from '../text'

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiFormControlLabel': {
    marginTop: theme.spacing(2),
  },
}))

type ValueType = string | number | boolean
export type CheckBoxGroupProps<T extends ValueType> = {
  name: string
  items: ReadonlyArray<{ label: string; value: T }>
  onChange: (value: ReadonlyArray<T>) => void
  disabled?: boolean
  error?: string
  rootClassName?: string
  selectedOptions?: ReadonlyArray<T>
}
export const CheckBoxGroup = React.forwardRef(
  <T extends ValueType = ValueType>(
    {
      name,
      items,
      onChange,
      error,
      disabled,
      rootClassName,
      selectedOptions = [],
    }: CheckBoxGroupProps<T>,
    ref,
  ): JSX.Element => {
    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value as unknown as T
        const newOpts = e.target.checked
          ? [...selectedOptions, val]
          : (selectedOptions.filter(
              (v) => v !== val,
            ) as unknown as ReadonlyArray<T>)
        onChange(newOpts)
      },
      [onChange, selectedOptions],
    )
    return (
      <div ref={ref} className={rootClassName}>
        {items.map(({ label, value: itemValue }) => (
          <StyledCheckbox
            key={label}
            {...{ label, disabled, name }}
            onChange={handleOnChange}
            error={error}
            value={itemValue}
            color="text.softBlack"
            checked={selectedOptions.includes(itemValue)}
          />
        ))}

        {error && (
          <Text use={['span', 'subheadings.14m']} color="semantic.error">
            {error}
          </Text>
        )}
      </div>
    )
  },
)
