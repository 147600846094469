import React, { FC } from 'react'

import { SelectFieldProps } from '@common/types'

import { EndIcon } from '../input'
import { Text } from '../text'
import { useIsDense } from '../utils'

import { Container, InputContainer, LabelContainer, Select } from './styles'

export const SelectField: FC<SelectFieldProps> = React.forwardRef(
  (props, ref) => {
    const {
      error,
      disabled,
      label,
      value,
      menuItems,
      rootClassName,
      loading,
      endIcon,
      variant,
      sx,
      ...restProps
    } = props
    const active =
      value !== undefined && value !== '' && value !== null && !error
    const dense = useIsDense()
    const small = variant && variant === 'small'

    return (
      <Container
        className={rootClassName}
        sx={sx}
        {...{ active, error, disabled, small }}
      >
        <label>
          {/* Label */}
          {label && (
            <LabelContainer>
              <Text
                use={[
                  'span',
                  dense
                    ? 'interactive.inputLabel14'
                    : 'interactive.inputLabel16',
                ]}
                sx={{ color: 'inherit' }}
              >
                {label}
              </Text>
            </LabelContainer>
          )}
        </label>

        {/* Input */}
        <InputContainer>
          <EndIcon
            sx={(theme) => ({
              right: theme.spacing(6),
            })}
            icon={error ? 'error' : loading ? 'loading' : endIcon}
          />

          <Select
            {...{ active, error, dense, small }}
            value={value}
            {...restProps}
            disabled={disabled}
            ref={ref}
          >
            {menuItems.map(({ displayName, value }) => {
              return (
                <option key={value} value={value}>
                  {displayName}
                </option>
              )
            })}
          </Select>
        </InputContainer>

        {/* Error Message */}
        {error && (
          <Text use={['span', 'subheadings.14m']} color="semantic.error">
            {error}
          </Text>
        )}
      </Container>
    )
  },
)
