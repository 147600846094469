import React from 'react'

import { styled } from '../reskin'

const ErrorParent = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: 8,
  fontSize: '0.875rem',
  '& a': {
    color: theme.palette.error.main,
  },
}))

export const Error: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  if (!children) {
    return null
  }
  return <ErrorParent>{children}</ErrorParent>
}
