import { useContext } from 'react'

import { ECampaignSource, LeadSourceContext } from './lead-source-context'

type UseLeadSource = {
  isCampaignInsuranceBlacklisted: boolean
  isDirectMail: boolean
}
export const useLeadSource = (): UseLeadSource => {
  const leadParams = useContext(LeadSourceContext)

  if (!leadParams) {
    console.warn(
      'Not able to find lead source context. Make sure your component is wrapped in a provider',
    )
  }

  /** Check if the campaign source matches provided source */
  const isSource = (...sources: ReadonlyArray<ECampaignSource>): boolean => {
    if (!('utm_source' in leadParams)) {
      return false
    }
    const trafficType = leadParams.utm_source
    return sources.includes(trafficType)
  }

  return {
    isCampaignInsuranceBlacklisted: isSource(ECampaignSource.progressive),
    isDirectMail: isSource(ECampaignSource.directMessage),
  }
}
