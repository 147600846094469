import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EyeDuotoneIcon: React.FC<SvgIconProps & { altTone: string }> = ({
  htmlColor = '#00C696',
  altTone = '#DFFFE3',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M24 36.8906C13.167 36.8906 4.57676 25.181 4.21973 24.682C3.92676 24.2746 3.92676 23.7253 4.21973 23.3179C4.57676 22.819 13.167 11.1094 24 11.1094C34.8329 11.1094 43.4232 22.819 43.7803 23.318C44.0732 23.7254 44.0732 24.2747 43.7803 24.6821C43.4232 25.181 34.8329 36.8906 24 36.8906Z"
      fill="currentColor"
    />
    <path
      d="M24 32.2031C19.4773 32.2031 15.7969 28.5227 15.7969 24C15.7969 19.4773 19.4773 15.7969 24 15.7969C28.5227 15.7969 32.2031 19.4773 32.2031 24C32.2031 28.5227 28.5227 32.2031 24 32.2031Z"
      fill={altTone}
    />
    <path
      d="M24 27.5156C22.0614 27.5156 20.4844 25.9386 20.4844 24C20.4844 22.0614 22.0614 20.4844 24 20.4844C25.9386 20.4844 27.5156 22.0614 27.5156 24C27.5156 25.9386 25.9386 27.5156 24 27.5156Z"
      fill="currentColor"
    />
    <path
      d="M24.0001 4C23.3523 4 22.8282 4.52414 22.8282 5.17188V7.51562C22.8282 8.16336 23.3523 8.6875 24.0001 8.6875C24.6478 8.6875 25.1719 8.16336 25.1719 7.51562V5.17188C25.1719 4.52414 24.6478 4 24.0001 4ZM24.0001 39.3125C23.3523 39.3125 22.8282 39.8366 22.8282 40.4844V42.8281C22.8282 43.4759 23.3523 44 24.0001 44C24.6478 44 25.1719 43.4759 25.1719 42.8281V40.4844C25.1719 39.8366 24.6478 39.3125 24.0001 39.3125ZM15.6401 7.17602C15.3151 6.61523 14.5953 6.42297 14.0391 6.74688C13.4783 7.07078 13.2861 7.78711 13.61 8.34789L14.7819 10.378C15.1094 10.9434 15.8283 11.1275 16.3829 10.8072C16.9436 10.4833 17.1359 9.76695 16.812 9.20617L15.6401 7.17602ZM33.2183 37.6219C32.8921 37.0611 32.1734 36.8677 31.6172 37.1927C31.0565 37.5166 30.8642 38.233 31.1881 38.7937L32.36 40.8239C32.6875 41.3893 33.4065 41.5734 33.961 41.253C34.5218 40.9291 34.714 40.2128 34.3901 39.652L33.2183 37.6219ZM31.6172 10.8073C32.1726 11.128 32.8913 10.9426 33.2183 10.3781L34.3901 8.34797C34.714 7.78719 34.5218 7.07078 33.961 6.74695C33.4002 6.42313 32.6838 6.61531 32.36 7.17609L31.1881 9.20625C30.8642 9.76695 31.0565 10.4834 31.6172 10.8073ZM16.3829 37.1927C15.821 36.8677 15.1069 37.0611 14.7819 37.6219L13.61 39.652C13.2861 40.2128 13.4783 40.9292 14.0391 41.253C14.5945 41.5738 15.3132 41.3884 15.6401 40.8239L16.812 38.7937C17.1359 38.233 16.9436 37.5166 16.3829 37.1927Z"
      fill={altTone}
    />
  </SvgIcon>
)
