import React, { FC } from 'react'
import { styled } from '@mui/material'

import { OmitSafe } from '@common/lib-types'
import { NewVehicleInformationFormModule } from '@common/types'

import { ButtonFineText, StackFields, Text } from '../../components'

const StyledButtonFineText = styled(ButtonFineText)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(6),
  },
}))

const DynamicFieldsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(6),
  },
}))

export const NewVehicleInfoForm: FC<{
  module: OmitSafe<NewVehicleInformationFormModule, 'Notification'>
}> = ({
  module: { FindBy, LoanBalance, Mileage, MonthlyPayment, Dynamic, Submit },
}) => {
  return (
    <>
      <Text use={['h3', 'subheadings.18b']} color="text.softBlack" mb={1}>
        Find your vehicle by
      </Text>
      <FindBy />
      {Dynamic && (
        <DynamicFieldsContainer>
          <StackFields>
            {'Vin' in Dynamic ? (
              <Dynamic.Vin />
            ) : 'LicensePlate' in Dynamic ? (
              <>
                <Dynamic.LicensePlate />
                <Dynamic.State />
              </>
            ) : (
              <>
                <Dynamic.Year />
                <Dynamic.Make />
                <Dynamic.Model />
                <Dynamic.Trim />
              </>
            )}
          </StackFields>
        </DynamicFieldsContainer>
      )}

      <Text
        use={['h3', 'subheadings.24b']}
        color="text.softBlack"
        mt={{ xs: 4, sm: 6 }}
        mb={{ xs: 2, sm: 3 }}
      >
        {'Financing information'}
      </Text>
      <StackFields>
        <LoanBalance />
        <Mileage />
        <MonthlyPayment />
      </StackFields>

      <StyledButtonFineText button={Submit} />
    </>
  )
}
