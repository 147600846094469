import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EducationIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4961 3.63176C12.1887 3.45608 11.8113 3.45608 11.5039 3.63176L2.75386 8.63176C2.44229 8.8098 2.25 9.14114 2.25 9.5C2.25 9.85886 2.44229 10.1902 2.75386 10.3682L5.375 11.866V16.375C5.375 16.7382 5.57189 17.0728 5.88936 17.2492L11.4985 20.3653C11.6374 20.446 11.7976 20.4942 11.9686 20.4995C11.9791 20.4998 11.9895 20.5 12 20.5C12.0128 20.5 12.0256 20.4998 12.0384 20.4993C12.2068 20.4929 12.3645 20.4449 12.5015 20.3653L18.1106 17.2492C18.4281 17.0728 18.625 16.7382 18.625 16.375V11.866L19.75 11.2232V16.375C19.75 16.9273 20.1977 17.375 20.75 17.375C21.3023 17.375 21.75 16.9273 21.75 16.375V9.5C21.75 9.19258 21.6113 8.91755 21.393 8.73411C21.3425 8.69163 21.2877 8.65406 21.2293 8.62214L12.4961 3.63176ZM13 17.8005L16.625 15.7866V13.0089L13 15.0803V17.8005ZM11 15.0803L7.375 13.0089V15.7866L11 17.8005V15.0803ZM12 13.3482L5.26556 9.5L12 5.65175L18.7344 9.5L12 13.3482Z"
      fill="currentColor"
    />
  </SvgIcon>
)
