import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PuzzleDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M22.8281 44V39.2865H22.2357C19.6438 39.2865 17.5352 37.1778 17.5352 34.5859C17.5352 31.9941 19.6438 29.8854 22.2357 29.8854H22.8281V25.1719H16.9427C16.2955 25.1719 15.7709 24.6472 15.7709 24V22.2357C15.7709 20.9362 14.7136 19.8789 13.4141 19.8789C12.1145 19.8789 11.0573 20.9362 11.0573 22.2357V24C11.0573 24.6472 10.5326 25.1719 9.88539 25.1719H4V41.6432C4 42.9427 5.05727 44 6.3568 44H22.8281Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M41.6432 44C42.9427 44 44 42.9427 44 41.6432V25.1719H39.2865V25.7643C39.2865 28.3562 37.1778 30.4648 34.5859 30.4648C31.9941 30.4648 29.8854 28.3562 29.8854 25.7643V25.1719H25.1719V31.0573C25.1719 31.7045 24.6472 32.2291 24 32.2291H22.2357C20.9362 32.2291 19.8789 33.2864 19.8789 34.5859C19.8789 35.8855 20.9362 36.9427 22.2357 36.9427H24C24.6472 36.9427 25.1719 37.4674 25.1719 38.1146V44H41.6432Z"
      fill="currentColor"
    />
    <path
      d="M34.5859 28.1211C35.8855 28.1211 36.9427 27.0638 36.9427 25.7643V24C36.9427 23.3528 37.4674 22.8281 38.1146 22.8281H44V6.3568C44 5.05727 42.9427 4 41.6432 4H25.1719V8.71352H25.7643C28.3562 8.71352 30.4648 10.8222 30.4648 13.4141C30.4648 16.0059 28.3562 18.1146 25.7643 18.1146H25.1719V22.8281H31.0573C31.7045 22.8281 32.2291 23.3528 32.2291 24V25.7643C32.2291 27.0638 33.2864 28.1211 34.5859 28.1211Z"
      fill="currentColor"
    />
    <path
      d="M6.3568 4C5.05727 4 4 5.05727 4 6.3568V22.8281H8.71352V22.2357C8.71352 19.6438 10.8222 17.5352 13.4141 17.5352C16.0059 17.5352 18.1146 19.6438 18.1146 22.2357V22.8281H22.8281V16.9427C22.8281 16.2955 23.3528 15.7709 24 15.7709H25.7643C27.0638 15.7709 28.1211 14.7136 28.1211 13.4141C28.1211 12.1145 27.0638 11.0573 25.7643 11.0573H24C23.3528 11.0573 22.8281 10.5326 22.8281 9.88539V4H6.3568Z"
      fill="currentColor"
    />
  </SvgIcon>
)
