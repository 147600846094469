import React, { FunctionComponent } from 'react'

import {
  NewFooter as Footer,
  NewHeader as Header,
  NewThemeProvider,
} from '@common/react-lib-consumer-pres'
import { GenericError } from '@src/components/generic-error'

import { BaseTemplateNew, withProviders } from '..'

const GenericErrorPage: FunctionComponent = () => {
  return (
    <BaseTemplateNew header={<Header />} footer={<Footer />}>
      <GenericError />
    </BaseTemplateNew>
  )
}

export default withProviders(NewThemeProvider)(GenericErrorPage)
