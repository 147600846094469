import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VolumeHighIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04346 5.74604C7.99337 4.63781 9.38012 4 10.8397 4H11C12.6569 4 14 5.34315 14 7V17C14 18.6569 12.6569 20 11 20H10.8397C9.38012 20 7.99337 19.3622 7.04346 18.254L6.92054 18.1106C6.62705 17.7681 6.22645 17.535 5.78377 17.4489L5.23652 17.3425C3.35696 16.977 2 15.3308 2 13.416V10.584C2 8.6692 3.35695 7.02297 5.23652 6.6575L5.78377 6.55109C6.22645 6.46502 6.62705 6.23185 6.92054 5.88945L7.04346 5.74604ZM10.8397 6C9.96396 6 9.13192 6.38269 8.56197 7.04763L8.43905 7.19103C7.85207 7.87584 7.05087 8.34217 6.1655 8.51433L5.61826 8.62073C4.67848 8.80347 4 9.62658 4 10.584V13.416C4 14.3734 4.67848 15.1965 5.61826 15.3793L6.1655 15.4857C7.05087 15.6578 7.85207 16.1242 8.43905 16.809L8.56197 16.9524C9.13192 17.6173 9.96397 18 10.8397 18H11C11.5523 18 12 17.5523 12 17V7C12 6.44772 11.5523 6 11 6H10.8397Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2941 6.63397C19.7724 6.35783 20.384 6.52171 20.6601 7C21.5378 8.52019 21.9999 10.2446 21.9999 12C21.9999 13.7554 21.5378 15.4798 20.6601 17C20.384 17.4783 19.7724 17.6422 19.2941 17.366C18.8158 17.0899 18.6519 16.4783 18.9281 16C19.6302 14.7838 19.9999 13.4043 19.9999 12C19.9999 10.5957 19.6302 9.21615 18.9281 8C18.6519 7.52171 18.8158 6.91012 19.2941 6.63397Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3991 8.30553C16.8774 8.02939 17.489 8.19326 17.7651 8.67155C18.3494 9.68353 18.657 10.8315 18.657 12C18.657 13.1685 18.3494 14.3164 17.7651 15.3284C17.489 15.8067 16.8774 15.9706 16.3991 15.6944C15.9208 15.4183 15.7569 14.8067 16.0331 14.3284C16.4418 13.6205 16.657 12.8174 16.657 12C16.657 11.1825 16.4418 10.3795 16.0331 9.67155C15.7569 9.19326 15.9208 8.58167 16.3991 8.30553Z"
      fill="currentColor"
    />
  </SvgIcon>
)
