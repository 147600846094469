/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { SetValueConfig, UseFormSetValue } from 'react-hook-form'

type TSetValue = UseFormSetValue<TResponse>
type TAutoComplete = {
  [key: string]: any
}
type TResponse = {
  address: string
  coaddress?: string
  zip?: string
  city?: string
  state?: string
  cozip?: string
  cocity?: string
  costate?: string
}
export const setAddressValidationPrefill = (
  setValue: TSetValue,
  userInfo: TResponse,
  coaddress?: boolean,
): void => {
  const setValueConfig: SetValueConfig = {
    shouldDirty: true,
    shouldValidate: true,
  }
  if (coaddress) {
    setValue('coaddress', userInfo.address, setValueConfig)
    setValue('cocity', userInfo.city, setValueConfig)
    setValue('costate', userInfo.state, setValueConfig)
    setValue('cozip', userInfo.zip, setValueConfig)
  } else {
    setValue('address', userInfo.address, setValueConfig)
    setValue('city', userInfo.city, setValueConfig)
    setValue('state', userInfo.state, setValueConfig)
    setValue('zip', userInfo.zip, setValueConfig)
  }
}

export class ValidationData {
  public AddressElement: HTMLInputElement
  public autoComplete: TAutoComplete
  public setValue: TSetValue
  public response: TResponse
  public constructor(AddressElement: HTMLInputElement, setValue: TSetValue) {
    this.AddressElement = AddressElement
    this.setValue = setValue
  }
}

export class NavigatorGeoLocate {
  constructor(private validationData: ValidationData) {}

  public geolocate(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        })
        this.validationData.autoComplete.setBounds(circle.getBounds())
      })
    }
  }
}
export class Validation {
  constructor(
    private validationData: ValidationData,
    private coaddress?: boolean,
  ) {}

  public handlePlaceChanged(): void {
    const place = this.validationData.autoComplete.getPlace()
    const streeAddressNumber = place.address_components.find((i) =>
      i.types[0].includes('street_number'),
    )
    const streetAddressRoad = place.address_components.find((i) =>
      i.types[0].includes('route'),
    )
    const zipCode = place.address_components.find((i) =>
      i.types[0].includes('postal_code'),
    )
    const city = place.address_components.find((i) =>
      i.types[0].includes('locality'),
    )
    const state = place.address_components.find((i) =>
      i.types[0].includes('administrative_area_level_1'),
    )

    const longStreeAddressNumber = streeAddressNumber?.long_name || ''
    const longStreetAddressRoad = streetAddressRoad?.long_name || ''
    const longZip = zipCode?.long_name || ''
    const longCity = city?.long_name || ''
    const shortState = state?.short_name || ''
    this.validationData.response = {
      address: `${longStreeAddressNumber} ${longStreetAddressRoad}`,
      zip: longZip,
      city: longCity,
      state: shortState,
    }
    setAddressValidationPrefill(
      this.validationData.setValue,
      this.validationData.response,
      this.coaddress,
    )
  }
}
export class FindAddress {
  constructor(
    private navigator: NavigatorGeoLocate,
    private validationData: ValidationData,
    private validation: Validation,
  ) {}

  public findAddress(): void {
    this.navigator.geolocate()
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (
          mutation.attributeName == 'autocomplete' &&
          (mutation.target as unknown as HTMLElement).getAttribute(
            'autocomplete',
          ) == 'off'
        ) {
          observer.disconnect()
          ;(mutation.target as unknown as HTMLElement).setAttribute(
            'autocomplete',
            'none',
          )
        }
      }
    })

    observer.observe(this.validationData.AddressElement, { attributes: true })
    this.validationData.autoComplete = new google.maps.places.Autocomplete(
      this.validationData.AddressElement,
      { types: ['geocode'] },
    )

    // remove default padding on parent element
    // to allow the prediction span the whole input width
    this.validationData.AddressElement.parentElement.setAttribute(
      'style',
      'padding: 0px',
    )
    this.validationData.AddressElement.setAttribute('style', 'padding: 16px')
    this.validationData.AddressElement.setAttribute(
      'autocomplete',
      'new-street-address',
    )

    const listnerHandler = this.validationData.autoComplete.addListener(
      'place_changed',
      () => {
        this.validation.handlePlaceChanged()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        google.maps.event.removeListener(listnerHandler)
        google.maps.event.clearInstanceListeners(
          this.validationData.autoComplete,
        )
      },
    )
  }
}
export const getGoogleAddress = (props: {
  AddressElement: HTMLInputElement
  setValue: TSetValue
  coaddress?: boolean
}): TResponse => {
  const validationData = new ValidationData(
    props.AddressElement,
    props.setValue,
  )
  const navigator = new NavigatorGeoLocate(validationData)
  const validation = new Validation(validationData, props.coaddress)

  const address = new FindAddress(navigator, validationData, validation)
  address.findAddress()
  return validationData.response
}
