import React from 'react'

import { TextFieldProps } from '@common/types'

import { Text } from '../text'
import { useIsDense } from '../utils'

import { EndIcon } from './end-icon'
import { Container, Input, InputContainer, LabelContainer } from './styles'

export const TextField = React.forwardRef<unknown, TextFieldProps>(
  (props, ref) => {
    const {
      error,
      disabled,
      label,
      loading,
      endIcon,
      value,
      hideErrorIcon,
      ...restProps
    } = props

    const active = !!value && !error
    const hasEndIcon = !!endIcon || !!error || loading

    const dense = useIsDense()
    const { 'data-testid': dataTestId, ...otherRestProps } = restProps

    return (
      <Container {...{ active, error, disabled }}>
        <label>
          {/* Label */}
          {label && (
            <LabelContainer>
              <Text
                use={[
                  'span',
                  {
                    xs: 'interactive.inputLabel14',
                    md: 'interactive.inputLabel16',
                  },
                ]}
                sx={{ color: 'inherit' }}
              >
                {label}
              </Text>
            </LabelContainer>
          )}
        </label>

        {/* Input */}
        <InputContainer>
          <EndIcon
            icon={
              !hideErrorIcon && error ? 'error' : loading ? 'loading' : endIcon
            }
          />

          <Input
            {...{ active, dense, hasEndIcon }}
            error={!!error}
            aria-label={label}
            type="text"
            {...otherRestProps}
            disabled={disabled}
            value={value}
            inputRef={ref}
            inputProps={{ 'data-testid': props['data-testid'] }}
          />
        </InputContainer>

        {/* Error Message */}
        {error && (
          <Text use={['span', 'subheadings.14m']} color="semantic.error">
            {error}
          </Text>
        )}
      </Container>
    )
  },
)
