import React, { FC } from 'react'
import { Box, styled, Theme } from '@mui/material'
import cx from 'classnames'

import { ButtonProps } from '@common/types'

import { LoaderIconWithAnimation as LoaderIcon, SelectedIcon } from '../icons'

type BaseButtonProps = Pick<
  ButtonProps,
  'loading' | 'disabled' | 'selected' | 'iconPosition'
> & { variant?: ButtonProps['type'] }

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getButtonStyles = (
  theme: Theme,
  { loading, iconPosition, variant }: BaseButtonProps,
) => ({
  fontSize: theme.extension.typography.interactive.buttonLabel18.fontSize,
  fontWeight: theme.extension.typography.interactive.buttonLabel18.fontWeight,
  lineHeight: theme.extension.typography.interactive.buttonLabel18.lineHeight,
  border: ['tertiary', 'card'].includes(variant)
    ? `1px solid ${theme.extension.colors.neutral[800]}`
    : '1px solid transparent',
  padding:
    variant === 'card'
      ? '16px 37.4px'
      : variant === 'text'
      ? 2
      : iconPosition === 'middle'
      ? theme.spacing(2.5)
      : theme.spacing(2, 4),
  backgroundColor:
    variant === 'primary'
      ? theme.extension.colors.expressive.blueSky[1000]
      : theme.extension.colors.text.white,
  color:
    variant === 'primary'
      ? theme.extension.colors.text.white
      : variant === 'card'
      ? theme.extension.colors.text.groundBlack
      : theme.extension.colors.text.black,
  '&:hover': {
    color:
      variant === 'primary'
        ? theme.extension.colors.text.white
        : theme.extension.colors.base.deepBlue,

    backgroundColor:
      variant === 'primary' ? theme.extension.colors.base.deepBlue : 'inherit',

    borderColor:
      variant === 'tertiary' || variant === 'card'
        ? theme.extension.colors.base.deepBlue
        : 'transparent',
  },
  '&:focus': {
    color:
      variant === 'primary'
        ? theme.extension.colors.text.white
        : theme.extension.colors.base.blueSky,
    outline: loading
      ? 'none'
      : `4px solid ${theme.extension.colors.expressive.blueSky[400]}`,
    border:
      variant === 'primary' || loading
        ? '1px solid transparent'
        : `1px solid ${theme.extension.colors.expressive.blueSky[1000]}`,
  },
  '&:disabled': {
    color: theme.extension.colors.neutral[700],
    backgroundColor:
      variant === 'text'
        ? theme.extension.colors.text.white
        : theme.extension.colors.neutral[400],
    outline: 'none',
    border: ['tertiary', 'card'].includes(variant)
      ? `1px solid ${theme.extension.colors.neutral[700]}`
      : '1px solid transparent',
  },
})

const BaseButton = styled('button', {
  shouldForwardProp: (props) => props !== 'active' && props !== 'loading',
})<BaseButtonProps>((props) => {
  const { disabled, loading, selected, theme } = props
  const isSelectedStyles = !disabled && !loading && selected

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: theme.spacing(8),
    outline: 'none',
    borderRadius: theme.spacing(0.5),
    pointerEvents: loading || disabled ? 'none' : 'auto',
    userSelect: loading || disabled ? 'none' : 'auto',
    boxSizing: 'border-box',
    fontFamily: theme.typography.fontFamily,

    ...(isSelectedStyles
      ? {
          outline: 'none',
          border: `1px solid ${theme.extension.colors.base.blueSky}`,
          backgroundColor: theme.extension.colors.base.blueSky,
          padding: 20,
          color: theme.extension.colors.text.white,
          fontSize:
            theme.extension.typography.interactive.buttonLabel16.fontSize,
          fontWeight:
            theme.extension.typography.interactive.buttonLabel16.fontWeight,
          lineHeight:
            theme.extension.typography.interactive.buttonLabel16.lineHeight,
        }
      : getButtonStyles(theme, props)),
  }
})

const Icon = styled(Box, {
  shouldForwardProp: (props) => props !== 'loading',
})<{ loading?: boolean }>(({ loading, theme }) => ({
  display: 'inline-flex',
  '& svg': {
    width: loading ? theme.spacing(4) : theme.spacing(3),
    height: loading ? theme.spacing(4) : theme.spacing(3),
  },
}))

export const Button: FC<ButtonProps> = ({
  children,
  selected,
  type = 'primary',
  icon,
  iconPosition,
  loading,
  onClick,
  disabled,
  className,
  sx,
  'data-testid': dataTestId,
}) => {
  const iconPos = loading || selected ? 'middle' : iconPosition

  const iconComponent = loading ? (
    <LoaderIcon type={type} />
  ) : selected ? (
    <SelectedIcon />
  ) : (
    icon
  )
  return (
    <BaseButton
      type="button"
      variant={type}
      data-testid={dataTestId}
      {...{
        disabled,
        loading,
        selected,
        onClick,
        sx,
      }}
      className={cx(className)}
    >
      {iconPos === 'start' && !selected && !loading && (
        <Icon mr={2}>{icon}</Icon>
      )}
      {iconPos === 'middle' && type !== 'text' && (
        <Icon m={!selected && '0 auto'} mr={selected && 2}>
          {iconComponent}
        </Icon>
      )}
      {!loading && children}
      {iconPos === 'end' && !selected && !loading && <Icon ml={2}>{icon}</Icon>}
    </BaseButton>
  )
}
