/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import {
  ResidenceAndEmploymentInfo,
  ResidenceAndEmploymentSubmissionInfo,
} from '@common/types'
import {
  getAuthToken,
  getLeadSourceAndRequestIdQueryParams,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'
import { pickNonNil } from '../utils'

export const submitResidenceAndEmployment = async (
  residenceAndEmploymentInfo: ResidenceAndEmploymentSubmissionInfo,
): Promise<{ resumeUrl: string }> => {
  const body = {
    ...pickNonNil(residenceAndEmploymentInfo),
    requestId: getRequestId(),
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-residence-and-employment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const submitResidenceAndEmploymentWithCoborrower = async ({
  borrower,
  coborrower,
}: {
  borrower: ResidenceAndEmploymentSubmissionInfo
  coborrower: ResidenceAndEmploymentSubmissionInfo
}): Promise<{ resumeUrl: string }> => {
  const body = {
    requestId: getRequestId(),
    residenceAndEmploymentInfo: borrower,
    coborrowerResidenceAndEmploymentInfo: coborrower,
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-residence-and-employment-with-coborrower`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const residenceAndEmploymentExists = async (): Promise<boolean> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/get-residence-and-employment-exists${getLeadSourceAndRequestIdQueryParams()}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data.residenceExists && data.currentEmploymentExists
}

export const getResidenceEmployment =
  async (): Promise<ResidenceAndEmploymentInfo> => {
    const response = await fetch(
      `${consumerXpAggregatorUrl}/v1/get-residence-and-employment${getLeadSourceQueryParams()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
        body: JSON.stringify({ requestId: getRequestId() }),
      },
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data?.message)
    }
    return data
  }

export const updateResidenceEmployment = async (
  residence: ResidenceAndEmploymentInfo,
): Promise<ResidenceAndEmploymentInfo> => {
  const body = {
    requestId: getRequestId(),
    ...residence,
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/update-residence-and-employment${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const getResidence = async (
  residenceUuid: string,
): Promise<ResidenceAndEmploymentInfo> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/residence/${residenceUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}
