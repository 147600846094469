import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { bool, object, SchemaOf } from 'yup'

import { Button, PageLevelError } from '@common/react-lib-consumer-pres'
import { NewReviewInfoFormModule, PageRoute } from '@common/types'

import { submitReviewMyInfoRest } from '../../../api'
import { useLogger, useResolver } from '../../../hooks'
import { trackReviewPageSubmitted } from '../../../tracking'
import { Element, useModule } from '../../utils'
import { CheckboxField } from '../controlled-fields'

import { ReviewInfoPopup, useNewReviewInfoFormsContext } from './forms-context'

type ReviewInformationForm = {
  module: NewReviewInfoFormModule
}

type ReviewInfoSchema = {
  legal: boolean
}

const schema: SchemaOf<ReviewInfoSchema> = object({
  legal: bool().oneOf([true], 'Please agree to the terms below'),
})

export const useNewReviewInfoForm = (): ReviewInformationForm => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const router = useRouter()

  const { handleSubmit, control } = useForm<ReviewInfoSchema>({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: { legal: false },
    resolver: useResolver(schema),
  })

  const [showSubmitError, setShowSubmitError] = useState(false)
  const hideSubmitError = useCallback(() => setShowSubmitError(false), [])

  const logger = useLogger()

  const [popupIsOpen, setPopupIsOpen] = useState(false)
  const openFormRef = useRef<HTMLDivElement>()

  const handleCloseModal = useCallback(() => {
    setPopupIsOpen(false)
    setTimeout(() =>
      openFormRef.current?.scrollIntoView({ behavior: 'smooth' }),
    )
  }, [])

  const { getOpenForm } = useNewReviewInfoFormsContext()
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.Processing)
    }
    prefetch().catch(console.error)
  }, [])
  const submit = handleSubmit(async () => {
    try {
      const openForm = getOpenForm()
      if (openForm) {
        setPopupIsOpen(true)
        openFormRef.current = openForm
        return
      }

      setShowSubmitError(false)
      setIsSubmitting(true)
      await submitReviewMyInfoRest()
      void trackReviewPageSubmitted()

      const nextURL = PageRoute.Processing
      void router.push({
        pathname: nextURL,
      })
    } catch (error) {
      setShowSubmitError(true)
      logger.error('Error submitting Review Info form: ', { error })
      setIsSubmitting(false)
    }
  })

  return {
    module: useModule({
      SubmitButton: Element(Button, {
        onClick: submit,
        loading: isSubmitting,
        children: 'Continue',
      }),
      Popup: Element(ReviewInfoPopup, {
        open: popupIsOpen,
        onClick: handleCloseModal,
      }),
      LegalCheckbox: Element(CheckboxField, {
        control,
        name: 'legal',
        label:
          'By clicking this box, I agree that I have read, understand and consent to:',
      }),
      Error:
        showSubmitError &&
        Element(PageLevelError, {
          onClose: hideSubmitError,
        }),
    }),
  }
}
