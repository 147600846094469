import React, { RefObject } from 'react'
import { PatternFormat } from 'react-number-format'

import { useSSNFormat } from '@common/react-lib-cms-context'
import {
  NumberFormatInputProps,
  SSNTextFieldProps,
  TextFieldProps,
} from '@common/types'

import { TextField } from './text-field'

const SSNInputFieldForward = React.forwardRef<
  RefObject<TextFieldProps>,
  NumberFormatInputProps
>(({ format = '', ...props }, ref) => {
  return (
    <PatternFormat
      aria-label={props.label}
      getInputRef={ref}
      customInput={TextField}
      format={format}
      type="tel"
      mask="_"
      {...props}
    />
  )
})

export const SSNInputField = React.forwardRef<
  RefObject<TextFieldProps>,
  SSNTextFieldProps
>(({ name, label, ...props }, ref) => {
  const format = useSSNFormat()
  return (
    <SSNInputFieldForward
      ref={ref}
      name={`${name}-format`}
      label={label}
      format={format.numberFormat}
      error={props.error}
      {...props}
    />
  )
})
