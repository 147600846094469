import { UnpackNestedValue } from 'react-hook-form'

import { identify } from '../../../tracking'
import { PersonalInfoForm } from '../../schemas'

export const segmentIdentify = (
  segmentUserId: string,
  values: UnpackNestedValue<PersonalInfoForm> & {
    customerUuid: string
    coborrowerUuid: string
    resumeUrl: string
    newResumeUrl: string
  },
): void => {
  identify(segmentUserId, {
    uuid: values.customerUuid,
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.cellphone,
    email: values.emailAddress,
    citizenship: values.citizenship,
    resumeUrl: values.resumeUrl,
    newResumeUrl: values.newResumeUrl,
    ...(values.cosigner && {
      coborrower: {
        uuid: values.coborrowerUuid,
        firstName: values.cofirstName,
        lastName: values.colastName,
        email: values.coemailAddress,
        birthdate: values.codob,
        citizenship: values.cocitizenship,
        phone: values.cocellphone,
        relationship: values.relationship,
      },
    }),
  })
}
