import fetchRetry from 'fetch-retry'
import compact from 'lodash/compact'

import { ELoanAasmState, ELoanUrl, PageRoute } from '@common/types'
import {
  getAuthToken,
  getLeadCampaign,
  getLeadSource,
  getLeadSourceQueryParams,
  getRequestId,
  LeadCampaign,
  LeadSource,
} from '@src/utils/storage'

import { customerAdapters } from '../api'
import { consumerXpAggregatorUrl } from '../config'

import { Logger, useLogger } from './use-logger'

const checkStatus = async (logger: Logger): Promise<LoanApplicationState> => {
  const fetchWithRetry = fetchRetry(fetch, {
    retries: 3,
    retryDelay: 500,
  })
  const response = await fetchWithRetry(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/loan-application-state${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  ).catch((error) => {
    logger.error('Error fetching loan application state in check status', {
      error,
    })
    logger.info('failed fetching loan application state in check status', {
      err: error,
      online: navigator.onLine,
      request_id: getRequestId(),
      lead_source: getLeadSource(),
    })
    throw error
  })

  const data = await (response as Response).json()
  if (!(response as Response).ok) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    throw new Error(data?.message)
  }
  return data
}

type LoanApplicationIssuesState = {
  /* eslint-disable @typescript-eslint/naming-convention */
  is_joint_loan: boolean
  is_credit_frozen: boolean
  frozen_credit_bureau_name: {
    primary_borrower: ReadonlyArray<string> | null
    secondary_borrower: ReadonlyArray<string> | null
  }
  /* eslint-enable @typescript-eslint/naming-convention */
}

type LoanApplicationState = {
  resumeUrl: string
  aasmState: string
  loanUrl: string
  loanApplicationIssues?: LoanApplicationIssuesState
  loanRoutes?: Map<string, string>
  directMailApp?: boolean
}

const loanStateRoutes = new Map<string, string>([
  [`${ELoanAasmState.SoftPullCompleted}:${ELoanUrl.Status}`, '/processing'],
  [`${ELoanAasmState.VehicleVerified}:${ELoanUrl.Status}`, '/processing'],
  [`${ELoanAasmState.Submitted}:${ELoanUrl.Onboardings}`, '/vehicle-info'],
  [
    `${ELoanAasmState.ExistingLoanSelectionNeeded}:${ELoanUrl.Tradeline}`,
    '/select-tradelines',
  ],
  [
    `${ELoanAasmState.VehicleOptionsNeeded}:${ELoanUrl.VehicleOptions}`,
    '/vehicle-options',
  ],
  [
    `${ELoanAasmState.OffersGenerated}:${ELoanUrl.OfferSelections}`,
    PageRoute.OfferSelections, //IF this gets changed, please ensure you also change the href in create-account's back-link component
  ],
  [
    `${ELoanAasmState.OffersAccepted}:${ELoanUrl.Credentials}`,
    '/create-account',
  ],
  [
    `${ELoanAasmState.VehicleInformationNeeded}:${ELoanUrl.VehicleLookup}`,
    '/vehicle-lookup',
  ],
  [`${ELoanAasmState.Paused}:${ELoanUrl.Pending}`, '/loan-application-error'],
])

const mapLoanStateToUrl = ({
  resumeUrl,
  aasmState,
  loanUrl,
  loanRoutes,
}: LoanApplicationState): string => {
  for (const [loanState, nextUrl] of loanRoutes.entries()) {
    const [state, url] = loanState.split(':')
    if (state === aasmState && loanUrl.includes(url)) {
      return nextUrl
    }
  }
  return resumeUrl
}

interface IGetNextUrlByLoanState {
  (): () => Promise<string>
}

export const useGetNextUrlByLoanState: IGetNextUrlByLoanState = () => {
  const logger = useLogger()
  const leadSource = getLeadSource()
  const requestId = getRequestId()

  return async () => {
    try {
      const {
        resumeUrl,
        aasmState,
        loanUrl,
        loanApplicationIssues,
        directMailApp,
      } = await checkStatus(logger)
      const { is_joint_loan: isJointLoan } = loanApplicationIssues || {}
      if (isJointLoan) {
        logger.info(
          'navigating user to coborrower found in useGetNextUrlByLoanState',
          {
            resume_url: resumeUrl,
            aasm_state: aasmState,
            loan_url: loanUrl,
            loan_application_issues: loanApplicationIssues,
            lead_source: getLeadSource(),
            request_id: requestId,
            lead_campaign: getLeadCampaign(),
          },
        )
        return '/coborrower-found'
      }

      const flaggedRoutes = new Map(
        compact([
          [`${ELoanAasmState.Closed}:${ELoanUrl.Pending}`, '/no-loan-options'],
          directMailApp && [
            `${ELoanAasmState.Paused}:${ELoanUrl.Pending}`,
            '/dm-additional-offers',
          ],
        ]),
      )

      // PARTNER LOAN ROUTES - These are the routes for partner specific loan flows
      const loanRoutes = new Map([...loanStateRoutes, ...flaggedRoutes])
      if (resumeUrl && aasmState && loanUrl) {
        //EXPERIAN
        if (getLeadSource() === LeadSource.EXPERIAN) {
          const { borrower } = await customerAdapters.getCustomers()
          if (borrower?.estimatedYearlyIncome === null) {
            return PageRoute.ExperianAdditionalInformationNeeded
          } else if (aasmState === ELoanAasmState.Submitted) {
            return PageRoute.ReviewInfo
          }
        }

        //THE ZEBRA
        if (
          getLeadSource() === LeadSource.THE_ZEBRA &&
          aasmState === ELoanAasmState.Submitted
        ) {
          return PageRoute.TheZebraAdditionalInformationNeeded
        }

        //CREDIT KARMA
        const url = mapLoanStateToUrl({
          resumeUrl,
          aasmState,
          loanUrl,
          loanRoutes,
        })

        if (url === '/loan-application-error') {
          logger.info(
            'navigating user to loan-application-error page in useGetNextUrlByLoanState',
            {
              resume_url: resumeUrl,
              aasm_state: aasmState,
              loan_url: loanUrl,
              loan_application_issues: loanApplicationIssues,
              loan_routes: JSON.stringify(loanRoutes),
              lead_source: leadSource,
              request_id: requestId,
              navigation_url: url,
            },
          )
        }
        if (
          url === PageRoute.OfferSelections &&
          getLeadSource() === LeadSource.CREDIT_KARMA &&
          getLeadCampaign() === LeadCampaign.LIGHTBOX
        ) {
          logger.info(
            'navigating user to offer-selections-credit-karma page in useGetNextUrlByLoanState',
            {
              resume_url: resumeUrl,
              aasm_state: aasmState,
              loan_url: loanUrl,
              loan_application_issues: loanApplicationIssues,
              loan_routes: JSON.stringify(loanRoutes),
              lead_source: getLeadSource(),
              request_id: requestId,
              lead_campaign: getLeadCampaign(),
              navigation_url: url,
            },
          )
          return PageRoute.OfferSelectionsCreditKarma
        }

        logger.info('navigating user in useGetNextUrlByLoanState', {
          resume_url: resumeUrl,
          aasm_state: aasmState,
          loan_url: loanUrl,
          loan_application_issues: loanApplicationIssues,
          loan_routes: JSON.stringify(loanRoutes),
          lead_source: getLeadSource(),
          request_id: requestId,
          lead_campaign: getLeadCampaign(),
          navigation_url: url,
        })
        return url
      }
    } catch (error) {
      logger.error('Error in useGetNextUrlByLoanState', { error })
      logger.info(
        'navigating user to loan-application-error page due to panic in useGetNextUrlByLoanState',
        {
          lead_source: leadSource,
          request_id: requestId,
          err: error,
        },
      )
      return '/loan-application-error'
    }
  }
}
