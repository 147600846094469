import { useCallback, useMemo } from 'react'
import { Control, Resolver, useForm } from 'react-hook-form'

import { omitSafe } from '@common/lib-types'
import {
  mapCitizenshipToDisplay,
  mapRelationshipToDisplay,
  NewVerifyFormButton,
} from '@common/react-lib-consumer-pres'
import {
  Citizenship,
  CitizenshipKeys,
  CustomersInfo,
  ERelationship,
  ReviewBorrowerFormModule,
  ReviewCoborrowerFormModule,
  ReviewInfoData,
} from '@common/types'

import { customerAdapters } from '../../../api'
import { useComponent, useResolver } from '../../../hooks'
import { trackReviewPageEdited } from '../../../tracking'
import { DateUtils, sanitizePhoneNumber } from '../../../utils'
import { Borrower, Coborrower } from '../../schemas'
import { Element, useModule } from '../../utils'
import { DateInput, PhoneInput, Select, TextField } from '../controlled-fields'

import { useFormControls } from './use-form-controls'
import { useTrackChanges } from './use-track-changes'

const emptyCoborrower = {
  firstName: '',
  lastName: '',
  email: '',
  birthdate: '',
  phoneNumber: '',
  citizenship: '' as CitizenshipKeys,
  relationship: '' as ERelationship,
} as unknown as CoBorrowerInfo

const mapToCoborrower = (customers: CustomersInfo): CoBorrowerInfo => {
  const { coborrower: fullCoborrower, relationship } = customers
  if (!fullCoborrower) {
    return emptyCoborrower
  }

  const { birthdate, ...coborrower } = fullCoborrower
  return {
    ...coborrower,
    birthdate: DateUtils.IsoToUs(birthdate),
    relationship,
  }
}

const mapToBorrower = (customers: CustomersInfo): PersonalInfo => {
  const {
    borrower: { birthdate, ...borrower },
    hasCoborrower,
  } = customers
  return {
    ...borrower,
    birthdate: DateUtils.IsoToUs(birthdate),
    hasCoborrower,
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const generateSharedElements = (
  controlPassed: Control<PersonalInfo> | Control<CoBorrowerInfo>,
  isSubmitting: boolean,
) => {
  // type workaround
  const control = controlPassed as Control<PersonalInfo | CoBorrowerInfo>
  return {
    FirstName: Element(TextField, {
      control,
      label: 'First name',
      name: 'firstName',
      placeholder: 'First name',
      disabled: isSubmitting,
      'data-testid': 'first-name-input',
    }),
    LastName: Element(TextField, {
      control,
      label: 'Last name',
      name: 'lastName',
      placeholder: 'Last name',
      disabled: isSubmitting,
    }),
    Email: Element(TextField, {
      control,
      label: 'Email',
      name: 'email',
      disabled: isSubmitting,
    }),
    PhoneNumber: Element(PhoneInput, {
      control,
      label: 'Phone number',
      name: 'phoneNumber',
      disabled: isSubmitting,
    }),
    Birthdate: Element(DateInput, {
      control,
      label: 'Birthdate',
      name: 'birthdate',
      disabled: isSubmitting,
    }),
    Citizenship: Element(Select, {
      control,
      label: 'Citizenship',
      name: 'citizenship',
      disabled: isSubmitting,
      menuItems: [
        { value: '', displayName: 'Select' },
        ...Object.values(Citizenship).map((value) => ({
          value,
          displayName: mapCitizenshipToDisplay(value),
        })),
      ],
    }),
  }
}

type PersonalInfo = ReviewInfoData['borrower']
type CoBorrowerInfo = ReviewInfoData['coborrower']

export const useNewBorrowerForm = (
  data: {
    borrower: PersonalInfo
    coborrower?: CoBorrowerInfo
  },
  setData: (data: {
    borrower: PersonalInfo
    coborrower?: CoBorrowerInfo
  }) => void,
  getOpenForm: () => HTMLDivElement | void,
): ReviewBorrowerFormModule => {
  const schema = useMemo(
    () => Borrower(data.borrower.email),
    [data.borrower.email],
  )

  const form = useForm<PersonalInfo>({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: data.borrower,
    // TODO: find a better typescript solution for optional fields
    resolver: useResolver(schema) as unknown as Resolver<PersonalInfo>,
  })
  const {
    control,
    formState: { isSubmitting },
  } = form

  const trackBorrowerChanges = useTrackChanges(
    {
      ...data.borrower,
      phoneNumber: sanitizePhoneNumber(data.borrower.phoneNumber),
    },
    trackReviewPageEdited,
  )

  const submit = async ({
    birthdate: newBirthday,
    firstName,
    lastName,
    hasCoborrower: newHasCoborrower,
    ...newBorrower
  }: PersonalInfo): Promise<void> => {
    const fName = firstName
    const lName = lastName
    const borrowerToSubmit = {
      ...omitSafe(newBorrower, ['ssn', 'segmentUserId']),
      firstName: fName,
      lastName: lName,
      birthdate: DateUtils.UsToIso(newBirthday),
    }
    const removedCoborrower =
      newHasCoborrower === false &&
      newHasCoborrower !== data.borrower.hasCoborrower
    if (removedCoborrower && data.coborrower !== emptyCoborrower) {
      const removeCoborrowerFn = customerAdapters.removeCoborrower
      await removeCoborrowerFn()
    }
    const updateCustomersFn = customerAdapters.updateCustomers
    const customers = await updateCustomersFn({
      borrower: borrowerToSubmit,
    })
    const addedCoborrower =
      newHasCoborrower === true &&
      newHasCoborrower !== data.borrower.hasCoborrower

    setData({
      borrower: {
        ...mapToBorrower(customers),
        hasCoborrower: addedCoborrower || customers.hasCoborrower,
      },
      coborrower: addedCoborrower
        ? emptyCoborrower
        : mapToCoborrower(customers),
    })

    trackBorrowerChanges({
      birthdate: newBirthday,
      hasCoborrower: newHasCoborrower,
      firstName: fName,
      lastName: lName,
      ...newBorrower,
    })
  }

  return {
    ...useFormControls(data.borrower, submit, form, getOpenForm),
    module: useModule({
      ...generateSharedElements(control, isSubmitting),
      HasCoborrower: Element(Select, {
        control,
        label: 'Co-borrower',
        name: 'hasCoborrower',
        disabled: isSubmitting,
        menuItems: [
          {
            value: String(true),
            displayName: 'Yes',
          },
          {
            value: String(false),
            displayName: 'No',
          },
        ],
      }),
    }),
  }
}

export const useNewCoborrowerForm = (
  data: CoBorrowerInfo = emptyCoborrower,
  setBorrower: (fn: (d: PersonalInfo) => PersonalInfo) => void,
  setCoborrower: (d: Partial<CoBorrowerInfo>) => void,
  getOpenForm: () => HTMLDivElement | void,
): ReviewCoborrowerFormModule => {
  const schema = useMemo(() => Coborrower(data.email), [data.email])
  const form = useForm<CoBorrowerInfo>({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: data,
    // TODO: find a better typescript solution for optional fields
    resolver: useResolver(schema) as unknown as Resolver<CoBorrowerInfo>,
  })
  const {
    control,
    formState: { isSubmitting },
  } = form

  const trackCoBorrowerChanges = useTrackChanges(
    {
      ...data,
      phoneNumber: data.phoneNumber && sanitizePhoneNumber(data.phoneNumber),
    },
    trackReviewPageEdited,
  )

  const submit = async ({
    birthdate: newBirthdate,
    firstName,
    lastName,
    ...data
  }: CoBorrowerInfo): Promise<void> => {
    const fName = firstName
    const lName = lastName
    const clearedData = {
      ...omitSafe(data, ['ssn', 'segmentUserId']),
      firstName: fName,
      lastName: lName,
      birthdate: DateUtils.UsToIso(newBirthdate),
    }
    const updateCustomersFn = customerAdapters.updateCustomers
    const customers = await updateCustomersFn({ coborrower: clearedData })
    setCoborrower(mapToCoborrower(customers))
    trackCoBorrowerChanges({
      ...data,
      firstName: fName,
      lastName: lName,
      birthdate: newBirthdate,
    })
  }

  const isNewCoborrower = data === emptyCoborrower

  const { controls, ...otherControlProps } = useFormControls(
    data,
    submit,
    form,
    getOpenForm,
    data === emptyCoborrower,
  )

  const removeCoborrower = useCallback(() => {
    setBorrower((borrower) => ({
      ...borrower,
      hasCoborrower: false,
    }))
  }, [setBorrower])

  const RemoveCoborrower = useComponent(NewVerifyFormButton, {
    disabled: false,
    onClick: removeCoborrower,
    variant: 'text',
    children: 'Remove Coborrower',
  })

  return {
    ...otherControlProps,
    controls: {
      ...controls,
      ...(isNewCoborrower && {
        Cancel: RemoveCoborrower,
      }),
    },
    module: useModule({
      ...generateSharedElements(control, isSubmitting),
      Relationship: Element(Select, {
        control,
        label: 'Relationship',
        name: 'relationship',
        disabled: isSubmitting,

        menuItems: [
          { value: '', displayName: 'Select' },
          ...Object.values(ERelationship).map((value) => ({
            value,
            displayName: mapRelationshipToDisplay(value),
          })),
        ],
      }),
    }),
  }
}
