import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CalendarCheckIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C8.55228 2 9 2.44772 9 3H17C19.7614 3 22 5.23858 22 8V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V8C2 5.23858 4.23858 3 7 3C7 2.44772 7.44772 2 8 2ZM7 5C5.34315 5 4 6.34315 4 8V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V8C20 6.34315 18.6569 5 17 5H9V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C16.5523 2 17 2.44772 17 3V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V3C15 2.44772 15.4477 2 16 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.684 10.2705C16.0869 10.6482 16.1073 11.281 15.7296 11.6839L11.9796 15.6839C11.6213 16.0661 11.0289 16.107 10.6215 15.7778L8.37151 13.9596C7.94195 13.6125 7.87512 12.9829 8.22224 12.5533C8.56936 12.1237 9.19899 12.0569 9.62855 12.404L11.1559 13.6383L14.2705 10.3161C14.6482 9.91315 15.2811 9.89274 15.684 10.2705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
