import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LightningDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M21.4302 26.5976L34.2156 6.44835C34.7201 5.6532 33.7251 4.76258 32.9905 5.35183L23.7559 12.7593C23.28 13.1411 22.5687 12.8988 22.4247 12.306L19.9921 2.28968C19.8145 1.55826 18.8349 1.41849 18.4598 2.07103L5.51792 24.5843C5.02881 25.4351 6.15631 26.2843 6.83904 25.5793L16.0937 16.0224C16.5502 15.551 17.3472 15.7677 17.5021 16.4055L19.9168 26.3478C20.0903 27.0622 21.0364 27.2183 21.4302 26.5976Z"
      fill="#CCF4EA"
    />
    <path
      d="M35.6051 15.7477L23.2777 35.1753C22.49 36.4166 20.5978 36.1043 20.2508 34.6757L17.9258 25.1024L9.08589 34.231C7.72044 35.641 5.46543 33.9426 6.44366 32.2409L18.9056 10.5626C19.6558 9.25755 21.6148 9.53708 21.9701 10.9999L24.3131 20.6471L33.155 13.5546C34.6241 12.3761 36.6142 14.1573 35.6051 15.7477Z"
      fill="#00C696"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
