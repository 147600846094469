import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const UserRectangleSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 17.8687 19.6307 18.6512 19.0406 19.1991C17.6885 16.6996 15.0436 15 11.9998 15C8.95605 15 6.31122 16.6995 4.95907 19.1988C4.36913 18.651 4 17.8686 4 17V7Z"
      fill="currentColor"
    />
  </SvgIcon>
)
