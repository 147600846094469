import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SendIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6204 2.65347C20.2045 2.20086 21.6691 3.66545 21.2165 5.24959L17.2169 19.2483C16.5502 21.5817 13.4332 22.02 12.1489 19.9609L9.35147 15.4762C9.10942 15.0881 8.78184 14.7606 8.39379 14.5185L3.90903 11.7211C1.84999 10.4367 2.28829 7.31978 4.62169 6.6531L18.6204 2.65347ZM19.2225 4.57404C19.2122 4.57119 19.1968 4.56882 19.1698 4.57652L5.17114 8.57614C4.50445 8.76663 4.37922 9.65718 4.96752 10.0241L9.45228 12.8216C10.099 13.225 10.645 13.7709 11.0484 14.4177L13.8458 18.9024C14.2128 19.4907 15.1033 19.3655 15.2938 18.6988L19.2935 4.70014C19.3012 4.6732 19.2988 4.65772 19.2959 4.64752C19.2922 4.63412 19.2835 4.61746 19.268 4.60196C19.2525 4.58646 19.2358 4.57777 19.2225 4.57404Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.435 9.43501C14.8255 9.82554 14.8255 10.4587 14.435 10.8492L10.8995 14.3848C10.509 14.7753 9.8758 14.7753 9.48528 14.3848C9.09475 13.9942 9.09475 13.3611 9.48528 12.9705L13.0208 9.43501C13.4113 9.04449 14.0445 9.04449 14.435 9.43501Z"
      fill="currentColor"
    />
  </SvgIcon>
)
