import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneOffSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L3.70711 21.7071C3.31658 22.0976 2.68342 22.0976 2.29289 21.7071C1.90237 21.3166 1.90237 20.6834 2.29289 20.2929L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4103 10.1755C10.5894 9.99636 10.6983 9.75575 10.6872 9.5027C10.6709 9.12785 10.6125 8.74997 10.509 8.37614C9.83024 5.9237 7.38659 4.40893 4.9684 5.21552C3.3929 5.74099 2.43384 6.72133 2.1159 8.02892C1.82039 9.24432 2.1284 10.5559 2.60677 11.715C3.0348 12.7521 3.64906 13.785 4.29425 14.7074C4.63905 15.2003 5.34281 15.243 5.76819 14.8176L10.4103 10.1755ZM6.26874 17.1455C6.36966 17.2525 6.46583 17.3515 6.5562 17.442C7.6108 18.4974 9.91998 20.4123 12.2585 21.3844C13.4197 21.8671 14.7361 22.1805 15.9571 21.8857C17.2712 21.5684 18.2565 20.6044 18.7848 19.0178C19.5902 16.5997 18.0782 14.1542 15.6263 13.4745C13.9503 13.01 12.1927 13.453 10.9932 14.5295C10.7117 14.3115 10.4409 14.0733 10.1827 13.8149C10.0849 13.7171 9.99006 13.6175 9.8981 13.5161L6.26874 17.1455Z"
      fill="currentColor"
    />
  </SvgIcon>
)
