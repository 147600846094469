import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NavigationCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2893 8.11003C15.3049 7.65479 16.3452 8.69513 15.89 9.71065L13.4608 15.1295C12.9578 16.2517 11.3825 16.2998 10.8119 15.2105L10.2177 14.0761C10.1519 13.9505 10.0495 13.8481 9.92394 13.7823L8.78952 13.1881C7.70021 12.6175 7.74835 11.0422 8.87047 10.5392L14.2893 8.11003ZM14.0135 9.9865L9.76247 11.8921L10.6661 12.3655C11.0799 12.5822 11.4178 12.9201 11.6345 13.3339L12.1079 14.2375L14.0135 9.9865Z"
      fill="currentColor"
    />
  </SvgIcon>
)
