export const PageRoute = {
  PersonalInfo: '/personal-info',
  VehicleInfo: '/vehicle-info',
  VehicleOptions: '/vehicle-options',
  OfferSelections: '/offer-selections',
  OfferSelectionsCreditKarma: '/offer-selections-credit-karma',
  NoLoanOptions: '/no-loan-options',
  LoanApplicationError: '/loan-application-error',
  SelectTradelines: '/select-tradelines',
  VehicleLookup: '/vehicle-lookup',
  CreateAccount: '/create-account',
  IncomeVerification: '/income-verification',
  ReviewInfo: '/review-info',
  Processing: '/processing',
  ResidenceAndEmployment: '/residence-and-employment',
  Resume: '/resume',
  ExperianAdditionalInformationNeeded:
    '/experian/additional-information-needed',
  TheZebraAdditionalInformationNeeded:
    '/the-zebra/additional-information-needed',
  AcceptOffer: '/accept-offer',
} as const

export type PageRouteKeys = (typeof PageRoute)[keyof typeof PageRoute]
