import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FireDuotoneIcon: React.FC<SvgIconProps & { altTone: string }> = ({
  htmlColor = '#00C696',
  altTone = '#DFFFE3',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M38.9145 27.4195C38.4563 21.4539 35.6788 17.7155 33.2285 14.4166C30.9596 11.3626 29 8.72523 29 4.83476C29 4.52226 28.825 4.23664 28.5475 4.09343C28.2691 3.94937 27.9347 3.97296 27.6816 4.15687C24.0016 6.79015 20.9312 11.2284 19.8586 15.463C19.114 18.4112 19.0155 21.7256 19.0016 23.9146C15.6032 23.1887 14.8334 18.1053 14.8252 18.0499C14.787 17.7862 14.6259 17.5568 14.3915 17.4315C14.1547 17.3078 13.8763 17.2988 13.6363 17.4177C13.458 17.5039 9.26203 19.6359 9.01789 28.1485C9.00078 28.4317 9 28.7158 9 28.9998C9 37.2698 15.7293 43.9987 24 43.9987C24.0114 43.9995 24.0236 44.0012 24.0334 43.9987C24.0366 43.9987 24.0398 43.9987 24.0439 43.9987C32.2943 43.9751 39 37.2552 39 28.9998C39 28.5839 38.9145 27.4195 38.9145 27.4195Z"
      fill="currentColor"
    />
    <path
      d="M17 35.2118C17 38.9549 20.1399 42 23.9999 42C27.8599 42 30.9998 38.9548 31 35.2118C31 35.015 30.9852 34.8317 30.9704 34.6495L30.9704 34.6486C30.7254 31.5693 29.3298 29.7635 27.9797 28.0169C27.0363 26.7962 26.0701 25.5454 25.5745 24C24.4603 25.1906 23.5808 26.6759 23.2127 28.0719C22.7992 29.6464 22.765 31.3909 22.765 32.9031C22.765 33.4901 22.2432 33.9651 21.5983 33.9651C19.633 33.9651 18.4231 32.7485 17.7463 31.4251C17.3851 32.1418 17.0558 33.2194 17.0091 34.798C16.9989 34.9556 17 35.0842 17 35.2118Z"
      fill={altTone}
    />
  </SvgIcon>
)
