import { UnpackNestedValue, useForm } from 'react-hook-form'

import {
  CitizenshipKeys,
  EmploymentStatusKeys,
  ERelationship,
  ResidenceStatusKeys,
  StateKeys,
} from '@common/types'

import { coborrowerAdapters } from '../../../api'
import { useConst, useLogger, useResolver } from '../../../hooks'
import { DateUtils, parseInteger } from '../../../utils'
import { CoborrowerInformationForm, CoborrowerInfoSchema } from '../../schemas'

type FormModel = CoborrowerInformationForm

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCoborrowerForm = () => {
  const logger = useLogger()
  const schema = useConst(() => CoborrowerInfoSchema(logger))
  const form = useForm<FormModel>({
    mode: 'onTouched',
    shouldFocusError: true,
    defaultValues: {
      sameAddress: true,
      firstName: '',
      lastName: '',
      emailAddress: '',
      confirmEmailAddress: '',
      phoneNumber: '',
      citizenship: '' as CitizenshipKeys,
      dob: '',
      relationship: '' as ERelationship,
      address: '',
      apartment: '',
      city: '',
      state: '' as StateKeys,
      zip: '',
      livedInAddress: {
        years: '',
        months: '',
      },
      rentOrOwn: '' as ResidenceStatusKeys,
      monthlyPayment: '',
      employmentStatus: '' as EmploymentStatusKeys,
      yearlyIncome: '',
    },
    resolver: useResolver(schema),
  })

  const submit = async (
    values: UnpackNestedValue<FormModel>,
  ): Promise<void> => {
    await coborrowerAdapters.submitCoborrower({
      ...values,
      dob: DateUtils.UsToIso(values.dob),
      livedInAddress: {
        years: Number.parseInt(`${values.livedInAddress.years}`),
        months: Number.parseInt(`${values.livedInAddress.months}`),
      },
      monthlyPayment: parseInteger(values.monthlyPayment),
      yearlyIncome: parseInteger(values.yearlyIncome),
    })
  }

  return {
    ...form,
    submit,
  }
}
