import React, { FC } from 'react'

import { SelectButtonProps } from '@common/types'

import { Button } from '../button'
import { CheckCircleIcon } from '../icons'
import { Text } from '../text'

export const SelectButton: FC<SelectButtonProps> = (props) => {
  const {
    selected,
    onClick,
    inactiveLabel = 'Select',
    activeLabel = 'Selected',
    ...rest
  } = props
  return (
    <>
      {selected && (
        <Button
          icon={<CheckCircleIcon htmlColor="common.white" />}
          iconPosition="start"
          type="primary"
          onClick={onClick}
          {...rest}
        >
          <Text
            use={['p', 'interactive.buttonLabel16']}
            color="text.white"
            margin={0}
          >
            {activeLabel}
          </Text>
        </Button>
      )}

      {!selected && (
        <Button type="tertiary" onClick={onClick} {...rest}>
          {inactiveLabel}
        </Button>
      )}
    </>
  )
}
