export enum ELoanAasmState {
  Submitted = 'submitted',
  Closed = 'closed',
  Paused = 'paused',
  SoftPullCompleted = 'successful_soft_pull_completed',
  ExistingLoanSelectionNeeded = 'existing_loan_selection_needed',
  VehicleOptionsNeeded = 'vehicle_options_needed',
  VehicleInformationNeeded = 'vehicle_information_needed',
  VehicleVerified = 'vehicle_verified',
  OffersGenerated = 'offers_generated',
  OffersAccepted = 'offer_accepted',
  OfferAcknowledged = 'offer_acknowledged',
  HardPullVerified = 'hard_pull_verified',
  PrequeueDataNeeded = 'prequeue_data_needed',
  SecondaryPrequeDataNeeded = 'secondary_prequeue_data_needed',
}

export enum ELoanUrl {
  Onboardings = 'onboardings', // Vehicle Info
  OfferAcknowledgement = 'offer_acknowledgements', // Post Offer Selection
  Credentials = 'credentials', // Create Account
  VehicleLookup = 'vehicle_information_collections', // Vehicle lookup page
  OfferSelections = 'offer_selections', // Offer selection page
  Pending = 'pending', // loan application error state
  Status = 'status', // Processing Page
  Tradeline = 'existing_loan_verifications', // Tradeline Page
  VehicleOptions = 'vehicle_options', // Vehicle Options Page
  PrequeuDataCollections = 'prequeue_data_collections',
  SecondaryPrequeueDataCollections = 'secondary_prequeue_data_collections',
}
