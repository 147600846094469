export const State = {
  AK: 'AK',
  AL: 'AL',
  AR: 'AR',
  AZ: 'AZ',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  GA: 'GA',
  GU: 'GU',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VI: 'VI',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
} as const

export type StateKeys = (typeof State)[keyof typeof State]
/** Flexible typesafe utility to create a map between string/number/string enum values */
const createValuesMap =
  <T extends string>(map: { [key in T]: string }) =>
  (key: T): string => {
    return map[key] ?? ''
  }

export const stateToDisplay = createValuesMap({
  [State.AL]: 'Alabama',
  [State.AK]: 'Alaska',
  [State.AZ]: 'Arizona',
  [State.AR]: 'Arkansas',
  [State.CA]: 'California',
  [State.CO]: 'Colorado',
  [State.CT]: 'Connecticut',
  [State.DE]: 'Delaware',
  [State.DC]: 'District of Columbia',
  [State.FL]: 'Florida',
  [State.GA]: 'Georgia',
  [State.GU]: 'Guam',
  [State.HI]: 'Hawaii',
  [State.ID]: 'Idaho',
  [State.IL]: 'Illinois',
  [State.IN]: 'Indiana',
  [State.IA]: 'Iowa',
  [State.KS]: 'Kansas',
  [State.KY]: 'Kentucky',
  [State.LA]: 'Louisiana',
  [State.ME]: 'Maine',
  [State.MD]: 'Maryland',
  [State.MA]: 'Massachusetts',
  [State.MI]: 'Michigan',
  [State.MN]: 'Minnesota',
  [State.MS]: 'Mississippi',
  [State.MO]: 'Missouri',
  [State.MT]: 'Montana',
  [State.NE]: 'Nebraska',
  [State.NV]: 'Nevada',
  [State.NH]: 'New Hampshire',
  [State.NJ]: 'New Jersey',
  [State.NM]: 'New Mexico',
  [State.NY]: 'New York',
  [State.NC]: 'North Carolina',
  [State.ND]: 'North Dakota',
  [State.OH]: 'Ohio',
  [State.OK]: 'Oklahoma',
  [State.OR]: 'Oregon',
  [State.PA]: 'Pennsylvania',
  [State.PR]: 'Puerto Rico',
  [State.RI]: 'Rhode Island',
  [State.SC]: 'South Carolina',
  [State.SD]: 'South Dakota',
  [State.TN]: 'Tennessee',
  [State.TX]: 'Texas',
  [State.UT]: 'Utah',
  [State.VT]: 'Vermont',
  [State.VI]: 'Virgin Islands',
  [State.VA]: 'Virginia',
  [State.WA]: 'Washington',
  [State.WV]: 'West Virginia',
  [State.WI]: 'Wisconsin',
  [State.WY]: 'Wyoming',
})
