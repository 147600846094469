import { FC } from 'react'
import { Box, styled } from '@mui/material'
import Image from 'next/legacy/image'

import { Link, Text } from '@common/react-lib-consumer-pres'
import { PageRoute } from '@common/types'

const Spacer = styled(Box)({
  marginTop: '144px',
})

const Container = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: 144,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.extension.colors.surface.cloudGray,
  borderTop: `1px solid ${theme.extension.colors.neutral[700]}`,

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const Content = styled(Box)({
  display: 'flex',
})

const Subheading = styled('span')(({ theme }) => ({
  fontSize: 28,
  fontWeight: theme.typography.fontWeightBold,

  [theme.breakpoints.down('md')]: {
    ...theme.extension.typography.subheadings['16b'],
    display: 'block',
  },
}))

const WorkHoursText = styled('span')(({ theme }) => ({
  fontSize: 12,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.extension.colors.text.softBlack,
}))

const ImageHeroContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 124,
  height: 68,
  marginRight: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    width: 88,
    height: 48,
    marginRight: theme.spacing(2),
  },
}))

export const DirectMailFooter: FC = () => {
  return (
    <>
      <Spacer />
      <Container>
        <Content>
          <ImageHeroContainer mr={{ xs: 2, md: 3 }}>
            <Image
              src="/assets/images/hero.png"
              alt="Team member picture"
              layout="fill"
            />
          </ImageHeroContainer>

          <Box display="flex" flexDirection="column">
            <Box>
              <Subheading>We are here to help. </Subheading>
              <Subheading>
                Call <Link href="tel:18448532868">1.844.853.2868</Link>
              </Subheading>
            </Box>

            <Box display={{ xs: 'none', md: 'flex' }}>
              <Text
                use={['span', 'subheadings.14m']}
                color="text.softBlack"
                mr={6}
              >
                Mon - Fri: 9 AM - 8 PM EST
              </Text>
              <Text use={['span', 'subheadings.14m']} color="text.softBlack">
                Sat - Sun: 9 AM - 4 PM EST
              </Text>
            </Box>
          </Box>
        </Content>

        {/* Mobile Work hours */}
        <Box display={{ xs: 'flex', md: 'none' }} mt={2}>
          <WorkHoursText sx={{ mr: 4 }}>
            Mon - Fri: 9 AM - 8 PM EST
          </WorkHoursText>
          <WorkHoursText>Sat - Sun: 9 AM - 4 PM EST</WorkHoursText>
        </Box>
      </Container>
    </>
  )
}

export const directMailFooterRoutes = [
  PageRoute.PersonalInfo,
  PageRoute.VehicleInfo,
  PageRoute.ResidenceAndEmployment,
  PageRoute.ReviewInfo,
  PageRoute.Processing,
  PageRoute.SelectTradelines,
  PageRoute.VehicleOptions,
] as Array<string>
