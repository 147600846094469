import { useEffect, useState } from 'react'

import { PartnerCustomerInfo } from '@common/types'

import { getCustomerPrefillInfo } from '../api'

type CustomerInfoHook = {
  customerInfo: PartnerCustomerInfo
  customerInfoLoaded: boolean
}

export const useCustomerInfo = (
  partner: string,
  token: string,
): CustomerInfoHook => {
  const [customerInfo, setCustomerInfo] = useState<PartnerCustomerInfo>()
  const [customerInfoLoaded, setCustomerInfoLoaded] = useState(false)

  const fetchData = async (partner: string, token: string): Promise<void> => {
    try {
      setCustomerInfoLoaded(false)

      const customerInfoData = await getCustomerPrefillInfo(partner, token)

      setCustomerInfo({
        ...customerInfoData,
        phoneNumber: customerInfoData.phoneNumber?.replace(/^\+1/, ''),
      })
    } finally {
      setCustomerInfoLoaded(true)
    }
  }

  useEffect(() => {
    if (!partner || !token) {
      setCustomerInfoLoaded(true)
      return
    }

    void fetchData(partner, token)
  }, [partner, token])

  return {
    customerInfo,
    customerInfoLoaded,
  }
}
