import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import once from 'lodash/once'
import {
  consumerDashboardUrl,
  consumerXpAggregatorUrl,
  datadogLogsClientToken,
  datadogRumApplicationId,
  datadogRumClientToken,
  datadogRumService,
  datadogRumSessionReplaySamplingRate,
  datadogRumSessionSamplingRate,
  datadogRumSite,
  datadogRumTrackUserInteractions,
  datadogRumVersion,
  environment,
  prefillServiceApiUrlPartner,
  refiApiUrl,
  refiPartnerAggBaseUrl,
  vehiclesServiceUrl,
  // eslint-disable-next-line import/no-internal-modules
} from 'src/src/config'

// Keep consistent environment strings in DD RUM across different webapps
// https://github.com/motorefi/site-marketing/blob/main/src/config.ts#L10
type DatadogEnvironment =
  | 'local-dev'
  | 'dev'
  | 'ci'
  | 'preview'
  | 'prod'
  | 'unknown'

/**
 * Map CxFlow environment to Datadog RUM environment.
 * This prevents an explosion of environment strings in Datadog RUM.
 *
 * @param cxFlowEnv The environment as defined in src/src/config.ts
 * @returns Normalized environment string for Datadog RUM
 */
const getDatadogEnvFromCxFlowEnv = (
  cxFlowEnv: typeof environment,
): DatadogEnvironment => {
  switch (cxFlowEnv) {
    // dev (equivalent) environments
    case 'local-dev': {
      return 'local-dev'
    }
    case 'dev': {
      return 'dev'
    }

    // automated testing environments
    case 'ci': {
      return 'ci'
    }

    // preview (equivalent) environments
    case 'preview':
    case 'review-app':
    case 'staging': {
      return 'preview'
    }

    case 'prod': {
      return 'prod'
    }

    case 'unknown': {
      return 'unknown'
    }
  }
}

export const getDatadogLogs = once(() => {
  const datadogEnvironment = getDatadogEnvFromCxFlowEnv(environment)
  datadogLogs.init({
    clientToken: datadogLogsClientToken,
    service: datadogRumService,
    env: datadogEnvironment,
    trackSessionAcrossSubdomains: true,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    version: datadogRumVersion,
    site: datadogRumSite,
  })

  return datadogLogs
})

export const getDatadogRum = once(() => {
  const datadogEnvironment = getDatadogEnvFromCxFlowEnv(environment)

  datadogRum.init({
    applicationId: datadogRumApplicationId,
    clientToken: datadogRumClientToken,
    defaultPrivacyLevel: 'mask-user-input',
    env: datadogEnvironment,
    service: datadogRumService,
    site: datadogRumSite,
    trackSessionAcrossSubdomains: true,
    allowedTracingUrls: [
      {
        match: consumerXpAggregatorUrl,
        propagatorTypes: ['datadog'],
      },
      {
        match: refiApiUrl,
        propagatorTypes: ['datadog'],
      },
      {
        match: vehiclesServiceUrl,
        propagatorTypes: ['datadog'],
      },
      {
        match: refiPartnerAggBaseUrl,
        propagatorTypes: ['datadog'],
      },
      {
        match: prefillServiceApiUrlPartner,
        propagatorTypes: ['datadog'],
      },
      {
        match: consumerDashboardUrl,
        propagatorTypes: ['datadog'],
      },
    ],

    // Specify a version number to identify the deployed version of your
    // application in Datadog
    version: datadogRumVersion,

    sessionReplaySampleRate: datadogRumSessionReplaySamplingRate,
    sessionSampleRate: datadogRumSessionSamplingRate,
    trackUserInteractions: datadogRumTrackUserInteractions,
  })

  datadogRum.startSessionReplayRecording()

  return datadogRum
})

export type DatadogRum = typeof datadogRum
