import React, { ChangeEvent, useCallback } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  InputLabelProps,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps,
  RadioProps as MuiRadioProps,
} from '@mui/material'
import cx from 'classnames'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

import { Box } from './content-elements'

export type RadioProps = {
  autoFocus?: MuiRadioProps['autoFocus']
  classes?: {
    root?: string
    control?: string
    group?: MuiRadioProps['className']
    formControlLabel?: Exclude<
      FormControlLabelProps['classes'],
      undefined
    >['label']
  }
  error?: boolean
  helperText?: React.ReactNode
  id?: MuiRadioProps['id']
  label?: string
  labelProps?: InputLabelProps
  name?: string
  onChange?: RadioGroupProps['onChange']
  options: ReadonlyArray<{ value: string; label: string }>
  row?: RadioGroupProps['row']
  value?: string
  ref?: React.Ref<HTMLInputElement>
  disabled?: MuiRadioProps['disabled']
}

/**
 *
 * Base Radio component wrapping the Mui RadioGroup.
 *
 */
// eslint-disable-next-line react/display-name
export const RadioGroup = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      name,
      value,
      onChange,
      error = false,
      helperText,
      label,
      labelProps,
      options,
      classes = {},
      disabled,
      ...props
    },
    ref,
  ): JSX.Element => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>, value: string): void => {
        if (!isNil(onChange)) {
          onChange(event, value)
        }
      },
      [onChange],
    )

    return (
      <Box className={cx(classes.root)}>
        <FormControl error={error} classes={{ root: cx(classes.control) }}>
          {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
          <MuiRadioGroup
            name={name}
            onChange={handleChange}
            value={value}
            className={cx(classes.group)}
            ref={ref}
            {...props}
          >
            {map(options, (item) => {
              return (
                <FormControlLabel
                  classes={{ label: cx(classes.formControlLabel) }}
                  name={item.value}
                  key={item.label}
                  value={item.value}
                  control={<MuiRadio />}
                  label={item.label}
                  disabled={disabled}
                />
              )
            })}
          </MuiRadioGroup>
          {!!error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </Box>
    )
  },
)
