import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const StarCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7148 7.19623C11.2978 6.2275 12.7022 6.2275 13.2852 7.19623L14.2841 8.85613L16.1715 9.29324C17.273 9.54835 17.707 10.8841 16.9658 11.7379L15.6958 13.2009L15.8633 15.1309C15.9611 16.2573 14.8249 17.0828 13.7838 16.6418L12 15.886L10.2162 16.6418C9.1751 17.0828 8.03888 16.2573 8.13663 15.1309L8.30414 13.2009L7.03417 11.7379C6.293 10.8841 6.727 9.54835 7.82847 9.29324L9.71582 8.85613L10.7148 7.19623ZM12 8.93936L11.3202 10.0689C11.1108 10.4169 10.7692 10.6651 10.3734 10.7568L9.08913 11.0542L9.95333 12.0498C10.2196 12.3565 10.3501 12.7581 10.315 13.1627L10.201 14.4761L11.4148 13.9619C11.7888 13.8034 12.2111 13.8034 12.5851 13.9619L13.799 14.4761L13.685 13.1627C13.6499 12.7581 13.7804 12.3565 14.0466 12.0498L14.9108 11.0542L13.6265 10.7568C13.2308 10.6651 12.8892 10.4169 12.6797 10.0689L12 8.93936Z"
      fill="currentColor"
    />
  </SvgIcon>
)
