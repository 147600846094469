import React, { FC, MouseEventHandler } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { SelectButton, Text } from '../../reskin'

export const showAccordionAt = 768

const ButtonBox = styled(Box)({
  justifyContent: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
})

const LoanInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1, 2),
  gap: '4px',

  [theme.breakpoints.down(showAccordionAt)]: {
    width: '100%',
    alignItems: 'center',
  },
}))

const InfoDivider = styled('div')(({ theme }) => ({
  width: '1px',
  height: '80px',
  background: theme.extension.colors.divider.lightGray,
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
  [theme.breakpoints.down(showAccordionAt)]: {
    display: 'none',
  },
}))

const OfferSelectionsHeaderStyles = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: `calc(100% + 90px)`,
  width: `calc(100vw - 2 * ${theme.spacing(2)})`,
  left: '50%',
  transform: 'translateX(-50%)',
  right: '0px',
  top: '0px',
  background: theme.extension.colors.base.deepGreen,
  borderRadius: '16px',
  zIndex: -1,
  [theme.breakpoints.down('md')]: {
    width: `100vw`,
  },
}))

const CardInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledBoxTextPayment = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  background: theme.palette.common.black,
  borderRadius: '4px',
  padding: theme.spacing(0.25, 1),
}))

type SelectCardButtonProps = {
  selected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  testId?: string
}

type CurrentMonthlyPaymentProps = {
  currentMonthlyPayment: number
  children: string
}

type SavingsProps = {
  savings: number | string
  paymentSavings: number
}

export type CarLoanInfoProps = {
  outstandingAmount: number
  remainingTerm: number
  estimatedRate: number
}

export const OfferSelectionsHeader: FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <OfferSelectionsHeaderStyles>
      {isMobile ? (
        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#a)">
            <rect
              width="100%"
              height="100%"
              rx="16"
              fill={theme.extension.colors.base.deepGreen}
            />
            <circle cx="543" cy="624" r="878" fill="#0D5262" />
            <circle cx="274.167" cy="529.622" r="583.427" fill="#0E4A58" />
            <circle
              cx="358.533"
              cy="439.533"
              r="330.322"
              fill={theme.extension.colors.base.deepGreen}
            />
          </g>
          <defs>
            <clipPath id="a">
              <rect
                width="100%"
                height="100%"
                rx="16"
                fill={theme.extension.colors.text.white}
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#a)">
            <rect
              width="100%"
              height="100%"
              rx="16"
              fill={theme.extension.colors.base.deepGreen}
            />
            <circle cx="406" cy="115" r="878" fill="#0D5262" />
            <circle cx="137.166" cy="20.622" r="583.427" fill="#0E4A58" />
            <circle
              cx="221.534"
              cy="-69.467"
              r="330.322"
              fill={theme.extension.colors.base.deepGreen}
            />
          </g>
          <defs>
            <clipPath id="a">
              <rect
                width="100%"
                height="100%"
                rx="16"
                fill={theme.extension.colors.text.white}
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </OfferSelectionsHeaderStyles>
  )
}

export const SelectCardButton: FC<SelectCardButtonProps> = (props) => {
  const { selected, onClick, testId } = props
  return (
    <ButtonBox>
      <SelectButton
        data-testid={testId}
        onClick={onClick}
        selected={selected}
        sx={{ width: '100%', height: 64 }}
      />
    </ButtonBox>
  )
}

export const CurrentMonthlyPayment: FC<CurrentMonthlyPaymentProps> = (
  props,
) => {
  const { children, currentMonthlyPayment } = props

  return (
    <CardInfo>
      <Text use={['p', 'subheadings.24b']} color="base.blueSky" margin={0}>
        {children}
      </Text>

      <Text use={['p', 'subheadings.18b']} color="base.blueSky" margin={0}>
        {'/mo'}
        <sup>*</sup>
      </Text>

      <StyledBoxTextPayment>
        <Text
          use={['p', 'subheadings.14sb']}
          color="text.white"
          margin={0}
          sx={{ textDecoration: 'line-through' }}
        >
          {`$${currentMonthlyPayment.toFixed(2)}`}
        </Text>
      </StyledBoxTextPayment>
    </CardInfo>
  )
}

export const Savings: FC<SavingsProps> = (props) => {
  const { savings, paymentSavings } = props
  return (
    <CardInfo>
      {paymentSavings < 0 ? (
        <>
          <Text
            use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
            color="base.blueSky"
            margin={0}
          >
            {'-'}
          </Text>
          <Text
            use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
            color="text.softBlack"
            margin={0}
          >
            {`$${savings}`}
          </Text>
        </>
      ) : (
        <>
          <Text
            use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
            color="base.blueSky"
            margin={0}
          >
            {'+'}
          </Text>
          <Text
            use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
            color="text.softBlack"
            margin={0}
          >
            {'$'}
          </Text>
          <Text
            use={['p', { xs: 'subheadings.16b', sm: 'subheadings.18b' }]}
            color="text.softBlack"
            margin={0}
          >
            {`${savings}`}
          </Text>
        </>
      )}
    </CardInfo>
  )
}

export const CarLoanInfo: FC<CarLoanInfoProps> = (props) => {
  const { outstandingAmount, remainingTerm, estimatedRate } = props
  return (
    <>
      <LoanInfoContainer>
        <Text
          use={['p', { xs: 'subheadings.14m', sm: 'subheadings.16m' }]}
          color="text.bodyGray"
          margin={0}
        >
          {'Outstanding amount: '}
        </Text>

        <Text
          use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
          color="text.softBlack"
          margin={0}
        >
          {'$'}
          {outstandingAmount.toLocaleString('en')}
        </Text>
      </LoanInfoContainer>
      <InfoDivider />

      <LoanInfoContainer>
        <Text
          use={['p', { xs: 'subheadings.14m', sm: 'subheadings.16m' }]}
          color="text.bodyGray"
          margin={0}
        >
          {'Remaining term: '}
        </Text>

        <Text
          use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
          color="text.softBlack"
          margin={0}
        >
          {remainingTerm ? (
            <span>
              {remainingTerm} {'months'}
            </span>
          ) : (
            'N/A'
          )}
        </Text>
      </LoanInfoContainer>
      <InfoDivider />

      <LoanInfoContainer>
        <Text
          use={['p', { xs: 'subheadings.14m', sm: 'subheadings.16m' }]}
          color="text.bodyGray"
          margin={0}
        >
          {'Estimated APR: ²'}
        </Text>

        <Text
          use={['p', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
          color="text.softBlack"
          margin={0}
        >
          {estimatedRate ? (
            <span>
              {estimatedRate}
              {'%'}
            </span>
          ) : (
            'N/A'
          )}
        </Text>
      </LoanInfoContainer>
    </>
  )
}
