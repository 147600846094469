/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Notification } from '@common/react-lib-consumer-pres'

import { vehicleAdapters } from '../../../api'
import { useFetchData } from '../../../hooks'
import { VehicleFormValues } from '../../schemas'
import { Element } from '../../utils'
import { VehicleLookupField } from '../controlled-fields'

export const useVin = ({
  control,
  getValues,
  watch,
  formState,
}: UseFormReturn<VehicleFormValues>) => {
  const {
    data: foundCar,
    retry: tryFindCar,
    loading,
    isError,
    setData: setFoundCar,
  } = useFetchData(
    async () => {
      const vin = getValues('dynamic.vin')?.replace(/_/g, '')
      if (vin?.length !== 17) {
        return null
      }
      return vehicleAdapters.lookupVehicle({ vin })
    },
    { handleInitialDataLoad: false, initialLoading: false },
  )

  const [showWarning, setShowWarning] = useState(false)
  const hideWarning = useCallback(() => setShowWarning(false), [])
  useEffect(() => {
    setShowWarning(isError)
  }, [isError])

  // reset found car on findBy change
  const findBy = watch('findBy')
  useEffect(() => {
    setFoundCar(null)
  }, [findBy])

  const handleVinChange = useCallback(() => {
    // set timeout is needed to wait for field validation
    setTimeout(() => {
      const hasError =
        !!formState.errors.dynamic &&
        'vin' in formState.errors.dynamic &&
        !!formState.errors.dynamic.vin?.message

      if (!hasError) {
        void tryFindCar()
      } else {
        setFoundCar(null)
      }
    })
  }, [])

  return {
    // TODO: error handling -> we should make another request in 30 seconds & show a different message
    // TODO: discuss the best UX to avoid refreshing the browser (all data will be lost)
    Warning:
      showWarning &&
      Element(Notification, {
        type: 'warning',
        title: 'Let’s give it a minute',
        body: 'We’re having trouble reaching the Motor Vehicle Database. Try to retype your VIN number.',
        onClose: hideWarning,
        scrollIntoView: true,
        shake: true,
      }),
    fields: {
      Vin: Element(VehicleLookupField, {
        label: 'Enter VIN',
        control,
        mask: '*'.repeat(17),
        name: 'dynamic.vin',
        loading,
        onBlur: handleVinChange,
        foundCar: isError ? undefined : foundCar,
        warning: isError,
        dataTestId: 'input-vin',
      }),
    },
  }
}
