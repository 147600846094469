import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LoaderV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C12.5523 2 13 2.44772 13 3C13 3.55228 12.5523 4 12 4C10.4178 4 8.87104 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47672 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5823 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17317C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2941 6.63397C19.7724 6.35783 20.384 6.52171 20.6601 7C20.7671 7.18526 20.8681 7.3739 20.9629 7.56563C21.2078 8.06065 21.0051 8.66047 20.5101 8.90537C20.0151 9.15028 19.4152 8.94752 19.1703 8.45251C19.0944 8.29912 19.0137 8.1482 18.9281 8C18.6519 7.52171 18.8158 6.91012 19.2941 6.63397Z"
      fill="currentColor"
    />
  </SvgIcon>
)
