import React from 'react'
import { IconButton, styled } from '@mui/material'

import { NotificationBaseProps } from '@common/types'

import {
  AlertCircleSolidIcon,
  CheckCircleSolidIcon,
  CloseIcon,
  Icon,
  WarningSolidIcon,
} from '../icons'
import { Text } from '../text'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(-2, -2, 0, 0),
}))

type ContainerProps = { type?: NotificationBaseProps['type'] }
const Container = styled('div')<ContainerProps>(({ theme, type }) => ({
  backgroundColor:
    type === 'error'
      ? theme.extension.colors.semantic.errorBg
      : type === 'warning'
      ? theme.extension.colors.semantic.warningBg
      : theme.extension.colors.semantic.successBg,
  border: `1px solid`,
  borderColor:
    type === 'error'
      ? theme.extension.colors.semantic.error
      : type === 'warning'
      ? theme.extension.colors.semantic.warning
      : theme.extension.colors.base.greenLeaf,
  borderRadius: '4px',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}))

const IconContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: 'flex',
}))

const ContentWrapper = styled('div')({
  display: 'flex',
})

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  rowGap: '2px',

  '& a': {
    color: theme.extension.colors.text.softBlack,
  },
}))

export const NotificationBase = React.forwardRef<
  HTMLDivElement,
  NotificationBaseProps
>(({ type, title, body, className, onClose, sx }, ref) => {
  return (
    <Container {...{ type, className, ref, sx }}>
      <ContentWrapper>
        <IconContainer>
          {type === 'error' ? (
            <Icon use={AlertCircleSolidIcon} color="semantic.error" size={24} />
          ) : type === 'warning' ? (
            <Icon use={WarningSolidIcon} color="semantic.warning" size={24} />
          ) : (
            <Icon use={CheckCircleSolidIcon} color="base.greenLeaf" size={24} />
          )}
        </IconContainer>
        <ContentContainer>
          {title && (
            <Text
              use={['h4', 'subheadings.14b']}
              color="text.softBlack"
              margin={0}
            >
              {title}
            </Text>
          )}
          {body && (
            <Text
              use={['p', 'paragraphs.14']}
              color="text.softBlack"
              margin={0}
            >
              {body}
            </Text>
          )}
        </ContentContainer>
      </ContentWrapper>
      {onClose && (
        <StyledIconButton onClick={onClose}>
          <Icon use={CloseIcon} size={16} />
        </StyledIconButton>
      )}
    </Container>
  )
})
