import { useRef } from 'react'

type ResultBox<T> = { value: T }

/**
 * React hook for creating a value exactly once.
 * useMemo doesn't give this guarantee unfortunately - https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 */
export const useConst = <T>(fn: () => T): T => {
  const ref = useRef<ResultBox<T>>()

  if (!ref.current) {
    ref.current = { value: fn() }
  }

  return ref.current.value
}
