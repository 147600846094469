import React, { useCallback } from 'react'

import { ToolbarButton } from '@common/react-lib-base'
import {
  CreateAccountFormModule as FormModule,
  CustomerInfo,
  SelectedOffer,
} from '@common/types'

import { useLogger } from '../../hooks'
import {
  trackActiveDutyServiceMemberSelected,
  trackCreateAccountSubmitted,
  trackTermsAndConditionSelected,
} from '../../tracking'
import { CheckBoxField, Element, SSNNormalTextField, useModule } from '../utils'

import { useCreateAccountForms } from './form'
import { TermsAndConditionsLabel } from './terms-and-conditions'

type CreateAccountFormModule = {
  module: FormModule
}

export const useCreateAccountFormModule = (
  customer: CustomerInfo,
  lender: Pick<SelectedOffer, 'lender_id' | 'lender_name'>,
  hasCoborrower?: boolean,
): CreateAccountFormModule => {
  const {
    getValues,
    control,
    handleSubmit,
    submit: submitForm,
    formState: { isValid, isSubmitted, isSubmitting, isDirty },
  } = useCreateAccountForms(lender, hasCoborrower)
  const logger = useLogger()

  const submit = handleSubmit(async (data): Promise<void> => {
    try {
      await submitForm(data)
      void trackCreateAccountSubmitted()
    } catch (error) {
      logger.error('Error submitting account: ', { error })
    }
  })

  return {
    module: useModule({
      Submit: Element(ToolbarButton, {
        onClick: submit,
        children: 'Submit Application',
        disabled: !isValid || isSubmitted || isSubmitting || !isDirty,
        loading: isSubmitting,
      }),
      SSN: Element(SSNNormalTextField, {
        name: 'ssn',
        label: 'Social Security Number (SSN)',
        control,
        id: 'ssn',
      }),
      CoborrowerSSN:
        hasCoborrower &&
        Element(SSNNormalTextField, {
          name: 'coborrowerSsn',
          label: 'Co-borrower Social Security Number (SSN)',
          control,
        }),
      IsCoborrowerActiveDuty:
        hasCoborrower &&
        Element(CheckBoxField, {
          name: 'coborrowerActiveDuty',
          children:
            'Co-borrower is an active-duty servicemember or covered dependent',
          control,
        }),
      IsActiveDuty: Element(CheckBoxField, {
        name: 'activeDuty',
        children: 'I am an active-duty servicemember or covered dependent',
        control,
        onChange: useCallback(() => {
          const value = getValues('activeDuty')
          if (value) {
            void trackActiveDutyServiceMemberSelected()
          }
        }, []),
      }),
      TermsAndConditions: Element(CheckBoxField, {
        name: 'terms',
        children: <TermsAndConditionsLabel />,
        control,
        onChange: useCallback(() => {
          const value = getValues('terms')
          if (value) {
            void trackTermsAndConditionSelected()
          }
        }, []),
      }),
    }),
  }
}
