import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MoneyBagDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M36.5 33.8333C32.365 33.8333 29 30.4683 29 26.3333C29 22.1983 32.365 18.8333 36.5 18.8333H40.6667V15.5C40.6667 13.6617 39.1717 12.1667 37.3333 12.1667H35.6667V8.83333C35.6667 6.995 34.1717 5.5 32.3333 5.5H9C6.52833 5.5 4.485 7.30667 4.085 9.665C4.04833 9.80167 4 9.935 4 10.0833V35.5C4 38.2567 6.24333 40.5 9 40.5H37.3333C39.1717 40.5 40.6667 39.005 40.6667 37.1667V33.8333H36.5ZM7.33333 10.5C7.33333 9.58167 8.08 8.83333 9 8.83333H32.3333V12.1667H9C8.08 12.1667 7.33333 11.4183 7.33333 10.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M42.75 21.332H36.5C33.7433 21.332 31.5 23.5754 31.5 26.332C31.5 29.0887 33.7433 31.332 36.5 31.332H42.75C43.44 31.332 44 30.772 44 30.082V22.582C44 21.892 43.44 21.332 42.75 21.332ZM36.5 27.9987C35.58 27.9987 34.8333 27.252 34.8333 26.332C34.8333 25.412 35.58 24.6654 36.5 24.6654C37.42 24.6654 38.1667 25.412 38.1667 26.332C38.1667 27.252 37.42 27.9987 36.5 27.9987Z"
      fill="currentColor"
    />
  </SvgIcon>
)
