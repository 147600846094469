import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BagV2CheckSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00013 4.76363C7.84713 3.06962 9.57835 2 11.4721 2H12.5279C14.4217 2 16.1531 3.07003 17 4.76396L17.8944 6.55279C18.1414 7.04677 17.9412 7.64744 17.4472 7.89443C16.9532 8.14142 16.3525 7.94119 16.1055 7.44721L15.2111 5.65838C14.703 4.64202 13.6642 4 12.5279 4H11.4721C10.3357 4 9.29711 4.64179 8.78898 5.65805L7.8944 7.44721C7.64741 7.94119 7.04674 8.14142 6.55276 7.89443C6.05878 7.64744 5.85856 7.04676 6.10555 6.55279L7.00013 4.76363Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45925 6C4.02505 6 2.1552 8.15595 2.49945 10.5657L3.51965 17.7071C3.87155 20.1704 5.98115 22 8.4694 22H15.531C18.0193 22 20.1289 20.1704 20.4808 17.7071L21.501 10.5657C21.8452 8.15595 19.9754 6 17.5412 6H6.45925ZM15.6839 11.2705C16.0869 11.6482 16.1073 12.281 15.7295 12.6839L11.9795 16.6839C11.6213 17.0661 11.0289 17.107 10.6215 16.7778L8.37148 14.9596C7.94192 14.6125 7.87508 13.9829 8.22221 13.5533C8.56933 13.1237 9.19896 13.0569 9.62852 13.404L11.1559 14.6383L14.2705 11.3161C14.6482 10.9131 15.281 10.8927 15.6839 11.2705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
