import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const UserV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 15C8.5576 15 6.74797 16.2198 5.84519 17.6976C5.53101 18.212 5.61003 18.6971 5.9936 19.1428C6.41203 19.629 7.16864 20 8.00005 20H16.0001C16.8315 20 17.5881 19.629 18.0065 19.1428C18.3901 18.6971 18.4691 18.212 18.1549 17.6976C17.2521 16.2198 15.4425 15 12.0001 15ZM4.13844 16.655C5.48775 14.4462 8.03689 13 12.0001 13C15.9632 13 18.5124 14.4462 19.8617 16.655C20.6991 18.0259 20.3786 19.4526 19.5224 20.4474C18.7011 21.4018 17.3778 22 16.0001 22H8.00005C6.62233 22 5.29901 21.4018 4.4777 20.4474C3.62153 19.4526 3.301 18.0259 4.13844 16.655Z"
      fill="currentColor"
    />
  </SvgIcon>
)
