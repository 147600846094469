import { consumerXpAggregatorUrl } from '../config'

export const acceptOffer = async (
  offerUuid: string,
  communicationsOptIn: boolean,
  signature: string,
  expiration: string,
): Promise<{ accessToken: string; sourceName: string; requestId: string }> => {
  const response = await fetch(`${consumerXpAggregatorUrl}/v1/accept-offer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      offerUuid,
      communicationsOptIn,
      signature,
      expiration,
    }),
  })

  return response.json()
}
