import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const UnlockIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13C4 10.7909 5.79086 9 8 9H16C18.2091 9 20 10.7909 20 13V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V13ZM8 11C6.89543 11 6 11.8954 6 13V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V13C18 11.8954 17.1046 11 16 11H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V6.5C16 7.05228 15.5523 7.5 15 7.5C14.4477 7.5 14 7.05228 14 6.5V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10V6Z"
      fill="currentColor"
    />
  </SvgIcon>
)
