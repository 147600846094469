import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VideoIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 5.79086 3.79086 4 6 4H13C15.2091 4 17 5.79086 17 8V16C17 18.2091 15.2091 20 13 20H6C3.79086 20 2 18.2091 2 16V8ZM6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H13C14.1046 18 15 17.1046 15 16V8C15 6.89543 14.1046 6 13 6H6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6621 6.75878C19.9491 5.60044 22 6.51382 22 8.24536V15.7546C22 17.4862 19.9491 18.3996 18.6621 17.2412L15.6621 14.5412C15.2406 14.1619 15 13.6216 15 13.0546V10.9454C15 10.3784 15.2406 9.83806 15.6621 9.45878L18.6621 6.75878ZM20 8.24537L17 10.9454L17 13.0546L20 15.7546L20 8.24537Z"
      fill="currentColor"
    />
  </SvgIcon>
)
