import React, { FC } from 'react'

import { Box, FormHelperText } from '@common/react-lib-base'
import { IncomeVerificationForm } from '@common/types'
import { makeStyles, Theme } from '@modules/core-ui'

import { Heading } from '../heading'

import { InformationalText } from './informational-text'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '670px',
    margin: 'auto',
  },
  heading: {
    textAlign: 'center',
    maxWidth: 'none',
    marginBottom: theme.spacing(1),
  },

  subheading: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 0,
  },
  actionContainer: {
    marginTop: theme.spacing(6),
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',

    alignItems: 'center',
    justifyContent: 'space-between',

    '& button': {
      padding: theme.spacing(2, 5),
      height: 'auto',
      fontSize: theme.typography.pxToRem(18),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gridTemplateColumns: '1fr',
      alignItems: 'center',
      marginTop: theme.spacing(4),
    },
  },
  informationTextsContainer: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
    },
    padding: theme.spacing(2),
    backgroundColor: '#F8F8F8',
  },
  informationTextTitle: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
    color: '#191C22',
  },
  noButton: {
    border: 'solid thin silver',
    background: '#fff',
    color: '#0075E3 !important',
    '&:hover': {
      border: 'solid thin silver',
      background: '#fff',
      color: '#0075E3 !important',
    },
  },
}))

export const IncomeVerificationRequirementsForm: FC<IncomeVerificationForm> = (
  props,
) => {
  const classes = useStyles()
  const {
    module: { Yes, No },
  } = props

  return (
    <Box className={classes.container}>
      <h3 className={classes.subheading}>
        {'For this offer you will need to provide proof of income'}
      </h3>
      <Heading className={classes.heading}>
        {'Can you provide your most recent W2 and 2 most recent pay stubs?'}
      </Heading>
      <Box className={classes.actionContainer}>
        <No className={classes.noButton} />
        <Yes />
      </Box>
      <Box className={classes.informationTextsContainer}>
        <FormHelperText className={classes.informationTextTitle}>
          {'Alternative Ways to Prove Your Income'}
        </FormHelperText>
        <InformationalText />
      </Box>
    </Box>
  )
}
