import { TTradeline } from '@common/types'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

export type GetTradelines = () => Promise<TradelinesResponse>

export type TradelinesResponse = ReadonlyArray<{
  account_number: string
  credit_pull_id: number
  current_balance: number
  date_effective: string
  date_opened: string
  estimated_apr: number
  joint_account: boolean
  lender_name: string
  lender_type: string
  monthly_payment: number
  num_payments_made: number
  original_amount: number
  remaining_payments: number
  total_payments: number
  total_remaining_interest: string
}>

/**
 * Calls submit-review-info, which takes only a request id and kicks
 * off side effects required after customer data confirmed.
 * @returns resumeUrl
 */
export const getTradelines = async (): Promise<ReadonlyArray<TTradeline>> => {
  // type Response = { resumeUrl: string }
  const requestId = getRequestId()
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${requestId}/tradelines${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data?.message)
  }

  const tradelines = (data as TradelinesResponse).map((tradeline, i) => ({
    id: i,
    currentLoanBalance: tradeline.current_balance,
    monthlyPayment: tradeline.monthly_payment,
    originalTotal: tradeline.original_amount,
    rate: tradeline.estimated_apr,
    term: tradeline.total_payments,
    loanInfo: {
      date: new Date(tradeline.date_opened),
      lender: tradeline.lender_name,
    },
  }))

  return tradelines
}

type SubmitTradelineParams = {
  loanIndex: number
}

export const submitTradeline = async ({
  loanIndex,
}: SubmitTradelineParams): Promise<string> => {
  const body = {
    requestId: getRequestId(),
    loanIndex: loanIndex < 0 ? null : loanIndex,
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-tradelines${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.text()

  return data
}
