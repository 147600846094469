import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CameraOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04057 2.65743C8.69679 2.23796 9.47301 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V16C22 16.5523 21.5523 17 21 17C20.4477 17 20 16.5523 20 16V9.42097C20 8.45702 19.3439 7.61676 18.4088 7.38297L17.6035 7.18166C16.7449 6.967 16.0554 6.32853 15.7755 5.48886C15.4791 4.59973 14.6471 4 13.7098 4H10.2902C9.86388 4 9.46009 4.12373 9.11776 4.34257C8.65242 4.64002 8.03406 4.50393 7.7366 4.03859C7.43914 3.57326 7.57523 2.95489 8.04057 2.65743ZM5.10615 5.44269L5.91139 5.24138L6.39646 7.18166L5.59123 7.38297C4.65605 7.61676 4 8.45702 4 9.42097V17C4 18.6569 5.34315 20 7 20H17C17.7104 20 18.3607 19.7543 18.8745 19.3424L20.1255 20.9029C19.2697 21.589 18.1815 22 17 22H7C4.23858 22 2 19.7614 2 17V9.42097C2 7.53928 3.28064 5.89906 5.10615 5.44269Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C9.55228 12 10 12.4477 10 13C10 13.2626 10.0517 13.5227 10.1522 13.7654C10.2528 14.008 10.4001 14.2285 10.5858 14.4142C10.7715 14.5999 10.992 14.7472 11.2346 14.8478C11.4773 14.9483 11.7374 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4747 17 10.9546 16.8965 10.4693 16.6955C9.98396 16.4945 9.54301 16.1999 9.17157 15.8284C8.80014 15.457 8.5055 15.016 8.30448 14.5307C8.10346 14.0454 8 13.5253 8 13C8 12.4477 8.44772 12 9 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
