import React, { createContext, FunctionComponent, useContext } from 'react'

import {
  IsoCountryCode,
  IsoCurrencyCode,
  PhoneNumberFormat,
  ReadonlyRecord,
} from '@common/lib-types'

type CurrencyFormat = Readonly<{
  symbol: string
  position: 'prefix' | 'postfix'
  separator: string
}>

type DateFormat = Readonly<{
  numberFormat: string
  dateFormat: string
}>

type SSNFormat = Readonly<{
  numberFormat: string
}>

// Potential defaults values to pull from
const currencyFormats: ReadonlyRecord<IsoCurrencyCode, CurrencyFormat> = {
  USD: { position: 'prefix', symbol: '$', separator: ',' },
}
const dateFormats: ReadonlyRecord<IsoCountryCode, DateFormat> = {
  US: { numberFormat: '##/##/####', dateFormat: 'MM/DD/YYYY' },
}
const phoneNumberFormats: ReadonlyRecord<IsoCountryCode, PhoneNumberFormat> = {
  US: '(###) ###-####' as PhoneNumberFormat,
}
const SSNFormats: ReadonlyRecord<IsoCountryCode, SSNFormat> = {
  US: { numberFormat: '###-##-####' },
}

type CMSContentContextStruct = Readonly<{
  currencyFormat: CurrencyFormat
  dateFormat: DateFormat
  phoneNumberFormat: PhoneNumberFormat
  ssnFormat: SSNFormat
}>

const cmsContentDefaults = {
  // Default to en_US locale values
  currencyFormat: currencyFormats.USD,
  dateFormat: dateFormats.US,
  phoneNumberFormat: phoneNumberFormats.US,
  ssnFormat: SSNFormats.US,
} as const

const CMSContentContext =
  createContext<CMSContentContextStruct>(cmsContentDefaults)
CMSContentContext.displayName = 'CMSContentContext'

type CMSContentContextProviderProps = Readonly<{
  content?: Partial<CMSContentContextStruct>
  children?: React.ReactNode
}>

/**
 * Provide values for current locale.  Generally, this will involve retrieving
 * them from the CMS in next.js `getStaticProps`, then providing them as content
 * to this Provider.
 *
 * In Next.js, this will look like CMS -> getStaticProps -> Context content
 */
export const CMSContentContextProvider: FunctionComponent<
  CMSContentContextProviderProps
> = ({ children, content = {} }) => (
  <CMSContentContext.Provider value={{ ...cmsContentDefaults, ...content }}>
    {children}
  </CMSContentContext.Provider>
)

export const useCurrencyFormat = (): CurrencyFormat =>
  useContext(CMSContentContext).currencyFormat

export const useDateFormat = (): DateFormat =>
  useContext(CMSContentContext).dateFormat

export const usePhoneNumberFormat = (): PhoneNumberFormat =>
  useContext(CMSContentContext).phoneNumberFormat

export const useSSNFormat = (): SSNFormat =>
  useContext(CMSContentContext).ssnFormat
