// eslint-disable-next-line no-restricted-imports
import React, { FC } from 'react'
import { styled } from '@mui/material'

import { Box, Grid } from '@common/react-lib-base'

import { ButtonFineText, Link } from '../../reskin'

const StyledGrid = styled(Grid)(({ theme }) => ({
  ...theme.extension.typography.subheadings['16b'],
  paddingLeft: '6px',
}))

const StyledUl = styled('ul')(({ theme }) => ({
  color: theme.extension.colors.neutral[800],
  margin: '0 0 0 0',
  paddingInlineStart: '16px',
}))

const StyledLi = styled('li')(({ theme }) => ({
  marginLeft: '8px',
  fontSize: theme.extension.typography.paragraphs[18].fontSize,
}))

const StyledTermText = styled(Box)(({ theme }) => ({
  ...theme.extension.typography.paragraphs[16],
  color: theme.extension.colors.text.bodyGray,
  paddingLeft: '6px',
}))

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6, 0, 0, 0),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(4, 0, 0, 0),
  },
}))

type TermsProps = {
  SubmitButton: FC
  LegalCheckbox: FC<{ color?: string }>
}

const StyledLegalCheckboxContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  [theme.breakpoints.only('xs')]: {
    marginTop: theme.spacing(4),
  },
}))

export const Terms: FC<TermsProps> = ({ LegalCheckbox, SubmitButton }) => {
  return (
    <>
      <StyledLegalCheckboxContainer>
        <LegalCheckbox color="text.bodyGray" />
      </StyledLegalCheckboxContainer>
      <StyledUl>
        <StyledLi>
          <StyledGrid>
            <Link
              href={'https://www.caribou.com/creditreportauthorization'}
              target="_blank"
            >
              {'Credit Report Authorization, '}
            </Link>
            <Link
              href={'https://www.gocaribou.com/terms-of-use'}
              target="_blank"
            >
              {'Terms & Conditions. '}
            </Link>
          </StyledGrid>
        </StyledLi>
        <StyledLi>
          <StyledTermText>
            {
              'Caribou and its affiliates share among them any transaction history relating to my financial products or services received through Caribou for the purpose of evaluating me for credit or insurance products.	I agree that Caribou may share my information with affiliates and marketing partners for marketing purposes and that I may opt out of information sharing for marketing purposes at any time.'
            }
          </StyledTermText>
        </StyledLi>
      </StyledUl>
      <Section>
        <ButtonFineText
          fineText="Comparing new rates will not impact your credit score. "
          button={SubmitButton}
        />
      </Section>
    </>
  )
}
