import React, { FC } from 'react'
import {
  Dialog as MuiDialog,
  DialogContent,
  Paper,
  styled,
} from '@mui/material'

import { ConfirmationDialogProps, DialogProps } from '@common/types'

import { Button } from '../button'
import { Text } from '../text'

const StyledContainer = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  [theme.breakpoints.only('xs')]: {
    margin: theme.spacing(2),
  },
}))
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 4, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.only('xs')]: {
    padding: theme.spacing(0, 3, 3),
  },
}))

export const Dialog: FC<DialogProps> = ({
  children,
  headerContent,
  className,
  ...restProps
}) => {
  return (
    <MuiDialog
      PaperProps={{
        classes: {
          root: className,
        },
      }}
      {...restProps}
      PaperComponent={StyledContainer}
    >
      {headerContent}
      <StyledDialogContent>{children}</StyledDialogContent>
    </MuiDialog>
  )
}

const ActionContainer = styled('div')(() => ({
  display: 'grid',
  width: '100%',
}))

const StyledTitle = styled(Text)(({ theme }) => ({
  color: theme.extension.colors.text.softBlack,
}))

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  content,
  disableEscapeKeyDown = true,
  cancelButton,
  submitButton,
  ...restProps
}) => {
  return (
    <Dialog
      onClose={(e, reason) => reason === 'backdropClick' && null}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...restProps}
      headerContent={
        title && (
          <StyledTitle
            align="center"
            use={['p', 'subheadings.24b']}
            m={0}
            pt={{ xs: 3, sm: 4 }}
            pb={1}
            pr={{ xs: 3, sm: 4 }}
          >
            {title}
          </StyledTitle>
        )
      }
    >
      <Text align="center" use={['p', 'paragraphs.18']} pb={3} margin={0}>
        {content}
      </Text>
      <ActionContainer>
        {cancelButton && (
          <Button type="tertiary" onClick={cancelButton?.onClick}>
            {cancelButton?.text}
          </Button>
        )}

        {submitButton && (
          <Button onClick={submitButton?.onClick}>{submitButton?.text}</Button>
        )}
      </ActionContainer>
    </Dialog>
  )
}
