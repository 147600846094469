import { useEffect } from 'react'
import { SetValueConfig, UseFormSetValue } from 'react-hook-form'

import { StateKeys } from '@common/types'
import { DirectMailUserAddressInfo } from '@common/types/api/direct-mail'
import { UseDirectMailUserInfoReturn } from '@src/hooks'

import { ResidenceEmploymentForm } from '../../schemas'

export const useDirectMailCodePrefillResidenceAndEmploymentInfo = (
  setValue: UseFormSetValue<ResidenceEmploymentForm>,
  {
    userInfoLoaded,
    userInfo,
  }: UseDirectMailUserInfoReturn<DirectMailUserAddressInfo>,
): void => {
  useEffect(() => {
    if (userInfoLoaded && userInfo) {
      const setValueConfig: SetValueConfig = {
        shouldDirty: true,
        shouldValidate: true,
      }
      setValue('city', userInfo.city, setValueConfig)
      setValue('state', userInfo.state as StateKeys, setValueConfig)
      setValue('address', userInfo.streetAddress1, setValueConfig)
      setValue('zip', userInfo.zip, setValueConfig)
    }
  }, [userInfo, userInfoLoaded, setValue])
}
