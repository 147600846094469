import { Box, InputBase, inputBaseClasses, styled, Theme } from '@mui/material'

const getInputColors = (theme: Theme) =>
  ({
    defaultColor: theme.extension.colors.neutral[700],
    activeColor: theme.extension.colors.text.softBlack,
    errorColor: theme.extension.colors.semantic.error,
    disabledColor: theme.extension.colors.neutral[700],
  } as const)

type InputStyleProps = {
  active?: boolean
  error?: string | boolean
  disabled?: boolean
  dense?: boolean
  hasEndIcon?: boolean
}

export const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'active' && props !== 'error',
})<InputStyleProps>(({ active, error, disabled, theme }) => {
  const { errorColor, activeColor, disabledColor } = getInputColors(theme)

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: error
      ? errorColor
      : disabled
      ? disabledColor
      : active
      ? activeColor
      : theme.extension.colors.text.bodyGray,

    '&:focus-within': {
      color: error ? errorColor : theme.extension.colors.base.blueSky,
    },
  }
})

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.5),
}))

export const InputContainer = styled(Box)({
  position: 'relative',
  width: '100%',
})

export const Input = styled(InputBase, {
  shouldForwardProp: (props) =>
    props !== 'dense' &&
    props !== 'hasEndIcon' &&
    props !== 'active' &&
    props !== 'error',
})<InputStyleProps>((props) => {
  const { active, dense, error, hasEndIcon, theme } = props
  const { activeColor, defaultColor, errorColor } = getInputColors(theme)

  const normalTypo = theme.extension.typography.subheadings['16m']
  const condesedTypo = theme.extension.typography.interactive.inputText14

  return {
    color: active ? activeColor : theme.extension.colors.text.bodyGray,
    width: '100%',
    fontSize: normalTypo.fontSize, // atleast 16px is needed to prevent zoom in by browser
    fontFamily: theme.typography.fontFamily,
    fontWeight: dense ? condesedTypo.fontWeight : normalTypo.fontWeight,
    height: dense ? 48 : 56,

    border: `2px solid ${
      active ? activeColor : error ? errorColor : defaultColor
    }`,
    borderRadius: 4,
    padding: hasEndIcon ? theme.spacing(2, 4.5, 2, 2) : theme.spacing(2),
    boxShadow: error
      ? `0 0 0 2px ${theme.extension.colors.semantic.errorGlow}`
      : 'none',

    '&:autofill': {
      backgroundColor: 'transparent',
    },

    '&:hover': {
      backgroundColor: theme.extension.colors.surface.cloudGray,
    },

    '&:focus-within': {
      borderColor: error ? errorColor : theme.extension.colors.base.blueSky,
      color: theme.extension.colors.text.softBlack,
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: theme.extension.colors.neutral[400],
      borderColor: theme.extension.colors.neutral[700],
      color: theme.extension.colors.neutral[700],
      opacity: 1,

      [`& .${inputBaseClasses.input}`]: {
        '&::placeholder': {
          color: theme.extension.colors.neutral[700],
        },
      },
    },

    [`& .${inputBaseClasses.input}`]: {
      '&::placeholder': {
        color: theme.extension.colors.text.bodyGray,
        opacity: 1,
      },
    },
  }
})
