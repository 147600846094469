import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { styled } from '@mui/material'

import { OmitSafe } from '@common/lib-types'
import { TextFieldProps } from '@common/types'

import { EyeIcon, EyeOffIcon } from '../../icons'
import { TextField } from '../text-field'

import { createMask, formatSSN, handleHiddenValue } from './helper'

const HideIcon = styled(EyeOffIcon)({
  cursor: 'pointer',
  color: '#191C22',
  width: 24,
  height: 24,
})
const ShowIcon = styled(EyeIcon)({
  cursor: 'pointer',
  color: '#191C22',
  width: 24,
  height: 24,
})

/** This is a 100% workaround. The types in this component are messed up
 *  we shouldn't override the onChnage function like that. It's better to just
 *  create a custom one
 */
type NewSSNFieldProps = OmitSafe<TextFieldProps, 'onChange'> & {
  onChange?: (event: { target: { value: string } }) => void
}

export const NewSSNField: FunctionComponent<NewSSNFieldProps> = ({
  onChange,
  value,
  ...props
}) => {
  const ssnInputRef = useRef<HTMLInputElement>(null)
  const [cursor, setCursor] = useState(null)
  const [hide, setHide] = useState(true)
  const [val, setVal] = useState(value || '')
  const [maskedVal, setMaskedVal] = useState(() => {
    const initialState = createMask(value)
    return initialState
  })

  const toggleVisibility = useCallback(() => {
    setHide(!hide)
  }, [hide])

  const onPaste = useCallback(
    (e: React.ClipboardEvent) => {
      const value = e.clipboardData.getData('Text')
      if (value !== val) {
        setVal(formatSSN(value))
        const newMaskedVal = createMask(value)
        setMaskedVal(newMaskedVal)
      }
    },
    [val],
  )

  const handleKeyChange = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const code = e.which || e.keyCode
      if (code === 8 || code === 46 || (code >= 48 && code <= 57)) {
        const [newVal, cursorPos] = handleHiddenValue(e, val as string)
        setCursor(cursorPos)
        setVal(newVal)
        const newMaskedVal = createMask(newVal)
        setMaskedVal(newMaskedVal)
      }
    },
    [val],
  )

  useEffect(() => {
    const newUnmaskedVal = [...`${value || ''}`]
      .filter((char) => char.match(/\d/) || char.match(/-/))
      .join('')
    setVal(newUnmaskedVal)
    const newMaskedVal = createMask(value)
    setMaskedVal(newMaskedVal)
  }, [value])

  useEffect(() => {
    onChange({
      target: { value: val },
    })
  }, [val, onChange])

  useEffect(() => {
    const input = ssnInputRef.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ssnInputRef, cursor, value])

  const displayValue = hide ? maskedVal : val

  const endIcon = !hide ? (
    <ShowIcon onClick={toggleVisibility} />
  ) : (
    <HideIcon onClick={toggleVisibility} />
  )

  return (
    <TextField
      {...props}
      id={'ssn'}
      hideErrorIcon
      value={displayValue ?? ''}
      placeholder={'***-**-****'}
      endIcon={endIcon}
      onPaste={onPaste}
      onKeyDown={handleKeyChange}
      inputRef={ssnInputRef}
      type={'tel'}
      disabled={props.disabled}
    />
  )
}
