import React, { FC, SetStateAction, useCallback, useRef } from 'react'
import isFunction from 'lodash/isFunction'

import { Box } from '@common/react-lib-base'
import {
  NewGrowForm,
  NewHeading,
  NewVerifyCoBorrowerInfo,
  NewVerifyPersonalInfo,
  NewVerifyResidenceInfo,
  NewVerifyVehicleInfo,
  ProgressBar,
  Terms,
} from '@common/react-lib-consumer-pres'
import { ReviewInfoData } from '@common/types'

import { useNewReviewInfoFormsContext } from './forms-context'
import { useNewBorrowerForm, useNewCoborrowerForm } from './personal-info'
import { useNewResidenceEmploymentForm } from './residence-employment'
import { useNewReviewVehicleForm } from './vehicle-info'

// shallow merge data
const getMergeData =
  (setData: React.Dispatch<SetStateAction<ReviewInfoData>>) =>
  <TKey extends keyof ReviewInfoData>(key: TKey) =>
  (
    dataOrFn:
      | Partial<ReviewInfoData[TKey]>
      | ((d: ReviewInfoData[TKey]) => ReviewInfoData[TKey]),
  ) =>
    setData((oldData: ReviewInfoData) => {
      const oldDataByKey = oldData[key]
      const newDataByKey = isFunction(dataOrFn)
        ? dataOrFn(oldDataByKey)
        : { ...oldDataByKey, ...dataOrFn }
      return {
        ...oldData,
        [key]: newDataByKey,
      }
    })

// Separating into seprate components as performance optimization to avoid running
// form logic for all forms on every rerender
type VerifyForm = FC<{
  data: ReviewInfoData
  setData: React.Dispatch<SetStateAction<ReviewInfoData>>
}>
const VerifyPersonalInfoForm: VerifyForm = ({
  data: { borrower, coborrower },
  setData,
}) => {
  const { trackOpenForm, getOpenForm } = useNewReviewInfoFormsContext()
  const setCustomers = useCallback(
    (data: { borrower: PersonalInfo; coborrower?: CoBorrowerInfo }) => {
      setData((oldData) => ({ ...oldData, ...data }))
    },
    [setData],
  )

  const borrowerForm = useNewBorrowerForm(
    { borrower, coborrower },
    setCustomers,
    getOpenForm,
  )

  const ref = useRef<HTMLDivElement>()
  trackOpenForm('borrower', borrowerForm.isEditing, ref.current)

  return <NewVerifyPersonalInfo {...borrowerForm} ref={ref} data={borrower} />
}

const VerifyCoBorrowerInfoForm: VerifyForm = ({
  data: { borrower, coborrower },
  setData,
}) => {
  const { getOpenForm, trackOpenForm } = useNewReviewInfoFormsContext()
  const setCoBorrowerInfo = useCallback(getMergeData(setData)('coborrower'), [
    setData,
  ])
  const setBorrowerInfo = useCallback(getMergeData(setData)('borrower'), [
    setData,
  ])
  const coborrowerForm = useNewCoborrowerForm(
    coborrower,
    setBorrowerInfo,
    setCoBorrowerInfo,
    getOpenForm,
  )
  const ref = useRef<HTMLDivElement>()
  trackOpenForm(
    'coborrower',
    borrower.hasCoborrower && coborrowerForm.isEditing,
    ref.current,
  )

  return (
    <NewVerifyCoBorrowerInfo {...coborrowerForm} ref={ref} data={coborrower} />
  )
}

const VerifyVehicleInfoForm: VerifyForm = ({ data: { vehicle }, setData }) => {
  const { getOpenForm, trackOpenForm } = useNewReviewInfoFormsContext()
  const setVehicleInfo = useCallback(getMergeData(setData)('vehicle'), [
    setData,
  ])
  const vehicleForm = useNewReviewVehicleForm(
    vehicle,
    setVehicleInfo,
    getOpenForm,
  )
  const ref = useRef<HTMLDivElement>()
  trackOpenForm('vehicle', vehicleForm.isEditing, ref.current)
  return <NewVerifyVehicleInfo {...vehicleForm} ref={ref} data={vehicle} />
}

const VerifyResidenceInfoForm: VerifyForm = ({
  data: { residence },
  setData,
}) => {
  const { getOpenForm, trackOpenForm } = useNewReviewInfoFormsContext()

  const setResidenceInfo = useCallback(getMergeData(setData)('residence'), [
    setData,
  ])
  const residenceForm = useNewResidenceEmploymentForm(
    residence,
    setResidenceInfo,
    getOpenForm,
  )
  const ref = useRef<HTMLDivElement>()
  trackOpenForm('residence', residenceForm.isEditing, ref.current)

  return (
    <NewVerifyResidenceInfo {...residenceForm} ref={ref} data={residence} />
  )
}

type PersonalInfo = ReviewInfoData['borrower']
type CoBorrowerInfo = ReviewInfoData['coborrower']

type Props = {
  data: ReviewInfoData
  setData: React.Dispatch<SetStateAction<ReviewInfoData>>
  module: { SubmitButton: FC; LegalCheckbox: FC }
}
export const NewReviewInfoForms: FC<Props> = ({
  data,
  setData,
  module: { SubmitButton, LegalCheckbox },
}) => {
  const getQueueOrderWithCoborrower = (normalOrder: number): number =>
    data.borrower.hasCoborrower ? normalOrder : normalOrder - 1

  return (
    <Box>
      <Box>
        <ProgressBar activeStep={3} />
      </Box>
      <NewHeading />

      <NewGrowForm queueOrder={1}>
        <VerifyPersonalInfoForm {...{ data, setData }} />
      </NewGrowForm>

      <NewGrowForm
        queueOrder={getQueueOrderWithCoborrower(2)}
        hide={!data.borrower.hasCoborrower}
      >
        <VerifyCoBorrowerInfoForm {...{ data, setData }} />
      </NewGrowForm>

      <NewGrowForm queueOrder={getQueueOrderWithCoborrower(3)}>
        <VerifyVehicleInfoForm {...{ data, setData }} />
      </NewGrowForm>
      <NewGrowForm queueOrder={getQueueOrderWithCoborrower(4)}>
        <VerifyResidenceInfoForm {...{ data, setData }} />
      </NewGrowForm>

      <Terms SubmitButton={SubmitButton} LegalCheckbox={LegalCheckbox} />
    </Box>
  )
}
