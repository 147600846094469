import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VolumeMuteIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04346 5.74604C7.99337 4.63781 9.38012 4 10.8397 4H11C12.6569 4 14 5.34315 14 7V17C14 18.6569 12.6569 20 11 20H10.8397C9.38012 20 7.99337 19.3622 7.04346 18.254L6.92054 18.1106C6.62705 17.7681 6.22645 17.535 5.78377 17.4489L5.23652 17.3425C3.35696 16.977 2 15.3308 2 13.416V10.584C2 8.6692 3.35695 7.02297 5.23652 6.6575L5.78377 6.55109C6.22645 6.46502 6.62705 6.23185 6.92054 5.88945L7.04346 5.74604ZM10.8397 6C9.96396 6 9.13192 6.38269 8.56197 7.04763L8.43905 7.19103C7.85207 7.87584 7.05087 8.34217 6.1655 8.51433L5.61826 8.62073C4.67848 8.80347 4 9.62658 4 10.584V13.416C4 14.3734 4.67848 15.1965 5.61826 15.3793L6.1655 15.4857C7.05087 15.6578 7.85207 16.1242 8.43905 16.809L8.56197 16.9524C9.13192 17.6173 9.96397 18 10.8397 18H11C11.5523 18 12 17.5523 12 17V7C12 6.44772 11.5523 6 11 6H10.8397Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289L19 10.5858L20.2929 9.29289C20.6834 8.90237 21.3166 8.90237 21.7071 9.29289C22.0976 9.68342 22.0976 10.3166 21.7071 10.7071L20.4142 12L21.7071 13.2929C22.0976 13.6834 22.0976 14.3166 21.7071 14.7071C21.3166 15.0976 20.6834 15.0976 20.2929 14.7071L19 13.4142L17.7071 14.7071C17.3166 15.0976 16.6834 15.0976 16.2929 14.7071C15.9024 14.3166 15.9024 13.6834 16.2929 13.2929L17.5858 12L16.2929 10.7071C15.9024 10.3166 15.9024 9.68342 16.2929 9.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
