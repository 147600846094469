import React from 'react'

import {
  PartnerAuthCode,
  PartnerAuthResponse,
  PartnerAuthToken,
  PartnerCustomerInfo,
  PartnerName,
  PartnerRewardsInfo,
  PartnerVehicleInfo,
} from '@common/types'
import { ifWindowExist } from '@src/utils'

const partnerAuthTokenKey = 'PARTNER_AUTH_TOKEN_KEY'

export const setCachedPartnerAuthToken = (authToken: string): void => {
  ifWindowExist(() => localStorage.setItem(partnerAuthTokenKey, authToken))
}

export const getCachedPartnerAuthToken = (): string => {
  return ifWindowExist(() => localStorage.getItem(partnerAuthTokenKey))
}

export type PartnerPrefillContext = {
  customerInfo: PartnerCustomerInfo
  rewardsInfo: PartnerRewardsInfo
  vehicleInfo: PartnerVehicleInfo
  dataLoaded: boolean
}

export const partnerPrefillContext = React.createContext<PartnerPrefillContext>(
  {
    customerInfo: {},
    vehicleInfo: [],
    rewardsInfo: {},
    dataLoaded: false,
  },
)

export const getPartnerAuthToken = async (
  partner: PartnerName,
  authorizationCode: PartnerAuthCode,
): Promise<PartnerAuthResponse> => {
  const path = 'v1/oauth/token'
  const response = await fetch(
    `/api/prefill?path=${path}&partner=${partner}&authorizationCode=${authorizationCode}`,
  )

  return response.json()
}

const customerInfoRequestFactory = <T>(
  path: string,
): ((partner: PartnerName, token: PartnerAuthToken) => Promise<T>) => {
  return async (partner: PartnerName, token: PartnerAuthToken): Promise<T> => {
    const response = await fetch(
      `/api/prefill?path=${path}&partner=${partner}&token=${token}`,
    )

    return response.json()
  }
}

export const getCustomerPrefillInfo =
  customerInfoRequestFactory<PartnerCustomerInfo>('v1/customer')
export const getCustomerRewardsInfo =
  customerInfoRequestFactory<PartnerRewardsInfo>('v1/rewards_info')
export const getCustomerVehicleInfo =
  customerInfoRequestFactory<PartnerVehicleInfo>('v1/vehicles')

export const saveCustomerRewardsInfo = async (
  customerUuid: string,
  rewardsInfo: PartnerRewardsInfo,
): Promise<void> => {
  const response = await fetch(`/api/customer`, {
    method: 'POST',
    body: JSON.stringify({ customerUuid, rewardsInfo }),
  })

  return response.json()
}
