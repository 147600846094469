import { useEffect } from 'react'
import { SetValueConfig, UseFormSetValue } from 'react-hook-form'

import { DirectMailUserNameInfo } from '@common/types/api/direct-mail'
import { UseDirectMailUserInfoReturn } from '@src/hooks'

import { PartnerPrefillContext } from '../../../api'
import { PersonalInfoForm } from '../../schemas'

export const usePrefillCustomerData = (
  setValue: UseFormSetValue<PersonalInfoForm>,
  {
    dataLoaded,
    customerInfo,
  }: Pick<PartnerPrefillContext, 'dataLoaded' | 'customerInfo'>,
): void => {
  useEffect(() => {
    if (dataLoaded && customerInfo) {
      const setValueConfig: SetValueConfig = {
        shouldDirty: true,
        shouldValidate: true,
      }
      setValue('firstName', customerInfo.firstName, setValueConfig)
      setValue('lastName', customerInfo.lastName, setValueConfig)
      setValue('emailAddress', customerInfo.email, setValueConfig)
      setValue('cellphone', customerInfo.phoneNumber, setValueConfig)
    }
  }, [customerInfo, dataLoaded, setValue])
}

export const useDirectMailCodePrefillPersonalInfo = (
  setValue: UseFormSetValue<PersonalInfoForm>,
  {
    userInfoLoaded,
    userInfo,
  }: UseDirectMailUserInfoReturn<DirectMailUserNameInfo>,
): void => {
  useEffect(() => {
    if (userInfoLoaded && userInfo) {
      const setValueConfig: SetValueConfig = {
        shouldDirty: true,
        shouldValidate: true,
      }
      setValue('firstName', userInfo.firstName, setValueConfig)
      setValue('lastName', userInfo.lastName, setValueConfig)
    }
  }, [userInfo, userInfoLoaded, setValue])
}
