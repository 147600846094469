import values from 'lodash/values'
import { object, SchemaOf, string, StringSchema } from 'yup'

import {
  EmploymentStatus,
  EmploymentStatusKeys,
  State,
  StateKeys,
} from '@common/types'

import {
  getMaxTest,
  getMinTest,
  noDecimal,
  notLessThanAMonth,
  notMoreThanMaxYrs,
} from './tests'

type EmplyomentStatus = Exclude<
  EmploymentStatusKeys,
  typeof EmploymentStatus.Student | typeof EmploymentStatus.SelfEmployed
>

export type EmploymentInfoForm = {
  employerName: string
  streetAddress1: string
  streetAddress2: string
  city: string
  state: StateKeys
  zip: string
  phoneNumber: string
  duration: {
    years: string
    months: string
  }
  employmentStatus: EmplyomentStatus
}
const EmploymentDuration = object({
  months: string()
    .required()
    .test(getMinTest(0))
    .test(noDecimal())
    .test(notLessThanAMonth(`Time at employment can't be less than a month`))
    .test(getMaxTest(11)),
  years: string()
    .required()
    .test(getMinTest(0))
    .test(noDecimal())
    .test(getMaxTest(60))
    .test(
      notMoreThanMaxYrs(
        60,
        `Time at employment can't be greater than 60 years`,
      ),
    ),
})

export const EmploymentInfo: SchemaOf<EmploymentInfoForm> = object({
  employmentStatus: string()
    .oneOf(values(EmploymentStatus))
    .required() as StringSchema<EmplyomentStatus>,
  employerName: string().when('employmentStatus', {
    is: (v: EmploymentStatusKeys) => v === EmploymentStatus.Employed,
    then: string().required(),
  }),
  duration: EmploymentDuration,
  city: string(),
  phoneNumber: string(),
  state: string().oneOf(values(State)) as StringSchema<StateKeys>,
  streetAddress1: string(),
  streetAddress2: string(),
  zip: string(),
})
