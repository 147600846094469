/* eslint-disable no-restricted-imports */
import React, { FC } from 'react'
import { ThemeProvider } from '@mui/material'

import { theme } from './theme'

export { makeNewStyles, useNewTheme } from './type-overrides'

export const NewThemeProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

// This was to get around restricted imports rule of mr-web

export {
  /**
   * @deprecated Use import from @mui/material
   */
  styled,
} from '@mui/material'
