import React, { FC, useCallback } from 'react'
import capitalize from 'lodash/capitalize'
import { DateTime } from 'luxon'

import { styled, Text } from '../../reskin'

import { SelectButton as BaseButton } from './select-button'

const SelectButton = styled(BaseButton)(({ theme }) => ({
  width: 235,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

const Dot: React.FC = (props) => <span {...props}>{'\u25CF'}</span>

const StyledDot = styled(Dot)({
  fontSize: 6,
  padding: '0 0.5rem',
})

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const Subtext = styled(Text)({
  display: 'flex',
  alignItems: 'center',
})

type Props = {
  selected?: number
  date: Date
  lender: string
  tradelineId: number
  handleSelect: (id: number) => void
}

export const CardHead: FC<Props> = ({
  handleSelect,
  date,
  lender,
  selected,
  tradelineId,
}) => {
  const issueDate = DateTime.fromJSDate(date).toFormat('MMM yyyy')
  const onClick = useCallback(() => {
    handleSelect(tradelineId)
  }, [handleSelect, tradelineId])
  return (
    <HeaderContainer>
      <div>
        <Text use={['div', { xs: 'subheadings.24b' }]} color="text.softBlack">
          {lender
            ?.split(' ')
            .map((word: string) => capitalize(word))
            .join(' ')}
        </Text>
        <Subtext use={['div', { xs: 'paragraphs.16' }]} color="text.softBlack">
          {'Auto Loan'}
          <StyledDot />
          {`Issued ${issueDate}`}
        </Subtext>
      </div>
      <SelectButton onClick={onClick} selected={tradelineId === selected} />
    </HeaderContainer>
  )
}
