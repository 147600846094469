/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { VehicleInfoData } from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useFetchData } from '../../../hooks'
import { VehicleLookupFormValues } from '../../schemas'
import { Element, useModule } from '../../utils'
import { TextMaskField } from '../controlled-fields-legacy'

export const useVin = ({
  control,
  getValues,
  watch,
  formState,
}: UseFormReturn<VehicleLookupFormValues>): {
  foundVehicle: VehicleInfoData
  fields: { Vin: FC }
} => {
  const {
    data: foundVehicle,
    retry: tryFindCar,
    setData: setFoundCar,
  } = useFetchData(
    async () => {
      const vin = getValues('dynamic.vin')?.replace(/_/g, '')
      if (vin?.length !== 17) {
        return null
      }
      return vehicleAdapters.lookupVehicle({ vin })
    },
    { handleInitialDataLoad: false, initialLoading: false },
  )

  // reset found car on findBy change
  const findBy = watch('findBy')
  useEffect(() => {
    setFoundCar(null)
  }, [findBy, setFoundCar])

  const handleVinChange = useCallback(() => {
    // set timeout is needed to wait for field validation
    setTimeout(() => {
      const hasError =
        !!formState.errors.dynamic &&
        'vin' in formState.errors.dynamic &&
        !!formState.errors.dynamic.vin?.message

      if (!hasError) {
        void tryFindCar()
      } else {
        setFoundCar(null)
      }
    })
  }, [formState.errors.dynamic, setFoundCar, tryFindCar])

  return {
    foundVehicle,
    fields: useModule({
      Vin: Element(TextMaskField, {
        control,
        label: 'Vehicle Identification Number (Vin)',
        name: 'dynamic.vin',
        mask: '*'.repeat(17),
        placeholder: 'ENTER YOUR VIN',
        onBlur: handleVinChange,
      }),
    }),
  }
}
