import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderPlusIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V8.25C20 7.14543 19.1046 6.25 18 6.25H12.8284C12.0328 6.25 11.2697 5.93393 10.7071 5.37132L9.62868 4.29289C9.44114 4.10536 9.18679 4 8.92157 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.92157C9.71722 2 10.4803 2.31607 11.0429 2.87868L12.1213 3.95711C12.3089 4.14464 12.5632 4.25 12.8284 4.25H18C20.2091 4.25 22 6.04086 22 8.25V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9C12.5523 9 13 9.44772 13 10V12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 14H9C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12H11V10C11 9.44772 11.4477 9 12 9Z"
      fill="currentColor"
    />
  </SvgIcon>
)
