import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HomeV5Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8186 4.6664C12.7439 3.77787 11.2561 3.77787 10.1815 4.6664L4.79539 9.11964C4.30425 9.52572 4 10.1673 4 10.8663V17.8109C4 19.0784 4.95214 20 6 20H8V16.7478C8 14.4927 9.73415 12.5587 12 12.5587C14.2659 12.5587 16 14.4927 16 16.7478V20H18C19.0479 20 20 19.0784 20 17.8109V10.8663C20 10.1673 19.6958 9.52572 19.2046 9.11965L13.8186 4.6664ZM15.093 3.12502L20.479 7.57827C21.4494 8.38059 22 9.59705 22 10.8663V17.8109C22 20.066 20.2659 22 18 22H16C14.8954 22 14 21.1046 14 20V16.7478C14 15.4803 13.0479 14.5587 12 14.5587C10.9521 14.5587 10 15.4803 10 16.7478V20C10 21.1046 9.10457 22 8 22H6C3.73415 22 2 20.066 2 17.8109V10.8663C2 9.59705 2.55059 8.38059 3.52097 7.57827L8.90703 3.12502C10.7213 1.62499 13.2787 1.62499 15.093 3.12502Z"
      fill="currentColor"
    />
  </SvgIcon>
)
