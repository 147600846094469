import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CompassIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 8.29289C15.975 8.56072 16.0685 8.95689 15.9487 9.31622L14.4487 13.8162C14.3492 14.1148 14.1149 14.3491 13.8163 14.4487L9.31625 15.9487C8.95692 16.0685 8.56075 15.9749 8.29292 15.7071C8.02509 15.4393 7.93157 15.0431 8.05134 14.6838L9.55134 10.1838C9.65088 9.88516 9.88519 9.65085 10.1838 9.55131L14.6838 8.05131C15.0431 7.93154 15.4393 8.02506 15.7071 8.29289ZM11.2906 11.2906L10.5812 13.4189L12.7095 12.7094L13.4189 10.5811L11.2906 11.2906Z"
      fill="currentColor"
    />
  </SvgIcon>
)
