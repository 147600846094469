import React, { useEffect, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'

import {
  getSetUtmParams,
  LeadSourceContext,
  UtmParams,
} from '@common/lead-source-context'

/**
 * Retrieves campaign details from cookie first to always attribute lead to
 * original/initial lead source. Falls back to reading the URL query params
 */
export const LeadSourceProvider: React.FC<{
  query: ParsedUrlQuery
  children: React.ReactNode
}> = ({ children, query }) => {
  const [campaignParams, setCampaignParams] = useState<UtmParams>({})

  useEffect(() => {
    const params = getSetUtmParams(query)
    setCampaignParams(params)
  }, [query])

  return (
    <LeadSourceContext.Provider value={campaignParams}>
      {children}
    </LeadSourceContext.Provider>
  )
}
