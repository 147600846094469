import { useCallback, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useRouter } from 'next/router'

import { PageRoute } from '@common/types/page-routes'
import {
  customerAdapters,
  getNewResumeUrl,
  getResumeUrlByLoanApplication,
  userValidationAdapter,
} from '@src/api'
import { ExpandedLoanApplication } from '@src/api/customer'
import { safeWindow } from '@src/utils'
import {
  getAuthToken,
  getRequestId,
  LeadSourceKeys,
  setLeadSource,
  setRequestId,
} from '@src/utils/storage'

import { authGuardDisabled, decodedJwtTokenDataKey } from '../config'

import { Logger } from './use-logger'

const nonAuthGuardedPages = new Set([
  '/',
  PageRoute.Resume,
  PageRoute.PersonalInfo,
  PageRoute.Processing,
  PageRoute.ExperianAdditionalInformationNeeded,
  PageRoute.AcceptOffer,
  PageRoute.LoanApplicationError,
  PageRoute.TheZebraAdditionalInformationNeeded,
  '/robots.txt',
])

const getCustomerUuidFromAuthToken = (
  logger: Logger,
  authToken: string,
): string => {
  const decodedToken = jwt_decode(authToken) as Record<
    string,
    Record<string, string>
  >
  const decodedTokenJwtData = decodedToken[decodedJwtTokenDataKey]
  if (!decodedTokenJwtData) {
    logger.error('unable to access decoded jwt token data', { authToken })
    return ''
  }

  const customerUuid = decodedTokenJwtData['customer_uuid']
  return customerUuid
}

const getLatestLoanApplication = (
  expandedLoanApplications: Array<ExpandedLoanApplication>,
): ExpandedLoanApplication => {
  if (expandedLoanApplications.length === 0) {
    return null
  }
  if (expandedLoanApplications.length === 1) {
    return expandedLoanApplications[0]
  }

  // Order by latest createdAt
  const orderedExpandedLoanApplications = expandedLoanApplications.sort(
    (a, b) => {
      return (
        -1 *
        (Date.parse(a.loanApplication.createdAt) -
          Date.parse(b.loanApplication.createdAt))
      )
    },
  )
  return orderedExpandedLoanApplications[0]
}

const getResumeUrl = async (
  logger: Logger,
  authToken: string,
): Promise<string> => {
  const customerUuid = getCustomerUuidFromAuthToken(logger, authToken)

  if (customerUuid === '') {
    logger.warn('unable to get customer uuid from auth token', { authToken })
    return PageRoute.PersonalInfo + safeWindow.location.search
  }

  logger.debug('retrieved customer uuid from auth token', { customerUuid })

  const expandedLoanApplications =
    await customerAdapters.getExpandedLoanApplications(authToken, customerUuid)

  if (expandedLoanApplications.length === 0) {
    logger.warn('unable to retrieve expandedLoanApplications', {
      customerUuid,
    })
    return PageRoute.PersonalInfo + safeWindow.location.search
  }

  const latestLoanApplication = getLatestLoanApplication(
    expandedLoanApplications,
  )

  const { partnerLeadSource, loanApplication } = latestLoanApplication

  if (partnerLeadSource !== undefined || partnerLeadSource !== null) {
    const { sourceName, requestId } = partnerLeadSource
    setRequestId(requestId)
    setLeadSource(sourceName as LeadSourceKeys)
    const { resumeUrl } = await getNewResumeUrl()
    return resumeUrl
  }

  const { resumeUrl } = await getResumeUrlByLoanApplication(
    loanApplication.uuid,
  )

  return resumeUrl
}

/**
 * This hook will check for requestId and leadSource, and the user's authentication status.
 * If the user is authenticated, or has requestId and leadSource set, they will be redirected to the resume page.
 * Otherwise, they will be redirected to the personal-info page.
 */
export const useAuthGuard = (logger: Logger): void => {
  const router = useRouter()
  const routerPush = useCallback(
    (route: string) => router.push(route),
    [router],
  )

  useEffect(() => {
    void (async () => {
      if (authGuardDisabled) {
        return
      }
      if (nonAuthGuardedPages.has(router.pathname)) {
        return
      }

      const requestId = getRequestId()
      const authToken = getAuthToken()

      logger.debug('[useAuthGuard] validating user', {})

      const { isRequestIdValid, isAuthTokenValid, isLeadSourceValid } =
        await userValidationAdapter.validate({
          requestId,
          token: authToken,
        })

      logger.debug('[useAuthGuard] user validation completed', {
        isAuthTokenValid,
        isRequestIdValid,
        isLeadSourceValid,
      })

      if (isRequestIdValid && isAuthTokenValid && isLeadSourceValid) {
        return
      }

      if (isAuthTokenValid && (!isRequestIdValid || !isLeadSourceValid)) {
        logger.warn(
          '[useAuthGuard] user token is validated, but either requestId or leadSource are invalid',
          {
            isAuthTokenValid,
            isRequestIdValid,
            isLeadSourceValid,
          },
        )
        const resumeUrl = await getResumeUrl(logger, authToken)
        void routerPush(resumeUrl)
        return
      }

      if (!isAuthTokenValid && isRequestIdValid && isLeadSourceValid) {
        logger.warn(
          '[useAuthGuard] user token is invalid, but requestId and leadSource are valid',
          {
            isAuthTokenValid,
            isRequestIdValid,
            isLeadSourceValid,
          },
        )
        const { resumeUrl } = await getNewResumeUrl()
        void routerPush(resumeUrl)
        return
      }

      logger.warn(
        '[useAuthGuard] user token, requestId and leadSource are invalid',
        {
          isAuthTokenValid,
          isRequestIdValid,
          isLeadSourceValid,
        },
      )
      void routerPush(PageRoute.PersonalInfo + safeWindow.location.search)
    })()
  }, [routerPush, logger, router.pathname])
}
