import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ChevronDownIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2071 7.79289C20.5976 8.18342 20.5976 8.81658 20.2071 9.20711L13.2071 16.2071C12.8166 16.5976 12.1834 16.5976 11.7929 16.2071L4.79289 9.20711C4.40237 8.81658 4.40237 8.18342 4.79289 7.79289C5.18342 7.40237 5.81658 7.40237 6.20711 7.79289L12.5 14.0858L18.7929 7.79289C19.1834 7.40237 19.8166 7.40237 20.2071 7.79289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
