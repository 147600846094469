import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const InfoCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2666 4C7.84832 4 4.2666 7.58172 4.2666 12C4.2666 16.4183 7.84832 20 12.2666 20C16.6849 20 20.2666 16.4183 20.2666 12C20.2666 7.58172 16.6849 4 12.2666 4ZM2.2666 12C2.2666 6.47715 6.74375 2 12.2666 2C17.7894 2 22.2666 6.47715 22.2666 12C22.2666 17.5228 17.7894 22 12.2666 22C6.74375 22 2.2666 17.5228 2.2666 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2666 11C12.8189 11 13.2666 11.4477 13.2666 12V16C13.2666 16.5523 12.8189 17 12.2666 17C11.7143 17 11.2666 16.5523 11.2666 16V12C11.2666 11.4477 11.7143 11 12.2666 11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2666 8C11.2666 7.44772 11.7143 7 12.2666 7H12.2766C12.8289 7 13.2766 7.44772 13.2766 8C13.2766 8.55228 12.8289 9 12.2766 9H12.2666C11.7143 9 11.2666 8.55228 11.2666 8Z"
      fill="currentColor"
    />
  </SvgIcon>
)
