import React, { FunctionComponent } from 'react'
import { Typography, TypographyProps } from '@mui/material'

type Props = Readonly<{
  id?: TypographyProps['id']
  align?: TypographyProps['align']
  children?: TypographyProps['children']
  classes?: TypographyProps['classes']
  color?: TypographyProps['color']
  display?: TypographyProps['display']
  noWrap?: TypographyProps['noWrap']
  className?: TypographyProps['className']
  sx?: TypographyProps['sx']
}>

export type HComponentType = FunctionComponent<Props>

/**
 * Typography component for heading level 1
 *
 * @param props See https://material-ui.com/api/typography/
 */
export const H1: HComponentType = ({ ...props }) => (
  <Typography variant="h1" {...props} />
)

/**
 * Typography component for heading level 2
 *
 * @param props See https://material-ui.com/api/typography/
 */
export const H2: HComponentType = ({ ...props }) => (
  <Typography variant="h2" {...props} />
)

/**
 * Typography component for heading level 3
 *
 * @param props See https://material-ui.com/api/typography/
 */
export const H3: HComponentType = ({ ...props }) => (
  <Typography variant="h3" {...props} />
)

/**
 * Typography component for heading level 6
 *
 * @param props See https://material-ui.com/api/typography/
 */
export const H6: HComponentType = ({ ...props }) => (
  <Typography variant="h6" {...props} />
)
