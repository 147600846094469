import { useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'

import { NumberFormatInput } from '@common/react-lib-consumer-pres'
import { NewReviewVehicleFormModule, ReviewInfoData } from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useResolver } from '../../../hooks'
import { trackReviewPageEdited } from '../../../tracking'
import { parseInteger } from '../../../utils'
import { SharedVehicle } from '../../schemas'
import { Element, useModule } from '../../utils'
import { CurrencyInput, TextField } from '../controlled-fields'

import { useFormControls } from './use-form-controls'
import { useTrackChanges } from './use-track-changes'

type VehicleInfo = ReviewInfoData['vehicle']

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const clearVehicleData = ({
  estimatedMileage,
  estimatedMonthlyPayment,
  loanValue,
  ...data
}: VehicleInfo) => ({
  ...data,
  estimatedMileage: parseInteger(estimatedMileage),
  estimatedMonthlyPayment: parseInteger(estimatedMonthlyPayment),
  loanValue: parseInteger(loanValue),
})

export const useNewReviewVehicleForm = (
  data: VehicleInfo,
  setData: (data: VehicleInfo) => void,
  getOpenForm: () => HTMLDivElement | void,
): NewReviewVehicleFormModule => {
  const clearedData = useMemo(
    () =>
      typeof data.estimatedMonthlyPayment == 'string' &&
      data.estimatedMonthlyPayment
        ? {
            ...data,
            estimatedMonthlyPayment: Number.parseInt(
              data.estimatedMonthlyPayment,
            ),
          }
        : data,
    [data],
  )

  const form = useForm<VehicleInfo>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: clearedData,
    // Do not need to validate other fields because they are uneditable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: useResolver(SharedVehicle) as any,
  })
  const {
    control,
    formState: { isSubmitting },
  } = form

  const estimatedMileage = useController({
    control,
    name: 'estimatedMileage',
  })

  const trackVehicleInfoFormChange = useTrackChanges(
    clearVehicleData(data),
    trackReviewPageEdited,
  )

  const submit = async (data: VehicleInfo): Promise<void> => {
    const clearedData = clearVehicleData(data)

    const newData = await vehicleAdapters.updateVehicle(clearedData)
    setData(newData)
    trackVehicleInfoFormChange(clearedData)
  }

  return {
    ...useFormControls(clearedData, submit, form, getOpenForm),
    module: useModule({
      VehicleVin: Element(TextField, {
        control,
        label: 'Vehicle identification number',
        name: 'vin',
        disabled: true,
      }),
      LicensePlateNumber: Element(TextField, {
        control,
        label: 'License plate number',
        name: 'licensePlateNumber',
        disabled: true,
      }),
      LicensePlateState: Element(TextField, {
        control,
        label: 'License plate state',
        name: 'licensePlateState',
        disabled: true,
      }),
      VehicleYear: Element(TextField, {
        control,
        label: 'Year',
        name: 'year',
        disabled: true,
      }),
      VehicleMake: Element(TextField, {
        control,
        label: 'Make',
        name: 'make',
        disabled: true,
      }),
      VehicleModel: Element(TextField, {
        control,
        label: 'Model',
        name: 'model',
        disabled: true,
      }),
      VehicleTrim: Element(TextField, {
        control,
        label: 'Trim',
        name: 'trim',
        disabled: true,
      }),
      Mileage: Element(NumberFormatInput, {
        control,
        ref: estimatedMileage.field.ref,
        onBlur: estimatedMileage.field.onBlur,
        onValueChange: ({ floatValue }) =>
          estimatedMileage.field.onChange(floatValue),
        value: estimatedMileage.field.value?.toString(),
        error: estimatedMileage.fieldState.error?.message,
        label: 'Mileage',
        name: 'estimatedMileage',
        disabled: isSubmitting,
        thousandSeparator: true,
      }),
      LoanValue: Element(CurrencyInput, {
        control,
        label: 'Loan value',
        name: 'loanValue',
        disabled: isSubmitting,
      }),
      MonthlyPayment: Element(CurrencyInput, {
        control,
        label: 'Monthly payment',
        name: 'estimatedMonthlyPayment',
        disabled: isSubmitting,
      }),
    }),
  }
}
