import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MoreVerticalRectangleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6999 8.00001C10.6999 8.71798 11.282 9.30001 11.9999 9.30001C12.7179 9.30001 13.2999 8.71798 13.2999 8.00001C13.2999 7.28204 12.7179 6.70001 11.9999 6.70001C11.282 6.70001 10.6999 7.28204 10.6999 8.00001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6999 16C10.6999 16.718 11.282 17.3 11.9999 17.3C12.7179 17.3 13.2999 16.718 13.2999 16C13.2999 15.282 12.7179 14.7 11.9999 14.7C11.282 14.7 10.6999 15.282 10.6999 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6999 12C10.6999 12.718 11.282 13.3 11.9999 13.3C12.7179 13.3 13.2999 12.718 13.2999 12C13.2999 11.282 12.7179 10.7 11.9999 10.7C11.282 10.7 10.6999 11.282 10.6999 12Z"
      fill="currentColor"
    />
  </SvgIcon>
)
