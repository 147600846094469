import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PartyIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" htmlColor={htmlColor} {...props}>
    <path
      d="M2.98116 19.7549L7.08789 10.3577C7.37079 9.71039 8.21749 9.55252 8.71466 10.0544L14.1903 15.5821C14.6931 16.0897 14.5168 16.9456 13.8543 17.2131L4.27191 21.0826C3.44116 21.418 2.62239 20.5758 2.98116 19.7549Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M16.5749 13.694C16.7827 13.9038 17.1197 13.9038 17.3276 13.694C19.33 11.6725 21.2799 12.2975 21.2994 12.304C21.5782 12.3978 21.8797 12.2457 21.9726 11.9641C22.0656 11.6827 21.9149 11.3783 21.636 11.2844C21.531 11.2492 19.0374 10.4481 16.5749 12.9341C16.367 13.1439 16.367 13.4842 16.5749 13.694Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M11.168 7.47663C13.6306 4.99094 12.8368 2.47338 12.802 2.36734C12.709 2.08578 12.4074 1.9339 12.1285 2.02775C11.85 2.12161 11.6992 2.42573 11.7921 2.70729C11.7985 2.727 12.4177 4.69541 10.415 6.71684C10.2084 6.92536 10.2063 7.26535 10.415 7.47663C10.6239 7.68755 10.9607 7.6859 11.168 7.47663Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M13.3058 9.63555C13.0979 9.84539 13.0979 10.1856 13.3058 10.3954C13.5118 10.6034 13.8464 10.6061 14.0554 10.3986C14.3303 10.2603 15.1838 10.7791 15.6581 10.3004C16.1273 9.82673 15.6288 8.98265 15.7533 8.68684C16.0468 8.56082 16.8826 9.06433 17.3518 8.59069C17.8211 8.11698 17.3225 7.27291 17.447 6.97706C17.7409 6.851 18.5762 7.35458 19.0454 6.88091C19.5148 6.4072 19.0162 5.56313 19.1407 5.26728C19.4328 5.14186 20.2702 5.6443 20.7392 5.17109C21.2133 4.6924 20.7004 3.82928 20.8364 3.55312C21.0411 3.34306 21.04 3.00523 20.8332 2.79642C20.6254 2.58658 20.2883 2.58658 20.0805 2.79642C19.6609 3.21994 19.743 3.81785 19.822 4.24531C19.3986 4.16554 18.8064 4.08268 18.3868 4.50617C17.9673 4.92969 18.0494 5.52759 18.1284 5.95506C17.705 5.87532 17.1127 5.79239 16.6932 6.21595C16.2736 6.63947 16.3558 7.23737 16.4348 7.66484C16.0113 7.58506 15.419 7.50217 14.9995 7.92569C14.58 8.34922 14.6621 8.94712 14.7411 9.37458C14.3176 9.29488 13.7253 9.21202 13.3058 9.63555Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </SvgIcon>
)
