import { array, object, SchemaOf, string } from 'yup'

import { UIAddonProduct } from '@src/api'

export type OfferSelectionsForm = {
  selectedOffer: string
  selectedProducts?: Array<UIAddonProduct['id']>
}

export const offerSelectionsSchema: SchemaOf<OfferSelectionsForm> = object({
  selectedOffer: string().required(),
  selectedProducts: array(),
})
