import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneV2SolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M10.7711 13.229C12.3541 14.811 14.1881 16.325 14.9131 15.6C15.9501 14.563 16.5901 13.659 18.8781 15.498C21.1651 17.336 19.4081 18.562 18.4031 19.566C17.2431 20.726 12.9191 19.628 8.64509 15.355C4.37209 11.081 3.27709 6.757 4.43809 5.597C5.44309 4.591 6.66309 2.835 8.50109 5.122C10.3401 7.409 9.43709 8.049 8.39809 9.087C7.67609 9.812 9.18909 11.646 10.7711 13.229Z"
      fill="currentColor"
    />
  </SvgIcon>
)
