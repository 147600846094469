import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NavigationV1Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0376 3.34234C10.7245 1.53912 13.2755 1.53913 13.9624 3.34234L19.6146 18.1793C20.4467 20.3637 18.394 22.5458 16.1628 21.8486L12.8948 20.8273C12.3121 20.6452 11.6878 20.6452 11.1052 20.8273L7.83713 21.8486C5.60597 22.5458 3.55323 20.3637 4.3854 18.1793L10.0376 3.34234ZM11.9398 4.00527C11.9341 4.00897 11.9204 4.018 11.9065 4.05433L6.25437 18.8912C6.01661 19.5154 6.60311 20.1388 7.24058 19.9396L10.5086 18.9184C11.4797 18.6149 12.5203 18.6149 13.4914 18.9184L16.7594 19.9396C17.3969 20.1388 17.9833 19.5154 17.7456 18.8912L12.0934 4.05433C12.0796 4.01801 12.0659 4.00897 12.0602 4.00527C12.0492 3.99815 12.0285 3.98993 12 3.98993C11.9715 3.98993 11.9507 3.99815 11.9398 4.00527Z"
      fill="currentColor"
    />
  </SvgIcon>
)
