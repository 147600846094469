import { getAuthToken } from '@src/utils/storage'

import { refiApiUrl } from '../config'

export type LoanApplicationInfo = {
  // the xhr response is string even though refi is bigdecimal.
  // Assumption: grape is converting bigdecimal to string in json serialization step
  estimatedMonthlyPayment?: string
  estimatedRemainingLoanAmount?: string
}

export const getLoanApplication = async (
  loanApplicationUuid: string,
): Promise<LoanApplicationInfo> => {
  const response = await fetch(
    `${refiApiUrl}/services/v2/loan_application/${loanApplicationUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const updateLoanApplication = async (
  loanApplicationUuid: string,
  loanApplicationInfo: LoanApplicationInfo,
): Promise<LoanApplicationInfo> => {
  const response = await fetch(
    `${refiApiUrl}/services/v2/loan_application/${loanApplicationUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(loanApplicationInfo),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}
