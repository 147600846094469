import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BoltIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9767 2.6979C12.9767 2.37415 12.7544 2.09322 12.439 2.01879C12.1246 1.94435 11.7999 2.09603 11.6548 2.38615L6.0736 13.5486C5.96568 13.7644 5.97689 14.0211 6.10428 14.2268C6.23166 14.4323 6.4559 14.558 6.69776 14.558H11.5813V21.3021C11.5813 21.6258 11.8037 21.9068 12.119 21.9812C12.4334 22.0556 12.7581 21.904 12.9032 21.6139L18.4844 10.4514C18.5923 10.2356 18.5811 9.97894 18.4537 9.77323C18.3264 9.56768 18.1021 9.442 17.8603 9.442H12.9767V2.6979ZM11.5814 5.65312V10.1396C11.5814 10.5247 11.894 10.8373 12.2791 10.8373H16.7309L12.9766 18.3468V13.8603C12.9766 13.4752 12.6641 13.1626 12.279 13.1626H7.82721L11.5814 5.65312Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.4"
    />
  </SvgIcon>
)
