import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VolumeHighSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2941 6.63399C19.7724 6.35785 20.384 6.52172 20.6601 7.00001C21.5378 8.52021 21.9999 10.2446 21.9999 12C21.9999 13.7554 21.5378 15.4798 20.6601 17C20.384 17.4783 19.7724 17.6422 19.2941 17.366C18.8158 17.0899 18.6519 16.4783 18.9281 16C19.6302 14.7839 19.9999 13.4043 19.9999 12C19.9999 10.5957 19.6302 9.21617 18.9281 8.00001C18.6519 7.52172 18.8158 6.91013 19.2941 6.63399Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3991 8.30553C16.8774 8.02939 17.489 8.19326 17.7651 8.67155C18.3494 9.68353 18.657 10.8315 18.657 12C18.657 13.1685 18.3494 14.3164 17.7651 15.3284C17.489 15.8067 16.8774 15.9706 16.3991 15.6944C15.9208 15.4183 15.7569 14.8067 16.0331 14.3284C16.4418 13.6205 16.657 12.8174 16.657 12C16.657 11.1825 16.4418 10.3795 16.0331 9.67155C15.7569 9.19326 15.9208 8.58167 16.3991 8.30553Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8397 4C9.38012 4 7.99337 4.63781 7.04346 5.74604C6.70415 6.1419 6.30972 6.44883 5.78377 6.55109L5.23652 6.6575C3.35695 7.02297 2 8.6692 2 10.584V13.416C2 15.3308 3.35696 16.977 5.23652 17.3425L5.78377 17.4489C6.30972 17.5512 6.70415 17.8581 7.04346 18.254C7.99337 19.3622 9.38012 20 10.8397 20H11C12.6569 20 14 18.6569 14 17V7C14 5.34315 12.6569 4 11 4H10.8397Z"
      fill="currentColor"
    />
  </SvgIcon>
)
