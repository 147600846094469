import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MinimizeIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7071 3.29289C21.0977 3.68342 21.0977 4.31658 20.7071 4.70711L16.4142 9.00004H19.1429C19.6952 9.00004 20.1429 9.44776 20.1429 10C20.1429 10.5523 19.6952 11 19.1429 11H14C13.4477 11 13 10.5523 13 10V4.85714C13 4.30485 13.4477 3.85714 14 3.85714C14.5523 3.85714 15 4.30485 15 4.85714V7.58583L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99996 14C3.99996 13.4477 4.44768 13 4.99996 13H9.99996C10.5522 13 11 13.4477 11 14V19C11 19.5523 10.5522 20 9.99996 20C9.44768 20 8.99996 19.5523 8.99996 19V16.4142L4.70711 20.7071C4.31659 21.0976 3.68342 21.0976 3.2929 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L7.58576 15H4.99996C4.44768 15 3.99996 14.5523 3.99996 14Z"
      fill="currentColor"
    />
  </SvgIcon>
)
