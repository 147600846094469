import React, { FC } from 'react'
import { Link } from '@mui/material'

import { Text } from '@common/react-lib-consumer-pres'

export const PrivacyPolicy: FC = () => (
  <Text use={['p', 'paragraphs.14']}>
    By clicking &apos;Continue&apos;, I agree to the{' '}
    <Link
      href="https://www.caribou.com/electronic-communications-agreement"
      target="_blank"
    >
      <b>
        <u>{'Electronic Communications Consent'}</u>
      </b>
    </Link>
    {' and '}
    <Link href="https://caribou.com/privacy-policy/" target="_blank">
      <b>
        <u>{'Privacy Policy'}</u>
      </b>
    </Link>
    {', '}
    and that Caribou may contact me about available offers and other Caribou
    products or services through any means, including emails, SMS, text
    messages, and calls to the email address and phone number provided. I also
    agree that Caribou may contact me by voice and text at the telephone number
    provided using pre-recorded messages, artificial voice, automatic telephone
    dialing systems, and an automatic texting system. Giving consent to receive
    voice calls and text messages through these methods is not required to
    purchase any products or services. I may revoke consent to any marketing
    communications by emailing{' '}
    <Link href="mailto:contact@gocaribou.com" target="_blank">
      <b>
        <u>contact@gocaribou.com</u>
      </b>
    </Link>
    .
  </Text>
)
