import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FacebookIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#1877F2',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4848 5.598C15.9117 5.48467 15.1376 5.4 14.6507 5.4C13.3324 5.4 13.2468 5.96667 13.2468 6.87333V8.48734H16.5421L16.2549 11.8307H13.2468V22H9.1207V11.8307H7V8.48734H9.1207V6.41933C9.1207 3.58667 10.4673 2 13.8483 2C15.0229 2 15.8827 2.17 17 2.39667L16.4848 5.598Z"
      fill="currentColor"
    />
  </SvgIcon>
)
