/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react'
import { UnpackNestedValue, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { PageRoute } from '@common/types'
import { trackAddonSelected, trackAddonUnselected } from '@src/tracking'

import { submitLoan, UIAddonProduct } from '../../../api'
import { useGetNextUrlByLoanState, useResolver } from '../../../hooks'
import { OfferSelectionsForm, offerSelectionsSchema } from '../../schemas'

type OfferSelectionsFormData = {
  selectedOffer?: string
  selectedProducts?: Array<UIAddonProduct['id']>
}

export const useOfferSelectionsForm = () => {
  const { setValue, formState, watch, handleSubmit, getValues } =
    useForm<OfferSelectionsForm>({
      mode: 'all',
      shouldFocusError: true,
      resolver: useResolver(offerSelectionsSchema),
      defaultValues: {
        selectedProducts: [],
      },
    })
  const router = useRouter()
  const selectedOffer = watch('selectedOffer')
  const selectedProducts = watch('selectedProducts')
  const getNextUrl = useGetNextUrlByLoanState()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.CreateAccount)
      await router.prefetch(PageRoute.IncomeVerification)
    }
    prefetch().catch(console.error)
  }, [router])

  const submit = async (data: UnpackNestedValue<OfferSelectionsFormData>) => {
    await submitLoan(data.selectedOffer, data.selectedProducts)
    const resumeUrl = await getNextUrl()
    void router.push(resumeUrl)
  }

  const toggleOffer = (id: string): void => {
    const selected = getValues('selectedOffer') === id
    setValue('selectedOffer', selected ? null : id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const selectOffer = (id: string): void => {
    setValue('selectedOffer', id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleProductSelect = (id: UIAddonProduct['id']): void => {
    if (selectedProducts.includes(id)) {
      // Unselect Addon
      const updatedProducts = selectedProducts.filter(
        (productId) => productId !== id,
      )

      setValue('selectedProducts', updatedProducts)
      void trackAddonUnselected({ id })
    } else {
      // Select Addon
      const updatedProducts = [...selectedProducts, id]

      setValue('selectedProducts', updatedProducts)
      void trackAddonSelected({ id })
    }
  }

  const resetSelectedAddonProducts = (): void => {
    setValue('selectedProducts', [])
  }

  return {
    handleSubmit,
    selectedOffer,
    selectedProducts,
    resetSelectedAddonProducts,
    toggleOffer,
    selectOffer,
    handleProductSelect,
    submit,
    formState,
  }
}
