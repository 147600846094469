import { useForm } from 'react-hook-form'

import {
  EmploymentStatusKeys,
  ResidenceAndEmploymentSubmissionInfo,
  ResidenceStatusKeys,
  StateKeys,
} from '@common/types'

import { residenceAndEmploymentAdapters } from '../../../api'
import { useResolver } from '../../../hooks'
import { parseInteger } from '../../../utils'
import { ResidenceEmployment, ResidenceEmploymentForm } from '../../schemas'

type FormModel = ResidenceEmploymentForm

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useResidenceForm = (hasCoborrower: boolean) => {
  const form = useForm<FormModel>({
    mode: 'onTouched',
    shouldFocusError: true,
    defaultValues: {
      address: '',
      apartment: '',
      city: '',
      state: '' as StateKeys,
      zip: '',
      livedInAddress: {
        months: '',
        years: '',
      },
      rentOrOwn: '' as ResidenceStatusKeys,
      monthlyPayment: '',
      employmentStatus: '' as EmploymentStatusKeys,
      yearlyIncome: '',
      //Coborrower
      coaddress: '',
      coapartment: '',
      cocity: '',
      costate: '' as StateKeys,
      cozip: '',
      colivedInAddress: {
        months: '',
        years: '',
      },
      corentOrOwn: '' as ResidenceStatusKeys,
      comonthlyPayment: '',
      coemploymentStatus: '' as EmploymentStatusKeys,
      coyearlyIncome: '',
      hasCoborrower: hasCoborrower ?? false,
      coborrowerResidence: hasCoborrower ?? null,
    },
    resolver: useResolver(ResidenceEmployment),
  })

  const submitResidenceAndEmploymentWithCoborrower = async (
    formValues: FormModel,
  ): Promise<string> => {
    const { resumeUrl } =
      await residenceAndEmploymentAdapters.submitResidenceAndEmploymentWithCoborrower(
        {
          borrower: {
            address: formValues.address,
            apartment: formValues.apartment,
            city: formValues.city,
            state: formValues.state,
            zip: formValues.zip,
            rentOrOwn: formValues.rentOrOwn,
            employmentStatus: formValues.employmentStatus,
            livedInAddress: {
              years: Number.parseInt(`${formValues.livedInAddress?.years}`),
              months: Number.parseInt(`${formValues.livedInAddress?.months}`),
            },
            monthlyPayment: parseInteger(formValues.monthlyPayment),
            yearlyIncome: parseInteger(formValues.yearlyIncome),
          },
          coborrower: {
            address: formValues.coaddress,
            apartment: formValues.coapartment,
            city: formValues.cocity,
            state: formValues.costate,
            zip: formValues.cozip,
            rentOrOwn: formValues.corentOrOwn,
            employmentStatus: formValues.coemploymentStatus,
            livedInAddress: {
              years: Number.parseInt(`${formValues.colivedInAddress?.years}`),
              months: Number.parseInt(`${formValues.colivedInAddress?.months}`),
            },
            monthlyPayment: parseInteger(formValues.comonthlyPayment),
            yearlyIncome: parseInteger(formValues.coyearlyIncome),
          },
        },
      )
    return resumeUrl
  }

  const submitResidenceAndEmployment = async (
    formValues: FormModel,
  ): Promise<string> => {
    const { resumeUrl } =
      await residenceAndEmploymentAdapters.submitResidenceAndEmploymentWithCoborrower(
        {
          borrower: {
            address: formValues.address,
            apartment: formValues.apartment,
            city: formValues.city,
            state: formValues.state,
            zip: formValues.zip,
            rentOrOwn: formValues.rentOrOwn,
            employmentStatus: formValues.employmentStatus,
            livedInAddress: {
              years: Number.parseInt(`${formValues.livedInAddress?.years}`),
              months: Number.parseInt(`${formValues.livedInAddress?.months}`),
            },
            monthlyPayment: parseInteger(formValues.monthlyPayment),
            yearlyIncome: parseInteger(formValues.yearlyIncome),
          },
          coborrower: {
            address: formValues.address,
            apartment: formValues.apartment,
            city: formValues.city,
            state: formValues.state,
            zip: formValues.zip,
            rentOrOwn: formValues.rentOrOwn,
            employmentStatus: formValues.coemploymentStatus,
            livedInAddress: {
              years: Number.parseInt(`${formValues.livedInAddress?.years}`),
              months: Number.parseInt(`${formValues.livedInAddress?.months}`),
            },
            monthlyPayment: parseInteger(formValues.monthlyPayment),
            yearlyIncome: parseInteger(formValues.coyearlyIncome),
          },
        },
      )
    return resumeUrl
  }

  const submitBorrowerResidenceAndEmployment = async (
    formValues: FormModel,
  ): Promise<string> => {
    const payload: ResidenceAndEmploymentSubmissionInfo = {
      address: formValues.address,
      apartment: formValues.apartment,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      rentOrOwn: formValues.rentOrOwn,
      employmentStatus: formValues.employmentStatus,
      livedInAddress: {
        years: Number.parseInt(`${formValues.livedInAddress?.years}`),
        months: Number.parseInt(`${formValues.livedInAddress?.months}`),
      },
      monthlyPayment: parseInteger(formValues.monthlyPayment),
      yearlyIncome: parseInteger(formValues.yearlyIncome),
    }

    const { resumeUrl } =
      await residenceAndEmploymentAdapters.submitResidenceAndEmployment(payload)
    return resumeUrl
  }

  const submitForm = async (formValues: FormModel): Promise<string> => {
    if (formValues.hasCoborrower && formValues.coborrowerResidence === false) {
      return submitResidenceAndEmploymentWithCoborrower(formValues)
    } else if (
      formValues.hasCoborrower &&
      formValues.coborrowerResidence === true
    ) {
      return submitResidenceAndEmployment(formValues)
    } else {
      return submitBorrowerResidenceAndEmployment(formValues)
    }
  }
  return {
    form,
    submitForm,
  }
}
