import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderUpIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7H13C12.0557 7 11.1666 6.55542 10.6 5.8L9.55 4.4C9.36115 4.14819 9.06476 4 8.75 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.75C9.69427 2 10.5834 2.44458 11.15 3.2L12.2 4.6C12.3889 4.85181 12.6852 5 13 5H18C20.2091 5 22 6.79086 22 9V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L15.7071 13.2929C16.0976 13.6834 16.0976 14.3166 15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071L13 13.4142L13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17L11 13.4142L9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929L11.2929 10.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
