import React, { ForwardRefRenderFunction, useCallback, useState } from 'react'
import some from 'lodash/fp/some'

import { TextField } from '@common/react-lib-base'
import { SSNHashedFieldProps as Props } from '@common/types'

type SsnPart = Readonly<{
  str: string
  ssnLengthToMask?: number
}>

const SSNHashedFieldToForward: ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = ({ onValueChange, ...props }, ref) => {
  const [value, setValue] = useState('')
  const [originalValue, setOriginalValue] = useState('')

  const clearValue = useCallback(() => setValue(''), [])

  const handleChange = useCallback(() => {
    onValueChange?.({ value, unformatted: originalValue })
  }, [originalValue, onValueChange, value])

  const handleFormat = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      handleChange()
      const rawValue = e.target.value.replaceAll(/[^\d*]/g, '').slice(0, 9)

      if (rawValue.length === 0) {
        clearValue()
        setOriginalValue('')
        return
      }

      if (
        /\d\*/g.test(rawValue) ||
        (rawValue.replaceAll('*', '').length === 0 &&
          originalValue.length === 0)
      ) {
        return
      }

      const currentOriginalValue =
        originalValue.slice(0, rawValue.length) +
        rawValue.slice(originalValue.length)

      if (rawValue.length <= 3) {
        setValue(currentOriginalValue)
        setOriginalValue(currentOriginalValue)
        return
      }

      const ssnParts: ReadonlyArray<SsnPart> = [
        { str: currentOriginalValue.slice(0, 3), ssnLengthToMask: 4 },
        { str: currentOriginalValue.slice(3, 5), ssnLengthToMask: 6 },
        { str: currentOriginalValue.slice(5, 9) },
      ]

      // copy/paste of masked ssn
      if (
        some<SsnPart>((part) => part.str.includes('*'), ssnParts) &&
        originalValue.length === 0
      ) {
        clearValue()
        return
      }

      const maskedValue = ssnParts
        .filter((ssnPart) => ssnPart.str.length)
        .map((ssnPart) => {
          const { str, ssnLengthToMask } = ssnPart

          if (currentOriginalValue.length >= ssnLengthToMask) {
            return '*'.repeat(str.length)
          }

          return str
        })
        .join('-')

      setOriginalValue(currentOriginalValue)

      setValue(maskedValue)
      return { value: maskedValue, unformatted: originalValue }
    },
    [clearValue, handleChange, originalValue],
  )

  return (
    <TextField {...props} ref={ref} onChange={handleFormat} value={value} />
  )
}

export const SSNHashedField = React.forwardRef(SSNHashedFieldToForward)
