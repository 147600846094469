import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SwitchChangeDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M32.5116 18.5417L41.7869 14.7856C38.7088 8.83633 32.7974 4.6383 25.8164 4V14.0245C28.6415 14.5646 31.0174 16.2177 32.5116 18.5417Z"
      fill="currentColor"
    />
    <path
      d="M13.904 23.9836C13.904 19.0164 17.4639 14.9165 22.1587 14.0573V4C12.0016 4.92471 4 13.5172 4 23.9836C4 28.4681 5.46152 32.6088 7.93548 35.9476L15.3982 29.2619C14.4756 27.707 13.904 25.8903 13.904 23.9836Z"
      fill="currentColor"
    />
    <path
      d="M43.1511 18.1571L33.8758 21.9132C34.0064 22.5843 34.0962 23.2471 34.0962 23.9509C34.0962 29.5237 29.5892 34.0736 23.9962 34.0736C21.6774 34.0736 19.5219 33.2799 17.8399 31.9378L10.3772 38.6236C13.9616 41.9623 18.7299 44 23.9962 44C35.0434 44 44.0002 35.0229 44.0002 23.9509C44.0084 21.946 43.69 20.0065 43.1511 18.1571Z"
      fill="currentColor"
    />
  </SvgIcon>
)
