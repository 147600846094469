import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6585 6.24744C20.0742 6.61112 20.1163 7.24288 19.7526 7.65852L11.0026 17.6585C10.6403 18.0726 10.0114 18.1162 9.59546 17.756L4.34546 13.2106C3.92792 12.8491 3.8825 12.2175 4.244 11.8C4.6055 11.3825 5.23704 11.337 5.65457 11.6985L10.1525 15.5929L18.2474 6.34151C18.6111 5.92587 19.2429 5.88375 19.6585 6.24744Z"
      fill="currentColor"
    />
  </SvgIcon>
)
