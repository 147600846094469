import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const RemoteDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M37.3762 32.7971V27.0724H35.4577V31.6489C35.4577 32.3645 34.9225 32.949 34.27 32.949H10.5098C9.85732 32.949 9.3221 32.365 9.3221 31.6489V16.0487C9.3221 15.3312 9.85732 14.7472 10.5098 14.7472H24.1033V13.4137H8.76877C8.01937 13.4137 7.40399 14.0821 7.40399 14.9043V23.853V32.7971V34.2877L4 38.7614C4 39.9972 4.91445 41.0001 6.04179 41.0001H38.7363C39.8636 41.0001 40.7781 39.9972 40.7781 38.7614L37.3741 34.2877V32.7971H37.3762ZM25.1169 40.2519H19.6664C19.4767 40.2519 19.3255 40.0864 19.3255 39.8788C19.3255 39.6713 19.4767 39.5057 19.6664 39.5057H25.1152C25.3018 39.5057 25.456 39.6713 25.456 39.8788C25.453 40.0864 25.3035 40.2519 25.1169 40.2519ZM16.7748 38.7614L17.8142 37.3958H26.966L28.0055 38.7614C28.0055 38.7614 16.7748 38.7614 16.7748 38.7614Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M22.9995 24.4147H20.1541V27.5261H22.9995V24.4147Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M24.7813 20.1371H21.9375V23.2518H24.7813V20.1371Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M27.5777 24.0412H24.7339V27.154H27.5777V24.0412Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M30.3705 18.87H26.9958V22.5639H30.3705V18.87Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M35.1435 7.54785H31.7688V11.2418H35.1435V7.54785Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M29.0207 14.2271H25.646V17.921H29.0207V14.2271Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M35.7693 12.65H30.9497V17.9255H35.7693V12.65Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M40.3798 19.0083H34.55V25.3914H40.3798V19.0083Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M43.9999 7H36.7559V14.9294H43.9999V7Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M33.4543 22.9871H30.0796V26.6811H33.4543V22.9871Z"
      fill="currentColor"
    />
  </SvgIcon>
)
