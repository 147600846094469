import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ConversationDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M12.7931 35.1254C9.50672 31.8976 7.4973 27.7148 7.03076 23.2027C5.28413 25.4106 4.33708 28.1187 4.34145 30.9608C4.34438 32.8093 4.76044 34.6468 5.54883 36.3084L3.92316 41.0395C3.6437 41.8528 3.84784 42.7359 4.45598 43.3441C4.88394 43.7721 5.44819 44 6.02847 44C6.27261 44 6.51961 43.9597 6.7605 43.8769L11.4917 42.2512C13.1533 43.0395 14.9908 43.4556 16.8394 43.4585C19.7371 43.4624 22.495 42.4793 24.7257 40.6672C20.2403 40.2614 16.0561 38.3303 12.7931 35.1254Z"
      fill="currentColor"
    />
    <path
      d="M44.2916 35.5761L41.9313 28.7072C43.069 26.3815 43.6703 23.7949 43.6743 21.1925C43.6813 16.6673 41.9305 12.3887 38.7444 9.1448C35.5577 5.90021 31.3112 4.0739 26.7871 4.00222C22.0959 3.92801 17.6864 5.71242 14.3714 9.02741C11.0563 12.3425 9.27157 16.7519 9.34586 21.4434C9.41753 25.9674 11.2438 30.214 14.4883 33.4007C17.7258 36.5806 21.9935 38.3309 26.509 38.3307C26.5179 38.3307 26.5273 38.3307 26.536 38.3307C29.1386 38.3267 31.7251 37.7254 34.0508 36.5877L40.9198 38.9481C41.206 39.0464 41.4996 39.0943 41.7897 39.0943C42.4792 39.0943 43.1497 38.8235 43.6584 38.3148C44.3811 37.592 44.6237 36.5427 44.2916 35.5761ZM28.1673 27.122H19.3038C18.6358 27.122 18.0943 26.5805 18.0943 25.9126C18.0943 25.2446 18.6359 24.7031 19.3038 24.7031H28.1672C28.8352 24.7031 29.3767 25.2447 29.3767 25.9126C29.3767 26.5805 28.8353 27.122 28.1673 27.122ZM33.7143 22.1479H19.3038C18.6359 22.1479 18.0944 21.6064 18.0944 20.9385C18.0944 20.2706 18.6359 19.729 19.3038 19.729H33.7143C34.3823 19.729 34.9238 20.2706 34.9238 20.9385C34.9238 21.6064 34.3823 22.1479 33.7143 22.1479ZM33.7143 17.1737H19.3038C18.6359 17.1737 18.0944 16.6321 18.0944 15.9642C18.0944 15.2963 18.6359 14.7548 19.3038 14.7548H33.7143C34.3823 14.7548 34.9238 15.2963 34.9238 15.9642C34.9238 16.6321 34.3823 17.1737 33.7143 17.1737Z"
      fill="currentColor"
    />
  </SvgIcon>
)
