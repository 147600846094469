import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MailCheckIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V10C22 10.5523 21.5523 11 21 11C20.4477 11 20 10.5523 20 10V8C20 6.89543 19.1046 6 18 6H6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H12C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20H6C3.79086 20 2 18.2091 2 16V8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21917 8.37531C6.56418 7.94405 7.19347 7.87413 7.62473 8.21914L12 11.7194L16.3753 8.21914C16.8066 7.87413 17.4359 7.94405 17.7809 8.37531C18.1259 8.80657 18.056 9.43587 17.6247 9.78088L12 14.2806L6.37534 9.78088C5.94408 9.43587 5.87416 8.80657 6.21917 8.37531Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.684 14.2705C23.0869 14.6482 23.1073 15.281 22.7296 15.6839L18.9796 19.6839C18.6213 20.0661 18.0289 20.107 17.6215 19.7778L15.3715 17.9596C14.942 17.6125 14.8751 16.9829 15.2222 16.5533C15.5694 16.1237 16.199 16.0569 16.6286 16.404L18.1559 17.6383L21.2705 14.3161C21.6482 13.9131 22.2811 13.8927 22.684 14.2705Z"
      fill="currentColor"
    />
  </SvgIcon>
)
