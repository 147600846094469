import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MedicalIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2747 4.11851C12.0952 4.06721 11.9048 4.06721 11.7253 4.11851L6.72528 5.54708C6.29598 5.66974 6 6.06212 6 6.5086V11.8831C6 14.1437 7.09176 16.2652 8.93133 17.5792L11.4188 19.3559C11.7665 19.6043 12.2335 19.6043 12.5812 19.3559L15.0687 17.5792C16.9082 16.2652 18 14.1437 18 11.8831V6.5086C18 6.06212 17.704 5.66974 17.2747 5.54708L12.2747 4.11851ZM11.1758 2.19546C11.7145 2.04156 12.2855 2.04156 12.8242 2.19546L17.8242 3.62403C19.1121 3.992 20 5.16916 20 6.5086V11.8831C20 14.7896 18.5963 17.5173 16.2311 19.2067L13.7437 20.9834C12.7006 21.7285 11.2994 21.7285 10.2563 20.9834L7.76886 19.2067C5.40369 17.5173 4 14.7896 4 11.8831V6.5086C4 5.16916 4.88793 3.992 6.17584 3.62403L11.1758 2.19546Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17154 11C8.17154 10.4477 8.61925 10 9.17154 10L11 10L11 8.17157C11 7.61929 11.4477 7.17157 12 7.17157C12.5522 7.17157 13 7.61929 13 8.17157L13 10L14.8284 10C15.3807 10 15.8284 10.4477 15.8284 11C15.8284 11.5523 15.3807 12 14.8284 12L13 12L13 13.8284C13 14.3807 12.5522 14.8284 12 14.8284C11.4477 14.8284 11 14.3807 11 13.8284L11 12L9.17154 12C8.61925 12 8.17154 11.5523 8.17154 11Z"
      fill="currentColor"
    />
  </SvgIcon>
)
