import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

export type SubmitReviewMyInfo = () => Promise<string>

/**
 * Calls submit-review-info, which takes only a request id and kicks
 * off side effects required after customer data confirmed.
 * @returns resumeUrl
 */
export const submitReviewMyInfoRest: SubmitReviewMyInfo = async () => {
  type Response = { resumeUrl: string }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-review-info${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify({ requestId: getRequestId() }),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  const { resumeUrl }: Response = data
  return resumeUrl
}
