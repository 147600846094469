import React from 'react'
import { Box, CircularProgress, styled } from '@mui/material'

const Container = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 250,
})

export const Loader = ({ children }: { children?: string }): JSX.Element => {
  return (
    <Container>
      <CircularProgress />
      {children && <p>{children}</p>}
    </Container>
  )
}
