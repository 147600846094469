import React, { FC } from 'react'
import { SvgIconProps, useTheme } from '@mui/material'
import get from 'lodash/get'

import { OmitSafe } from '@common/lib-types'

import { ColorPath } from '../types'

/**
 * A wrapper around icons for ease of styling
 * @example
 * <Icon use={CheckCircleSolidIcon} color="base.blueSky" size={16} />
 */
export const Icon: FC<
  { use: FC<SvgIconProps>; color?: ColorPath; size?: number } & OmitSafe<
    SvgIconProps,
    'color' | 'htmlColor'
  >
> = ({ use: IconComponent, color, size, ...props }) => {
  const theme = useTheme()
  return (
    <IconComponent
      {...props}
      sx={{ width: size, height: size }}
      htmlColor={color && get(theme.extension.colors, color)}
    />
  )
}
