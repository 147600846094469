import React, { FC } from 'react'
import { Box, styled, Typography } from '@mui/material'
import Image from 'next/legacy/image'

import { Image as ImageType } from '@common/types'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '68px',

  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
  fontSize: '1rem',
}))

const Display = styled(Box)(({ theme }) => ({
  border: 'solid thin silver',
  background: 'hsla(193, 11%, 84%, 0.1)',
  height: '500px',
  width: '800px;',
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: '0px',
  },
}))

const Content = styled(Box)(({ theme }) => ({
  marginTop: '80px',
  padding: '15px',

  [theme.breakpoints.down('md')]: {
    marginTop: '0px',
  },
}))

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  minWidth: 0,
})

export type NoLoanOptionsErrorProps = Partial<{
  title: string
  message: string
  image: ImageType
}>

const defaultProps: NoLoanOptionsErrorProps = {
  image: {
    title: 'Road Highway',
    src: '/assets/images/roads-caribou.webp',
    width: 1600,
    height: 2000,
  },
  message:
    'Based on the information provided, we could not find any loan options from our lending partners for you this time. Thanks for visiting Caribou.',
  title: "Sorry, we weren't able to find any loan options.",
}

export const NoLoanOptionsError: FC<NoLoanOptionsErrorProps> = ({
  message = defaultProps.message,
  title = defaultProps.title,
  image = defaultProps.image,
}) => {
  return (
    <Container>
      <Display>
        <Content>
          <Typography variant="h2" fontWeight="normal">
            {title}
          </Typography>

          <p>{message}</p>
        </Content>

        <ImageContainer>
          <Image
            alt={image.title}
            src={image.src}
            layout="fill"
            objectFit="cover"
          />
        </ImageContainer>
      </Display>
    </Container>
  )
}
