// eslint-disable-next-line no-restricted-imports
import { createTheme, ThemeOptions } from '@mui/material'

import { colors } from './colors'
import { mrSansSerifFontStack } from './font-utils'
import { EFontWeight, typography as allTypography } from './typography'

const typography: ThemeOptions['typography'] = {
  fontFamily: mrSansSerifFontStack('Poppins'),
  fontWeightMedium: EFontWeight.medium,
  fontWeightRegular: EFontWeight.regular,
  fontSize: 16,
}

export const theme = createTheme({
  typography,
  palette: {
    primary: {
      main: colors.base.blueSky,
      light: colors.base.littleBoyBlue,
      dark: colors.base.deepBlue,
    },
    secondary: {
      main: colors.base.freshMint,
      light: colors.base.lightMint,
      dark: colors.base.greenLeaf,
    },

    grey: colors.neutral,
    divider: colors.divider.lightGray,

    text: {
      primary: colors.text.softBlack,
      secondary: colors.text.bodyGray,
    },

    error: {
      main: colors.semantic.error,
      light: colors.semantic.errorGlow,
    },

    common: {
      black: colors.text.black,
      white: colors.text.white,
    },
  },
  components: {
    // Idk why this was overriden but it may cause bugs in other components which use backdrops
    // If this is needed replace in another place other than root
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'rgba(0, 0, 0, 0.8)',
    //     },
    //   },
    // },
  },
  extension: {
    colors,
    typography: allTypography,
  },
})
