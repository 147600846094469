import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DenverDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3998 15.2938L21.2779 40H23.6573L26.668 10.0851L18.3998 15.2938ZM12.3333 20.1648L8.05199 22.6827L7.09287 25.895H13.2482L12.3333 20.1648ZM6.05052 27.7141H2L5.751 40H8.6592V33.4277C8.6592 32.9256 8.97989 32.5187 9.37603 32.5187H13.127C13.5228 32.5187 13.8435 32.9256 13.8435 33.4277V40H15.5001L13.5384 27.7141H6.05721L6.05571 27.7144L6.0546 27.7145L6.05256 27.7143L6.05052 27.7141ZM10.0929 34.3377V40H12.4102V34.3377H10.0929ZM16.9542 15.3258L12.6447 12.9565L16.9628 40H19.8286L16.9542 15.3258Z"
      fill="currentColor"
    />
    <g opacity="0.24">
      <path
        d="M46 8L35.3163 12.3043L38.0817 20.5545L46 8Z"
        fill="currentColor"
      />
      <path
        d="M33.6727 39.9994L36.6385 27.7135L35.4417 25.0544L31.142 29.574L25.297 38.0615L33.6727 39.9994Z"
        fill="currentColor"
      />
      <path
        d="M14.2737 11.8691L17.6575 13.7297L20.9212 11.8691H14.2737Z"
        fill="currentColor"
      />
      <path
        d="M36.7617 21.5544L33.9469 12.856L27.9536 15.271L31.9215 27.0543L36.7617 21.5544Z"
        fill="currentColor"
      />
      <path
        d="M30.6015 28.0497L27.5214 18.0545L25.3213 35.5542L30.6015 28.0497Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)
