import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShieldFailSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8242 2.19551C12.2855 2.0416 11.7145 2.0416 11.1758 2.19551L6.17584 3.62408C4.88793 3.99205 4 5.16921 4 6.50865V11.8831C4 14.7897 5.40369 17.5173 7.76886 19.2067L10.2563 20.9834C11.2994 21.7285 12.7006 21.7285 13.7437 20.9834L16.2311 19.2067C18.5963 17.5173 20 14.7897 20 11.8831V6.50865C20 5.16921 19.1121 3.99205 17.8242 3.62408L12.8242 2.19551ZM9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289L12 9.58579L13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711L13.4142 11L14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071C14.3166 14.0976 13.6834 14.0976 13.2929 13.7071L12 12.4142L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.90237 13.3166 8.90237 12.6834 9.29289 12.2929L10.5858 11L9.29289 9.70711C8.90237 9.31658 8.90237 8.68342 9.29289 8.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
