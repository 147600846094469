/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { bool, object, SchemaOf, string } from 'yup'

import { updateYupErrorMessages } from './yup'

updateYupErrorMessages()

export type CreateAccountForm = {
  ssn: string
  activeDuty: boolean
  terms: boolean
  coborrowerSsn?: string
  coborrowerActiveDuty?: boolean
}

const SSN = string().required()

const Terms = bool().oneOf([true], 'Please agree to the terms above')

export const getNewResumeFlowCreateAccountSchema = (
  hasCoborrower: boolean,
): SchemaOf<CreateAccountForm> =>
  object({
    email: string(),
    ssn: SSN,
    coborrowerSsn: hasCoborrower ? SSN : string(),
    coborrowerActiveDuty: hasCoborrower ? bool().required() : bool(),
    activeDuty: bool().required(),
    terms: Terms,
  })
