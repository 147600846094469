import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NotFoundDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7487 4.75H18.5C15.4624 4.75 13 7.21243 13 10.25V16.314C14.3113 15.9431 15.695 15.7446 17.125 15.7446C25.4783 15.7446 32.25 22.5163 32.25 30.8695C32.25 35.9817 29.714 40.5014 25.8311 43.2393H35C38.0376 43.2393 40.5 40.7769 40.5 37.7393V18.5026H30.8736C28.5955 18.5026 26.7487 16.6557 26.7487 14.3776V4.75ZM40.4909 15.7526H30.8736C30.1143 15.7526 29.4987 15.137 29.4987 14.3776V4.75H29.5231L40.4909 15.7526Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5 30.8695C29.5 37.7041 23.9595 43.2445 17.125 43.2445C10.2905 43.2445 4.75 37.7041 4.75 30.8695C4.75 24.0351 10.2905 18.4946 17.125 18.4946C23.9595 18.4946 29.5 24.0351 29.5 30.8695ZM17.125 23.9946C16.3656 23.9946 15.75 24.6102 15.75 25.3696V30.8695C15.75 31.629 16.3656 32.2445 17.125 32.2445C17.8844 32.2445 18.5 31.629 18.5 30.8695V25.3696C18.5 24.6102 17.8844 23.9946 17.125 23.9946ZM17.125 38.0882C18.0742 38.0882 18.8438 37.3188 18.8438 36.3695C18.8438 35.4205 18.0742 34.6507 17.125 34.6507C16.1758 34.6507 15.4062 35.4205 15.4062 36.3695C15.4062 37.3188 16.1758 38.0882 17.125 38.0882Z"
      fill="currentColor"
    />
  </SvgIcon>
)
