import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const VolumeMediumIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04346 5.74604C7.99337 4.63781 9.38012 4 10.8397 4H11C12.6569 4 14 5.34315 14 7V17C14 18.6569 12.6569 20 11 20H10.8397C9.38012 20 7.99337 19.3622 7.04346 18.254L6.92054 18.1106C6.62705 17.7681 6.22645 17.535 5.78377 17.4489L5.23652 17.3425C3.35696 16.977 2 15.3308 2 13.416V10.584C2 8.6692 3.35695 7.02297 5.23652 6.6575L5.78377 6.55109C6.22645 6.46502 6.62705 6.23185 6.92054 5.88945L7.04346 5.74604ZM10.8397 6C9.96396 6 9.13192 6.38269 8.56197 7.04763L8.43905 7.19103C7.85207 7.87584 7.05087 8.34217 6.1655 8.51433L5.61826 8.62073C4.67848 8.80347 4 9.62658 4 10.584V13.416C4 14.3734 4.67848 15.1965 5.61826 15.3793L6.1655 15.4857C7.05087 15.6578 7.85207 16.1242 8.43905 16.809L8.56197 16.9524C9.13192 17.6173 9.96397 18 10.8397 18H11C11.5523 18 12 17.5523 12 17V7C12 6.44772 11.5523 6 11 6H10.8397Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3991 8.30553C17.8774 8.02939 18.489 8.19326 18.7651 8.67155C19.3494 9.68353 19.657 10.8315 19.657 12C19.657 13.1685 19.3494 14.3164 18.7651 15.3284C18.489 15.8067 17.8774 15.9706 17.3991 15.6944C16.9208 15.4183 16.7569 14.8067 17.0331 14.3284C17.4418 13.6205 17.657 12.8174 17.657 12C17.657 11.1825 17.4418 10.3795 17.0331 9.67155C16.7569 9.19326 16.9208 8.58167 17.3991 8.30553Z"
      fill="currentColor"
    />
  </SvgIcon>
)
