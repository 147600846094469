// eslint-disable-next-line unicorn/prefer-node-protocol
import { createContext } from 'react'
import tap from 'lodash/fp/tap'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'
import startsWith from 'lodash/startsWith'
import { IncomingHttpHeaders } from 'node:http'
import { pipeWith } from 'pipe-ts'
// eslint-disable-next-line unicorn/prefer-node-protocol
import { ParsedUrlQuery } from 'querystring'
import Cookies from 'universal-cookie'

export enum ECampaignSource {
  progressive = 'progressive',
  google = 'google',
  bing = 'bing',
  facebook = 'facebook',
  reddit = 'reddit',
  linkedIn = 'linkedin',
  nextDoor = 'nextdoor',
  quora = 'quora',
  partnerCentric = 'partnercentric',
  impact = 'impact',
  pinterest = 'pinterest',
  directMessage = 'dm',
  creditKarma = 'ck',
}

/** Taken from here: https://ga-dev-tools.web.app/campaign-url-builder/ */
type CampaignParams = {
  gclid?: string
  utm_source: ECampaignSource
  utm_medium: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}
// eslint-disable-next-line @typescript-eslint/ban-types
export type UtmParams = CampaignParams | {}

export const LeadSourceContext = createContext<UtmParams>({})

export const getUrlUtm = (query: ParsedUrlQuery): UtmParams => {
  const UTM_PARAM_PREFIXES = ['utm_', 'gclid']

  const paramMatchesPrefix = (paramName: string): boolean =>
    some(UTM_PARAM_PREFIXES, (prefix) => startsWith(paramName, prefix))

  const utmValues = pickBy(query, (paramValue, paramName) =>
    paramMatchesPrefix(paramName),
  ) as unknown as UtmParams
  return utmValues
}

export const UTM_COOKIE_STORAGE_KEY = 'mr_utm_params'

export const getCookieUtm = (reqCookies?: string): UtmParams => {
  const cookies = new Cookies(reqCookies)
  return cookies.get(UTM_COOKIE_STORAGE_KEY) ?? {}
}

/**
 * Side effecting function that places utm params we would like forwarded
 * to apply.motorefi.com into a cookie for that purpose
 */
export const setUtmParamsCookie = (utmParams: UtmParams): void => {
  const cookies = new Cookies()
  return cookies.set(UTM_COOKIE_STORAGE_KEY, utmParams)
}

/**
 * Get UTM params we would like forwarded to apply.motorefi.com.  First, look
 * at cookie for this purpose, and fall back to reading the URL qs params
 */
export const getSetUtmParams = (
  query: ParsedUrlQuery,
  requestHeaders?: IncomingHttpHeaders,
): UtmParams => {
  // Check for cookie value first, to always attribute lead to
  // original/initial lead source.
  const cookieUtm = getCookieUtm(requestHeaders?.cookie)
  return !isEmpty(cookieUtm)
    ? cookieUtm
    : pipeWith(getUrlUtm(query), tap(setUtmParamsCookie))
}
