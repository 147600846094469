import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PieChartV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3317 3.61609C10.5016 4.09704 10.2497 4.62474 9.76906 4.79473C6.89968 5.80954 4.84613 8.5478 4.84613 11.7636C4.84613 15.8445 8.15229 19.1527 12.2306 19.1527C15.455 19.1527 18.199 17.0843 19.2051 14.1984C19.373 13.7168 19.8994 13.4625 20.3807 13.6306C20.8621 13.7986 21.1162 14.3252 20.9482 14.8069C19.6915 18.4116 16.2644 21 12.2306 21C7.1327 21 3 16.8647 3 11.7636C3 7.7406 5.56993 4.32057 9.15384 3.05305C9.63449 2.88305 10.1619 3.13514 10.3317 3.61609Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4.78134C11 3.80372 11.8151 2.88737 12.9258 3.01129C13.7773 3.1063 14.613 3.3209 15.4078 3.65009C16.5157 4.10901 17.5224 4.78166 18.3704 5.62963C19.2183 6.4776 19.891 7.48429 20.3499 8.59221C20.6791 9.38697 20.8937 10.2227 20.9887 11.0742C21.1126 12.1849 20.1963 13 19.2187 13L12.8264 13C11.8177 13 11 12.1823 11 11.1736L11 4.78134ZM12.8264 4.83864V11.1736L19.1614 11.1736C19.08 10.528 18.9126 9.89482 18.6626 9.29113C18.2954 8.40479 17.7573 7.59944 17.0789 6.92107C16.4006 6.24269 15.5952 5.70457 14.7089 5.33744C14.1052 5.08738 13.472 4.91998 12.8264 4.83864Z"
      fill="currentColor"
    />
  </SvgIcon>
)
