import React, { FunctionComponent } from 'react'

export const Favicon: FunctionComponent = () => {
  return (
    <>
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link
        rel="icon"
        href="/favicon-16x16.png"
        sizes="16x16"
        type="image/png"
      />
      <link
        rel="icon"
        href="/favicon-32x32.png"
        sizes="32x32"
        type="image/png"
      />
      <link
        rel="icon"
        href="/favicon-194x194.png"
        sizes="194x194"
        type="image/png"
      />

      <link rel="icon" sizes="36x36" href="/android-chrome-36x36.png" />
      <link rel="icon" sizes="48x48" href="/android-chrome-48x48.png" />
      <link rel="icon" sizes="72x72" href="/android-chrome-72x72.png" />
      <link rel="icon" sizes="96x96" href="/android-chrome-96x96.png" />
      <link rel="icon" sizes="144x144" href="/android-chrome-144x144.png" />
      <link rel="icon" sizes="192x192" href="/android-chrome-192x192.png" />
      <link rel="icon" sizes="256x256" href="/android-chrome-256x256.png" />
      <link rel="icon" sizes="384x384" href="/android-chrome-384x384.png" />
      <link rel="icon" sizes="512x512" href="/android-chrome-512x512.png" />

      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-touch-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon-180x180.png"
      />

      <link
        rel="apple-touch-icon-precomposed"
        href="/apple-touch-icon-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/apple-touch-icon-60x60-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/apple-touch-icon-76x76-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/apple-touch-icon-120x120-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/apple-touch-icon-152x152-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="180x180"
        href="/apple-touch-icon-180x180-precomposed.png"
      />

      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00c696" />
      <link rel="manifest" href="/site.webmanifest" />

      <meta name="msapplication-config" content="/browserconfig.xml" />

      <meta name="msapplication-TileColor" content="#00c696" />
      <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
    </>
  )
}
