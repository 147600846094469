import React from 'react'
import { styled } from '@mui/material'

import { ToolbarButtonProps } from '@common/react-lib-base'
import { MaterialProps } from '@lib/mui'

import { Button, ReviewEditIcon, Text } from '../../reskin'

type Props = MaterialProps &
  Pick<
    ToolbarButtonProps,
    'children' | 'onClick' | 'disabled' | 'loading' | 'variant'
  > & {
    startIcon?: 'edit'
    mobile?: boolean
  }

const EditButton = styled(Button)<{ mobile?: boolean }>(
  ({ theme, mobile }) => ({
    color: theme.extension.colors.base.blueSky,
    ...theme.extension.typography.interactive.inputLabel16,

    ...(mobile && {
      display: 'none',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        height: 'auto',
        padding: 0,
      },
    }),
  }),
)

export const NewVerifyEditFormText = ({
  children,
  loading,
  startIcon,
  mobile,
  ...props
}: Props): JSX.Element => {
  return (
    <EditButton
      mobile={mobile}
      type="text"
      {...props}
      icon={<ReviewEditIcon sx={{ mr: '-8px' }} />}
      iconPosition="start"
    >
      <Text use={['span', 'interactive.inputLabel16']}>{children}</Text>
    </EditButton>
  )
}

export const NewVerifySubmitFormButton = ({
  children,
  loading,
  startIcon,
  ...props
}: Props): JSX.Element => {
  return (
    <>
      {loading && <Button loading={loading} type="primary" {...props} />}

      {!loading && (
        <Button type="primary" {...props}>
          {children}
        </Button>
      )}
    </>
  )
}

export const NewVerifyFormButton = ({
  children,
  loading,
  startIcon,
  ...props
}: Props): JSX.Element => {
  return (
    <>
      {loading && <Button loading={loading} type="tertiary" {...props} />}

      {!loading && (
        <Button type="tertiary" {...props}>
          {children}
        </Button>
      )}
    </>
  )
}
