import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const TrashV1Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C4 3.44772 4.44772 3 5 3H19C19.5523 3 20 3.44772 20 4C20 4.55228 19.5523 5 19 5H5C4.44772 5 4 4.55228 4 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.92335 7.00295C6.47401 6.96059 6.95474 7.37265 6.9971 7.92331L7.78403 18.1534C7.86419 19.1954 8.73307 20 9.77814 20H14.222C15.267 20 16.1359 19.1954 16.2161 18.1534L17.003 7.92331C17.0454 7.37265 17.5261 6.96059 18.0767 7.00295C18.6274 7.04531 19.0395 7.52604 18.9971 8.0767L18.2102 18.3068C18.0499 20.3908 16.3121 22 14.222 22H9.77814C7.688 22 5.95023 20.3908 5.78992 18.3068L5.00299 8.0767C4.96064 7.52604 5.37269 7.04531 5.92335 7.00295Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.15051C12.5784 4.69033 13.3463 4.99999 14.1552 4.99999L16 4.99999V2.99999L14.1552 2.99999C13.7679 2.99999 13.4061 2.8064 13.1913 2.4841C12.6246 1.63405 11.3754 1.63404 10.8087 2.4841C10.5939 2.8064 10.2321 2.99999 9.84479 2.99999L8 2.99999V4.99999L9.84479 4.99999C10.6537 4.99999 11.4216 4.69033 12 4.15051Z"
      fill="currentColor"
    />
  </SvgIcon>
)
