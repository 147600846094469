import React from 'react'

import {
  EmploymentStatus,
  EmploymentStatusKeys,
  NewReviewResidenceFormModule,
  ReviewInfoData,
} from '@common/types'
import { normalizeString } from '@src/utils'

import { Format } from '../../format'
import {
  CaseIcon,
  ClockCircleIcon,
  DollarMoneyIcon,
  HomeV2Icon,
  PieChartV1Icon,
  StackFields,
  WalletIcon,
} from '../../reskin'
import {
  Container,
  DynamicFieldsContainer,
  FormRowContainer,
  FormRowName,
  FormRowValue,
  SaveCancelContainer,
  StyledIcon,
} from '../verify-form'

const plural = (num: number, word: string, suffix = 's'): string =>
  `${num} ${word}${Number(num) === 1 ? '' : suffix}`

const mapEmploymentToDisplay = (value: EmploymentStatusKeys): string =>
  value === EmploymentStatus.SelfEmployed
    ? 'Self-employed'
    : normalizeString(value)

type ResidenceInfo = ReviewInfoData['residence']

export const NewVerifyResidenceInfo = React.forwardRef<
  HTMLDivElement,
  NewReviewResidenceFormModule & {
    data: ResidenceInfo
  }
>(
  (
    {
      data,
      submitError,
      isEditing,
      controls: { Save, Edit, Cancel, Popup },
      module: {
        Address,
        Apartment,
        City,
        State,
        Zip,
        ResidenceStatus,
        MonthlyPayment,
        Years,
        Months,
        EmploymentStatus,
        YearlyIncome,
      },
    },
    ref,
  ) => {
    return (
      <Container
        title={
          !isEditing
            ? 'Residential Information'
            : 'Edit Residential Information'
        }
        error={submitError}
        actions={!isEditing && <Edit />}
        ref={ref}
      >
        <>
          <Popup />
          {!isEditing && (
            <>
              {/* Street Address */}
              <FormRowContainer sx={{ py: '0px !important' }}>
                <FormRowName>
                  <StyledIcon use={HomeV2Icon} />
                  {'Street address'}
                </FormRowName>
                <FormRowValue>
                  <span>{`${data.address},`}</span>
                </FormRowValue>
              </FormRowContainer>

              <FormRowContainer sx={{ py: '0px !important' }}>
                <FormRowName />
                <FormRowValue>
                  <span>{`${data.city}, ${data.state} ${data.zip}`}</span>
                </FormRowValue>
              </FormRowContainer>

              {/* Rent or Own */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={PieChartV1Icon} />
                  {'Rent or own'}
                </FormRowName>
                <FormRowValue>{normalizeString(data.rentOrOwn)}</FormRowValue>
              </FormRowContainer>

              {/* Monthly Rent */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={WalletIcon} />
                  {'Monthly rent/mortgage'}
                </FormRowName>
                <FormRowValue>
                  {Format.newCurrency(data.monthlyPayment)}
                </FormRowValue>
              </FormRowContainer>

              {/* Time at Residence */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={ClockCircleIcon} />
                  {'Time at residence'}
                </FormRowName>
                <FormRowValue>{`${plural(
                  data.livedInAddress.years,
                  'year',
                )}, ${plural(
                  data.livedInAddress.months,
                  'month',
                )}`}</FormRowValue>
              </FormRowContainer>

              {/* Employment Status */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={CaseIcon} />
                  {'Employment status'}
                </FormRowName>
                <FormRowValue>
                  {mapEmploymentToDisplay(data.employmentStatus)}
                </FormRowValue>
              </FormRowContainer>

              {/* Yearly Income */}
              <FormRowContainer>
                <FormRowName>
                  <StyledIcon use={DollarMoneyIcon} />
                  {'Yearly income'}
                </FormRowName>
                <FormRowValue>
                  {Format.newCurrency(data.yearlyIncome)}
                </FormRowValue>
              </FormRowContainer>
              <Edit mobile />
            </>
          )}
          {isEditing && (
            <>
              <StackFields>
                <Address />
                <Apartment />
                <City />
                <State />
                <Zip />
              </StackFields>
              <DynamicFieldsContainer>
                <StackFields>
                  <ResidenceStatus />
                  <MonthlyPayment />
                  <Years />
                  <Months />
                  <EmploymentStatus />
                  <YearlyIncome />
                </StackFields>
              </DynamicFieldsContainer>
              <SaveCancelContainer>
                <Cancel sx={{ width: '100%' }} />
                <Save sx={{ width: '100%' }} />
              </SaveCancelContainer>
            </>
          )}
        </>
      </Container>
    )
  },
)
