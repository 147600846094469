/* eslint-disable react/jsx-no-bind */
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import Carousel from 'react-slick'
import { Box, styled, useTheme } from '@mui/material'
import debounce from 'lodash/debounce'
import toArray from 'lodash/toArray'

import {
  EOfferOption,
  OfferSelectionLoanFlexibleOption,
  OfferSelectionLoanLowestOption,
} from '@common/types'
import { safeWindow } from '@src/utils'

const isSmall = (): boolean => safeWindow.innerWidth < 540

const leftAlignFirstSlide = (): void => {
  const trackEl: HTMLDivElement = document.querySelector('.slick-track')
  if (trackEl) {
    trackEl.style.transform = `translate3d(0, 0, 0)`
  }
}

const getTrackEl = (): HTMLDivElement => document.querySelector('.slick-track')

const getTranslateXY = (
  transform: string,
): { translateX: number; translateY: number } => {
  const matrix = new DOMMatrixReadOnly(transform)
  return {
    translateX: matrix.m41,
    translateY: matrix.m42,
  }
}

const rightAlignLastSlide = (currentTransform: string): void => {
  const { translateX: translateXFrom } = getTranslateXY(currentTransform)
  const activeSlideEl = document.querySelector('.slick-active')
  const { right } = activeSlideEl.getBoundingClientRect()
  const cardPaddingRight = 8
  const change = safeWindow.innerWidth - right - cardPaddingRight

  getTrackEl().style.transform = `translate3d(${
    translateXFrom + change
  }px, 0, 0)`
}

type CardsCarouselProps = {
  LowestOption: FC<Pick<OfferSelectionLoanLowestOption, 'style'>>
  FlexibleOptions: FC<Pick<OfferSelectionLoanFlexibleOption, 'style'>>
  onSwipeTo?: (option: EOfferOption) => void
}

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: theme.spacing(0, 0, 0, 1),
}))

const CarouselCardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex !important',
  flexDirection: 'column',
  padding: theme.spacing(1),
  overflow: 'visible',
}))

const CarouselCards = styled(Carousel)(() => ({
  '& .slick-dots li button::before': {
    backgroundColor: '#D1D1D1',
    borderRadius: '100%',
    content: '""',
    height: '8px',
    opacity: '1',
    width: '8px',
  },
  '& .slick-dots li.slick-active button::before': {
    backgroundColor: '#008765',
    boxShadow: `0 0 0 5px #FFFFFF, 0 0 0 6px #00C696`,
  },
  '& .slick-list': {
    paddingBottom: '20px !important',
  },
}))

export const CardsCarousel: React.FC<CardsCarouselProps> = ({
  LowestOption,
  FlexibleOptions,
  onSwipeTo,
}) => {
  const theme = useTheme()
  const width = `min(420px, calc(100vw - ${theme.spacing(6)}))`
  const ref = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [trackElHeight, setTrackElHeight] = useState(0)
  const cardVerticalPadding = 16
  const minCardHeight = Math.max(0, trackElHeight - cardVerticalPadding)
  const [showSwipeHelper, setShowSwipeHelper] = React.useState(true)

  const resizeCards = useCallback(() => {
    setTrackElHeight(0)

    setTimeout(() => {
      const trackEl = getTrackEl()
      if (!trackEl) {
        return
      }
      const { height } = trackEl.getBoundingClientRect()
      setTrackElHeight(height)
    })
  }, [setTrackElHeight])

  useEffect(() => {
    const onResize = debounce(() => {
      resizeCards()

      if (!isSmall()) {
        return
      }
      const slides = document.querySelectorAll('.slick-slide')
      const activeSlideIdx = toArray(slides).findIndex((s: HTMLDivElement) =>
        s.classList.contains('slick-active'),
      )

      const isFirstSlide = activeSlideIdx === 0
      if (isFirstSlide) {
        setTimeout(leftAlignFirstSlide)
      }

      const isLastSlide = activeSlideIdx === 1
      if (isLastSlide) {
        setTimeout(() => {
          rightAlignLastSlide(getTrackEl().style.transform)
        })
      }
    }, 50)
    safeWindow.addEventListener('resize', onResize)
    return () => {
      safeWindow.removeEventListener('resize', onResize)
    }
  }, [resizeCards])

  useLayoutEffect(() => {
    if (!height && ref.current !== null) {
      setHeight(ref.current.clientHeight)
    }
  }, [])

  return (
    <CarouselContainer>
      <CarouselCards
        infinite={false}
        slidesToShow={1}
        slidesToScroll={1}
        centerPadding={'0px'}
        arrows={false}
        dots
        variableWidth
        centerMode
        afterChange={(slide) => {
          onSwipeTo?.(slide === 1 ? EOfferOption.flexible : EOfferOption.lowest)
        }}
        onInit={() => {
          if (isSmall()) {
            setTimeout(leftAlignFirstSlide)
          }

          resizeCards()
        }}
        beforeChange={(currSlide, nextSlide) => {
          if (showSwipeHelper) {
            setShowSwipeHelper(false)
          }

          if (!isSmall()) {
            return
          }

          const isFirstSlide = nextSlide === 0
          if (isFirstSlide) {
            setTimeout(leftAlignFirstSlide)
          }

          const isLastSlide = nextSlide === 1
          if (isLastSlide) {
            const currentTransform = getTrackEl().style.transform
            setTimeout(() => {
              rightAlignLastSlide(currentTransform)
            })
          }
        }}
      >
        <CarouselCardsContainer style={{ width }}>
          <LowestOption style={{ minHeight: minCardHeight }} />
        </CarouselCardsContainer>
        <CarouselCardsContainer style={{ width }}>
          <FlexibleOptions style={{ minHeight: minCardHeight }} />
        </CarouselCardsContainer>
      </CarouselCards>
    </CarouselContainer>
  )
}
