import React from 'react'

export const formatSSN = (value = ''): string => {
  let formattedValue = value?.replace(/-/g, '')
  const stringLength = formattedValue.length
  if (stringLength > 3 && stringLength <= 5) {
    formattedValue = formattedValue.replace(/^(\d{3})(\d{2})?/, '$1-$2')
  } else if (stringLength > 5) {
    formattedValue = formattedValue.replace(
      /^(\d{3})(\d{2})?(\d{4})?/,
      '$1-$2-$3',
    )
  }
  return formattedValue
}

export const createMask = (rawValue): string => {
  if (!rawValue) {
    return ''
  }
  const value = formatSSN(rawValue)
  return [...value]
    .map((char, index) => {
      if (index === 3 || index === 6) {
        return '-'
      }
      return '*'
    })
    .join('')
}

export const handleHiddenValue = (
  event: React.KeyboardEvent<HTMLInputElement>,
  value: string,
): [string, number] => {
  const { currentTarget, key } = event
  const isFirstPosition = !!(
    currentTarget.selectionStart === 0 && currentTarget.selectionEnd === 0
  )

  let cursorPos = currentTarget.selectionStart

  const deleteArray = [
    currentTarget.selectionStart,
    currentTarget.selectionEnd,
  ].sort((a, b) => (a > b) as unknown as number)

  const amount = deleteArray[1] - deleteArray[0]
  let splitValue = [...value]

  if (/\d/.test(key) && (amount > 0 || splitValue.length < 11)) {
    splitValue.splice(deleteArray[0], amount, key)
  } else if (['Backspace'].includes(key) && !isFirstPosition) {
    if (!amount) {
      const deletePosition =
        deleteArray[0] === 4 || deleteArray[0] === 7
          ? deleteArray[0] - 2
          : deleteArray[0] - 1
      splitValue.splice(deletePosition, 1)
      cursorPos = deletePosition
    } else {
      cursorPos = deleteArray[0]
      splitValue.splice(deleteArray[0], deleteArray[1])
    }
  } else if (key === 'Delete') {
    cursorPos = deleteArray[0]
    splitValue.splice(deleteArray[0], amount || 1)
  }
  splitValue = splitValue.filter((elem: string) => elem !== '-')
  if (splitValue.length > 3) {
    splitValue.splice(3, 0, '-')
  }
  if (splitValue.length > 6) {
    splitValue.splice(6, 0, '-')
  }
  const newValue = splitValue.join('').slice(0, 11)
  return [newValue, cursorPos]
}
