import { omitSafe, pickSafe } from '@common/lib-types'
import { ReviewInfoData } from '@common/types'

import { identify } from './tracking'

export const trackUpdateUserInfo = (data: ReviewInfoData): void => {
  const {
    borrower: { phoneNumber: phone, ...borrower },
    coborrower,
    residence,
    vehicle,
  } = data
  identify(borrower.segmentUserId, {
    ...borrower,
    phone,
    employmentStatus: residence.employmentStatus,
    residence: {
      ...pickSafe(residence, ['city', 'state']),
      residedFor: residence.livedInAddress.years,
      status: residence.rentOrOwn,
      street: residence.address,
      zipCode: residence.zip,
    },
    vehicle: {
      ...pickSafe(vehicle, ['estimatedMonthlyPayment', 'vin']),
      loanBalance: vehicle.loanValue,
      mileage: vehicle.estimatedMileage,
      licensePlate: vehicle.licensePlateNumber,
      state: vehicle.licensePlateState,
      attributes: pickSafe(vehicle, ['year', 'make', 'model', 'trim']),
    },
    ...(coborrower && {
      coborrower: {
        ...omitSafe(coborrower, ['phoneNumber']),
        phone: coborrower.phoneNumber,
      },
    }),
  })
}
