import React, { FunctionComponent } from 'react'
import { Grid, styled } from '@mui/material'

import { OldTheme } from '@modules/core-ui'

import { Logo } from './logo'
import { Link } from './reskin'

const Container = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  height: '70px',
  background: theme.mrExtensions.palette.accentTwo.contrast,
  padding: theme.spacing(2, 6),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 3),
  },
}))

type HeaderProps = Partial<{
  logoLink: string
  menuLinks: ReadonlyArray<{ name: string; href: string }>
}>

const defaultProps: HeaderProps = {
  logoLink: 'https://www.caribou.com/',
  menuLinks: [{ name: 'Login', href: 'https://apply.motorefi.com/login' }],
}

/**
 *
 * @deprecated Use 'react-lib-consumer-pres/newer-flow/header/header.tsx' instead
 *
 */
export const Header: FunctionComponent<HeaderProps> = ({
  logoLink = defaultProps.logoLink,
  menuLinks = defaultProps.menuLinks,
}) => {
  return (
    <Container
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs container>
        <Link href={logoLink} target="_blank" sx={{ lineHeight: 0 }}>
          <Logo height={36} />
        </Link>
      </Grid>
      <Grid item xs container justifyContent="flex-end">
        {menuLinks.map((link) => (
          <Grid item key={link.name}>
            <Link
              href={link.href}
              color={({ palette }) => palette.secondary.main}
              fontWeight="bold"
              sx={({ typography }: OldTheme) => ({
                fontSize: typography.pxToRem(20),
              })}
            >
              {link.name}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
