import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ExperienceDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M9.67544 29.5191C12.7349 29.5191 15.2151 27.0389 15.2151 23.9794C15.2151 20.9199 12.7349 18.4397 9.67544 18.4397C6.61595 18.4397 4.13574 20.9199 4.13574 23.9794C4.13574 27.0389 6.61595 29.5191 9.67544 29.5191Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M11.7592 30.9875H7.63998C4.65706 30.9875 2.14763 33.2129 1.8162 36.1958L1.20067 40.9779C1.10598 41.5935 1.53211 42.1616 2.14763 42.3037C4.51502 42.8245 7.0718 43.0612 9.67593 43.0612C10.7649 43.0612 11.8066 43.0139 12.8009 42.9192L13.3217 38.6579C13.6058 36.4325 14.6948 34.5386 16.3046 33.2129C15.263 31.8398 13.5585 30.9875 11.7592 30.9875Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M37.7077 29.5191C40.7672 29.5191 43.2474 27.0389 43.2474 23.9794C43.2474 20.9199 40.7672 18.4397 37.7077 18.4397C34.6482 18.4397 32.168 20.9199 32.168 23.9794C32.168 27.0389 34.6482 29.5191 37.7077 29.5191Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M45.6139 36.1958C45.2825 33.2129 42.7257 30.9875 39.7901 30.9875H35.6709C33.8243 30.9875 32.1671 31.8398 31.0781 33.2129C32.688 34.5386 33.8243 36.4325 34.061 38.6579L34.5819 42.9192C35.5762 43.0139 36.6652 43.0612 37.7068 43.0612C40.3583 43.0612 42.8677 42.7772 45.2351 42.3037C45.8506 42.1616 46.2294 41.5935 46.1821 40.9779L45.6139 36.1958Z"
      fill="currentColor"
    />
    <path
      d="M25.7275 33.7805H21.6083C18.6253 33.7805 16.1159 36.0059 15.7845 38.9414L15.2163 43.7236C15.1216 44.3391 15.5477 44.9546 16.1633 45.0493C18.5306 45.5701 21.0874 45.8069 23.6916 45.8069C26.343 45.8069 28.8525 45.5228 31.2199 45.0493C31.8354 44.9072 32.2142 44.3391 32.1668 43.7236L31.5986 38.9414C31.2199 36.0059 28.7104 33.7805 25.7275 33.7805Z"
      fill="currentColor"
    />
    <path
      d="M23.692 32.3091C26.7515 32.3091 29.2317 29.8289 29.2317 26.7694C29.2317 23.7099 26.7515 21.2297 23.692 21.2297C20.6325 21.2297 18.1523 23.7099 18.1523 26.7694C18.1523 29.8289 20.6325 32.3091 23.692 32.3091Z"
      fill="currentColor"
    />
    <path
      d="M18.5298 12.2381C18.8612 12.5222 19.0033 12.9957 18.9086 13.4218L18.009 17.1149C17.7722 18.1566 18.9086 18.9615 19.8082 18.3933L23.0752 16.4047C23.454 16.168 23.9274 16.168 24.3062 16.4047L27.5732 18.3933C28.4728 18.9615 29.6092 18.1093 29.3724 17.1149L28.4728 13.4218C28.3781 12.9957 28.5202 12.5222 28.8516 12.2381L31.7398 9.77604C32.5448 9.06582 32.1186 7.74008 31.077 7.69273L27.2891 7.3613C26.863 7.31395 26.4369 7.02986 26.2948 6.65108L24.8271 3.14734C24.4009 2.15304 23.0278 2.15304 22.6017 3.14734L21.1339 6.60373C20.9445 7.02986 20.5658 7.31395 20.1396 7.31395L16.3518 7.64538C15.3101 7.74008 14.884 9.06582 15.6889 9.72869L18.5298 12.2381Z"
      fill="currentColor"
    />
  </SvgIcon>
)
