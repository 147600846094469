import React, { FC } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { OfferSelectionCurrentLoanInfo } from '@common/types'

import { Text } from '../../reskin'

import { CarLoanInfoDropdown } from './car-loan-info-dropdown'
import { CarLoanInfo, showAccordionAt } from './offer-selections-components'

const CurrentLoanContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '16px',
  padding: theme.spacing(3, 4),
  background: theme.extension.colors.surface.cloudGray,
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

const CurrentLoanHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0),
  width: '100%',
}))

const CurrentLoanInfoContainer = styled(CurrentLoanHeader)(({ theme }) => ({
  [theme.breakpoints.down(showAccordionAt)]: {
    flexDirection: 'column',
  },
}))

const CarInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2, 2),
  background: theme.extension.colors.neutral[500],
  borderRadius: '4px',

  [theme.breakpoints.down(showAccordionAt)]: {
    width: '100%',
    alignItems: 'center',
  },
}))

const StyledBoxTextPayment = styled('div')(({ theme }) => ({
  display: 'flex',
  background: theme.palette.common.black,
  borderRadius: '4px',
  padding: theme.spacing(0.25, 1),
}))

export const CurrentLoanInfo: FC<OfferSelectionCurrentLoanInfo> = (props) => {
  const {
    carModel,
    estimatedRate,
    monthlyPayment,
    outstandingAmount,
    remainingTerm,
    bureau,
  } = props

  const theme = useTheme()
  const showAccordion = useMediaQuery(theme.breakpoints.down(showAccordionAt))

  return (
    <CurrentLoanContainer>
      <CurrentLoanHeader>
        {monthlyPayment ? (
          <Text
            use={['p', 'subheadings.24b']}
            color="text.softBlack"
            margin={0}
          >
            {`$${monthlyPayment.toLocaleString('en', {
              minimumFractionDigits: 2,
            })}/mo`}
          </Text>
        ) : (
          'N/A'
        )}

        <StyledBoxTextPayment>
          <Text use={['p', 'subheadings.14sb']} color="text.white" margin={0}>
            {'Your existing loan'}
          </Text>
        </StyledBoxTextPayment>
      </CurrentLoanHeader>

      <CurrentLoanInfoContainer>
        <CarInfoContainer>
          <Text
            use={['p', { xs: 'subheadings.16b', sm: 'subheadings.18b' }]}
            color="text.softBlack"
            margin={0}
          >
            {carModel}
          </Text>

          <Text
            use={['p', { xs: 'paragraphs.14', sm: 'paragraphs.16' }]}
            color="text.softBlack"
            margin={0}
          >
            {`As reported to ${bureau}`}
          </Text>
        </CarInfoContainer>

        {showAccordion ? (
          <CarLoanInfoDropdown
            outstandingAmount={outstandingAmount}
            remainingTerm={remainingTerm}
            estimatedRate={estimatedRate}
          />
        ) : (
          <CarLoanInfo
            outstandingAmount={outstandingAmount}
            remainingTerm={remainingTerm}
            estimatedRate={estimatedRate}
          />
        )}
      </CurrentLoanInfoContainer>
    </CurrentLoanContainer>
  )
}
