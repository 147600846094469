import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5209 14.1522C12.7391 13.0518 14.5303 12.5976 16.238 13.0706C18.728 13.7603 20.2626 16.2414 19.4451 18.6942C18.9079 20.3058 17.9077 21.282 16.576 21.6033C15.3373 21.9022 14 21.5847 12.8177 21.0936C10.4368 20.1046 8.08466 18.1558 7.01038 17.0815L8.42459 15.6673C9.40153 16.6442 11.5391 18.3968 13.5849 19.2466C14.616 19.6749 15.4763 19.8113 16.1069 19.6591C16.6444 19.5294 17.1851 19.1498 17.5477 18.0618C17.9584 16.8294 17.2318 15.4212 15.7042 14.9981C14.5674 14.6832 13.3985 15.0479 12.7024 15.7928L12.5 14.5M12.5 14.5L12.7024 15.7928C12.2611 16.2651 11.4213 16.5331 10.7075 16.0352C10.1992 15.6806 9.7164 15.2777 9.26528 14.8266L10.6795 13.4124C10.9474 13.6803 11.2285 13.9268 11.5209 14.1522"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L3.70711 21.7071C3.31658 22.0976 2.68342 22.0976 2.29289 21.7071C1.90237 21.3166 1.90237 20.6834 2.29289 20.2929L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.38387 4.65635C7.83661 3.83887 10.3177 5.37345 11.0074 7.86337C11.1068 8.22231 11.1652 8.58459 11.1853 8.94399C11.2163 9.49541 10.7943 9.9675 10.2429 9.99843C9.69148 10.0294 9.21939 9.60743 9.18846 9.05601C9.17618 8.83721 9.14065 8.61645 9.07993 8.39722M5.38387 4.65635C4.34239 5.00346 3.54442 5.54994 3.02661 6.3C2.50869 7.05024 2.33943 7.9051 2.37535 8.74441C2.44488 10.3691 3.28874 12.1073 4.19753 13.5366C4.49386 14.0026 5.1119 14.1402 5.57795 13.8439C6.044 13.5475 6.18158 12.9295 5.88525 12.4634C5.0225 11.1066 4.41975 9.73917 4.37352 8.6589C4.35156 8.1457 4.45714 7.7482 4.67251 7.43624C4.88799 7.1241 5.28178 6.79853 6.01623 6.55375C7.24857 6.14301 8.65682 6.86963 9.07993 8.39722"
      fill="currentColor"
    />
  </SvgIcon>
)
