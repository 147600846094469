import React, { FC, ReactNode, useRef } from 'react'
import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material'

import { consumerDashboardUrl } from '@src/config'
import { getLeadSource, LeadSource } from '@src/utils/storage'

import { Link as LinkBase } from '../../reskin'

import { Login } from './login'
import { NewLogo } from './logo'

const Container = styled(Grid)<NewHeaderProps>(({ config, theme }) => ({
  background: theme.palette.common.white,
  borderBottom:
    config === 'hero'
      ? 'none'
      : `solid 1px ${theme.extension.colors.divider.lightGray}`,
  padding: theme.spacing(1, 5),
  marginBottom: theme.spacing(config === 'hero' ? 0 : 5),

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(config === 'hero' ? 0 : 4),
    padding: theme.spacing(1, 2, 1, 2),
    borderBottom: 'none',
    boxShadow:
      config === 'hero'
        ? ''
        : `0 -5px 55px ${theme.extension.colors.divider.lightGray}`,
    position: 'sticky',
    zIndex: 2,
    top: 0,
  },
}))

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(0),
  },
}))

const Link = styled(LinkBase)({
  lineHeight: '0',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
})

const CobrandSpan = styled('span')({
  display: 'flex',
  alignItems: 'baseline',
})

const CobrandPlusSignSpan = styled('span')(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.common.black,
  margin: theme.spacing(0, 6, -2),
  padding: theme.spacing(2),
}))

const LoginItemLink = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  textTransform: 'none',
  textDecoration: 'none !important',
  '&:hover p': {
    background: 'none',
    color: `${theme.extension.colors.base.deepBlue} !important`,
    textDecorationLine: 'none',
  },
  padding: theme.spacing(0.5, 1, 0.5, 0.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}))

const LoginText = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  marginLeft: theme.spacing(1.25),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1),
  },
  display: 'block',
  margin: 'auto',
  color: theme.palette.common.black,
  ...theme.extension.typography.subheadings['16sb'],
}))

type NewHeaderProps = { config?: 'hero'; cobrand?: ReactNode }
export const NewHeader: FC<NewHeaderProps> = ({ config, ...props }) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const cobrand = getLeadSource() === LeadSource.CREDIT_KARMA
  const shouldShowCobrand = useMediaQuery(theme.breakpoints.up('md'))

  const menuRef = useRef<HTMLDivElement>(null)

  return (
    <Container
      config={config}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid item xs container>
        <Link href="https://www.caribou.com/" target="_blank">
          <LogoContainer>
            {isMobile ? <NewLogo mobile={true} /> : <NewLogo mobile={false} />}
            {cobrand && shouldShowCobrand && props.cobrand && (
              <CobrandSpan>
                <CobrandPlusSignSpan>{'+'}</CobrandPlusSignSpan>
                {props.cobrand}
              </CobrandSpan>
            )}
          </LogoContainer>
        </Link>
      </Grid>
      <Grid item xs container justifyContent="flex-end">
        <Grid ref={menuRef}>
          <LoginItemLink href={consumerDashboardUrl}>
            <Login />
            <LoginText>{'Log in'}</LoginText>
          </LoginItemLink>
        </Grid>
      </Grid>
    </Container>
  )
}
