import { AnalyticsBrowser } from '@segment/analytics-next'

import { segmentApiHost, segmentBrowserKey, segmentCdnUrl } from '@src/config'

const segmentProxyIsDefined =
  segmentApiHost !== undefined && segmentCdnUrl !== undefined

// Note that this is part of Next.js's suggest approach
// See: https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics-pages-router/lib/segment.ts
export const segmentAnalytics: AnalyticsBrowser = segmentProxyIsDefined
  ? AnalyticsBrowser.load(
      {
        writeKey: segmentBrowserKey,
        cdnURL: segmentCdnUrl,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: segmentApiHost,
            protocol: 'https',
          },
        },
      },
    )
  : AnalyticsBrowser.load({
      writeKey: segmentBrowserKey,
    })
