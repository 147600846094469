import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SpeedIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M19.0453 4.93177C17.1483 3.04483 14.6386 2 11.9805 2C9.32631 2 6.82436 3.04873 4.9313 4.93567C3.04215 6.82261 2 9.33333 2 11.9844C2 14.6394 3.03825 17.1462 4.9274 19.0448C6.82436 20.9474 9.32631 22 11.9805 22C13.3232 22 14.6269 21.7271 15.8642 21.2008C17.0546 20.694 18.1241 19.961 19.0492 19.0409C19.9742 18.1209 20.6963 17.0487 21.2037 15.8596C21.7307 14.6238 22 13.3177 22 11.9805C22 9.32554 20.95 6.82261 19.0453 4.93177ZM17.9446 17.9376C16.3365 19.5439 14.2209 20.4289 11.9805 20.4289C7.33958 20.4289 3.56128 16.6394 3.56128 11.9805C3.56128 9.74659 4.4395 7.63743 6.03591 6.04288C7.63232 4.44834 9.74005 3.57115 11.9805 3.57115C16.6448 3.57115 20.4387 7.34113 20.4387 11.9805C20.4387 14.2144 19.5527 16.3314 17.9446 17.9376Z"
      fill="currentColor"
    />
    <path
      d="M12.8006 9.9697V5.23676C12.8006 4.80791 12.4493 4.45703 12.02 4.45703C11.5906 4.45703 11.2393 4.80791 11.2393 5.23676V9.95411C10.4196 10.2621 9.83807 11.0496 9.83807 11.9736C9.83807 13.1627 10.8061 14.1334 12.0004 14.1334C13.1948 14.1334 14.1628 13.1666 14.1628 11.9736C14.1628 11.0652 13.5969 10.2894 12.8006 9.9697ZM12.0004 12.574C11.6687 12.574 11.3994 12.305 11.3994 11.9736C11.3994 11.6422 11.6687 11.3732 12.0004 11.3732C12.3322 11.3732 12.6015 11.6422 12.6015 11.9736C12.6015 12.305 12.3322 12.574 12.0004 12.574ZM6.67258 11.2017H5.85291C5.42355 11.2017 5.07227 11.5525 5.07227 11.9814C5.07227 12.4102 5.42355 12.7611 5.85291 12.7611H6.67258C7.10193 12.7611 7.45322 12.4102 7.45322 11.9814C7.45322 11.5525 7.10193 11.2017 6.67258 11.2017ZM17.0551 11.2017C16.6257 11.2017 16.2744 11.5525 16.2744 11.9814C16.2744 12.4102 16.6257 12.7611 17.0551 12.7611H17.8748C18.3041 12.7611 18.6554 12.4102 18.6554 11.9814C18.6554 11.5525 18.3041 11.2017 17.8748 11.2017H17.0551ZM16.6921 6.72994C16.3603 6.45313 15.8685 6.49992 15.5914 6.8313L15.0684 7.46288C14.7912 7.79426 14.8381 8.28549 15.1698 8.56229C15.3143 8.68315 15.4938 8.74163 15.6694 8.74163C15.8919 8.74163 16.1183 8.64417 16.2705 8.46093L16.7936 7.82935C17.0707 7.49797 17.0239 7.00674 16.6921 6.72994ZM8.22995 6.87419C7.95283 6.5428 7.46102 6.49602 7.12925 6.77282C6.79748 7.04962 6.75064 7.54085 7.02777 7.87224L7.5508 8.50382C7.70692 8.69095 7.92941 8.78452 8.15189 8.78452C8.32753 8.78452 8.50318 8.72604 8.6515 8.60518C8.98327 8.32838 9.03011 7.83715 8.75298 7.50576L8.22995 6.87419Z"
      fill="currentColor"
    />
  </SvgIcon>
)
