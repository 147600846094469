import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FeeDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6571 12.0331C29.0946 9.74338 25.2212 9.74338 22.6587 12.0331L22.2507 12.3976C21.6805 12.9071 20.9542 13.208 20.1907 13.2509L19.6444 13.2816C16.2134 13.4745 13.4745 16.2134 13.2816 19.6444L13.2509 20.1907C13.208 20.9542 12.9071 21.6805 12.3976 22.2507L12.0331 22.6587C9.74338 25.2212 9.74338 29.0946 12.0331 31.6571L12.3976 32.0651C12.9071 32.6353 13.208 33.3616 13.2509 34.1251L13.2816 34.6714C13.4745 38.1024 16.2134 40.8413 19.6444 41.0342L20.1907 41.0649C20.9542 41.1078 21.6805 41.4087 22.2507 41.9182L22.6587 42.2827C25.2212 44.5724 29.0946 44.5724 31.6571 42.2827L32.0651 41.9182C32.6353 41.4087 33.3616 41.1078 34.1251 41.0649L34.6714 41.0342C38.1024 40.8413 40.8413 38.1024 41.0342 34.6714L41.0649 34.1251C41.1078 33.3616 41.4087 32.6353 41.9182 32.0651L42.2828 31.6571C44.5724 29.0946 44.5724 25.2212 42.2827 22.6587L41.9182 22.2507C41.4087 21.6805 41.1078 20.9542 41.0649 20.1907L41.0342 19.6444C40.8413 16.2134 38.1024 13.4745 34.6714 13.2816L34.1251 13.2509C33.3616 13.208 32.6353 12.9071 32.0651 12.3976L31.6571 12.0331ZM33.4163 20.8998C34.0755 21.559 34.0755 22.6279 33.4163 23.2872L23.2876 33.4159C22.6283 34.0751 21.5595 34.0751 20.9002 33.4159C20.241 32.7566 20.241 31.6878 20.9002 31.0285L31.0289 20.8998C31.6882 20.2405 32.757 20.2405 33.4163 20.8998ZM33.9107 31.3781C33.9107 32.7766 32.777 33.9103 31.3785 33.9103C29.9801 33.9103 28.8464 32.7766 28.8464 31.3781C28.8464 29.9796 29.9801 28.8459 31.3785 28.8459C32.777 28.8459 33.9107 29.9796 33.9107 31.3781ZM22.9379 25.4697C24.3364 25.4697 25.4701 24.336 25.4701 22.9375C25.4701 21.5391 24.3364 20.4054 22.9379 20.4054C21.5395 20.4054 20.4058 21.5391 20.4058 22.9375C20.4058 24.336 21.5395 25.4697 22.9379 25.4697Z"
      fill="currentColor"
    />
    <ellipse cx="18.7369" cy="20.3158" rx="10.5263" ry="11.0526" fill="white" />
    <path
      d="M36.5262 15.8432L35.967 16.3429L36.5262 15.8432L36.1617 15.4352C35.7653 14.9917 35.5313 14.4267 35.4979 13.8328L35.4672 13.2865C35.2529 9.47444 32.2098 6.43134 28.3977 6.21701L27.8514 6.1863C27.2575 6.15291 26.6926 5.91889 26.249 5.52255L25.841 5.158C22.9939 2.614 18.6903 2.614 15.8432 5.158L15.4352 5.52255C14.9917 5.91889 14.4267 6.15291 13.8328 6.1863L13.2865 6.21701C9.47444 6.43134 6.43134 9.47444 6.21701 13.2865L6.1863 13.8328C6.15291 14.4267 5.91889 14.9917 5.52255 15.4352L5.158 15.8432C2.614 18.6903 2.614 22.9939 5.158 25.841L5.52255 26.249C5.91889 26.6926 6.15291 27.2575 6.1863 27.8514L6.21701 28.3977C6.43134 32.2098 9.47444 35.2529 13.2865 35.4672L13.8328 35.4979C14.4267 35.5313 14.9917 35.7653 15.4352 36.1617L15.8432 36.5262C18.6903 39.0702 22.9939 39.0702 25.841 36.5262L26.249 36.1617C26.6925 35.7653 27.2575 35.5313 27.8514 35.4979L28.3977 35.4672C32.2098 35.2529 35.2529 32.2098 35.4672 28.3977L35.4979 27.8514C35.5313 27.2575 35.7653 26.6925 36.1617 26.249L36.5262 25.841C39.0702 22.9939 39.0702 18.6903 36.5262 15.8432ZM26.5702 15.1143C26.9365 15.4807 26.9365 16.0747 26.5702 16.441L16.4414 26.5697C16.0751 26.9361 15.4811 26.9361 15.1147 26.5697C14.7484 26.2034 14.7484 25.6094 15.1147 25.243L25.2435 15.1143C25.6098 14.748 26.2038 14.748 26.5702 15.1143ZM26.8449 25.0623C26.8449 26.0466 26.047 26.8445 25.0627 26.8445C24.0785 26.8445 23.2806 26.0466 23.2806 25.0623C23.2806 24.0781 24.0785 23.2802 25.0627 23.2802C26.047 23.2802 26.8449 24.0781 26.8449 25.0623ZM18.4043 16.6217C18.4043 17.606 17.6064 18.4039 16.6221 18.4039C15.6379 18.4039 14.84 17.606 14.84 16.6217C14.84 15.6375 15.6379 14.8396 16.6221 14.8396C17.6064 14.8396 18.4043 15.6375 18.4043 16.6217Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="1.5"
    />
  </SvgIcon>
)
