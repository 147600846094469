import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const QuoteSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M9.40962 18.1927C9.31752 18.6617 8.90636 19 8.42836 19H1.50866C0.792802 19 0.308802 18.2698 0.587633 17.6105L5.66252 5.61049C5.81902 5.24045 6.18178 5 6.58355 5H10.7846C11.4142 5 11.8871 5.57485 11.7658 6.19267L9.40962 18.1927ZM21.4096 18.1927C21.3175 18.6617 20.9064 19 20.4284 19H13.5615C12.8457 19 12.3617 18.2698 12.6405 17.6105L17.7154 5.61049C17.8719 5.24045 18.2346 5 18.6364 5H22.7846C23.4142 5 23.8871 5.57485 23.7658 6.19267L21.4096 18.1927Z"
      fill="currentColor"
    />
  </SvgIcon>
)
