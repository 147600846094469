import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { CitizenshipKeys, ERelationship } from '@common/types'
import { customerAdapters } from '@src/api'
import { getLeadSource, LeadSource } from '@src/utils/storage'

import { useConst, useResolver } from '../../../hooks'
import { DateUtils } from '../../../utils'
import { PersonalInfoForm, PersonalInfoSchema } from '../../schemas'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePersonalInfoForm = () => {
  const schema = useConst(PersonalInfoSchema)
  const form = useForm<PersonalInfoForm>({
    mode: 'onTouched',
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      confirmEmailAddress: '',
      dob: '',
      cellphone: '',
      citizenship: '' as CitizenshipKeys,
      relationship: '' as ERelationship,
      cofirstName: '',
      colastName: '',
      coemailAddress: '',
      coconfirmEmailAddress: '',
      codob: '',
      cocellphone: '',
      cocitizenship: '' as CitizenshipKeys,
      cosigner: false,
    },
    resolver: useResolver(schema),
  })

  const { query } = useRouter()

  const submitWithCoborrower = async (
    values: PersonalInfoForm,
  ): Promise<customerAdapters.CreateCustomerWithCoborrowerResponse> => {
    const submitOptions = {
      queryParams: new URLSearchParams(
        query as Record<string, string>,
      ).toString(),
      leadSource: getLeadSource() || LeadSource['MR_CONSUMER_SITE'],
    }

    return customerAdapters.submitCustomerWithCoborrower(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailAddress,
        phoneNumber: values.cellphone,
        birthdate: DateUtils.UsToIso(values.dob),
        citizenship: values.citizenship,
      },
      {
        firstName: values.cofirstName,
        lastName: values.colastName,
        email: values.coemailAddress,
        phoneNumber: values.cocellphone,
        birthdate: DateUtils.UsToIso(values.codob),
        citizenship: values.cocitizenship,
      },
      values.relationship,
      submitOptions,
    )
  }

  const submit = async (
    values: PersonalInfoForm,
  ): Promise<customerAdapters.CreateCustomerResponse> => {
    const submitOptions = {
      queryParams: new URLSearchParams(
        query as Record<string, string>,
      ).toString(),
      leadSource: getLeadSource() || LeadSource['MR_CONSUMER_SITE'],
    }

    return customerAdapters.submitCustomer(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailAddress,
        phoneNumber: values.cellphone,
        birthdate: DateUtils.UsToIso(values.dob),
        citizenship: values.citizenship,
      },
      submitOptions,
    )
  }

  return {
    ...form,
    submit,
    submitWithCoborrower,
  }
}
