import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SendSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.61 2.6455C20.1949 2.19023 21.6569 3.65225 21.2016 5.23707L17.1785 19.2417C16.5079 21.5761 13.3904 22.0197 12.1096 19.9629L10.3339 17.1113C9.84268 16.3226 9.96161 15.2974 10.6207 14.6383L14.4111 10.8479C14.8023 10.4567 14.8033 9.82358 14.4135 9.43375C14.0237 9.04391 13.3905 9.04499 12.9994 9.43615L9.20907 13.2265C8.54993 13.8857 7.52477 14.0046 6.73602 13.5134L3.88419 11.7375C1.82743 10.4567 2.27098 7.33919 4.60539 6.66859L18.61 2.6455Z"
      fill="currentColor"
    />
  </SvgIcon>
)
