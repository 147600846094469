import { useEffect, useState } from 'react'

import {
  PartnerAuthToken,
  PartnerName,
  PartnerRewardsInfo,
} from '@common/types'

import { getCustomerRewardsInfo } from '../api'

type RewardsInfoHook = {
  rewardsInfo: PartnerRewardsInfo
  rewardsInfoLoaded: boolean
}

export const useRewardsInfo = (
  partner: PartnerName,
  token: PartnerAuthToken,
): RewardsInfoHook => {
  const [rewardsInfo, setRewardsInfo] = useState<PartnerRewardsInfo>()
  const [rewardsInfoLoaded, setRewardsInfoLoaded] = useState(false)

  const fetchData = async (partner: string, token: string): Promise<void> => {
    try {
      setRewardsInfoLoaded(false)

      const rewardsInfoData = await getCustomerRewardsInfo(partner, token)

      setRewardsInfo(rewardsInfoData)
    } finally {
      setRewardsInfoLoaded(true)
    }
  }

  useEffect(() => {
    if (!partner || !token) {
      setRewardsInfoLoaded(true)
      return
    }
    void fetchData(partner, token)
  }, [partner, token])

  return {
    rewardsInfo,
    rewardsInfoLoaded,
  }
}
