import { useCallback } from 'react'
import { UseControllerReturn } from 'react-hook-form'

import {
  CurrencyTextField,
  DateTextField as DateTextFieldBase,
  NumberFormatInput as NumberFormatInputBase,
  PhoneNumberField as PhoneNumberFieldBase,
  SelectField as SelectBase,
  TextField as TextFieldBase,
} from '@common/react-lib-consumer-pres'
import { SelectFieldProps, TextFieldProps } from '@common/types'

import { UseMapProps, withController } from './controlled-fields'

type MappedTextFieldProps = Pick<
  TextFieldProps,
  'name' | 'error' | 'label' | 'placeholder' | 'onBlur' | 'onChange'
>

const useDefaultMapProps = <T extends MappedTextFieldProps>(
  { field, fieldState: { error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: MappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [field.onBlur, props.onChange],
  )

  const mergedOnBlur: MappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onChange],
  )

  return {
    ...props,
    ...field,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
    error: error?.message,
  }
}

export const TextField = withController(TextFieldBase, useDefaultMapProps)

export const NumberFormatInput = withController(
  NumberFormatInputBase,
  useDefaultMapProps,
)

export const CurrencyFormatInput = withController(
  CurrencyTextField,
  useDefaultMapProps,
)

export const Select = withController(
  SelectBase,
  useDefaultMapProps as unknown as UseMapProps<SelectFieldProps>,
)

export const PhoneNumberField = withController(
  PhoneNumberFieldBase,
  useDefaultMapProps,
)

export const DateTextField = withController(
  DateTextFieldBase,
  useDefaultMapProps,
)
