import React, { FunctionComponent } from 'react'
import Image from 'next/legacy/image'

import { Url } from '@common/lib-types'

export const Rating: FunctionComponent = () => {
  const url = '/assets/images/norton-seal.gif' as Url

  return <Image src={url} alt={'Rating'} width="130" height="88" />
}
