import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ScanIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3C14 2.44772 14.4477 2 15 2H16C18.7614 2 21 4.23858 21 7V8C21 8.55228 20.5523 9 20 9C19.4477 9 19 8.55228 19 8V7C19 5.34315 17.6569 4 16 4H15C14.4477 4 14 3.55228 14 3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C20.5523 12 21 12.4477 21 13V17C21 19.7614 18.7614 22 16 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H16C17.6569 20 19 18.6569 19 17V13C19 12.4477 19.4477 12 20 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C3 4.23858 5.23858 2 8 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4H8C6.34315 4 5 5.34315 5 7V8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8V7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4.55228 12 5 12.4477 5 13V17C5 18.6569 6.34315 20 8 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H8C5.23858 22 3 19.7614 3 17V13C3 12.4477 3.44772 12 4 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13C1 12.4477 1.44772 12 2 12L22 12C22.5523 12 23 12.4477 23 13C23 13.5523 22.5523 14 22 14L2 14C1.44772 14 1 13.5523 1 13Z"
      fill="currentColor"
    />
  </SvgIcon>
)
