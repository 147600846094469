import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import memoize from 'lodash/memoize'
import values from 'lodash/values'
import { DateTime as LuxonDateTime, DurationUnits } from 'luxon'
import {
  BaseSchema,
  bool,
  mixed,
  object,
  SchemaOf,
  string,
  StringSchema,
} from 'yup'

import {
  Citizenship as CitizenshipValues,
  CitizenshipKeys,
  EmploymentStatus as EmploymentStatusValues,
  EmploymentStatusKeys,
  ERelationship,
  ResidenceStatus as EResidenceStatus,
  ResidenceStatusKeys,
  State as EState,
  StateKeys,
} from '@common/types'
import { getCustomerEmailExists } from '@src/api'
import { Logger } from '@src/hooks'

// import { useLogger } from '@src/hooks'
import { emailIsInUseMessage } from './personal-info'
import { EErrorType } from './tests'
import { sequence } from './yup'

const toLuxonDate = (format: string) => (val: string) =>
  LuxonDateTime.fromFormat(val ?? '', format)
const personalInfoDateToLuxonDate = toLuxonDate('MM/dd/yyyy')
const compareTodayToLuxonDate =
  (units: DurationUnits) => (start: LuxonDateTime) =>
    LuxonDateTime.now().diff(start, units)

const ifRequired = (formValue: string) => {
  return <T>(schema: BaseSchema<T>) =>
    mixed<T>().when(formValue, { is: false, then: schema })
}

const ifSameAddress = ifRequired('sameAddress')

const Name = string().required()
const Email = string().required().email('Please enter a valid email address')

const getAndLogEmailExists = async (email: string, logger: Logger) => {
  const { emailExists, accountCreated } = await getCustomerEmailExists({
    email,
  })
  if (emailExists) {
    logger.error(
      new Error(
        'Unable to create coborrower customer: Email has already been taken',
      ),
    )
  }
  return { emailExists, accountCreated }
}

const CustomerEmailAddress = (
  logger: Logger,
  initialEmail?: string,
): StringSchema => {
  const getEmailExists = memoize(getAndLogEmailExists)
  return sequence(
    'email',
    Email,
    string().test({
      name: 'Duplicate email',
      message: emailIsInUseMessage,
      test: async (email, ctx) => {
        if (initialEmail && email === initialEmail) {
          return true
        }

        const { emailExists, accountCreated } = await getEmailExists(
          email,
          logger,
        )

        return accountCreated
          ? ctx.createError({
              message:
                "Looks like you've been here before! Sign in to continue with your existing account, or use a different email to start a new application.",
              type: EErrorType.accountCreated,
            })
          : emailExists
          ? ctx.createError({
              message:
                "Looks like you've been here before! Sign in to continue with your existing account, or use a different email to start a new application.",
              type: EErrorType.emailExists,
            })
          : true
      },
    }),
  )
}

const ConfirmEmail = <T extends {}>(emailFieldName: keyof T): StringSchema =>
  Email.concat(
    string().test({
      name: 'validate confirm email',
      message: 'Email addresses should match',
      test: (value, context) => value === context.parent[emailFieldName],
    }),
  )

const Citizenship = string().oneOf(
  values(CitizenshipValues),
  'This field is required',
) as StringSchema<CitizenshipKeys>

const Dob = string()
  .required()
  .test({
    name: 'Valid date',
    message: 'Please enter a valid date of birth',
    test: flow(personalInfoDateToLuxonDate, get('isValid')),
  })
  .test({
    name: 'Over 18',
    message: 'Must be over 18 and younger than 125',
    test: flow(
      personalInfoDateToLuxonDate,
      compareTodayToLuxonDate('years'),
      get('years'),
      (years) => years >= 18 && years <= 125,
    ),
  })

const Cellphone = string()
  .required()
  .transform((val: string) => val.replaceAll(/\D/g, ''))
  .min(10, 'Please enter a valid phone number')

const Relationship = string()
  .required()
  .oneOf(
    values(ERelationship),
    'This field is required',
  ) as StringSchema<ERelationship>
const ResidenceStatus = string()
  .required()
  .oneOf(
    values(EResidenceStatus),
    'This field is required',
  ) as StringSchema<ResidenceStatusKeys>
const Address = string().required()
const City = string().required()
const Zip = string().required()
const SameAddress = bool().required()
const LivedInAddress = object({
  months: string().required(),
  years: string().required(),
})
const EmploymentStatus = string()
  .required()
  .oneOf(
    values(EmploymentStatusValues),
    'This field is required',
  ) as StringSchema<EmploymentStatusKeys>
const MonthlyPayment = string().required()
const YearlyIncome = string().required()
const State = string()
  .required()
  .oneOf(values(EState), 'This field is required') as StringSchema<StateKeys>

export type CoborrowerInformationForm = {
  sameAddress: boolean
  firstName: string
  lastName: string
  emailAddress: string
  confirmEmailAddress: string
  phoneNumber: string
  citizenship: CitizenshipKeys
  dob: string
  relationship: ERelationship
  address: string
  apartment: string
  city: string
  state: StateKeys
  zip: string
  livedInAddress: {
    years: string
    months: string
  }
  rentOrOwn: ResidenceStatusKeys
  monthlyPayment: string
  employmentStatus: EmploymentStatusKeys
  yearlyIncome: string
}
export const CoborrowerInfoSchema = (
  logger: Logger,
): SchemaOf<CoborrowerInformationForm> =>
  object({
    sameAddress: SameAddress,
    firstName: Name,
    lastName: Name,
    emailAddress: CustomerEmailAddress(logger),
    phoneNumber: Cellphone,
    confirmEmailAddress:
      ConfirmEmail<CoborrowerInformationForm>('emailAddress'),
    citizenship: Citizenship,
    dob: Dob,
    relationship: Relationship,
    address: ifSameAddress(Address),
    apartment: string(),
    city: ifSameAddress(City),
    state: ifSameAddress(State),
    zip: ifSameAddress(Zip),
    livedInAddress: object().when('sameAddress', {
      is: false,
      then: LivedInAddress,
    }) as typeof LivedInAddress,
    rentOrOwn: ifSameAddress(ResidenceStatus),
    monthlyPayment: ifSameAddress(MonthlyPayment),
    employmentStatus: EmploymentStatus,
    yearlyIncome: YearlyIncome,
  })
