import React, { FunctionComponent } from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import { default as NextLink } from 'next/link'

import { makeStyles, Theme } from '@modules/core-ui'

import { isNextLink } from './is-next-link'

const useButtonStyles = makeStyles((theme: Theme) => ({
  root:
    //@css
    {
      borderRadius: '3rem',
      height: '3rem',
      width: '11.125rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...theme.select.fontScale(1),
      textAlign: 'center',
    },
  label:
    //@css
    {
      color: theme.palette.common.black,
    },
  contained:
    //@css
    {
      color: theme.palette.common.black,
      width: 'fit-content',
      '&:active': {
        color: theme.palette.common.white,
      },
    },

  outlined:
    //@css
    {
      background: theme.palette.common.white,
      border: `0.125rem solid`,
      color: theme.palette.text.primary,
      '&:active': {
        color: theme.palette.secondary.light,
      },
    },
  sizeLarge:
    //@css
    {
      borderRadius: '4rem',
      height: '4rem',
      minWidth: '14.875rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...theme.select.fontScale(3),
      textAlign: 'center',
    },
  sizeSmall:
    //@css
    {
      borderRadius: '2.25rem',
      height: '2.25rem',
      minWidth: '9.875rem',
      ...theme.select.fontScale(1),
      textAlign: 'center',
    },
  disabled:
    //@css
    {
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderColor: theme.select.colorByCoords({
        paletteName: 'grey',
        colorName: 'light',
      }),
      '&& color': theme.palette.grey[100],
    },
}))

type ButtonProps = Readonly<{
  /**
   * Explicitly set a link to external.  Useful for relative
   * links that need a page load (e.g., downloads)
   */
  external?: boolean

  /**
   * An href to which this button should navigate when clicked.
   *
   * Will cause the button to be wrapped in a next.js Link component if set.
   */
  href?: MuiButtonProps['href']

  /**
   * For next.js dynamic route links.
   * See https://nextjs.org/docs#dynamic-routing
   */
  as?: MuiButtonProps['href']

  onClick?: MuiButtonProps['onClick']

  children?: MuiButtonProps['children']

  endIcon?: MuiButtonProps['endIcon']

  variant?: MuiButtonProps['variant']

  color?: MuiButtonProps['color']

  classes?: MuiButtonProps['classes']

  className?: MuiButtonProps['className']

  disabled?: MuiButtonProps['disabled']

  size?: MuiButtonProps['size']

  name?: MuiButtonProps['name']

  sx?: MuiButtonProps['sx']
}>

/**
 *
 * Clickable button that supports HREF navigation and/or onclick handling.
 *
 * Supports each variant and size from mui with the official MR styles.
 *
 * @param props See https://material-ui.com/api/button/
 * @deprecated Avoid if you can. Prefer import from @mui/material
 */
export const Button: FunctionComponent<ButtonProps> = ({
  as,
  external,
  href,
  classes,
  ...props
}) => {
  const buttonClasses = useButtonStyles(props)

  return isNextLink(href, { as, external }) ? (
    <NextLink {...{ href, as }} legacyBehavior>
      <MuiButton {...{ classes, ...props }} />
    </NextLink>
  ) : (
    <MuiButton
      {...{
        classes: { ...buttonClasses, ...classes },
        href,
        ...props,
      }}
      className={props.className}
    />
  )
}
