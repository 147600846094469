import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PoundIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4327 3.6055C10.9268 3.66039 11.2827 4.10537 11.2278 4.59939L10.7833 8.6H13.9723L14.4389 4.40061C14.4937 3.9066 14.9387 3.55061 15.4327 3.6055C15.9268 3.66039 16.2827 4.10537 16.2278 4.59939L15.7833 8.6H18.6667C19.1637 8.6 19.5667 9.00294 19.5667 9.5C19.5667 9.99706 19.1637 10.4 18.6667 10.4H15.5833L15.2278 13.6H18.6667C19.1637 13.6 19.5667 14.0029 19.5667 14.5C19.5667 14.9971 19.1637 15.4 18.6667 15.4H15.0278L14.5612 19.5994C14.5063 20.0934 14.0613 20.4494 13.5673 20.3945C13.0733 20.3396 12.7173 19.8946 12.7722 19.4006L13.2167 15.4H10.0278L9.56118 19.5994C9.50629 20.0934 9.06131 20.4494 8.56729 20.3945C8.07328 20.3396 7.71729 19.8946 7.77219 19.4006L8.2167 15.4H5.33335C4.83629 15.4 4.43335 14.9971 4.43335 14.5C4.43335 14.0029 4.83629 13.6 5.33335 13.6H8.4167L8.77225 10.4H5.33335C4.83629 10.4 4.43335 9.99706 4.43335 9.5C4.43335 9.00294 4.83629 8.6 5.33335 8.6H8.97225L9.43885 4.40061C9.49374 3.9066 9.93872 3.55061 10.4327 3.6055ZM13.4167 13.6L13.7723 10.4H10.5833L10.2278 13.6H13.4167Z"
      fill="currentColor"
    />
  </SvgIcon>
)
