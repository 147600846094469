/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerReturn,
} from 'react-hook-form'
import omit from 'lodash/omit'

import { SimpleFunctionComponent } from '@common/lib-types'
import {
  CurrencyTextField,
  DateTextField as DateTextFieldBase,
  DateTextFieldProps,
  NumberFormatInput as NumberFormatInputBase,
  PhoneNumberTextField as PhoneNumberTextFieldBase,
  RadioGroup as RadioGroupBase,
  RadioProps,
  Select as SelectBase,
  SelectProps,
  SSNTextField,
  TextField as TextFieldBase,
  TextFieldProps,
  TextMaskField as TextMaskFieldBase,
} from '@common/react-lib-base'
import {
  SSNHashedField as SSNHashedFieldBase,
  TermsCheckBox,
  TextField as NewTextFieldBase,
} from '@common/react-lib-consumer-pres'
import {
  SSNHashedFieldProps,
  TextFieldProps as NewTextFieldProps,
} from '@common/types'

type OverrideProps<T extends {}, O extends {}> = Omit<T, keyof O> & O

export type UseMapProps<TProps> = (
  controller: UseControllerReturn,
  props: Omit<TProps, 'name'>,
) => TProps

type ControlledComponent<TOldProps> = SimpleFunctionComponent<
  OverrideProps<TOldProps, { name: Path<FieldValues>; control: Control }>
>

export const withController =
  <TProps extends {}>(
    Field: SimpleFunctionComponent<TProps>,
    useMapProps: UseMapProps<TProps>,
  ): ControlledComponent<TProps> =>
  <TFieldValues extends FieldValues = FieldValues>({
    name,
    control,
    ...props
  }: OverrideProps<
    TProps,
    { name: Path<TFieldValues>; control: Control<TFieldValues> }
  >) => {
    const controller = useController({
      name,
      control,
    }) as unknown as UseControllerReturn
    const mappedProps = useMapProps(
      controller,
      props as unknown as Omit<TProps, 'name'>,
    )

    return <Field {...mappedProps} />
  }

type NewMappedTextFieldProps = Pick<
  NewTextFieldProps,
  'name' | 'error' | 'value' | 'onChange' | 'onBlur' | 'label'
>

export const useNewDefaultMapProps = <T extends NewMappedTextFieldProps>(
  { field, fieldState: { invalid, error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: NewMappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [props.onChange, field.onChange],
  )

  const mergedOnBlur: NewMappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onBlur],
  )

  return {
    ...props,
    ...field,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
    error: error?.message ?? invalid,
  }
}

type MappedTextFieldProps = Pick<
  TextFieldProps,
  'name' | 'error' | 'helperText' | 'value' | 'onChange' | 'onBlur' | 'label'
>

export const useDefaultMapProps = <T extends MappedTextFieldProps>(
  { field, fieldState: { invalid, error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: MappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [field.onChange, props.onChange],
  )

  const mergedOnBlur: MappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onBlur],
  )

  return {
    ...props,
    ...field,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
    error: invalid,
    helperText: error?.message,
  }
}

export const useCheckboxMapProps = <T extends MappedTextFieldProps>(
  { field, fieldState: { error } }: UseControllerReturn,
  props: T,
): T => {
  const mergedOnChange: MappedTextFieldProps['onChange'] = useCallback(
    (e) => {
      field.onChange(e)
      props.onChange?.(e)
    },
    [field.onChange, props.onChange],
  )

  const mergedOnBlur: MappedTextFieldProps['onBlur'] = useCallback(
    (e) => {
      field.onBlur()
      props.onBlur?.(e)
    },
    [field.onBlur, props.onBlur],
  )

  return {
    ...props,
    ...field,
    errorMessage: error?.message,
    onChange: mergedOnChange,
    onBlur: mergedOnBlur,
  }
}

/**
 * Passing ref causing some formatting issues. This is a known issue and doesn't affect us right now.
 * It might affect us when we start scrolling to invalid fields on submit
 **/
export const useMapPropsNoRef = <T extends MappedTextFieldProps>(
  controller: UseControllerReturn,
  props: T,
): T => omit(useDefaultMapProps(controller, props), ['ref']) as T

const useDateFieldMapProps: UseMapProps<DateTextFieldProps> = (
  controller,
  props,
) => {
  const defaultProps = omit(useDefaultMapProps(controller, props), ['onChange'])
  return {
    ...props,
    ...defaultProps,
    onValueChange: (numbers) => {
      controller.field.onChange(numbers.formattedValue)
      props.onValueChange?.(numbers)
    },
  }
}

const useSsnFieldMapProps: UseMapProps<SSNHashedFieldProps> = (
  controller,
  props,
) => {
  const defaultProps = omit(useDefaultMapProps(controller, props), ['onChange'])
  return {
    ...props,
    ...defaultProps,
    onValueChange: (numbers) => {
      controller.field.onChange(numbers.value)
      props.onValueChange?.(numbers)
    },
  }
}

const useSelectMapProps: UseMapProps<SelectProps> = (controller, props) => {
  return {
    // adding defaults
    displayPropertyName: 'displayName',
    valuePropertyName: 'value',
    ...useDefaultMapProps(controller, props as any),
    // fixing TS complaints
    menuItems: props.menuItems,
  }
}

const useRadioGroupProps: UseMapProps<RadioProps> = (controller, props) =>
  omit(useDefaultMapProps(controller, props as any), ['onBlur']) as any

export const NewTextField = withController(
  NewTextFieldBase,
  useNewDefaultMapProps,
)

export const TextField = withController(TextFieldBase, useDefaultMapProps)

export const NumberFormatInput = withController(
  NumberFormatInputBase,
  useDefaultMapProps,
)

export const Select = withController(SelectBase, useSelectMapProps)

export const PhoneNumberTextField = withController(
  PhoneNumberTextFieldBase,
  useDefaultMapProps,
)
export const DateTextField = withController(
  DateTextFieldBase,
  useDateFieldMapProps,
)
export const SSNHashedField = withController(
  SSNHashedFieldBase,
  useSsnFieldMapProps,
)
export const SSNNormalTextField = withController(
  SSNTextField,
  useDateFieldMapProps,
)
export const TextMaskField = withController(
  TextMaskFieldBase,
  useDefaultMapProps,
)
export const CurrencyFieldOldBase = withController(
  CurrencyTextField,
  useDefaultMapProps,
)

export const RadioGroup = withController(RadioGroupBase, useRadioGroupProps)

export const CheckBoxField = withController(TermsCheckBox, useCheckboxMapProps)
