import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DeleteSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54405 4C8.25225 4 7.03997 4.62386 6.28912 5.67505L3.01681 10.2563C2.27174 11.2994 2.27174 12.7006 3.01681 13.7437L6.28912 18.325C7.03997 19.3761 8.25225 20 9.54405 20H18.0002C20.2093 20 22.0002 18.2091 22.0002 16V8C22.0002 5.79086 20.2093 4 18.0002 4H9.54405ZM10.2929 8.29289C10.6834 7.90237 11.3166 7.90237 11.7071 8.29289L14 10.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L15.4142 12L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3166 16.0976 16.6834 16.0976 16.2929 15.7071L14 13.4142L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071C9.90237 15.3166 9.90237 14.6834 10.2929 14.2929L12.5858 12L10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
