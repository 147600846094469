import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const StarIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0341 3.09718C10.9258 1.63427 13.0742 1.63427 13.9659 3.09719L16.131 6.64885L20.2215 7.58413C21.9063 7.96937 22.5702 9.98647 21.4365 11.2758L18.684 14.4062L19.0471 18.5359C19.1966 20.2369 17.4586 21.4835 15.8661 20.8175L12 19.2005L8.13385 20.8175C6.5414 21.4835 4.80339 20.2369 4.95292 18.5359L5.31596 14.4062L2.56352 11.2758C1.4298 9.98647 2.09366 7.96937 3.77852 7.58413L7.86904 6.64885L10.0341 3.09718ZM12 4.2652L9.83496 7.81686C9.51459 8.34242 8.99203 8.71724 8.38674 8.85564L4.29622 9.79092L7.04867 12.9213C7.45596 13.3845 7.65556 13.9909 7.60184 14.602L7.2388 18.7317L11.1049 17.1147C11.677 16.8754 12.323 16.8754 12.8951 17.1147L16.7612 18.7317L16.3982 14.602C16.3444 13.9909 16.544 13.3845 16.9513 12.9213L19.7038 9.79092L15.6133 8.85564C15.008 8.71724 14.4854 8.34242 14.165 7.81686L12 4.2652Z"
      fill="currentColor"
    />
  </SvgIcon>
)
