import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AndroidSmartphoneDeleteIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6ZM8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H9.64251C10.5523 2 11.4034 2.38623 12 3.0377C12.5966 2.38623 13.4477 2 14.3575 2H16V4H14.3575C13.9043 4 13.49 4.25604 13.2873 4.66139C12.7569 5.72221 11.2431 5.72221 10.7127 4.66139C10.51 4.25604 10.0957 4 9.64251 4H8V2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.79289 10.2929C9.18342 9.90237 9.81658 9.90237 10.2071 10.2929L15.2071 15.2929C15.5976 15.6834 15.5976 16.3166 15.2071 16.7071C14.8166 17.0976 14.1834 17.0976 13.7929 16.7071L8.79289 11.7071C8.40237 11.3166 8.40237 10.6834 8.79289 10.2929Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2071 10.2929C14.8166 9.90237 14.1834 9.90237 13.7929 10.2929L8.79289 15.2929C8.40237 15.6834 8.40237 16.3166 8.79289 16.7071C9.18342 17.0976 9.81658 17.0976 10.2071 16.7071L15.2071 11.7071C15.5976 11.3166 15.5976 10.6834 15.2071 10.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
