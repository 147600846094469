/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react'
import { UnpackNestedValue, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Button as BaseButton, ToolbarButton } from '@common/react-lib-base'
import { Lookup } from '@common/react-lib-consumer-pres'
import {
  SubmittedVehicle,
  VehicleFindBy,
  VehicleLookupFormModule,
} from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useGetNextUrlByLoanState, useResolver } from '../../../hooks'
import {
  trackVehicleLookupMethodSelected,
  trackVehicleLookupSubmitted,
} from '../../../tracking'
import { VehicleLookup, VehicleLookupFormValues } from '../../schemas'
import { Element, genericErrorMessage, useModule } from '../../utils'

import { useLicensePlate } from './use-license-plate'
import { useVin } from './use-vin'

const mapValuesToSubmit = (
  values: UnpackNestedValue<VehicleLookupFormValues>,
): SubmittedVehicle => {
  return values.findBy === VehicleFindBy.VIN
    ? {
        vin: values.dynamic.vin,
      }
    : {
        licensePlateNumber: values.dynamic.licensePlateNumber,
        licensePlateState: values.dynamic.state,
      }
}

export const useVehicleLookupForm = (): {
  module: VehicleLookupFormModule
  submitError?: string
} => {
  const form = useForm<VehicleLookupFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: {
      findBy: null,
      dynamic: {
        vin: '',
        licensePlateNumber: '',
        state: '',
      } as VehicleLookupFormValues['dynamic'],
    } as VehicleLookupFormValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: useResolver(VehicleLookup as any),
  })

  const {
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    handleSubmit,
    setValue,
    reset,
    register,
  } = form

  register('findBy')

  const findBy = watch('findBy')
  const vin = useVin(form)
  const licensePlate = useLicensePlate(form)

  const [submitError, setSubmitError] = useState('')

  const handleBackClick = () => setValue('findBy', null)
  const handleRetryClick = () => reset()

  const foundVehicle = vin.foundVehicle || licensePlate.foundVehicle

  const router = useRouter()
  const getNextUrlByLoanState = useGetNextUrlByLoanState()

  const handleSelectMethod = (method: 'vin' | 'licensePlate') => {
    if (method === 'vin') {
      void trackVehicleLookupMethodSelected({ method: 'vin' })
      setValue('findBy', VehicleFindBy.VIN)
    } else {
      void trackVehicleLookupMethodSelected({ method: 'licensePlate' })
      setValue('findBy', VehicleFindBy.LICENSEPLATE)
    }
  }

  const onSubmit = handleSubmit(async (values): Promise<void> => {
    try {
      const data = mapValuesToSubmit(values)
      setSubmitError('')

      await vehicleAdapters.updateVehicleLookup(data)
      void trackVehicleLookupSubmitted({
        method: values.findBy === VehicleFindBy.VIN ? 'vin' : 'licensePlate',
      })

      const resumeUrl = await getNextUrlByLoanState()
      void router.push(resumeUrl)
    } catch (error) {
      setSubmitError(genericErrorMessage)
      throw error
    }
  })

  return {
    module: useModule({
      Dynamic:
        findBy === VehicleFindBy.VIN
          ? Element(Lookup, {
              Fields: vin.fields,
              onBackClick: handleBackClick,
              onRetryClick: handleRetryClick,
              foundVehicle,
            })
          : findBy === VehicleFindBy.LICENSEPLATE
          ? Element(Lookup, {
              Fields: licensePlate.fields,
              onBackClick: handleBackClick,
              onRetryClick: handleRetryClick,
              foundVehicle,
            })
          : null,

      SelectVin: Element(BaseButton, {
        onClick: () => handleSelectMethod('vin'),
      }),
      SelectLicensePlate: Element(BaseButton, {
        onClick: () => handleSelectMethod('licensePlate'),
      }),

      Submit: Element(ToolbarButton, {
        onClick: onSubmit,
        disabled: !foundVehicle || isSubmitting,
        loading: isSubmitting || isSubmitSuccessful,
        children: 'Continue',
      }),
    }),
    submitError,
  }
}
