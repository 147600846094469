import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FileDuotoneIcon: React.FC<SvgIconProps & { altTone: string }> = ({
  htmlColor = '#00C696',
  altTone = '#DFFFE3',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M42.0016 12.0883C42.0016 10.3414 40.5723 8.91217 38.8254 8.91217H15.0033C13.2564 8.91217 11.8271 10.3414 11.8271 12.0883V41.7323C11.8271 43.4792 13.2564 44.9085 15.0033 44.9085H32.9999L41.999 36.4388L42.0016 12.0883Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M11.8268 12.0879C11.8268 10.3411 13.2561 8.9118 15.003 8.9118H36.1783V6.26501C36.1783 4.51813 34.749 3.08887 33.0022 3.08887H9.18005C7.43317 3.08887 6.00391 4.51813 6.00391 6.26501V35.909C6.00391 37.6559 7.43317 39.0852 9.18005 39.0852H11.8268V12.0879Z"
      fill="currentColor"
    />
    <path
      d="M36.1761 36.4407H41.9991L33 44.9104V39.6168C33 37.8699 34.4293 36.4407 36.1761 36.4407Z"
      fill={altTone}
    />
    <path
      d="M19.766 22.9415H34.0586C34.4975 22.9415 34.8526 22.5858 34.8526 22.1475C34.8526 21.7087 34.4974 21.3535 34.0586 21.3535H19.766C19.3272 21.3535 18.9719 21.7087 18.9719 22.1475C18.9719 22.5858 19.3271 22.9415 19.766 22.9415Z"
      fill="white"
    />
    <path
      d="M19.766 17.6471H34.0586C34.4975 17.6471 34.8526 17.2913 34.8526 16.8531C34.8526 16.4143 34.4974 16.059 34.0586 16.059H19.766C19.3272 16.059 18.9719 16.4143 18.9719 16.8531C18.9719 17.2913 19.3271 17.6471 19.766 17.6471Z"
      fill="white"
    />
    <path
      d="M19.766 28.2355H34.0586C34.4975 28.2355 34.8526 27.8797 34.8526 27.4414C34.8526 27.0026 34.4974 26.6474 34.0586 26.6474H19.766C19.3272 26.6474 18.9719 27.0026 18.9719 27.4414C18.9719 27.8797 19.3271 28.2355 19.766 28.2355Z"
      fill="white"
    />
    <path
      d="M19.766 33.5253H27.7063C28.1451 33.5253 28.5004 33.1695 28.5004 32.7312C28.5004 32.2923 28.1451 31.9372 27.7063 31.9372H19.766C19.3272 31.9372 18.9719 32.2923 18.9719 32.7312C18.9719 33.1695 19.3271 33.5253 19.766 33.5253Z"
      fill="white"
    />
  </SvgIcon>
)
