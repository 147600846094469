import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PhoneIncomingIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9684 5.21548C7.38659 4.4089 9.83024 5.92366 10.509 8.3761C10.9715 10.0472 10.5335 11.7996 9.46537 12.9995C9.68413 13.2828 9.92317 13.5552 10.1827 13.8149C10.4409 14.0733 10.7117 14.3115 10.9932 14.5295C12.1927 13.4529 13.9503 13.01 15.6263 13.4745C18.0782 14.1542 19.5902 16.5997 18.7848 19.0178C18.2565 20.6044 17.2712 21.5683 15.9571 21.8856C14.7361 22.1805 13.4197 21.867 12.2585 21.3844C9.91998 20.4122 7.6108 18.4974 6.5562 17.442C5.4767 16.3617 3.57009 14.0491 2.60677 11.715C2.1284 10.5559 1.82039 9.24428 2.1159 8.02889C2.43384 6.7213 3.3929 5.74096 4.9684 5.21548ZM8.58147 8.90958C8.16878 7.4185 6.79755 6.71369 5.60122 7.11273C4.54962 7.46346 4.18488 7.98485 4.05928 8.50141C3.91127 9.11016 4.03998 9.94514 4.45551 10.952C5.27998 12.9497 6.98986 15.0465 7.97094 16.0283C8.92827 16.9863 11.0228 18.7048 13.0263 19.5376C14.0361 19.9574 14.8752 20.0894 15.4877 19.9415C16.0071 19.8161 16.5332 19.4492 16.8873 18.3858C17.2867 17.1867 16.5807 15.8145 15.0921 15.4018C13.9842 15.0948 12.8451 15.4505 12.1672 16.1766C11.7295 16.6454 10.8951 16.9126 10.1851 16.4171C9.68559 16.0684 9.2112 15.6722 8.76793 15.2286C8.32396 14.7843 7.92757 14.3087 7.57884 13.8081C7.08641 13.1012 7.34917 12.27 7.81458 11.8312C8.53521 11.1518 8.88748 10.0153 8.58147 8.90958Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7072 3.29289C21.0977 3.68342 21.0977 4.31658 20.7072 4.70711L17.4142 8.00006H19.2858C19.8381 8.00006 20.2858 8.44778 20.2858 9.00006C20.2858 9.55235 19.8381 10.0001 19.2858 10.0001H15C14.4477 10.0001 14 9.55235 14 9.00006V4.71429C14 4.16201 14.4477 3.71429 15 3.71429C15.5523 3.71429 16 4.16201 16 4.71429V6.58585L19.293 3.29289C19.6835 2.90237 20.3166 2.90237 20.7072 3.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
