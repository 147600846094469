/* eslint-disable @typescript-eslint/no-explicit-any, react/jsx-handler-names, @typescript-eslint/no-misused-promises */

import React, { FunctionComponent, ReactNode } from 'react'
import { Box, Divider, Grid, styled } from '@mui/material'
import Image from 'next/legacy/image'

import { P } from '@common/react-lib-base'
import { Image as ImageType } from '@common/types'
import { CxColors, OldTheme } from '@modules/core-ui'
import { caribouPhone } from '@src/config'

import { Logo } from './logo'
import { Link } from './reskin'

const FooterHero = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  position: 'relative',
  minHeight: '90px',
  padding: theme.spacing(0, 2),
  flexDirection: 'row',

  [theme.breakpoints.up('md')]: {
    boxShadow: `0 -5px 10px ${theme.palette.divider}`,
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
}))

const CallToUsInfoContainer = styled(Grid)(
  ({ theme }: { theme: OldTheme }) => ({
    alignItems: 'center',
    ...theme.typography.caption,
    '& a': {
      fontWeight: theme.typography.fontWeightBold,
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
)

const CallUsImageContainer = styled(Grid)({
  maxWidth: '130px',
  marginRight: '10px',

  '& img': {
    width: '100%',
  },
})

const BusinessHourP = styled('p')(({ theme }: { theme: OldTheme }) => ({
  fontWeight: theme.typography.fontWeightBold as number,
  textTransform: 'uppercase',
  margin: '0',
}))

const ActionButtonContainer = styled(Grid)(
  ({ theme }: { theme: OldTheme }) => ({
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-end',
    justifyContent: 'center',

    '& button': {
      maxWidth: `calc(100vw - ${theme.spacing(3)})`,
      minWidth: `calc(320px - ${theme.spacing(3)})`,
      padding: theme.spacing(2, 5),
      height: 'auto',
      fontSize: theme.typography.pxToRem(18),
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(6),
    },
  }),
)

const CtaContainer = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const FooterErrorContainer = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: 400,
  textAlign: 'center',
  maxWidth: `calc(100vw - ${theme.spacing(6)})`,
  margin: theme.spacing(0, 0, 2),
  color: theme.palette.error.main,
  ...theme.typography.subtitle2,

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 0,
    transform: 'translateY(-110%)',
    textAlign: 'right',
  },
}))

const ImageLinksContainer = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  padding: theme.spacing(2),
  justifyContent: 'flex-start',
  alignItems: 'center',

  '& img': {
    height: '40px',
  },
  '& a:not(:first--of-type)': {
    marginLeft: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(6, 2),
    justifyContent: 'center',
  },
}))

const FooterFineText = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  minHeight: '65px',
  padding: theme.spacing(6, 3),
  backgroundColor: theme.palette.text.primary,
  '& *': {
    color: theme.palette.grey[700],
    fontWeight: 'normal',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const StyledLogo = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}))

const StyledDivider = styled(Divider)(({ theme }: { theme: OldTheme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    margin: theme.spacing(5, 0),
    background: CxColors.divider.darkGray,
  },
}))

const FooterLinks = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  fontSize: theme.typography.fontSize,
  alignItems: 'center',

  '& a': {
    marginRight: '12px',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const Copyright = styled(Grid)(({ theme }: { theme: OldTheme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',

  '& p': {
    fontSize: theme.typography.fontSize,
    margin: '0',
  },

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    margin: theme.spacing(3, 0, 0),
  },
}))

const CallUsText = styled('p')(({ theme }: { theme: OldTheme }) => ({
  margin: theme.spacing(2, 0, 1, 0),
}))

type FooterCta = {
  businessHours: ReadonlyArray<string>
  callUsImage: ImageType
  callUsNumber: string
  callUsText: string
  ctaSideText?: string
}

type FooterFineText = {
  copyright: string
  footerLinks: ReadonlyArray<{ text: string; href: string }>
}

export type FooterProps = Partial<{
  ctaButton?: { helpText?: string; button: ReactNode }
  imageLinks: ReadonlyArray<ImageType & { href: string }>
  footerCta: FooterCta
  footerFineText: FooterFineText
}>

const defaultProps: FooterProps = {
  imageLinks: [
    {
      src: '/assets/BBB_A.svg',
      title: 'BBB Accredited Business',
      href: 'https://www.bbb.org/washington-dc-eastern-pa/business-reviews/financial-services/motorefi-in-alexandria-va-236020294/#sealclick',
      width: 165,
      height: 40,
    },
  ],
  footerCta: {
    businessHours: ['Mon - Fri 9 AM - 8 PM EST', 'Sat - Sun 9 AM - 4 PM EST'],
    callUsImage: {
      src: '/assets/images/caribou-profiles.jpg',
      title: 'Staff Members',
      width: 390,
      height: 144,
    },
    callUsText: 'Need help? Call us at',
    callUsNumber: caribouPhone,
  },

  footerFineText: {
    copyright: `© ${new Date().getFullYear()} Caribou. All rights reserved.`,
    footerLinks: [
      {
        text: 'Privacy Policy',
        href: 'https://www.caribou.com/privacy-policy',
      },
      {
        text: 'Terms & Conditions',
        href: 'https://www.caribou.com/terms-of-use',
      },
      {
        text: 'Disclosures',
        href: 'https://www.caribou.com/disclosures',
      },
      {
        text: 'Electronic Communications Consent',
        href: 'https://www.caribou.com/electronic-communications-agreement',
      },
    ],
  },
}

export const Footer: FunctionComponent<FooterProps> = ({
  footerCta = defaultProps.footerCta,
  footerFineText = defaultProps.footerFineText,
  imageLinks = defaultProps.imageLinks,
  ctaButton = defaultProps.ctaButton,
}) => {
  return (
    // Footer Hero Section
    <Grid container direction="column">
      <FooterHero item container>
        <CallToUsInfoContainer item xs={12} md={5} container>
          <CallUsImageContainer item container>
            <Image
              src={footerCta.callUsImage.src}
              alt={footerCta.callUsImage.title}
              width={footerCta.callUsImage.width}
              height={footerCta.callUsImage.height}
            />
          </CallUsImageContainer>

          <Grid item xs={12} md container flexDirection="column">
            <CallUsText>
              {footerCta.callUsText}{' '}
              <Link href={`tel:${footerCta.callUsNumber}`}>
                {footerCta.callUsNumber}
              </Link>
            </CallUsText>

            {footerCta.businessHours.map((businessHour) => (
              <BusinessHourP key={businessHour}>{businessHour}</BusinessHourP>
            ))}
          </Grid>
        </CallToUsInfoContainer>

        {ctaButton && (
          <ActionButtonContainer item xs container>
            {ctaButton.helpText && (
              <FooterErrorContainer>{ctaButton.helpText}</FooterErrorContainer>
            )}
            <CtaContainer>{ctaButton.button}</CtaContainer>
          </ActionButtonContainer>
        )}
      </FooterHero>

      {/* Footer Main Section */}
      <ImageLinksContainer item xs container>
        {imageLinks?.map((image, index) => (
          <a key={`${image.title}-${index}`} href={image.href} target="blank">
            <Image src={image.src} alt={image.src} width="165" height="40" />
          </a>
        ))}
      </ImageLinksContainer>

      {/* Footer Fine Text Section */}
      <FooterFineText item container>
        <StyledLogo item xs container>
          <Logo height={32} />
        </StyledLogo>
        <StyledDivider />
        <FooterLinks item xs={12} md container>
          {footerFineText.footerLinks !== null
            ? footerFineText.footerLinks.map((link) => (
                <Link href={link.href} key={link.text} target="_blank">
                  {link.text}
                </Link>
              ))
            : null}
        </FooterLinks>
        <Copyright item xs container>
          <P>{footerFineText.copyright}</P>
        </Copyright>
      </FooterFineText>
    </Grid>
  )
}
