import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LayersDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M3.81021 18.4767L23.0599 30.7265C23.3469 30.9085 23.6742 30.9995 23.9996 30.9995C24.3269 30.9995 24.6524 30.9085 24.9394 30.7265L44.1891 18.4767C44.6931 18.1548 44.9993 17.5983 44.9993 16.9998C44.9993 16.4013 44.6931 15.8448 44.1891 15.5245L24.9393 3.27297C24.3653 2.90901 23.6338 2.90901 23.0598 3.27297L3.81021 15.5245C3.30622 15.8448 3 16.4013 3 16.9998C2.99992 17.5983 3.30622 18.1548 3.81021 18.4767Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M44.1895 29.523L38.1749 24.5234L26.8193 31.5792C25.9706 32.1165 24.9976 32.4 24.0001 32.4C23.0027 32.4 22.0297 32.1165 21.1862 31.581L9.82496 24.5234L3.8107 29.523C3.30671 29.845 3.00049 30.4015 3.00049 31C3.00049 31.5985 3.30671 32.155 3.8107 32.4752L23.0604 44.7268C23.3474 44.9088 23.6729 44.9998 24.0001 44.9998C24.3256 44.9998 24.6528 44.9088 24.9399 44.7268L44.1895 32.4752C44.6935 32.155 44.9997 31.5985 44.9997 31C44.9998 30.4015 44.6935 29.845 44.1895 29.523Z"
      fill="currentColor"
    />
  </SvgIcon>
)
