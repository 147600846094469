import React, { FC } from 'react'
import { Box, Grid, styled, useTheme } from '@mui/material'
import Image from 'next/legacy/image'

import { Url } from '@common/lib-types'
import { OldTheme } from '@modules/core-ui'

interface ImagePositionProps {
  top?: string | number
  bottom?: string | number
  right?: string | number
  left?: string | number
}

const ImageQuotes = styled(Box)<{ theme?: OldTheme } & ImagePositionProps>(
  ({ theme, top, bottom, right, left }) => ({
    width: theme.spacing(3),
    height: 19,
    position: 'absolute',
    top,
    bottom,
    right,
    left,
  }),
)

const FiveStarIcon = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  width: theme.spacing(13),
  top: theme.spacing(3),
  position: 'absolute',
  left: '50%',
  marginLeft: `-${theme.spacing(7)}`,
}))

const ExpertQuote = styled('p')(({ theme }: { theme: OldTheme }) => ({
  marginBottom: '0',
  padding: theme.spacing(5, 5, 0, 5),
  fontSize: '0.9rem',
  lineHeight: 'initial',
}))

const ExpertName = styled('p')(({ theme }: { theme: OldTheme }) => ({
  fontSize: '0.9rem',
  textAlign: 'center',
  marginTop: '0',
  padding: theme.spacing(0, 5, 4, 5),
  fontWeight: theme.typography.fontWeightBold,
}))

const ImageContainer = styled('section')(({ theme }: { theme: OldTheme }) => ({
  backgroundColor: theme.palette.grey[100],
  position: 'relative',
}))

export const Testimony: FC = () => {
  const theme = useTheme()

  return (
    <Grid container>
      <ImageContainer>
        <ImageQuotes top={theme.spacing(3)} left={theme.spacing(3)}>
          <Image
            width="24"
            height="20"
            src={'/quote-open.png' as Url}
            alt={'Open quote'}
          />
        </ImageQuotes>
        <FiveStarIcon>
          <Image
            src={'/five-stars.png' as Url}
            alt={'Five Star Icon'}
            width="104"
            height="20"
          />
        </FiveStarIcon>
        <ImageQuotes right={theme.spacing(5)} bottom={theme.spacing(4)}>
          <Image
            src={'/quote-close.png' as Url}
            alt={'Close quote'}
            width="24"
            height="20"
          />
        </ImageQuotes>
        <ExpertQuote>
          {
            'I am a former loan officer. I would have to say this is the best way to refinance your current auto loan… Well done!'
          }
        </ExpertQuote>
        <ExpertName>{'Michael L., Michigan'}</ExpertName>
      </ImageContainer>
    </Grid>
  )
}
