import React, { FC } from 'react'
import { styled } from '@mui/material'

import type { TTradeline } from '@common/types'

import { CardBody } from './card-body'
import { CardContainer } from './card-container'
import { CardHead } from './card-head'

const Divider = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.extension.colors.neutral[500]}`,
  margin: theme.spacing(3, 0, 2),
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}))

type Props = TTradeline & {
  handleSelect: (id: number) => void
  selected?: number
}

export const TradelineCard: FC<Props> = (props) => {
  const { loanInfo, selected, id, handleSelect } = props
  return (
    <CardContainer highlight={selected === id}>
      <CardHead
        {...loanInfo}
        selected={selected}
        tradelineId={id}
        handleSelect={handleSelect}
      />
      <Divider />
      <CardBody
        selected={selected}
        tradelineId={id}
        handleSelect={handleSelect}
        {...props}
      />
    </CardContainer>
  )
}
