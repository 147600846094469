import React, { FunctionComponent } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { NewPersonalFormModule as PersonalFormModule } from '@common/types'

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '25px',
  columnGap: '30px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}))

export const PersonalInformationForm: FunctionComponent<{
  module: PersonalFormModule['borrower']
}> = ({
  module: {
    FirstName,
    LastName,
    Email,
    EmailExistError,
    ConfirmEmail,
    Birthdate,
    Citizenship,
    Relationship,
    Cellphone,
  },
}) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container>
      <FormContainer>
        <FirstName />

        <LastName />

        <Email />
        {EmailExistError && isMobileView && (
          <EmailExistError
            sx={{
              gridColumn: '1 / -1',
            }}
          />
        )}

        <ConfirmEmail />

        {EmailExistError && !isMobileView && (
          <EmailExistError
            sx={{
              gridColumn: '1 / -1',
            }}
          />
        )}

        <Birthdate />

        <Citizenship />

        <Cellphone />

        {Relationship && (
          <Relationship
            sx={{ width: '100%' }}
            // className={cx(classes.relationship)}
            // formControlClasses={{ root: cx(classes.relationship) }}
            // labelProps={{ className: cx(classes.relationship) }}
          />
        )}
      </FormContainer>
    </Container>
  )
}
