import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

import { ButtonProps } from '@common/types'

import { CheckBoxGroupProps, Text } from '../../reskin'

const VehicleOptionsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  '& div': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    // for some reason if it's not wrapped in a string template, this style gets omitted
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing(2),
      gridTemplateColumns: '1fr',
    },
  },
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(6),
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
  },
  '& button': {
    minWidth: 263,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const VehicleOptionsForm: FC<{
  module: {
    Submit: FC<Pick<ButtonProps, 'className'>>
    Options: FC<Pick<CheckBoxGroupProps<string>, 'rootClassName'>>
  }
}> = ({ module: { Submit, Options } }) => {
  return (
    <>
      <Text
        align="left"
        use={[
          'p',
          {
            md: 'headings.48',
            xs: 'headings.32',
          },
        ]}
        mt={{ xs: 0, sm: 1 }}
        mb={{ xs: 3, sm: 4 }}
        color="text.softBlack"
      >
        Select your car’s special features
      </Text>
      <VehicleOptionsContainer>
        <Options />
      </VehicleOptionsContainer>
      <ButtonContainer>
        <Submit />
      </ButtonContainer>
    </>
  )
}
