import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const StopwatchDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M10.6406 30H6.73438C6.08719 30 5.5625 30.5373 5.5625 31.2C5.5625 31.8627 6.08719 32.4 6.73438 32.4H10.6406C11.2878 32.4 11.8125 31.8627 11.8125 31.2C11.8125 30.5373 11.2878 30 10.6406 30Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M6.73438 22.8H10.6406C11.2878 22.8 11.8125 22.2627 11.8125 21.6C11.8125 20.9373 11.2878 20.4 10.6406 20.4H6.73438C6.08719 20.4 5.5625 20.9373 5.5625 21.6C5.5625 22.2627 6.08719 22.8 6.73438 22.8Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M7.90625 26.4C7.90625 25.7373 7.38156 25.2 6.73438 25.2H5.17188C4.52469 25.2 4 25.7373 4 26.4C4 27.0627 4.52469 27.6 5.17188 27.6H6.73438C7.38156 27.6 7.90625 27.0627 7.90625 26.4Z"
      fill="currentColor"
    />
    <path
      d="M28.7656 27.6C29.4128 27.6 29.9375 27.0628 29.9375 26.4C29.9375 25.7373 29.4128 25.2 28.7656 25.2C28.1184 25.2 27.5938 25.7373 27.5938 26.4C27.5938 27.0628 28.1184 27.6 28.7656 27.6Z"
      fill="currentColor"
    />
    <path
      d="M29.9375 10.8454V8.4H31.1094C31.7566 8.4 32.2812 7.86272 32.2812 7.2C32.2812 6.53728 31.7566 6 31.1094 6H26.4219C25.7747 6 25.25 6.53728 25.25 7.2C25.25 7.86272 25.7747 8.4 26.4219 8.4H27.5938V10.8454C20.1145 11.4281 14.1452 17.5412 13.5762 25.2H10.6406C9.99344 25.2 9.46875 25.7373 9.46875 26.4C9.46875 27.0627 9.99344 27.6 10.6406 27.6H13.5756C14.1741 35.655 20.7462 42 28.7656 42C37.1794 42 44 35.0156 44 26.4C44 18.1882 37.8037 11.4582 29.9375 10.8454V10.8454ZM28.7656 37.2C22.9501 37.2 18.2188 32.3551 18.2188 26.4C18.2188 20.4449 22.9501 15.6 28.7656 15.6C29.4128 15.6 29.9375 16.1373 29.9375 16.8C29.9375 17.4627 29.4128 18 28.7656 18C24.2424 18 20.5625 21.7682 20.5625 26.4C20.5625 31.0318 24.2424 34.8 28.7656 34.8C33.2888 34.8 36.9688 31.0318 36.9688 26.4C36.9688 25.3336 36.7666 24.2751 36.378 23.285L32.2214 25.7425C32.26 25.9558 32.2812 26.1754 32.2812 26.4C32.2812 28.385 30.7041 30 28.7656 30C26.8271 30 25.25 28.385 25.25 26.4C25.25 24.415 26.8271 22.8 28.7656 22.8C29.6362 22.8 30.4332 23.1264 31.0479 23.665L36.2924 20.5645C36.8526 20.2333 37.5687 20.4295 37.8927 21.0026C38.8216 22.6463 39.3125 24.5126 39.3125 26.4C39.3125 32.3551 34.5813 37.2 28.7656 37.2V37.2Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M14.788 15.4811C15.2609 14.8491 15.7765 14.2441 16.3356 13.6717C16.8946 13.0992 17.4853 12.5712 18.1025 12.0869L17.9928 11.9746C17.0775 11.0373 15.5936 11.0373 14.6783 11.9746C13.763 12.9119 13.763 14.4315 14.6783 15.3688L14.788 15.4811Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M42.7426 15.481L42.8523 15.3687C43.7676 14.4314 43.7676 12.9119 42.8523 11.9746C41.937 11.0373 40.453 11.0373 39.5378 11.9746L39.4281 12.0869C40.0453 12.5711 40.6361 13.0992 41.1951 13.6717C41.7541 14.2441 42.2697 14.849 42.7426 15.481Z"
      fill="currentColor"
    />
  </SvgIcon>
)
