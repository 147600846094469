import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CameraOffSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84401 5.25822L5.10615 5.44269C3.28064 5.89906 2 7.53928 2 9.42097V17C2 19.7614 4.23858 22 7 22H17C18.5298 22 19.8992 21.3129 20.8163 20.2306L15.5085 14.9227C14.8285 16.1608 13.5123 17 12 17C9.79086 17 8 15.2091 8 13C8 11.4877 8.83921 10.1715 10.0773 9.49154L5.84401 5.25822ZM15.9763 12.5621C15.7739 10.7028 14.2972 9.22615 12.4379 9.0237L7.32157 3.90736C7.14245 3.72824 7.12339 3.44094 7.30037 3.2597C8.06949 2.47205 9.13844 2 10.2902 2H13.7098C15.5079 2 17.1043 3.15059 17.6729 4.85641C17.7365 5.04736 17.8933 5.19256 18.0886 5.24138L18.8938 5.44268C20.7194 5.89906 22 7.53928 22 9.42097V17C22 17.1894 21.9895 17.3763 21.969 17.5603C21.9268 17.9385 21.4745 18.0603 21.2053 17.7911L15.9763 12.5621ZM11.6215 11.0358C10.6979 11.2127 10 12.0248 10 13C10 14.1046 10.8954 15 12 15C12.9752 15 13.7873 14.3021 13.9642 13.3785L11.6215 11.0358Z"
      fill="currentColor"
    />
  </SvgIcon>
)
