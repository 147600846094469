import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9.375C20 8.27043 19.1046 7.375 18 7.375H13.0704C12.0673 7.375 11.1306 6.8737 10.5742 6.0391L9.51168 4.4453C9.32622 4.1671 9.01399 4 8.67963 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.67963C9.68269 2 10.6194 2.5013 11.1758 3.3359L12.2383 4.9297C12.4238 5.2079 12.736 5.375 13.0704 5.375H18C20.2091 5.375 22 7.16586 22 9.375V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
  </SvgIcon>
)
