import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MessageCheckIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 13.6921 4.52425 15.2588 5.41916 16.5503C5.71533 16.9778 5.78673 17.5484 5.55213 18.0532L4.64729 20H12C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22H3.86159C2.72736 22 2.00986 20.7933 2.53406 19.8016L3.62175 17.4613C2.59621 15.8909 2 14.0137 2 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.684 9.27047C16.0869 9.6482 16.1073 10.281 15.7296 10.6839L11.9796 14.6839C11.6213 15.0661 11.0289 15.107 10.6215 14.7778L8.37151 12.9596C7.94195 12.6125 7.87512 11.9829 8.22224 11.5533C8.56936 11.1237 9.19899 11.0569 9.62855 11.404L11.1559 12.6383L14.2705 9.31606C14.6482 8.91315 15.2811 8.89274 15.684 9.27047Z"
      fill="currentColor"
    />
  </SvgIcon>
)
