import { useEffect } from 'react'
import { Resolver, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { PageRoute, SelectedOffer } from '@common/types'

import { submitCreateAccountForm } from '../../api'
import { useGetNextUrlByLoanState, useResolver } from '../../hooks'
import {
  CreateAccountForm,
  getNewResumeFlowCreateAccountSchema,
} from '../schemas'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCreateAccountForms = (
  lender: Pick<SelectedOffer, 'lender_id' | 'lender_name'>,
  hasCoborrower: boolean,
) => {
  const router = useRouter()
  const getNextUrlByLoanState = useGetNextUrlByLoanState()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.VehicleLookup)
    }
    prefetch().catch(console.error)
  }, [])

  const submit = async (data: CreateAccountForm): Promise<void> => {
    await submitCreateAccountForm(data)

    const nextUrl = await getNextUrlByLoanState()
    void router.push(nextUrl)
  }

  const formResolver = getNewResumeFlowCreateAccountSchema(hasCoborrower)

  const form = useForm({
    mode: 'all',
    defaultValues: {
      ssn: '',
      activeDuty: false,
      terms: false,
      coborrowerSsn: '',
      coborrowerActiveDuty: false,
    },
    shouldUnregister: true,
    resolver: useResolver(
      formResolver,
    ) as unknown as Resolver<CreateAccountForm>,
  })

  return {
    ...form,
    submit,
  }
}
