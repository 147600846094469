import { useCallback, useRef } from 'react'

/**
 * Utility to convert value into a getter to simplify working with async in React
 *
 * @example
 * const MyComponent = () => {
 *  const [num, setNum] = useState(1)
 *  const double = num * 2
 *  const getDouble = useLatest(double)
 *
 *  return (
 *    <button onClick={async () => {
 *      setNum(2)
 *      await fetch(...)
 *
 *      // logs { double: 2, latestDouble: 4 }
 *      console.log({ double, latestDouble: getDouble() })
 *    }} />
 *  )
 * }
 */
export const useLatest = <T>(value: T): (() => T) => {
  const ref = useRef(value)
  ref.current = value
  return useCallback(() => ref.current, [])
}
