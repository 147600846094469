/* eslint-disable import/no-internal-modules */
import {
  BaseSchema,
  setLocale,
  string,
  StringSchema,
  TestConfig,
  ValidationError,
} from 'yup'
import Lazy from 'yup/lib/Lazy'
import Reference from 'yup/lib/Reference'
import { AnySchema } from 'yup/lib/schema'

// Export internal module
export { yupResolver } from '@hookform/resolvers/yup'

const makeTestSequence = <T>(
  name: string,
  ...schemas: ReadonlyArray<BaseSchema>
): TestConfig<T> => {
  return {
    name,
    test: async (value, context) => {
      try {
        for (const schema of schemas) {
          await schema.validate(value, { context })
        }
      } catch (error) {
        if (error instanceof ValidationError) {
          // Original error's path is empty for some reason
          return context.createError({
            message: error.message,
            type: error.type,
          })
        }
        throw error
      }
      return true
    },
  }
}

/**
 * Yup utility to create chain validation.
 * Yup runs all validation in parallel by default.
 * Use this method when you want to run certain tests before the others.
 * */
// For now supports only string schemas
export const sequence = (
  name: string,
  ...schemas: ReadonlyArray<StringSchema>
): StringSchema => {
  return string().test(makeTestSequence<string>(name, ...schemas))
}

export type YupObject<T extends {}> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in keyof T]?: AnySchema | Reference | Lazy<any, any>
}

export const updateYupErrorMessages = (): void => {
  // Setting default error messages
  setLocale({
    mixed: {
      required: 'This field is required',
    },
  })
}
