import React from 'react'
import InputMask from 'react-input-mask'

import { TextMaskFieldProps as TextMaskFieldPropsBase } from '@common/types'

import { TextField } from './text-field'

type TextMaskFieldProps = TextMaskFieldPropsBase &
  // mask is a required prop. If it's not passed in, onChange doesn't work properly
  Required<Pick<TextMaskFieldPropsBase, 'mask'>>

export const TextMaskField = React.forwardRef<
  HTMLInputElement,
  TextMaskFieldProps
>((props, ref) => {
  return (
    <InputMask {...props} mask={props.mask} value={props.value}>
      {
        ((inputProps) => (
          <TextField {...inputProps} ref={ref} disabled={props.disabled} />
        )) as unknown as JSX.Element
      }
    </InputMask>
  )
})
