import { Box, styled, Theme } from '@mui/material'

const getArrowDownUri = (color: string): string =>
  `"data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289Z' fill='${color.replace(
    '#',
    '%23',
  )}'/></g></svg>"`

const getArrowUpUri = (color: string): string =>
  `"data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071L12 9.41421L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071Z' fill='${color.replace(
    '#',
    '%23',
  )}'/></g></svg>"`

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getSelectColors = (theme: Theme) => ({
  defaultColor: theme.extension.colors.neutral[700],
  defaultArrowColor: theme.extension.colors.neutral[800],
  activeColor: theme.extension.colors.text.softBlack,
  errorColor: theme.extension.colors.semantic.error,
  disabledColor: theme.extension.colors.neutral[700],
})

type SelectProps = {
  error?: boolean | string
  disabled?: boolean
  active?: boolean
  dense?: boolean
  small?: boolean
}

export const Container = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'active' && props !== 'error' && props !== 'small',
})<SelectProps>(({ active, error, disabled, small, theme }) => {
  const { activeColor, disabledColor, errorColor } = getSelectColors(theme)

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: error
      ? errorColor
      : disabled
      ? disabledColor
      : active
      ? activeColor
      : theme.extension.colors.text.bodyGray,

    width:
      small && theme.breakpoints.down('md') ? '-webkit-fill-available' : '100%',

    '&:focus-within': {
      color: error ? errorColor : theme.extension.colors.base.blueSky,
    },
  }
})

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.5),
}))

export const InputContainer = styled(Box)({
  width: '100%',
  position: 'relative',
})

export const Select = styled('select', {
  shouldForwardProp: (props) =>
    props !== 'active' &&
    props !== 'error' &&
    props !== 'dense' &&
    props !== 'small',
})<SelectProps>((props) => {
  const { active, error, dense, theme } = props
  const {
    activeColor,
    defaultArrowColor,
    defaultColor,
    disabledColor,
    errorColor,
  } = getSelectColors(theme)

  return {
    backgroundImage: active
      ? `url(${getArrowDownUri(activeColor)})`
      : `url(${getArrowDownUri(defaultArrowColor)})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `right ${theme.spacing(2)} top 50%`,
    backgroundSize: '24px auto, 100%',
    backgroundColor: 'inherit',
    appearance: 'none',
    color: active ? activeColor : theme.extension.colors.text.bodyGray,
    width: '100%',
    fontSize: theme.extension.typography.subheadings['16m'].fontSize, // atleast 16px is needed to prevent zoom in by browser
    fontFamily: theme.typography.fontFamily,
    fontWeight: dense
      ? theme.extension.typography.interactive.inputText14.fontWeight
      : theme.extension.typography.subheadings['16m'].fontWeight,
    height: dense ? 48 : 56,

    border: `2px solid ${
      active ? activeColor : error ? errorColor : defaultColor
    }`,
    borderRadius: 4,
    padding: error ? theme.spacing(1, 6.5, 1, 2) : theme.spacing(1, 7.5, 1, 2),
    boxShadow: error
      ? `0 0 0 2px ${theme.extension.colors.semantic.errorGlow}`
      : 'none',
    '&:hover': {
      backgroundColor: theme.extension.colors.surface.cloudGray,
      backgroundImage: `url(${getArrowDownUri(activeColor)})`,
    },

    '&:focus': {
      borderColor: error ? errorColor : theme.extension.colors.base.blueSky,
      color: theme.extension.colors.text.softBlack,
      outline: 'none',
      backgroundSize: '24px auto, 100%',
      backgroundImage: `url(${getArrowUpUri(activeColor)})`,
    },

    '&:disabled': {
      backgroundImage: `url(${getArrowDownUri(disabledColor)})`,
      borderColor: theme.extension.colors.neutral[700],
      backgroundColor: theme.extension.colors.neutral[400],
      color: theme.extension.colors.neutral[700],
      opacity: 1,
    },
  }
})
