import React, { FunctionComponent, ReactNode } from 'react'
import { Box, styled } from '@mui/material'

import { OldTheme } from '@modules/core-ui'

const Container = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  ...theme.select.gutters.page('margin'),
}))

type PageGutterComponentProps = Readonly<{
  children?: ReactNode
  className?: string
}>

/**
 *
 * @deprecated This is in general useless and gutter functions are deprecated in mui5. Avoid if you can
 */
export const PageGutterContainer: FunctionComponent<
  PageGutterComponentProps
> = (props) => <Container {...props} />
