/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Notification } from '@common/react-lib-consumer-pres'
import { State, StateKeys, stateToDisplay } from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useFetchData } from '../../../hooks'
import { VehicleFormValues } from '../../schemas'
import { Element } from '../../utils'
import { Select, VehicleLookupField } from '../controlled-fields'

export const useLicensePlate = ({
  control,
  getValues,
  watch,
  formState,
}: UseFormReturn<VehicleFormValues>) => {
  const {
    data: foundCar,
    retry: tryFindCar,
    loading,
    isError,
    setData: setFoundCar,
  } = useFetchData(
    async () => {
      const [licensePlate, state] = getValues([
        'dynamic.licensePlateNumber',
        'dynamic.state',
      ]) as [string, StateKeys]
      return vehicleAdapters.lookupVehicle({ licensePlate, state })
    },
    { handleInitialDataLoad: false, initialLoading: false },
  )

  const [showWarning, setShowWarning] = useState(false)
  const hideWarning = useCallback(() => setShowWarning(false), [])
  useEffect(() => {
    setShowWarning(isError)
  }, [isError])

  // reset found car on findBy change
  const findBy = watch('findBy')
  useEffect(() => {
    setFoundCar(null)
  }, [findBy, setFoundCar])

  const handleLookupDataChange = useCallback(() => {
    const hasError = (
      field: KeysOfUnion<VehicleFormValues['dynamic']>,
    ): boolean => {
      return (
        !!formState.errors.dynamic &&
        field in formState.errors.dynamic &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        !!formState.errors.dynamic[field]?.message
      )
    }

    // set timeout is needed to wait for field validation
    setTimeout(() => {
      const [licensePlate, state] = getValues([
        'dynamic.licensePlateNumber',
        'dynamic.state',
      ]) as [string, StateKeys]

      if (
        licensePlate &&
        state &&
        !hasError('licensePlateNumber') &&
        !hasError('state')
      ) {
        void tryFindCar()
      } else {
        setFoundCar(null)
      }
    })
  }, [formState.errors.dynamic, getValues, setFoundCar, tryFindCar])

  return {
    // TODO: error handling -> we should make another request in 30 seconds & show a different message
    // TODO: discuss the best UX to avoid refreshing the browser (all data will be lost)
    Warning:
      showWarning &&
      Element(Notification, {
        type: 'warning',
        title: 'Let’s give it a minute',
        body: 'We’re having trouble reaching the Motor Vehicle Database. Make sure the license plate and state fields are correct.',
        onClose: hideWarning,
        scrollIntoView: true,
        shake: true,
      }),
    fields: {
      LicensePlate: Element(VehicleLookupField, {
        label: 'License plate number',
        control,
        name: 'dynamic.licensePlateNumber',
        onChange: () => setFoundCar(null),
        // there's currently no good validation so waiting for onBlur to prevent
        // too many requests to the server
        onBlur: handleLookupDataChange,
        loading,
        foundCar: isError ? undefined : foundCar,
        warning: isError,
        dataTestId: 'input-license-plate-number',
      }),
      State: Element(Select, {
        label: 'State',
        control,
        name: 'dynamic.state',
        onChange: handleLookupDataChange,
        menuItems: [
          { value: '', displayName: 'Select State' },
          ...Object.values(State).map((value) => ({
            value,
            displayName: stateToDisplay(value),
          })),
        ],
        'data-testid': 'select-license-plate-state',
      }),
    },
  }
}

type KeysOfUnion<T> = T extends T ? keyof T : never
