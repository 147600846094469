import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AndroidSmartphoneDownIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6ZM8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H9.64251C10.5523 2 11.4034 2.38623 12 3.0377C12.5966 2.38623 13.4477 2 14.3575 2H16V4H14.3575C13.9043 4 13.49 4.25604 13.2873 4.66139C12.7569 5.72221 11.2431 5.72221 10.7127 4.66139C10.51 4.25604 10.0957 4 9.64251 4H8V2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17C12.5523 17 13 16.5523 13 16L13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10L11 16C11 16.5523 11.4477 17 12 17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929L12 14.5858L14.2929 12.2929C14.6834 11.9024 15.3166 11.9024 15.7071 12.2929C16.0976 12.6834 16.0976 13.3166 15.7071 13.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
