import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FaqSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M22.0001 15.5352C22.0001 13.0533 20.5763 10.8383 18.4438 9.76093C18.3776 14.5195 14.5196 18.3776 9.76099 18.4438C10.8384 20.5762 13.0534 22 15.5352 22C16.6989 22 17.8304 21.6901 18.8247 21.1013L21.9718 21.9718L21.1013 18.8246C21.6902 17.8304 22.0001 16.6988 22.0001 15.5352Z"
      fill="currentColor"
    />
    <path
      d="M17.2734 9.63672C17.2734 5.42575 13.8477 2 9.63672 2C5.42575 2 2 5.42575 2 9.63672C2 11.0091 2.3653 12.3453 3.05896 13.5184L2.02808 17.2452L5.75504 16.2145C6.92813 16.9081 8.26434 17.2734 9.63672 17.2734C13.8477 17.2734 17.2734 13.8477 17.2734 9.63672ZM8.46484 7.85938H7.29297C7.29297 6.56696 8.3443 5.51562 9.63672 5.51562C10.9291 5.51562 11.9805 6.56696 11.9805 7.85938C11.9805 8.51535 11.7028 9.14584 11.2183 9.58896L10.2227 10.5002V11.4141H9.05078V9.98416L10.4271 8.7244C10.6731 8.49933 10.8086 8.19217 10.8086 7.85938C10.8086 7.21317 10.2829 6.6875 9.63672 6.6875C8.99051 6.6875 8.46484 7.21317 8.46484 7.85938ZM9.05078 12.5859H10.2227V13.7578H9.05078V12.5859Z"
      fill="currentColor"
    />
  </SvgIcon>
)
