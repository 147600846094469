import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FaqCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" strokeWidth={0} htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2666 4C7.84832 4 4.2666 7.58172 4.2666 12C4.2666 16.4183 7.84832 20 12.2666 20C16.6849 20 20.2666 16.4183 20.2666 12C20.2666 7.58172 16.6849 4 12.2666 4ZM2.2666 12C2.2666 6.47715 6.74375 2 12.2666 2C17.7894 2 22.2666 6.47715 22.2666 12C22.2666 17.5228 17.7894 22 12.2666 22C6.74375 22 2.2666 17.5228 2.2666 12Z"
      fill="currentColor"
    />
    <path
      d="M12 17H12.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)
