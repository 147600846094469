import React, { FC } from 'react'
import { styled } from '@mui/material'

import { ErrorMessageProps } from '@common/types'

import { Link, Text } from './reskin'

const Error = styled(Text)({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: '800px',
  margin: '0 auto',
})

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  return (
    <Error
      use={['h3', { xs: 'subheadings.28m', sm: 'headings.32' }]}
      color="text.softBlack"
      className={props.className}
      sx={props.sx}
    >
      {'customMessage' in props ? (
        props.customMessage
      ) : (
        <span>
          {
            'Oops! Looks like something went wrong. Try to refresh the page or call us at '
          }
          <Link href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</Link>
        </span>
      )}
    </Error>
  )
}
