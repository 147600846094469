import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PriceTagDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M43.7659 18.5313L41.6681 15.7343L40.5017 23.8993C40.3949 24.6472 40.0417 25.3535 39.5072 25.8879L25.1441 40.251C23.8913 41.504 22.2237 42.1941 20.4485 42.1941C19.918 42.1941 19.3974 42.1311 18.8943 42.0113C19.6584 43.2058 20.9956 44.0001 22.5159 44.0001H39.7034C42.0727 44.0001 44.0003 42.0725 44.0003 39.7032V19.2344C44.0003 18.9809 43.918 18.7342 43.7659 18.5313Z"
      fill="currentColor"
    />
    <path
      d="M28.1821 23.4686C28.8293 23.4686 29.354 22.944 29.354 22.2968C29.354 21.6495 28.8293 21.1249 28.1821 21.1249C27.5349 21.1249 27.0103 21.6495 27.0103 22.2968C27.0103 22.944 27.5349 23.4686 28.1821 23.4686Z"
      fill="currentColor"
    />
    <path
      d="M14.9237 23.4686C15.5709 23.4686 16.0956 22.944 16.0956 22.2968C16.0956 21.6495 15.5709 21.1249 14.9237 21.1249C14.2765 21.1249 13.7518 21.6495 13.7518 22.2968C13.7518 22.944 14.2765 23.4686 14.9237 23.4686Z"
      fill="currentColor"
    />
    <path
      d="M23.4867 38.5937L37.8498 24.2306C38.0291 24.0513 38.1454 23.8187 38.1813 23.5677L39.8386 11.9667C39.8907 11.6015 39.768 11.2331 39.5071 10.9723L37.0212 8.48634L33.7067 11.801L34.5353 12.6296C34.993 13.0872 34.993 13.8292 34.5353 14.2869C34.3065 14.5157 34.0066 14.6302 33.7067 14.6302C33.4069 14.6302 33.1069 14.5157 32.8782 14.2869L29.5635 10.9723C29.1059 10.5147 29.1059 9.77267 29.5635 9.31501C30.0211 8.85743 30.7631 8.85743 31.2208 9.31501L32.0495 10.1437L35.364 6.82907L32.8781 4.34313C32.6173 4.08227 32.2489 3.95993 31.8837 4.01164L20.2826 5.66899C20.0316 5.70485 19.799 5.82118 19.6197 6.00048L5.25654 20.3636C3.58115 22.0389 3.58115 24.765 5.25654 26.4404L17.4099 38.5937C18.2476 39.4315 19.348 39.8502 20.4483 39.8502C21.5486 39.8502 22.649 39.4315 23.4867 38.5937V38.5937ZM28.1823 18.7815C30.1208 18.7815 31.6979 20.3586 31.6979 22.2971C31.6979 24.2357 30.1208 25.8128 28.1823 25.8128C26.2438 25.8128 24.6667 24.2357 24.6667 22.2971C24.6667 20.3586 26.2438 18.7815 28.1823 18.7815ZM14.924 25.8128C12.9855 25.8128 11.4084 24.2357 11.4084 22.2971C11.4084 20.3586 12.9855 18.7815 14.924 18.7815C16.8625 18.7815 18.4396 20.3586 18.4396 22.2971C18.4396 24.2357 16.8626 25.8128 14.924 25.8128ZM18.7912 31.7558C18.6684 31.7558 18.5434 31.7363 18.4205 31.6954C17.8065 31.4907 17.4747 30.8271 17.6793 30.213L23.2035 13.6401C23.4082 13.0261 24.0719 12.6943 24.6859 12.8989C25.2999 13.1036 25.6317 13.7672 25.427 14.3813L19.9028 30.9542C19.7391 31.4451 19.2818 31.7558 18.7912 31.7558V31.7558Z"
      fill="currentColor"
    />
    <path
      d="M39.5072 6.00045C39.9648 5.54287 39.9648 4.80084 39.5072 4.34318C39.0496 3.88561 38.3075 3.88561 37.8499 4.34318L35.364 6.82913L37.0213 8.48639L39.5072 6.00045Z"
      fill="currentColor"
    />
  </SvgIcon>
)
