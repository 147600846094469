import React, { FunctionComponent } from 'react'
import { styled } from '@mui/material'
import Image from 'next/legacy/image'

import { OldTheme } from '@modules/core-ui'

const ImageContainer = styled('div')(({ theme }: { theme: OldTheme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(3, 0, 0, 3),
  minHeight: '100%',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  '& img': {
    width: '50%',
    marginRight: '1em',
  },
}))

const ImageContext = styled('div')(({ theme }: { theme: OldTheme }) => ({
  marginLeft: theme.spacing(1),
  display: 'inline-block',
  verticalAlign: 'top',
}))

const RatingLetter = styled('div')(({ theme }: { theme: OldTheme }) => ({
  border: 'solid 1px black',
  background: 'black',
  color: 'white',
  fontWeight: 'bold',
  padding: '0.5em',
  borderRadius: theme.spacing(2),
  fontSize: '26px',
}))

const RatingLabel = styled('div')(({ theme }: { theme: OldTheme }) => ({
  color: 'black',
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: theme.typography.fontWeightBold,
  paddingLeft: theme.spacing(1),
}))

export const BBB: FunctionComponent = () => {
  return (
    <ImageContainer>
      <a
        href="https://www.bbb.org/washington-dc-eastern-pa/business-reviews/financial-services/motorefi-in-alexandria-va-236020294/#sealclick"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Image
          width="115"
          height="60"
          src="https://seal-dc-easternpa.bbb.org/seals/black-seal-160-82-bbb-236020294.png"
          alt="MotoRefi BBB Business Review"
        />
        <ImageContext>
          <RatingLetter>{' A+ '}</RatingLetter>
          <RatingLabel>{' Rating '}</RatingLabel>
        </ImageContext>
      </a>
    </ImageContainer>
  )
}
