import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const WalletSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V8H15C12.7909 8 11 9.79086 11 12C11 14.2091 12.7909 16 15 16H22V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM15 10H22V14H15C13.8954 14 13 13.1046 13 12C13 10.8954 13.8954 10 15 10ZM15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13H15.1C15.6523 13 16.1 12.5523 16.1 12C16.1 11.4477 15.6523 11 15.1 11H15Z"
      fill="currentColor"
    />
  </SvgIcon>
)
