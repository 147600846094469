import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MessageNewIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 13.6921 4.52425 15.2588 5.41916 16.5503C5.71533 16.9778 5.78673 17.5484 5.55213 18.0532L4.64729 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H3.86159C2.72736 22 2.00986 20.7933 2.53406 19.8016L3.62175 17.4613C2.59621 15.8909 2 14.0137 2 12C2 6.47715 6.47715 2 12 2C17.8965 2 22.583 7.28427 21.939 13.1099C21.8783 13.6588 21.3841 14.0546 20.8352 13.9939C20.2863 13.9333 19.8904 13.4391 19.9511 12.8901C20.465 8.24156 16.6976 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C14.8954 16 14 16.8954 14 18C14 19.1046 14.8954 20 16 20C17.1046 20 18 19.1046 18 18C18 16.8954 17.1046 16 16 16ZM12 18C12 15.7909 13.7909 14 16 14C18.2091 14 20 15.7909 20 18C20 20.2091 18.2091 22 16 22C13.7909 22 12 20.2091 12 18Z"
      fill="currentColor"
    />
  </SvgIcon>
)
