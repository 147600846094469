/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  ChangeHandler,
  FormState,
  get,
  UseFormRegisterReturn,
} from 'react-hook-form'
import identity from 'lodash/identity'

import { TextFieldProps } from '@common/react-lib-base'

type RegisterReturn = Omit<UseFormRegisterReturn, 'onChange' | 'onBlur'> & {
  error: TextFieldProps['error']
  helperText: TextFieldProps['helperText']
  onChange: TextFieldProps['onChange']
  onBlur: TextFieldProps['onBlur']
}

/**
 * @deprecated
 * Use module + controlled fields approach instead
 **/
export const registerMapBuilder =
  <T>(formState: FormState<T>) =>
  (
    registerReturn: UseFormRegisterReturn,
    extendOnChange: (handler: ChangeHandler) => ChangeHandler = identity,
    extendOnBlur: (handler: ChangeHandler) => ChangeHandler = identity,
  ): RegisterReturn => {
    const error = get(formState?.errors, registerReturn.name)
    const mappedRegister = {
      ...registerReturn,
      error: !!error?.type,
      helperText: error?.message || '',
      onChange: (e) => {
        void extendOnChange(registerReturn.onChange)(e)
      },
      onBlur: (e) => {
        void extendOnBlur(registerReturn.onBlur)(e)
      },
    }
    return mappedRegister
  }
