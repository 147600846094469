import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AndroidSmartphoneUpIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6ZM8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H9.64251C10.5523 2 11.4034 2.38623 12 3.0377C12.5966 2.38623 13.4477 2 14.3575 2H16V4H14.3575C13.9043 4 13.49 4.25604 13.2873 4.66139C12.7569 5.72221 11.2431 5.72221 10.7127 4.66139C10.51 4.25604 10.0957 4 9.64251 4H8V2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9C12.5523 9 13 9.44772 13 10L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 10C11 9.44772 11.4477 9 12 9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L12 11.4142L14.2929 13.7071C14.6834 14.0976 15.3166 14.0976 15.7071 13.7071C16.0976 13.3166 16.0976 12.6834 15.7071 12.2929L12.7071 9.29289C12.3166 8.90237 11.6834 8.90237 11.2929 9.29289L8.29289 12.2929C7.90237 12.6834 7.90237 13.3166 8.29289 13.7071Z"
      fill="currentColor"
    />
  </SvgIcon>
)
