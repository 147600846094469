import React, { FC, useCallback } from 'react'
import { styled } from '@mui/material'

import { Text } from '../../reskin'

import { CardContainer } from './card-container'
import { SelectButton as BaseButton } from './select-button'

const noneMessage =
  'If this is a new loan, it may not be reflected on your credit report yet. Select “None of the Above” and we’ll use the info you’ve already entered to estimate your options'

const SelectButton = styled(BaseButton)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 312,
  },
}))

const NoneMessageText = styled(Text)(({ theme }) => {
  return {
    margin: theme.spacing(1, 0, 3),
  }
})

const NoneCardContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0, 0),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(6, 0, 0),
  },
}))

type Props = {
  selected?: number
  handleSelect: (id: number) => void
}

export const NoneCard: FC<Props> = ({ handleSelect, selected }) => {
  const onClick = useCallback(() => {
    handleSelect(-1)
  }, [handleSelect])
  return (
    <NoneCardContainer>
      <CardContainer highlight={selected < 0}>
        <Text use={['div', { xs: 'subheadings.24b' }]} color="text.softBlack">
          {'Don’t see the loan you’re thinking of?'}
        </Text>
        <NoneMessageText
          use={['div', { xs: 'paragraphs.16' }]}
          color="text.softBlack"
        >
          {noneMessage}
        </NoneMessageText>
        <SelectButton
          inactiveLabel="None of the Above"
          onClick={onClick}
          selected={selected === -1}
        />
      </CardContainer>
    </NoneCardContainer>
  )
}
