import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PieChartV1Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12H21C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3V5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4.00001C11 2.92944 11.8926 1.92598 13.1088 2.06168C14.0414 2.16572 14.9565 2.40072 15.8268 2.76122C17.0401 3.26376 18.1425 4.00036 19.0711 4.92894C19.9997 5.85753 20.7362 6.95992 21.2388 8.17318C21.5993 9.04349 21.8343 9.95863 21.9383 10.8912C22.074 12.1074 21.0706 13 20 13L13 13C11.8954 13 11 12.1046 11 11L11 4.00001ZM13 4.06276V11L19.9373 11C19.8482 10.293 19.6649 9.59963 19.391 8.93854C18.989 7.96794 18.3997 7.08603 17.6569 6.34316C16.914 5.60029 16.0321 5.01101 15.0615 4.60897C14.4004 4.33514 13.707 4.15183 13 4.06276Z"
      fill="currentColor"
    />
  </SvgIcon>
)
