import { useEffect } from 'react'

export const useEffectIf = (condition: unknown, action: () => void): void => {
  useEffect(() => {
    if (condition) {
      action()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition])
}
