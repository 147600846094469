import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const IosSmartphoneMinusIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6ZM8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H8.11111C9.35367 2 10.3977 2.84985 10.6938 4H13.3062C13.6023 2.84985 14.6463 2 15.8889 2H16V4H15.8889C15.5207 4 15.2222 4.29848 15.2222 4.66667C15.2222 5.40305 14.6253 6 13.8889 6H10.1111C9.37473 6 8.77778 5.40305 8.77778 4.66667C8.77778 4.29848 8.4793 4 8.11111 4H8V2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z"
      fill="currentColor"
    />
  </SvgIcon>
)
