import React, { FC } from 'react'

import { Box, Link } from '@common/react-lib-base'
import { makeStyles, Theme } from '@modules/core-ui'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 2, 6, 2),
  },

  backBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },

  title: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  textInput: {
    width: '100%',
    margin: theme.spacing(6, 0, 0, 0),
    transform: 'none',
  },

  selectInput: {
    margin: theme.spacing(6, 0, 0, 0),
  },

  foundVehicleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    paddingTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },

  foundTitle: {
    color: theme.palette.grey[800],
  },

  foundClear: {
    color: theme.palette.secondary.main,
  },
}))

type LookupProps = {
  Fields:
    | {
        Vin: FC<{ className?: string }>
      }
    | {
        LicensePlate: FC<{ className?: string }>
        State: FC<{ formControlClasses?: { root?: string } }>
      }
    | null
  foundVehicle?: {
    make: string
    model: string
    trim: string
    year: number
  }
  onBackClick?: () => void
  onRetryClick?: () => void
}

export const Lookup: FC<LookupProps> = (props) => {
  const { Fields, onBackClick, onRetryClick, foundVehicle } = props

  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {!foundVehicle?.make && (
        <Link href="#" className={classes.backBtn} onClick={onBackClick}>
          {'< Back'}
        </Link>
      )}
      <span className={classes.title}>
        {'Vin' in Fields ? 'Find by VIN' : 'Find by License Plate'}
      </span>

      <Box className={classes.content}>
        {'Vin' in Fields ? (
          <Fields.Vin className={classes.textInput} />
        ) : 'LicensePlate' in Fields ? (
          <>
            <Fields.LicensePlate className={classes.textInput} />
            <Fields.State formControlClasses={{ root: classes.selectInput }} />
          </>
        ) : null}

        {foundVehicle?.make && (
          <Box className={classes.foundVehicleContainer}>
            <span className={classes.foundTitle}>{'Found it!'}</span>
            <span>
              {foundVehicle.year} {foundVehicle.make} {foundVehicle.model}{' '}
              {foundVehicle.trim}
            </span>
            <span className={classes.foundClear}>
              {'Not your car? '}
              <Link href="#" onClick={onRetryClick}>
                {'Clear and retry'}
              </Link>
            </span>
          </Box>
        )}
      </Box>
    </Box>
  )
}
