/**
 * Referencing this file
 * https://www.figma.com/file/avlpKiPf6U8EcT3lCfGmWL/Boreal-Foundations%3A-Color?node-id=1%3A3
 */
export const colors = {
  base: {
    blueSky: '#0075E3',
    deepBlue: '#0054A3',
    deepGreen: '#09414E',
    freshMint: '#00C696',
    greenLeaf: '#008765',
    lightMint: '#DFFFE3',
    lightSky: '#EBF4FD',
    littleBoyBlue: '#479CEB',
    creditKarmaBrandGreen: '#008600',
  },
  expressive: {
    blueSky: {
      1000: '#0075E3',
      800: '#3391E9',
      600: '#66ACEE',
      400: '#99C8F4',
      200: '#CCE3F9',
    },
    deepGreen: {
      1000: '#09414E',
      800: '#3A6771',
      600: '#6B8D95',
      400: '#9DB3B8',
      200: '#CED9DB',
    },
    freshMint: {
      1000: '#00C696',
      800: '#32D1A9',
      600: '#66DDC0',
      400: '#99E8D4',
      200: '#CCF4EA',
      100: '#EBFFFA',
    },
    greenLeaf: {
      1000: '#008765',
      800: '#339F84',
      600: '#66B7A3',
      400: '#99CFC1',
      200: '#CCE8E1',
    },
  },
  semantic: {
    error: '#E00000',
    warning: '#FF7A00',
    errorGlow: '#F5ADAD',
    errorBg: '#FFEBEB',
    warningBg: '#FFF5EB',
    successBg: '#EBFFF3',
  },
  surface: {
    babyGray: '#F4F4F4',
    cloudGray: '#F8F8F8',
  },
  divider: {
    darkGray: '#47494E',
    lightGray: '#DDDDDD',
  },
  text: {
    // button labels, small type
    black: '#000000',
    // headings/ subheadings
    softBlack: '#191C22',
    groundBlack: '#161616',

    // body copy
    bodyGray: '#636363',
    // knockout text
    white: '#FFFFFF',
  },
  neutral: {
    1000: '#000000',
    900: '#636363',
    800: '#8C8C8C',
    700: '#D1D1D1',
    600: '#DDDDDD',
    500: '#E8E8E8',
    400: '#F3F3F3',
    300: '#F9F9F9',
  },
}

export type Colors = typeof colors
