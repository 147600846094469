import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

type SubmitIncomeVerificaionRequirements = {
  accept: boolean
}

export const submitIncomeVerificationRequirements = async ({
  accept,
}: SubmitIncomeVerificaionRequirements): Promise<string> => {
  const body = {
    accept,
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/` +
      getRequestId() +
      `/income_verification${getLeadSourceQueryParams()}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data?.resumeUrl
}
