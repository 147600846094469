/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import {
  State,
  StateKeys,
  stateToDisplay,
  VehicleInfoData,
} from '@common/types'

import { vehicleAdapters } from '../../../api'
import { useFetchData } from '../../../hooks'
import { VehicleLookupFormValues } from '../../schemas'
import { Element, useModule } from '../../utils'
import { SelectInput, TextField } from '../controlled-fields-legacy'

export const useLicensePlate = ({
  control,
  getValues,
  watch,
  formState,
}: UseFormReturn<VehicleLookupFormValues>): {
  foundVehicle: VehicleInfoData
  fields: { LicensePlate: FC; State: FC }
} => {
  const {
    data: foundVehicle,
    retry: tryFindCar,
    setData: setFoundCar,
  } = useFetchData(
    async () => {
      const [licensePlate, state] = getValues([
        'dynamic.licensePlateNumber',
        'dynamic.state',
      ]) as [string, StateKeys]
      return vehicleAdapters.lookupVehicle({ licensePlate, state })
    },
    { handleInitialDataLoad: false, initialLoading: false },
  )

  // reset found car on findBy change
  const findBy = watch('findBy')
  useEffect(() => {
    setFoundCar(null)
  }, [findBy, setFoundCar])

  const handleLookupDataChange = useCallback(() => {
    const hasError = (
      field: KeysOfUnion<VehicleLookupFormValues['dynamic']>,
    ): boolean => {
      return (
        !!formState.errors.dynamic &&
        field in formState.errors.dynamic &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        !!formState.errors.dynamic[field]?.message
      )
    }

    // set timeout is needed to wait for field validation
    setTimeout(() => {
      const [licensePlate, state] = getValues([
        'dynamic.licensePlateNumber',
        'dynamic.state',
      ]) as [string, StateKeys]

      if (
        licensePlate &&
        state &&
        !hasError('licensePlateNumber') &&
        !hasError('state')
      ) {
        void tryFindCar()
      } else {
        setFoundCar(null)
      }
    })
  }, [formState.errors.dynamic, getValues, setFoundCar, tryFindCar])

  return {
    foundVehicle,
    fields: useModule({
      LicensePlate: Element(TextField, {
        control,
        label: 'License plate number',
        placeholder: 'ENTER YOUR LICENSE PLATE NUMBER',
        name: 'dynamic.licensePlateNumber',
        onChange: () => setFoundCar(null),
        // there's currently no good validation so waiting for onBlur to prevent
        // too many requests to the server
        onBlur: handleLookupDataChange,
      }),
      State: Element(SelectInput, {
        label: 'State',
        control,
        name: 'dynamic.state',
        displayPropertyName: 'displayName',
        valuePropertyName: 'value',
        onChange: handleLookupDataChange,
        menuItems: Object.values(State).map((state) => ({
          displayName: stateToDisplay(state),
          value: state,
        })),
      }),
    }),
  }
}

type KeysOfUnion<T> = T extends T ? keyof T : never
