import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BellOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C10.9588 4 10.1484 4.24709 9.53196 4.63436C9.0643 4.92815 8.44702 4.7872 8.15323 4.31954C7.85944 3.85188 8.00038 3.23461 8.46804 2.94082C9.44403 2.32769 10.6336 2 12 2C14.0305 2 15.651 2.72578 16.7518 3.98079C17.836 5.21692 18.3333 6.87057 18.3333 8.6V10.3333C18.3333 10.5774 18.4428 10.8709 18.7283 11.2498C19.002 11.6129 19.3573 11.9534 19.7498 12.3297C19.7718 12.3508 19.794 12.372 19.8163 12.3934C20.5 13.0494 21 13.9996 21 15.1333C21 15.6856 20.5523 16.1333 20 16.1333C19.4477 16.1333 19 15.6856 19 15.1333C19 14.6136 18.7752 14.1662 18.4317 13.8366C18.4024 13.8085 18.3723 13.7797 18.3414 13.7502C17.9742 13.399 17.5066 12.9517 17.1312 12.4535C16.7206 11.9088 16.3333 11.1939 16.3333 10.3333V8.6C16.3333 7.23664 15.9417 6.09029 15.2482 5.29962C14.5713 4.52782 13.5251 4 12 4ZM6.89225 6.5108C7.43857 6.59173 7.81584 7.10022 7.73491 7.64654C7.68976 7.95127 7.66667 8.27002 7.66667 8.6V10.3333C7.66667 11.1939 7.27938 11.9088 6.86882 12.4535C6.49336 12.9517 6.02577 13.399 5.65859 13.7502C5.62775 13.7797 5.59762 13.8085 5.56831 13.8366C5.22482 14.1662 5 14.6136 5 15.1333C5 15.6585 5.39364 16 5.77778 16H16.5C17.0523 16 17.5 16.4477 17.5 17C17.5 17.5523 17.0523 18 16.5 18H5.77778C4.19824 18 3 16.67 3 15.1333C3 13.9996 3.49997 13.0494 4.18372 12.3934C4.206 12.372 4.22817 12.3508 4.25022 12.3297C4.6427 11.9534 4.99798 11.6129 5.27166 11.2498C5.55716 10.8709 5.66667 10.5774 5.66667 10.3333V8.6C5.66667 8.17698 5.69626 7.76009 5.7565 7.35346C5.83743 6.80713 6.34592 6.42986 6.89225 6.5108Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44313 19.1695C9.90183 18.8619 10.523 18.9843 10.8306 19.443C10.93 19.5912 11.0844 19.731 11.292 19.8345C11.4999 19.938 11.7445 19.9965 12.0001 19.9965C12.2557 19.9965 12.5003 19.938 12.7082 19.8345C12.9158 19.731 13.0702 19.5912 13.1696 19.443C13.4772 18.9843 14.0984 18.8619 14.5571 19.1695C15.0158 19.4771 15.1382 20.0983 14.8306 20.557C14.5235 21.0149 14.0945 21.3783 13.6001 21.6246C13.1059 21.8708 12.5547 21.9965 12.0001 21.9965C11.4455 21.9965 10.8943 21.8708 10.4001 21.6246C9.90574 21.3783 9.47669 21.0149 9.16957 20.557C8.86196 20.0983 8.98444 19.4771 9.44313 19.1695Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
)
