import React, { FC, useCallback, useRef } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { BehaviorSubject } from 'rxjs'
// eslint-disable-next-line import/no-internal-modules
import { filter, first } from 'rxjs/operators'

import { userValidationAdapter } from '@src/api'
import {
  getAuthToken,
  getEmail,
  getRequestId,
  removeAuthToken,
  removeLeadSource,
  removeRequestId,
  setAuthToken,
  setRequestId,
} from '@src/utils/storage'

import { publicAuth0Config } from '../config'
// eslint-disable-next-line import/no-internal-modules
import { useLatest } from '../hooks/use-latest'
import { assert, safeWindow } from '../utils'

/**
 * Validate token + requestId combination
 */
export const validateAuth = async ({
  requestId,
  token,
}: {
  requestId: string
  token: string
}): Promise<boolean> => {
  try {
    // Making request to a protected route
    // Assuming this call should always be successful once the account is created
    const validationResult = await userValidationAdapter.validate({
      requestId,
      token,
    })

    return (
      validationResult.isAuthTokenValid &&
      validationResult.isRequestIdValid &&
      validationResult.isRequestIdValid
    )
  } catch {
    return false
  }
}

const useWaitFor = <T,>(
  value: T,
  predicate: (value: T) => boolean,
): (() => Promise<T>) => {
  const { current: value$ } = useRef(new BehaviorSubject(value))
  value$.next(value)

  return () => {
    return value$.pipe(filter(predicate), first()).toPromise()
  }
}

/** Redirect URI should match for login & getToken calls */
const reconstructRedirectUri = (): string => {
  const query = new URLSearchParams(safeWindow.location.search)
  // Remove 'code' query parameter added by auth0
  query.delete('code')
  query.delete('state')

  return `${safeWindow.location.origin}${
    safeWindow.location.pathname
  }?${query.toString()}`
}

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Auth0Provider
      domain={publicAuth0Config.auth0Domain}
      clientId={publicAuth0Config.auth0ClientId}
      audience={publicAuth0Config.auth0Audience}
      redirectUri={
        typeof window !== 'undefined' &&
        `${safeWindow.location.origin}${safeWindow.location.pathname}`
      }
    >
      {children}
    </Auth0Provider>
  )
}

type Auth = {
  authorize: () => Promise<void>
  logout: () => void
  isAuthenticated: boolean
}

export const useAuth = (): Auth => {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    logout: auth0Logout,
    isLoading,
    isAuthenticated,
    error,
    user,
  } = useAuth0()

  const waitForAuth0ToLoad = useWaitFor(isLoading, (l) => l === false)
  const getLatest = useLatest({ isAuthenticated, isLoading, error, user })

  const authorize: Auth['authorize'] = useCallback(async () => {
    const requestId = getRequestId()
    const email = getEmail()
    assert(requestId, 'Request id is missing')

    const oldToken = getAuthToken()
    if (oldToken) {
      const valid = await validateAuth({ token: oldToken, requestId })
      if (!valid) {
        setAuthToken('')
      } else {
        return
      }
    }

    await waitForAuth0ToLoad()
    const { isAuthenticated, error } = getLatest()

    if (error) {
      throw error
    } else if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: publicAuth0Config.auth0Audience,
      })
      setAuthToken(token)
      const valid = await validateAuth({ token, requestId })
      assert(
        valid,
        'Authentication failed. Request id might be invalid or the user logged in into wrong account',
      )

      setRequestId(requestId)
    } else {
      await loginWithRedirect(
        email
          ? {
              redirectUri: reconstructRedirectUri(),
              mode: 'passwordless',
              email: email,
            }
          : {
              redirectUri: reconstructRedirectUri(),
              mode: 'passwordless',
            },
      )
    }
  }, [getAccessTokenSilently, loginWithRedirect, waitForAuth0ToLoad, getLatest])

  const logout: Auth['logout'] = useCallback(() => {
    removeRequestId()
    removeAuthToken()
    removeLeadSource()
    auth0Logout()
  }, [auth0Logout])

  return {
    isAuthenticated,
    authorize,
    logout,
  }
}
