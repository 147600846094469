import React, { useCallback, useRef, useState } from 'react'

import { Grow } from '@common/react-lib-base'

import { makeNewStyles } from '../../reskin'

const useStyles = makeNewStyles((theme) => ({
  form: {
    width: '100%',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
}))
export const NewGrowForm: React.FC<{
  queueOrder: number
  hide?: boolean
  children: React.ReactNode
}> = ({ hide, queueOrder, children }): JSX.Element => {
  const show = !hide
  const initialShowRef = useRef(show)

  const [entered, setEntered] = useState(false)
  const updateEntered = useCallback(() => setEntered(true), [])

  const styles = useStyles()

  const initialDisplayPassed = !initialShowRef.current || entered
  // Show forms in a queue for initial display
  const timeout = initialDisplayPassed ? 1000 : 600 * queueOrder

  return (
    <Grow
      in={show}
      timeout={timeout}
      mountOnEnter
      unmountOnExit
      onEntered={updateEntered}
    >
      {/* div is necessary for Grow to work */}
      <div className={styles.form}>{children}</div>
    </Grow>
  )
}
