import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
} from 'react'
import noop from 'lodash/noop'

import { ConfirmationDialog } from '@common/react-lib-consumer-pres'

type FormKey = 'borrower' | 'coborrower' | 'vehicle' | 'residence'
type TContext = {
  getOpenForm: () => HTMLDivElement | void
  trackOpenForm: (key: FormKey, isOpen: boolean, el: HTMLDivElement) => void
}
const FormsContext = createContext<TContext>({
  getOpenForm: noop,
  trackOpenForm: noop,
})

export const NewReviewInfoFormsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const formsRef = useRef<
    // eslint-disable-next-line @typescript-eslint/ban-types
    Record<FormKey, { isOpen: boolean; el?: HTMLDivElement }>
  >({
    borrower: { isOpen: false },
    coborrower: { isOpen: false },
    residence: { isOpen: false },
    vehicle: { isOpen: false },
  })

  const getOpenForm = useCallback(() => {
    return Object.values(formsRef.current).find((v) => v.isOpen)?.el
  }, [])

  const trackOpenForm = useCallback(
    (key: FormKey, isOpen: boolean, el: HTMLDivElement) => {
      formsRef.current[key] = { isOpen, el }
    },
    [],
  )

  return (
    <FormsContext.Provider value={{ getOpenForm, trackOpenForm }}>
      {children}
    </FormsContext.Provider>
  )
}

export const useNewReviewInfoFormsContext = (): TContext =>
  useContext(FormsContext)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ReviewInfoPopup: FC<{ open: boolean; onClick: () => void }> = ({
  open,
  onClick,
}) => (
  <ConfirmationDialog
    {...{ open, onClick }}
    title={'You have unsaved changes'}
    content={'To continue, please save your information'}
    submitButton={{
      text: 'Ok',
      onClick: onClick,
    }}
  />
)
