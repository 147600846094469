import { useEffect } from 'react'
import router from 'next/router'

import { getLeadSource, getRequestId } from '@src/utils/storage'

import { useLogger } from './use-logger'

/**
 *
 * @param error If is true will redirect
 * @returns void
 */
export const useErrorRedirect = (error: boolean, reason: string): void => {
  const logger = useLogger()
  const requestId = getRequestId()
  const leadSource = getLeadSource()
  return useEffect(() => {
    if (error) {
      logger.info('Triggering Error Redirect', {
        requestId,
        leadSource,
        url: location.href,
        path: location.pathname,
        reason: reason,
      })
      void router.push({
        pathname: '/loan-application-error',
      })
    }
  }, [error, leadSource, logger, requestId])
}
