import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MaximizeIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 13.2929C11.0977 13.6834 11.0977 14.3166 10.7071 14.7071L6.41421 19H9.1429C9.69519 19 10.1429 19.4478 10.1429 20C10.1429 20.5523 9.69519 21 9.1429 21H4C3.44772 21 3 20.5523 3 20V14.8571C3 14.3049 3.44772 13.8571 4 13.8571C4.55228 13.8571 5 14.3049 5 14.8571V17.5858L9.29292 13.2929C9.68345 12.9024 10.3166 12.9024 10.7071 13.2929Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4C14 3.44772 14.4477 3 15 3H20C20.5522 3 21 3.44772 21 4V9C21 9.55228 20.5522 10 20 10C19.4477 10 19 9.55228 19 9V6.41423L14.7071 10.7071C14.3166 11.0976 13.6834 11.0976 13.2929 10.7071C12.9024 10.3166 12.9024 9.68343 13.2929 9.2929L17.5858 5H15C14.4477 5 14 4.55228 14 4Z"
      fill="currentColor"
    />
  </SvgIcon>
)
