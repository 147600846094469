import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShieldDoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2747 4.11851C12.0952 4.06721 11.9048 4.06721 11.7253 4.11851L6.72528 5.54708C6.29598 5.66974 6 6.06212 6 6.5086V11.8831C6 14.1437 7.09176 16.2652 8.93133 17.5792L11.4188 19.3559C11.7665 19.6043 12.2335 19.6043 12.5812 19.3559L15.0687 17.5792C16.9082 16.2652 18 14.1437 18 11.8831V6.5086C18 6.06212 17.704 5.66974 17.2747 5.54708L12.2747 4.11851ZM11.1758 2.19546C11.7145 2.04156 12.2855 2.04156 12.8242 2.19546L17.8242 3.62403C19.1121 3.992 20 5.16916 20 6.5086V11.8831C20 14.7896 18.5963 17.5173 16.2311 19.2067L13.7437 20.9834C12.7006 21.7285 11.2994 21.7285 10.2563 20.9834L7.76886 19.2067C5.40369 17.5173 4 14.7896 4 11.8831V6.5086C4 5.16916 4.88793 3.992 6.17584 3.62403L11.1758 2.19546Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.684 8.27046C16.0869 8.64819 16.1073 9.28103 15.7296 9.68394L11.9796 13.6839C11.6213 14.0661 11.0289 14.107 10.6215 13.7778L8.37151 11.9596C7.94195 11.6125 7.87512 10.9829 8.22224 10.5533C8.56936 10.1237 9.19899 10.0569 9.62855 10.404L11.1559 11.6383L14.2705 8.31606C14.6482 7.91315 15.2811 7.89273 15.684 8.27046Z"
      fill="currentColor"
    />
  </SvgIcon>
)
