import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const UnlockCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 15C5 11.134 8.13401 8 12 8C15.866 8 19 11.134 19 15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15ZM12 10C9.23858 10 7 12.2386 7 15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15C17 12.2386 14.7614 10 12 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V6.23077C16 6.78305 15.5523 7.23077 15 7.23077C14.4477 7.23077 14 6.78305 14 6.23077V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9V6Z"
      fill="currentColor"
    />
  </SvgIcon>
)
