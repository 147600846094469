import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FileTextUpIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L15.7071 13.2929C16.0976 13.6834 16.0976 14.3166 15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071L13 13.4142L13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17L11 13.4142L9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929L11.2929 10.2929Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V8.82843C18 8.56321 17.8946 8.30886 17.7071 8.12132L13.8787 4.29289C13.6911 4.10536 13.4368 4 13.1716 4H8ZM4 6C4 3.79086 5.79086 2 8 2H13.1716C13.9672 2 14.7303 2.31607 15.2929 2.87868L19.1213 6.70711C19.6839 7.26972 20 8.03278 20 8.82843V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3V5C14 6.65685 15.3431 8 17 8H19V10H17C14.2386 10 12 7.76142 12 5V3H14Z"
      fill="currentColor"
    />
  </SvgIcon>
)
