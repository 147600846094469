import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LocationIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C10.4255 4 8.90461 4.65749 7.77503 5.84574C6.64381 7.03572 6 8.66001 6 10.3636C6 12.844 7.53632 15.2652 9.24308 17.1603C10.0789 18.0884 10.9177 18.8486 11.5484 19.3769C11.7154 19.5168 11.8673 19.64 12 19.7453C12.1327 19.64 12.2846 19.5168 12.4516 19.3769C13.0823 18.8486 13.9211 18.0884 14.7569 17.1603C16.4637 15.2652 18 12.844 18 10.3636C18 8.66001 17.3562 7.03572 16.225 5.84574C15.0954 4.65749 13.5745 4 12 4ZM12 21C11.4258 21.8187 11.4256 21.8186 11.4253 21.8184L11.4228 21.8166L11.4172 21.8127L11.3986 21.7994C11.3829 21.7882 11.3607 21.7722 11.3325 21.7517C11.2762 21.7106 11.1956 21.6511 11.0943 21.5741C10.8917 21.4203 10.6058 21.1962 10.2641 20.9101C9.58227 20.3389 8.67111 19.5139 7.75692 18.4988C5.96368 16.5076 4 13.6105 4 10.3636C4 8.16134 4.83118 6.0397 6.32548 4.46777C7.82141 2.89413 9.86146 2 12 2C14.1385 2 16.1786 2.89413 17.6745 4.46777C19.1688 6.0397 20 8.16134 20 10.3636C20 13.6105 18.0363 16.5076 16.2431 18.4988C15.3289 19.5139 14.4177 20.3389 13.7359 20.9101C13.3942 21.1962 13.1083 21.4203 12.9057 21.5741C12.8044 21.6511 12.7238 21.7106 12.6675 21.7517C12.6393 21.7722 12.6171 21.7882 12.6014 21.7994L12.5828 21.8127L12.5772 21.8166L12.5754 21.8179C12.5751 21.8181 12.5742 21.8187 12 21ZM12 21L12.5742 21.8187C12.2295 22.0604 11.7699 22.0601 11.4253 21.8184L12 21Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10Z"
      fill="currentColor"
    />
  </SvgIcon>
)
