const formatNumber = (num: number): string => (num ?? 0).toLocaleString()

const formatCurrency =
  (currency = '$') =>
  (num: number): string =>
    `${currency} ${formatNumber(num)}`

const formatPhone = (phone: string): string => {
  //Filter only numbers from the input
  const numbers = (phone ?? '').replaceAll(/\D/g, '')
  if (!numbers) {
    return ''
  }
  const [areaCode, exchangeCode, lineNum] = [
    numbers.slice(0, 3),
    numbers.slice(3, 6),
    numbers.slice(6, 10),
  ]
  const firstPart = `(${areaCode}) ${exchangeCode}`
  return exchangeCode.length === 3
    ? `${firstPart}-${lineNum}`
    : firstPart.trim()
}

const formatNewNumber = (num: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(num)

export const Format = {
  number: formatNumber,
  currency: formatCurrency('$'),
  currencyWithCents: formatNewNumber,
  newCurrency: (num: number): string =>
    formatNewNumber(num).replace(/\.00$/, ''),
  phone: formatPhone,
}
