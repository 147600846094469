/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'

import { getDatadogLogs } from '@common/telemetry'

// eslint-disable-next-line import/no-internal-modules
import { useDatadogRum } from '../contexts/datadog'

export type Logger = {
  error: (e: any, context?: object) => void
  info: (message: string, context: object) => void
  debug: (message: string, context: object) => void
  warn: (message: string, context: object) => void
}
export const useLogger = (): Logger => {
  const datadogRum = useDatadogRum()
  const datadogLogs = getDatadogLogs()
  const logger = {
    info: (message: string, context: object): void => {
      datadogLogs.logger.info(message, context)
    },
    error: (e: any, context?: object): void => {
      datadogLogs.logger.error(e, context)
      datadogRum?.addError(e)
    },
    debug: (message: string, context: object): void => {
      datadogLogs.logger.debug(message, context)
    },
    warn: (message: string, context: object): void => {
      datadogLogs.logger.warn(message, context)
    },
  }

  return useMemo(() => logger, [])
}
