import React, { ReactElement } from 'react'
import { Box, Container, styled } from '@mui/material'

import { P } from '@common/react-lib-base'
import { CreateAccountFormModule } from '@common/types'
import { OldTheme } from '@modules/core-ui'

type CreateAccountFormProps = {
  module: CreateAccountFormModule
}

const InputField = styled(Box)({
  width: '100%',
  marginLeft: '0',
  marginRight: '0',
})

const BoxMargin = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  marginLeft: theme.spacing(0.5),
  '& p': {
    color: theme.palette.grey[800],
  },
}))

const SSNInformation = styled(P)({
  fontSize: '0.7em',
  lineHeight: 'normal',
})

const PaddingContainer = styled(Container)(
  ({ theme }: { theme: OldTheme }) => ({
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  }),
)

export const CreateAccountForm = ({
  module: {
    SSN,
    CoborrowerSSN,
    IsActiveDuty,
    IsCoborrowerActiveDuty,
    TermsAndConditions,
  },
}: CreateAccountFormProps): ReactElement => {
  return (
    <PaddingContainer>
      <InputField as={SSN} />
      <InputField as={CoborrowerSSN} />
      <BoxMargin>
        <SSNInformation>
          {
            'The security of your information is important to us. Your SSN is needed to perform a credit check for your application.'
          }
        </SSNInformation>
      </BoxMargin>

      <BoxMargin>
        <IsActiveDuty />
      </BoxMargin>

      {IsCoborrowerActiveDuty && (
        <BoxMargin>
          <IsCoborrowerActiveDuty />
        </BoxMargin>
      )}

      <BoxMargin>
        <TermsAndConditions />
      </BoxMargin>
    </PaddingContainer>
  )
}
