import React, { FC } from 'react'
import { CircularProgress, styled } from '@mui/material'

import { MaterialProps } from '@lib/mui'

import { AlertCircleSolidIcon, Icon } from '../icons'

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: '100%',
  right: theme.spacing(2),
}))

type EndIconProps = MaterialProps & {
  icon?: JSX.Element | 'loading' | 'error'
}

export const EndIcon: FC<EndIconProps> = ({ icon, ...props }) => {
  return icon ? (
    <Container {...props}>
      {icon === 'error' ? (
        <Icon use={AlertCircleSolidIcon} color="semantic.error" size={24} />
      ) : icon === 'loading' ? (
        /* TODO: udpate with new component loader */
        <CircularProgress size={24} color="secondary" />
      ) : (
        icon
      )}
    </Container>
  ) : null
}
