import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const KeyDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M26.375 25.1875C26.375 25.4725 26.4225 25.7693 26.5294 26.0306C26.2206 26.1137 25.9 26.185 25.5794 26.2443C25.4606 26.2918 25.3181 26.3156 25.1875 26.3156C24.3919 26.4106 23.2637 26.3868 22.3019 26.185L11.7806 36.7181C11.555 36.9318 11.2581 37.0625 10.9375 37.0625H7.375C6.72187 37.0625 6.1875 36.5281 6.1875 35.875V32.3125C6.1875 31.9918 6.31812 31.695 6.53187 31.4693L7.71937 30.2818C7.945 30.0681 8.24187 29.9375 8.5625 29.9375H10.0825L10.9969 27.1825C11.1156 26.8381 11.4006 26.5531 11.745 26.4343L14.2744 25.5912L13.3481 21.91C13.2413 21.5062 13.36 21.0787 13.6569 20.7818L15.0225 19.4281C14.1081 16.1506 15.0225 12.5881 17.445 10.1537C18.7987 8.79997 20.5088 7.89747 22.385 7.54122C22.48 7.5056 22.575 7.48185 22.6462 7.46997C23.5844 7.32747 24.5344 7.33935 25.4606 7.49372C23.1212 9.4531 21.625 12.3981 21.625 15.6875C21.625 19.3331 23.3825 22.5987 26.375 24.57V25.1875Z"
      fill="currentColor"
    />
    <path
      d="M32.3125 6.1875C27.0744 6.1875 22.8125 10.4494 22.8125 15.6875C22.8125 19.1384 24.6139 22.2247 27.5625 23.9121V25.1875C27.5625 25.5022 27.6872 25.805 27.9104 26.0271L30.8673 28.9839L30.0622 30.593C29.8947 30.9279 29.8947 31.3209 30.0622 31.6558L30.9849 33.5L30.0634 35.3442C29.9803 35.5081 29.9375 35.6909 29.9375 35.875V38.25C29.9375 38.5647 30.0622 38.8675 30.2854 39.0896L32.6604 41.4646C32.892 41.6961 33.196 41.8125 33.5 41.8125C33.804 41.8125 34.108 41.6961 34.3396 41.4646L36.7146 39.0896C36.9378 38.8675 37.0625 38.5647 37.0625 38.25V23.9121C40.0111 22.2247 41.8125 19.1384 41.8125 15.6875C41.8125 10.4494 37.5506 6.1875 32.3125 6.1875ZM32.3541 16.2812C31.0454 16.305 29.9612 15.2576 29.9375 13.9478C29.9149 12.6392 30.9623 11.555 32.2709 11.5312C33.5796 11.5075 34.6638 12.5549 34.6875 13.8647C34.7101 15.1733 33.6627 16.2575 32.3541 16.2812Z"
      fill="currentColor"
    />
  </SvgIcon>
)
