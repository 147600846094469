import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EyeIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.65907 8.63233C6.33994 9.58138 5.26401 10.7442 4.58379 11.57C4.37149 11.8278 4.37149 12.1722 4.58379 12.43C5.26401 13.2558 6.33994 14.4186 7.65906 15.3677C8.98608 16.3224 10.4741 17 11.9999 17C13.5257 17 15.0138 16.3224 16.3408 15.3677C17.6599 14.4186 18.7359 13.2558 19.4161 12.43C19.6284 12.1722 19.6284 11.8278 19.4161 11.57C18.7359 10.7442 17.6599 9.58138 16.3408 8.63233C15.0138 7.6776 13.5257 7 11.9999 7C10.4741 7 8.98608 7.6776 7.65907 8.63233ZM6.49104 7.00884C7.99408 5.92747 9.89 5 11.9999 5C14.1099 5 16.0058 5.92747 17.5088 7.00885C19.0198 8.0959 20.22 9.40025 20.9598 10.2985C21.7805 11.2948 21.7805 12.7052 20.9598 13.7015C20.22 14.5998 19.0198 15.9041 17.5088 16.9912C16.0058 18.0725 14.1099 19 11.9999 19C9.89 19 7.99408 18.0725 6.49104 16.9912C4.9801 15.9041 3.77988 14.5998 3.04001 13.7015C2.21941 12.7052 2.21941 11.2948 3.04002 10.2985C3.77988 9.40024 4.9801 8.0959 6.49104 7.00884Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
      fill="currentColor"
    />
  </SvgIcon>
)
