import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HeartIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57209 3.29227C7.23841 3.06517 7.93598 3.00015 8.55284 3.00015C10.0176 3.00015 11.1219 3.62548 12.0004 4.25312C12.8858 3.62067 13.9828 3.00014 15.4648 3.00015C16.1236 3.00015 16.7976 3.09224 17.4507 3.30951C21.4401 4.60179 22.7251 8.89706 21.6113 12.4253L21.6066 12.4402L21.6015 12.4549C20.9851 14.2145 19.9771 15.8215 18.6547 17.1362L18.649 17.1419L18.6432 17.1474C16.7964 18.9254 14.7226 20.5315 12.5147 21.8573L11.9934 22.1703L11.4756 21.8516C9.27254 20.4953 7.23782 18.9324 5.36862 17.1399L5.36091 17.1325L5.35336 17.1249C4.04226 15.8127 3.03435 14.2175 2.40877 12.4596L2.40354 12.4449L2.39877 12.43C1.26341 8.89403 2.55494 4.6039 6.57209 3.29227ZM8.55284 5.00015C8.05951 5.00015 7.59896 5.0545 7.21304 5.18679L7.20561 5.18934L7.19814 5.19177C4.50999 6.06635 3.40893 9.01169 4.29832 11.8039C4.82392 13.2739 5.66716 14.6072 6.76073 15.7038C8.37525 17.2511 10.1218 18.6185 12.0055 19.823C13.883 18.6438 15.655 17.2474 17.2502 15.7123C18.3495 14.6178 19.1915 13.2778 19.7087 11.8086C20.5858 9.00787 19.4774 6.06646 16.8312 5.21116L16.8223 5.20827L16.8223 5.20823C16.3986 5.0669 15.9402 5.00015 15.4648 5.00015C14.3739 5.00015 13.6199 5.52513 12.6048 6.29627L11.9989 6.7566L11.3937 6.29532C10.3948 5.53396 9.62417 5.00015 8.55284 5.00015Z"
      fill="currentColor"
    />
  </SvgIcon>
)
