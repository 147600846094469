import React from 'react'
import {
  alpha,
  Box,
  Card as MuiCard,
  CardContent,
  CardHeader as MuiCardHeader,
  cardHeaderClasses,
  styled,
} from '@mui/material'

import { Icon, Text } from '../../reskin'

const Card = styled(MuiCard)<{ error?: boolean | string }>(
  ({ error, theme }) => ({
    maxWidth: '928px',
    borderRadius: '16px',
    width: '100%',
    boxShadow:
      '0px -4px 20px rgba(0, 0, 0, 0.04), 0px 32px 48px rgba(118, 124, 119, 0.08)',
    fontSize: '1.3rem',
    padding: theme.spacing(4),

    ...(error && {
      '& $header': {
        borderColor: theme.palette.error.main,
        background: alpha(theme.palette.error.main, 0.2),
      },
    }),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      borderRadius: '8px',
    },
  }),
)

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0, 1, 0),
  },
  maxHeight: '36px',
  boxSizing: 'content-box',

  [`& .${cardHeaderClasses.action}`]: {
    margin: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: 34,
    minWidth: 150,
    alignSelf: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 10,
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      backgroundColor: 'purple',
    },
  },
}))

type Props = {
  title: string
  actions: React.ReactNode
  error?: string
  children?: JSX.Element
}
export const Container = React.forwardRef<HTMLDivElement, Props>(
  ({ title, children, actions, error }, ref) => {
    return (
      <Card error={error} elevation={4} ref={ref}>
        <CardHeader
          title={
            <Text
              use={['h1', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
              color="text.softBlack"
            >
              {title}
            </Text>
          }
          action={actions}
        />
        <CardContent sx={{ padding: '0 !important' }}>{children}</CardContent>
      </Card>
    )
  },
)

export const DynamicFieldsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}))

export const FormRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  lineHeight: '1.4em',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&:first-of-type': {
    paddingTop: 0,
  },
  '&:nth-last-of-type(2)': {
    paddingBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:nth-last-of-type(2)': {
      paddingBottom: '24px !important',
    },
  },
}))

export const FormRowName = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  overflowWrap: 'anywhere',
  justifyContent: 'flex-start',
  alignItems: 'center',
  ...theme.extension.typography.paragraphs[18],
  color: theme.extension.colors.text.softBlack,
  paddingRight: 12,
  [theme.breakpoints.down('sm')]: {
    paddingRight: 0,
    ...theme.extension.typography.paragraphs[16],
  },
}))

export const FormRowValue = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  overflowWrap: 'anywhere',
  ...theme.extension.typography.subheadings['18m'],
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    ...theme.extension.typography.subheadings['16m'],
    textAlign: 'left',
  },
}))

export const SaveCancelContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: theme.spacing(3),
  columnGap: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginTop: theme.spacing(2),
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(2),
  },
}))

export const StyledIcon = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(1),
  color: theme.extension.colors.text.softBlack,
  [theme.breakpoints.down('sm')]: {
    width: 16,
    height: 16,
  },
}))
