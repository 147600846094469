import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import {
  DirectMailUserAddressInfo,
  DirectMailUserNameInfo,
} from '@common/types/api/direct-mail'

import {
  getCustomerAddressByDirectMailCode,
  getCustomerNameByDirectMailCode,
} from '../api'

type DirectMailUserInfo = DirectMailUserNameInfo | DirectMailUserAddressInfo

export type UseDirectMailUserInfoReturn<DirectMailUserInfo> = {
  userInfo: DirectMailUserInfo
  userInfoLoaded: boolean
}

export const useDirectMailUserInfo = (
  directMailService,
): UseDirectMailUserInfoReturn<DirectMailUserInfo> => {
  const [userInfo, setUserInfo] = useState<DirectMailUserInfo | undefined>()
  const [userInfoLoaded, setUserInfoLoaded] = useState(false)
  const router = useRouter()

  const {
    query: { dm_code: code, zip },
  } = router
  const fetchData = async (code: string, zip: string): Promise<void> => {
    try {
      setUserInfoLoaded(false)
      const userInfoData: DirectMailUserInfo = await directMailService({
        code,
        zip,
      })
      setUserInfo(userInfoData?.lookupCode ? userInfoData : undefined)
    } finally {
      setUserInfoLoaded(true)
    }
  }

  useEffect(() => {
    if (!code) {
      setUserInfoLoaded(true)
      return
    }
    void fetchData(code as string, zip as string)
  }, [code])

  return {
    userInfo,
    userInfoLoaded,
  }
}

export const useDirectMailUserNameInfo =
  (): UseDirectMailUserInfoReturn<DirectMailUserNameInfo> => {
    return useDirectMailUserInfo(
      getCustomerNameByDirectMailCode,
    ) as UseDirectMailUserInfoReturn<DirectMailUserNameInfo>
  }

export const useDirectMailUserAddressInfo =
  (): UseDirectMailUserInfoReturn<DirectMailUserAddressInfo> => {
    return useDirectMailUserInfo(
      getCustomerAddressByDirectMailCode,
    ) as UseDirectMailUserInfoReturn<DirectMailUserAddressInfo>
  }
