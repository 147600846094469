import React, { RefObject } from 'react'
import { NumericFormat } from 'react-number-format'

import { CurrencyTextFieldProps, TextFieldProps } from '@common/types'

import { TextField } from './text-field'

export const CurrencyTextField = React.forwardRef<
  RefObject<TextFieldProps>,
  CurrencyTextFieldProps
>((props, ref) => {
  return (
    <NumericFormat
      getInputRef={ref}
      customInput={TextField}
      allowNegative={false}
      thousandSeparator
      prefix="$"
      {...props}
    />
  )
})
