/* eslint-disable no-restricted-imports */
import {
  alpha,
  darken,
  lighten,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material'
import propertyOf from 'lodash/propertyOf'
import values from 'lodash/values'

import { mrSansSerifFontStack } from './fonts'
import { createTheme } from './mui-wrapper'
import { FontScaleMap, Palette, ThemeOptions } from './theme-types'

type ConsumerFont = 'Poppins'
const poppins: ConsumerFont = 'Poppins'

const fontScaleMap: FontScaleMap = {
  0: { fontSize: '.875rem', lineHeight: '1.375rem' }, // label
  1: { fontSize: '1rem', lineHeight: '1.8rem' },
  2: { fontSize: '1.25rem', lineHeight: '1.875rem' },
  3: { fontSize: '1.5rem', lineHeight: '2.25rem' },
  4: { fontSize: '1.75rem', lineHeight: '2.25rem' },
  5: { fontSize: '2rem', lineHeight: '2.2rem' },
  6: { fontSize: '2.5rem', lineHeight: '2.75rem' },
  7: { fontSize: '3rem', lineHeight: '3.3rem' },
  inherit: { fontSize: 'inherit', lineHeight: 'inherit' },
}

enum EFontWeight {
  black = 900,
  bold = 700,
  semibold = 600,
  medium = 500,
  regular = 400,
}

/**
 * Referencing this file
 * https://www.figma.com/file/avlpKiPf6U8EcT3lCfGmWL/Boreal-Foundations%3A-Color?node-id=101%3A165
 */
export const CxColors = {
  base: {
    freshMint: '#00C696',
    lightMint: '#DFFFE3',
    greenLeaf: '#008765',
    blueSky: '#0075E3',
    deepGreen: '#09414E',
    groundBlack: '#161616',
  },
  semantic: {
    error: '#E00000',
    errorGlow: '#F5ADAD',
    warning: '#EAC400',
  },
  surface: {
    babyGray: '#F4F4F4',
    cloudGray: '#F8F8F8',
  },
  divider: {
    darkGray: '#47494E',
    lightGray: '#DDDDDD',
  },
  text: {
    black: '#000000', // button labels, small type
    white: '#FFFFFF', // knockout text
    softBlack: '#191C22', // headings/ subheadings
    bodyGray: '#636363', // body copy
  },
  neutral: {
    200: '#d1d8da',
    300: '#F9F9F9',
    400: '#F3F3F3',
    500: '#E8E8E8',
    600: '#DDDDDD',
    700: '#D1D1D1',
    800: '#8C8C8C',
    900: '#636363',
    1000: '#000000',
  },
}

const fontScale = propertyOf(fontScaleMap) as <TKey extends keyof FontScaleMap>(
  path: TKey,
) => FontScaleMap[TKey]

const typography: ThemeOptions['typography'] = {
  fontFamily: mrSansSerifFontStack(poppins),
  fontWeightMedium: EFontWeight.medium,
  fontWeightRegular: EFontWeight.medium,
  h1:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(7),
    },
  h2:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(6),
    },
  h3:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(5),
    },
  h4:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(4),
    },
  h5:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(4),
      letterSpacing: '0.0875rem',
      textTransform: 'uppercase',
    },
  h6:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(3),
    },
  body1:
    //@css
    {
      //Maps to Body copy LG in Figma
      fontStyle: 'normal',
      ...fontScale(3), //NOTE: Figma says 1.5rem after P, unclear what that means.
    },
  body2:
    //@css
    {
      //Maps to Body copy MD in Figma
      fontStyle: 'normal',
      ...fontScale(2), //NOTE: Figma says 1.5rem after P, unclear what that means.
    },
  caption:
    //@css
    {
      //Maps to Body copy MD in Figma
      fontStyle: 'normal',
      fontWeight: EFontWeight.regular,
      ...fontScale(0),
      fontSize: '0.75rem',
    },
  button:
    //@css
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      ...fontScale(3), //NOTE: Figma says 1.5rem after P, unclear what that means.
      letterSpacing: '0.02rem',
      textTransform: 'uppercase',
    },
} as const

const accentOne: Palette = {
  contrast: CxColors.base.groundBlack,
  dark: darken(CxColors.base.blueSky, 0.15),
  light: lighten(CxColors.base.blueSky, 0.1),
  main: CxColors.base.blueSky,
}

const accentTwo: Palette = {
  contrast: CxColors.base.deepGreen,
  dark: CxColors.base.greenLeaf,
  light: CxColors.base.lightMint,
  main: CxColors.base.freshMint,
}

const grey: Palette = {
  contrast: CxColors.neutral[1000],
  dark: CxColors.neutral[800],
  main: CxColors.neutral[600],
  light: CxColors.neutral[400],
}

const background: Palette = {
  contrast: '',
  dark: '',
  light: CxColors.surface.cloudGray,
  main: CxColors.surface.babyGray,
}

// Palettes for the Theme.
const themePalette: PaletteOptions & {
  secondary: SimplePaletteColorOptions
  tertiary: Palette
} = {
  text: {
    primary: CxColors.text.softBlack,
    secondary: CxColors.text.bodyGray,
  },
  primary: {
    main: CxColors.base.blueSky,
    light: lighten(CxColors.base.lightMint, 0.1),
    dark: darken(CxColors.base.blueSky, 0.15),
    contrastText: CxColors.base.groundBlack,
  },
  secondary: {
    main: CxColors.base.freshMint,
    light: CxColors.base.lightMint,
    dark: CxColors.base.greenLeaf,
    contrastText: CxColors.base.deepGreen,
  },
  tertiary: {
    main: lighten(CxColors.base.blueSky, 0.7),
    light: lighten(CxColors.base.blueSky, 0.8),
    dark: '',
    contrast: '',
  },
  divider: CxColors.divider.lightGray,
  grey: CxColors.neutral,
  background: { default: '#fff' },
  error: {
    main: CxColors.semantic.error,
    light: CxColors.semantic.error,
  },
  // type: 'light' as const,
}

const overrides: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        scrollBehavior: 'smooth',
      },

      body: {
        overflowX: 'hidden',
        maxWidth: '100vw',
        fontSize: '1.25rem',
        lineHeight: 1.43,
      },

      ul: {
        paddingInlineStart: '20px',
      },
    },
  },

  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: accentOne.main,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        '&.Mui-checked': {
          color: CxColors.base.blueSky,
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        margin: '1.125rem',
        // Group label
        '& .MuiFormLabel-root': {
          display: 'block',
          ...fontScale(0),
          fontWeight: EFontWeight.medium,
          color: CxColors.text.bodyGray,
          // Add spacing between radio group and its label
          '& + .MuiFormGroup-root': {
            marginTop: '4px',
          },
        },
        // Radio group
        '& .MuiFormGroup-root': {
          marginLeft: '.625rem',
          // Radio label
          '& .MuiFormControlLabel-label': {
            ...fontScale(1),
          },
          // Radio button
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        },

        // Gray out radio labels unless the form group is focused
        '& .MuiFormLabel-root:not(.Mui-focused) + .MuiFormGroup-root': {
          color: CxColors.text.bodyGray,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        // If there is a label attached to the
        // TextField, shift it down.
        transform: 'translate(0px, 1.125rem)',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '50px',
        border: `1px solid ${grey.main}`,
        ...fontScale(1),

        '&& .MuiInputBase-input.MuiInputBase-input::-webkit-input-placeholder':
          {
            opacity: '0.42 !important', // the value 0.42 is from the material style
          },

        '& fieldset': {
          border: 'none',
        },

        '& .Mui-error': {
          borderColor: 'red',
        },

        '& .MuiInputBase-input': {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
      inputAdornedStart: {
        borderLeft: `1px solid ${grey.main}`,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        height: '56px',
        boxSizing: 'border-box',

        borderWidth: '2px',
        borderColor: alpha(CxColors.text.softBlack, 0.1),
        '&$focused': {
          opacity: 1,
          borderColor: CxColors.base.freshMint,
        },
        // Fix autofill background not filling the whole input
        '& input': {
          height: '100%',
          boxSizing: 'border-box',
        },
      },
      input: {
        ...fontScale(1),
        padding: '0.375rem 0.375rem 0.4375rem 0.375rem',
      },
      adornedStart: {
        paddingLeft: '0.375rem',
        '& div': {
          height: '100%',
          marginRight: '0.375rem',
          padding: '0 0.25rem',
          '& p': {
            ...fontScale(1),
            height: 'auto',
          },
        },
      },
      inputAdornedStart: {
        paddingLeft: '0.375rem',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...fontScale(1),
        fontWeight: 600,
        color: accentTwo.main,
        transform: 'translate(0rem, -1.5rem) !important',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        display: 'block',
        ...fontScale(0),
        fontWeight: EFontWeight.medium,
        color: CxColors.text.bodyGray,
        '& .Mui-focused': {
          color: CxColors.text.softBlack,
        },

        '& .Mui-error': {
          color: 'inherit',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '& .Mui-error': {
          color: 'red',
        },

        '& .MuiFormHelperText-contained': {
          marginTop: 0,
          marginLeft: 0,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: '4px',

        '& .MuiFormGroup-root': {
          ...fontScale(1),
        },
        '& .MuiFormControlLabel-label': {
          ...fontScale(1),
        },
        '&.MuiRadio-colorSecondary.Mui-checked:not(.Mui-disabled)': {
          color: CxColors.base.blueSky,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        minWidth: '120px',
      },
      // Mui V5 doesn't support this property MuiMenuItem is trying to fix that
      // selectMenu: {
      //   height: '100%',
      //   display: 'flex',
      //   alignItems: 'center',
      // },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        '& .MuiMenu-list': {
          '& .MuiMenuItem-root': {
            lineHeight: '1.1875em',
            fontSize: '1em',
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-containedPrimary': {
          fontWeight: EFontWeight.medium,
          color: '#fff',
        },
      },
      containedPrimary: {},
      containedSecondary: {
        backgroundColor: themePalette.secondary.main,
        color: '#000',
        lineHeight: '1.5rem',
        paddingLeft: '1.125rem',
        paddingRight: '1.125rem',
        borderRadius: '4rem',
        '&:hover': {
          backgroundColor: themePalette.secondary.dark,
        },
        '&.MuiButton-containedSizeSmall': {
          paddingLeft: '1.125rem',
          paddingRight: '1.125rem',
        },
      },
    },
  },
}

export const theme = createTheme({
  mrExtensions: {
    fontLoaderConfig: {
      google: {
        families: [`${poppins}:${values(EFontWeight).join(',')}`],
      },
    },
    fontScale: fontScaleMap,
    palette: {
      accentOne,
      accentTwo,
      grey,
      background,
    },
  },
  palette: themePalette,
  typography,
  components: overrides,
})
