import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BagV2DeleteIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49921 10.5657C2.15496 8.15595 4.02481 6 6.459 6H17.5409C19.9751 6 21.845 8.15595 21.5007 10.5657L20.4805 17.7071C20.1286 20.1704 18.019 22 15.5308 22H8.46916C5.9809 22 3.8713 20.1704 3.51941 17.7071L2.49921 10.5657ZM6.459 8C5.2419 8 4.30698 9.07798 4.4791 10.2828L5.49931 17.4243C5.71044 18.9022 6.9762 20 8.46916 20H15.5308C17.0237 20 18.2895 18.9022 18.5006 17.4243L19.5208 10.2828C19.693 9.07798 18.758 8 17.5409 8H6.459Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00013 4.76363C7.84713 3.06962 9.57835 2 11.4721 2H12.5279C14.4217 2 16.1531 3.07003 17 4.76396L17.8944 6.55279C18.1414 7.04677 17.9412 7.64744 17.4472 7.89443C16.9532 8.14142 16.3525 7.94119 16.1055 7.44721L15.2111 5.65838C14.703 4.64202 13.6642 4 12.5279 4H11.4721C10.3357 4 9.29711 4.64179 8.78898 5.65805L7.8944 7.44721C7.64741 7.94119 7.04674 8.14142 6.55276 7.89443C6.05878 7.64744 5.85856 7.04676 6.10555 6.55279L7.00013 4.76363Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.79289 11.2929C9.18342 10.9024 9.81658 10.9024 10.2071 11.2929L12 13.0858L13.7929 11.2929C14.1834 10.9024 14.8166 10.9024 15.2071 11.2929C15.5976 11.6834 15.5976 12.3166 15.2071 12.7071L13.4142 14.5L15.2071 16.2929C15.5976 16.6834 15.5976 17.3166 15.2071 17.7071C14.8166 18.0976 14.1834 18.0976 13.7929 17.7071L12 15.9142L10.2071 17.7071C9.81658 18.0976 9.18342 18.0976 8.79289 17.7071C8.40237 17.3166 8.40237 16.6834 8.79289 16.2929L10.5858 14.5L8.79289 12.7071C8.40237 12.3166 8.40237 11.6834 8.79289 11.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
