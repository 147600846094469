import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ShareIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L12 4.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V3C11 2.44772 11.4477 2 12 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5.55228 12 6 12.4477 6 13V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V13C4 12.4477 4.44772 12 5 12Z"
      fill="currentColor"
    />
  </SvgIcon>
)
