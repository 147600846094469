import values from 'lodash/values'
import { BaseSchema, bool, object, SchemaOf, string, StringSchema } from 'yup'

import {
  EmploymentStatus as EmploymentStatusValues,
  EmploymentStatusKeys,
  ResidenceStatus as EResidenceStatus,
  ResidenceStatusKeys,
  State as EState,
  StateKeys,
} from '@common/types'

import {
  getMaxTest,
  getMinTest,
  noDecimal,
  notLessThanAMonth,
  notMoreThan120Yrs,
  positiveCurrency,
  positiveOrZeroNumber,
  zipCode,
} from './tests'
import { updateYupErrorMessages } from './yup'

updateYupErrorMessages()

const LivedInAddress = object({
  months: string()
    .required()
    .test(getMinTest(0))
    .test(noDecimal())
    .test(notLessThanAMonth())
    .test(getMaxTest(11)),
  years: string()
    .required()
    .test(getMinTest(0))
    .test(noDecimal())
    .test(getMaxTest(120))
    .test(notMoreThan120Yrs()),
})

const ResidenceStatus = string()
  .required()
  .oneOf(
    values(EResidenceStatus),
    'This field is required',
  ) as StringSchema<ResidenceStatusKeys>
const EmploymentStatus = string()
  .required()
  .oneOf(
    values(EmploymentStatusValues),
    'This field is required',
  ) as StringSchema<EmploymentStatusKeys>
const State = string()
  .required()
  .oneOf(values(EState), 'This field is required') as StringSchema<StateKeys>

const ifBoolCoborrower = (schema: BaseSchema) =>
  bool().nullable().when('hasCoborrower', { is: true, then: schema })
const ifCoborrower = (schema: BaseSchema) =>
  string().nullable().when('hasCoborrower', { is: true, then: schema })
const ifCoborrowerResidence = (schema: BaseSchema) =>
  string().when('coborrowerResidence', { is: false, then: schema })

export type ResidenceEmploymentForm = {
  hasCoborrower: boolean
  address: string
  apartment: string
  city: string
  state: StateKeys
  zip: string
  livedInAddress: {
    years: string
    months: string
  }

  rentOrOwn: ResidenceStatusKeys
  monthlyPayment: string
  employmentStatus: EmploymentStatusKeys
  yearlyIncome: string
  coborrowerResidence: boolean
  coaddress: string
  coapartment: string
  cocity: string
  costate: StateKeys
  cozip: string
  colivedInAddress: {
    years: string
    months: string
  }

  corentOrOwn: ResidenceStatusKeys
  comonthlyPayment: string
  coemploymentStatus: EmploymentStatusKeys
  coyearlyIncome: string
}

export const ReviewResidenceEmployment = object({
  address: string().required(),
  city: string().required(),
  state: State,
  zip: string().required().test(zipCode()),
  livedInAddress: LivedInAddress,
  rentOrOwn: ResidenceStatus,
  monthlyPayment: string().required().test(positiveOrZeroNumber()),
  employmentStatus: EmploymentStatus,
  yearlyIncome: string().required().test(positiveCurrency()),
})

export const ResidenceEmployment: SchemaOf<ResidenceEmploymentForm> =
  ReviewResidenceEmployment.shape({
    hasCoborrower: bool().required(),
    coborrowerResidence: ifBoolCoborrower(bool().required()),
    coaddress: ifCoborrowerResidence(string().required()),
    cocity: ifCoborrowerResidence(string().required()),
    costate: ifCoborrowerResidence(State) as StringSchema<StateKeys>,
    cozip: ifCoborrowerResidence(string().required().test(zipCode())),
    colivedInAddress: object().when('coborrowerResidence', {
      is: false,
      then: LivedInAddress,
    }) as typeof LivedInAddress,
    corentOrOwn: ifCoborrowerResidence(
      ResidenceStatus,
    ) as StringSchema<ResidenceStatusKeys>,
    comonthlyPayment: ifCoborrowerResidence(
      string().required().test(positiveCurrency()),
    ),
    coemploymentStatus: ifCoborrower(
      EmploymentStatus,
    ) as StringSchema<EmploymentStatusKeys>,
    coyearlyIncome: ifCoborrower(string().required().test(positiveCurrency())),
    apartment: string(),
    coapartment: string(),
  })
