import React, { FC } from 'react'
import { Box, styled } from '@mui/material'

const Root = styled(Box)({
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
})

export const Content = styled(Box)({
  flex: '1 0 auto',
})

const Footer = styled(Box)({
  flexShrink: 0,
})

type Props = {
  header?: React.ReactNode
  footer?: React.ReactNode
  classes?: { content: string }
  children: React.ReactNode
}

const BaseTemplate: FC<Props> = ({ children, footer, header }) => {
  return (
    <Root>
      {header}

      <Content>{children}</Content>

      <Footer>{footer}</Footer>
    </Root>
  )
}

export default BaseTemplate
