import React, { FC } from 'react'
import {
  Checkbox as MuiCheckbox,
  checkboxClasses,
  FormControlLabel as FormControlLabelBase,
  styled,
} from '@mui/material'

import { CheckboxProps } from '@common/types'

import { Text } from '../text'
import { ColorPath } from '../types'

type CheckboxPropsWithColor = CheckboxProps & {
  color?: ColorPath
}

const FormControlLabel = styled(FormControlLabelBase, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ error, theme }) => ({
  marginBottom: 'auto',
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    marginTop: 'auto',
  },
  color: error
    ? theme.extension.colors.semantic.error
    : theme.extension.colors.neutral[800],

  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

const StyledCheckbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ error, theme }) => ({
  marginBottom: 'auto',
  [theme.breakpoints.up('sm')]: {
    marginTop: 'auto',
  },
  color: error
    ? theme.extension.colors.semantic.error
    : theme.extension.colors.neutral[800],
  padding: theme.spacing(0, 1),
  '&:hover': {
    backgroundColor: 'transparent',
  },

  [`&.${checkboxClasses.checked}`]: {
    color: theme.extension.colors.base.blueSky,
  },
}))

export const Checkbox: FC<CheckboxPropsWithColor> = React.forwardRef(
  (props, ref) => {
    const {
      checked,
      onChange,
      error,
      label,
      value,
      color = 'text.softBlack',
      'data-testid': dataTestId,
    } = props

    return (
      <>
        {error && (
          <Text
            use={['span', 'subheadings.14m']}
            color="semantic.error"
            mt={{ xs: 4, sm: 6 }}
            sx={{ display: 'flex' }}
          >
            {error}
          </Text>
        )}
        <FormControlLabel
          error={!!error}
          ref={ref}
          label={
            <Text
              use={[
                'span',
                {
                  xs: error ? 'subheadings.16m' : 'paragraphs.16',
                  sm: error ? 'subheadings.18m' : 'paragraphs.18',
                },
              ]}
              color={error ? 'semantic.error' : color}
              margin={0}
            >
              {label}
            </Text>
          }
          control={
            <StyledCheckbox
              error={!!error}
              checked={checked}
              onChange={onChange}
              value={value}
              // https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              inputProps={{ 'data-testid': dataTestId }}
            />
          }
        />
      </>
    )
  },
)
