import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FaqDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M30.2618 17.1803C37.2003 17.0744 42.8635 22.5355 42.9979 29.4735C43.0176 30.4922 42.9158 31.4837 42.7061 32.4347C42.3508 34.045 42.1397 35.6837 42.1397 37.3328V40.5203C42.1397 41.0087 41.7438 41.4046 41.2554 41.4046H38.0679C36.4188 41.4046 34.7801 41.6157 33.1698 41.9709C32.2188 42.1807 31.2274 42.2825 30.2087 42.2628C23.2707 42.1285 17.8096 36.4654 17.9155 29.527C18.0187 22.7546 23.4894 17.2838 30.2618 17.1803Z"
      fill="currentColor"
    />
    <path
      d="M4.25294 20.6972L4.25294 20.6973C4.22782 21.9977 4.35779 23.2648 4.62607 24.4808C5.04928 26.3996 5.29839 28.3412 5.29839 30.2864V34.1697C5.29839 35.1788 6.11642 35.997 7.12568 35.997H11.009C12.9541 35.997 14.8957 36.2461 16.8146 36.6693C18.0305 36.9375 19.2976 37.0676 20.5981 37.0424C29.4641 36.8707 36.4451 29.6306 36.31 20.7654C36.178 12.1095 29.1862 5.11759 20.5304 4.98546C11.6651 4.85002 4.42468 11.8311 4.25294 20.6972Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M18.0136 23.5361H21.2465V23.2174C21.2465 22.1614 21.7602 21.6301 22.7533 21.0656C24.0889 20.3084 24.9999 19.3587 24.9999 17.7449C24.9999 15.2477 22.8286 13.9991 19.904 13.9991C17.2396 13.9991 15.0273 15.2212 14.9999 18.1434H18.5067C18.5067 17.3796 19.1369 16.8416 19.8766 16.8416C20.5615 16.8416 21.1095 17.2933 21.1095 17.9574C21.1095 18.7012 20.4451 19.1595 19.5752 19.7107C18.5547 20.3549 18.0136 21.0324 18.0136 23.2174V23.5361ZM19.6848 27.9991C20.6917 27.9991 21.5889 27.1756 21.6026 26.1396C21.5889 25.1301 20.6917 24.3065 19.6848 24.3065C18.6232 24.3065 17.7533 25.1301 17.767 26.1396C17.7533 27.1756 18.6232 27.9991 19.6848 27.9991Z"
      fill="white"
    />
  </SvgIcon>
)
