import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PaperclipIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1717 3.41422C14.7338 1.85212 17.2664 1.85212 18.8285 3.41422C20.3906 4.97632 20.3906 7.50897 18.8285 9.07107L9.98969 17.9099C9.01338 18.8862 7.43047 18.8862 6.45416 17.9099C5.47785 16.9336 5.47785 15.3507 6.45416 14.3744L15.293 5.53554C15.6835 5.14501 16.3167 5.14501 16.7072 5.53554C17.0977 5.92606 17.0977 6.55923 16.7072 6.94975L7.86837 15.7886C7.67311 15.9838 7.67311 16.3004 7.86837 16.4957C8.06363 16.691 8.38022 16.691 8.57548 16.4957L17.4143 7.65686C18.1954 6.87581 18.1954 5.60948 17.4143 4.82843C16.6333 4.04738 15.3669 4.04738 14.5859 4.82843L5.74705 13.6673C4.38021 15.0341 4.38022 17.2502 5.74705 18.617C7.11388 19.9838 9.32996 19.9838 10.6968 18.617L19.793 9.52082C20.1835 9.1303 20.8167 9.1303 21.2072 9.52082C21.5977 9.91134 21.5977 10.5445 21.2072 10.935L12.111 20.0312C9.96313 22.1791 6.48072 22.1791 4.33284 20.0312C2.18495 17.8833 2.18495 14.4009 4.33284 12.2531L13.1717 3.41422Z"
      fill="currentColor"
    />
  </SvgIcon>
)
