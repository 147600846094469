import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import memoize from 'lodash/memoize'
import values from 'lodash/values'
import { DateTime as LuxonDateTime, DurationUnits } from 'luxon'
import {
  BaseSchema,
  bool,
  mixed,
  object,
  SchemaOf,
  string,
  StringSchema,
} from 'yup'

import {
  Citizenship as CitizenshipValues,
  CitizenshipKeys,
  ERelationship,
} from '@common/types'

import { getCustomerEmailExists } from '../../api'
import { asyncDebounce } from '../utils'

import { EErrorType } from './tests'
import { sequence, updateYupErrorMessages } from './yup'

updateYupErrorMessages()

const toLuxonDate = (format: string) => (val: string) =>
  LuxonDateTime.fromFormat(val ?? '', format)
const personalInfoDateToLuxonDate = toLuxonDate('MM/dd/yyyy')
const compareTodayToLuxonDate =
  (units: DurationUnits) => (start: LuxonDateTime) =>
    LuxonDateTime.now().diff(start, units)

export const emailIsInUseMessage =
  'This email is already in use. Please enter a different email or log in to continue'

export const Email = string()
  .required()
  .email('Please enter a valid email address')
/**
 * @warning
 * Make sure you don't reinitiate this on every rerender inside the component.
 * Because of debouncing this schema is statefull
 */

const CustomerEmailAddress = (initialEmail?: string): StringSchema => {
  const getEmailExists = memoize(getCustomerEmailExists, JSON.stringify)
  return sequence(
    'email',
    Email,
    string().test({
      name: 'Duplicate email',
      message: emailIsInUseMessage,
      test: async (email, ctx) => {
        if (initialEmail && email === initialEmail) {
          return true
        }

        const { emailExists, accountCreated } = await getEmailExists({ email })

        return accountCreated
          ? ctx.createError({
              message:
                "Looks like you've been here before! Sign in to continue with your existing account, or use a different email to start a new application.",
              type: EErrorType.accountCreated,
            })
          : emailExists
          ? ctx.createError({
              message:
                "Looks like you've been here before! Sign in to continue with your existing account, or use a different email to start a new application.",
              type: EErrorType.emailExists,
            })
          : true
      },
    }),
  )
}

const CoEmailAddress = (): StringSchema => {
  const getEmailExists = asyncDebounce(
    memoize(getCustomerEmailExists, JSON.stringify),
    250,
  )
  return sequence(
    'email',
    Email,
    string().test({
      name: 'Duplicate email',
      message: emailIsInUseMessage,
      test: async (email) => {
        const { emailExists } = await getEmailExists({ email })
        return !emailExists
      },
    }),
  )
}

const ConfirmEmail = <T extends {}>(emailFieldName: keyof T): StringSchema =>
  Email.concat(
    string().test({
      name: 'validate confirm email',
      message: 'Email addresses should match',
      test: (value, context) => value === context.parent[emailFieldName],
    }),
  )

const DistinctEmails = string().test({
  name: 'Borrower email duplicate',
  message: "Co-borrower's email address should be different from borrower's",
  test: (value, context) => {
    return value?.toLowerCase() !== context.parent.emailAddress?.toLowerCase()
  },
})

const Name = string()
  .required()
  .transform((val: string) => val?.trim())
  .matches(
    /* Name with alphabet may contain - or ' in between two words and length should be between 1 to 40
     * e.g Test or Test'Test or Test-Test etc..
     */
    /^(?=.{1,40}$)[A-Za-z\u00C0-\u00FF]+(?:[ '-][A-Za-z\u00C0-\u00FF]+)*$/,
    'Please enter a valid name',
  )
const Cellphone = string()
  .required()
  .transform((val: string) => val.replaceAll(/\D/g, ''))
  .min(10, 'Please enter a valid phone number')

const Dob = string()
  .required()
  .test({
    name: 'Valid date',
    message: 'Please enter a valid date of birth',
    test: flow(personalInfoDateToLuxonDate, get('isValid')),
  })
  .test({
    name: 'Over 18',
    message: 'Must be over 18 and younger than 125',
    test: flow(
      personalInfoDateToLuxonDate,
      compareTodayToLuxonDate('years'),
      get('years'),
      (years) => years >= 18 && years <= 125,
    ),
  })

const Citizenship = string().oneOf(
  values(CitizenshipValues),
  'This field is required',
) as StringSchema<CitizenshipKeys>
const Relationship = string()
  .required()
  .oneOf(
    values(ERelationship),
    'This field is required',
  ) as StringSchema<ERelationship>

const Customer = (initialEmail: string) => {
  return object({
    firstName: Name,
    lastName: Name,
    email: CustomerEmailAddress(initialEmail),
    phoneNumber: Cellphone,
    birthdate: Dob,
    citizenship: Citizenship,
  })
}

export const Borrower = (initialEmail: string) =>
  Customer(initialEmail).shape({
    hasCoborrower: bool().required(),
  })

export const Coborrower = (initialEmail: string) =>
  Customer(initialEmail).shape({
    relationship: Relationship,
  })

const ifCosigner = (schema: BaseSchema) =>
  mixed().when('cosigner', { is: true, then: schema })

export type PersonalInfoForm = {
  firstName: string
  lastName: string
  emailAddress: string
  cellphone: string
  confirmEmailAddress: string
  citizenship: CitizenshipKeys
  dob: string
  cosigner: boolean
  cofirstName: string
  colastName: string
  coemailAddress: string
  cocellphone: string
  coconfirmEmailAddress: string
  cocitizenship: CitizenshipKeys
  codob: string
  relationship: ERelationship
}

export const PersonalInfoSchema = (): SchemaOf<PersonalInfoForm> =>
  object({
    firstName: Name,
    lastName: Name,
    emailAddress: CustomerEmailAddress(),
    confirmEmailAddress: ConfirmEmail<PersonalInfoForm>('emailAddress'),
    cellphone: Cellphone,
    security: string(),
    dob: Dob,

    cosigner: bool().required(),

    cofirstName: ifCosigner(Name),
    colastName: ifCosigner(Name),
    coemailAddress: ifCosigner(
      // eslint-disable-next-line unicorn/prefer-spread
      Email.concat(DistinctEmails).concat(CoEmailAddress()),
    ),
    cocellphone: ifCosigner(Cellphone),
    coconfirmEmailAddress: ifCosigner(
      ConfirmEmail<PersonalInfoForm>('coemailAddress'),
    ),
    cosecurity: string(),
    codob: ifCosigner(Dob),

    citizenship: Citizenship,
    cocitizenship: ifCosigner(Citizenship),
    relationship: ifCosigner(Relationship),
  })
