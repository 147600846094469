import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ChartDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M15.1632 33.2188V42.4375C15.1632 43.3005 14.4637 44 13.6007 44H5.5625C4.69953 44 4 43.3005 4 42.4375V33.2188C4 32.3558 4.69953 31.6563 5.5625 31.6563H13.6007C14.4637 31.6563 15.1632 32.3558 15.1632 33.2188ZM29.5816 27.2755V42.4375C29.5816 43.3005 28.882 44 28.0191 44H19.9809C19.118 44 18.4184 43.3005 18.4184 42.4375V27.2755C18.4184 26.4125 19.118 25.713 19.9809 25.713H28.0191C28.882 25.713 29.5816 26.4125 29.5816 27.2755ZM44 21.3323V42.4375C44 43.3005 43.3005 44 42.4375 44H34.3993C33.5363 44 32.8368 43.3005 32.8368 42.4375V21.3323C32.8368 20.4693 33.5363 19.7698 34.3993 19.7698H42.4375C43.3005 19.7698 44 20.4693 44 21.3323Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M8.47452 21.7902C7.86429 21.1799 7.86429 20.1905 8.47452 19.5804L17.6229 10.432C18.2331 9.82195 19.2224 9.8218 19.8326 10.432L25.5848 16.1842L34.644 7.125H30.6302C29.7673 7.125 29.0677 6.42547 29.0677 5.5625C29.0677 4.69953 29.7673 4 30.6302 4H38.4154C39.2923 3.99953 39.9787 4.71648 39.9787 5.5625V13.3484C39.9787 14.2114 39.2791 14.9109 38.4162 14.9109C37.5532 14.9109 36.8537 14.2114 36.8537 13.3484V9.33477L26.6896 19.4988C26.0794 20.1091 25.0901 20.1091 24.4799 19.4988L18.7277 13.7466L10.6842 21.7902C10.0741 22.4004 9.0846 22.4002 8.47452 21.7902Z"
      fill="currentColor"
    />
  </SvgIcon>
)
