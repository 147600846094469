import { getAuthToken } from '@src/utils/storage'

import { refiApiUrl } from '../config'

export const DocumentType = {
  TermsOfService: 'terms-of-service',
  PrivacyPolicy: 'privacy-policy',
  CreditReportAuthorization: 'credit-report-authorization',
  CommunicationConsent: 'communication-consent',
} as const

export type DocumentTypeKeys = (typeof DocumentType)[keyof typeof DocumentType]

const DocumentSourceName = 'mr-consumer-site'

export type LegalAcceptance = {
  customerUuid: string
  document: DocumentTypeKeys
}

export const insertLegalAcceptance = async (
  legalAcceptance: LegalAcceptance,
): Promise<void> => {
  await fetch(`${refiApiUrl}/services/v1/legal_acceptance`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({
      customerUuid: legalAcceptance.customerUuid,
      document: legalAcceptance.document,
      sourceName: DocumentSourceName,
    }),
  })
}
