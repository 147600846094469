import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const LoaderV1Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3L13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6L11 3C11 2.44772 11.4477 2 12 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 12C17 11.4477 17.4477 11 18 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H18C17.4477 13 17 12.5523 17 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C11.4477 22 11 21.5523 11 21L11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18L13 21C13 21.5523 12.5523 22 12 22Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 11.4477 2.44772 11 3 11L6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13L3 13C2.44772 13 2 12.5523 2 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92879 4.92893C5.31931 4.5384 5.95248 4.5384 6.343 4.92893L8.46432 7.05025C8.85484 7.44077 8.85484 8.07393 8.46432 8.46446C8.0738 8.85498 7.44063 8.85498 7.05011 8.46446L4.92879 6.34314C4.53826 5.95261 4.53826 5.31945 4.92879 4.92893Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0709 4.92893C19.4614 5.31945 19.4614 5.95261 19.0709 6.34314L16.9495 8.46446C16.559 8.85498 15.9258 8.85498 15.5353 8.46446C15.1448 8.07394 15.1448 7.44077 15.5353 7.05025L17.6566 4.92893C18.0472 4.5384 18.6803 4.5384 19.0709 4.92893Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0712 19.071C18.6807 19.4616 18.0475 19.4616 17.657 19.071L15.5357 16.9497C15.1452 16.5592 15.1452 15.926 15.5357 15.5355C15.9262 15.145 16.5594 15.145 16.9499 15.5355L19.0712 17.6568C19.4617 18.0474 19.4617 18.6805 19.0712 19.071Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92912 19.071C4.5386 18.6805 4.5386 18.0473 4.92912 17.6568L7.05044 15.5355C7.44097 15.145 8.07413 15.145 8.46466 15.5355C8.85518 15.926 8.85518 16.5592 8.46466 16.9497L6.34334 19.071C5.95281 19.4616 5.31965 19.4616 4.92912 19.071Z"
      fill="currentColor"
    />
  </SvgIcon>
)
