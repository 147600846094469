import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const RocketDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47008 35.3424C5.83905 35.3424 6.20821 35.2015 6.4897 34.92L11.1978 30.212C11.761 29.649 11.761 28.7359 11.1978 28.1727C10.6347 27.6096 9.72172 27.6096 9.15845 28.1727L4.45045 32.8807C3.88728 33.4437 3.88728 34.3568 4.45045 34.92C4.73204 35.2016 5.10101 35.3424 5.47008 35.3424ZM15.5134 32.4891C14.9503 31.9261 14.0372 31.9261 13.474 32.4891L4.42238 41.5405C3.85921 42.1035 3.85921 43.0166 4.42238 43.5798C4.70396 43.8613 5.07303 44.0021 5.4421 44.0021C5.81117 44.0021 6.18023 43.8612 6.46172 43.5797L15.5134 34.5283C16.0765 33.9653 16.0765 33.0522 15.5134 32.4891ZM17.7878 36.8007C18.351 36.2377 19.2641 36.2377 19.8272 36.8007C20.3904 37.3638 20.3904 38.2768 19.8272 38.8399L15.1191 43.5478C14.8376 43.8294 14.4684 43.9702 14.0995 43.9702C13.7305 43.9702 13.3614 43.8294 13.0798 43.5478C12.5167 42.9847 12.5167 42.0716 13.0798 41.5085L17.7878 36.8007Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1839 32.1835C22.592 32.5916 23.1719 32.7825 23.7412 32.6873C23.8885 32.6627 24.0353 32.6363 24.1815 32.6083L24.9255 37.4122C25.0238 38.0463 25.4327 38.5798 26.0196 38.8394C26.2636 38.9474 26.522 39.0006 26.7792 39.0006C27.1402 39.0006 27.4989 38.8955 27.8115 38.6888L33.1008 35.1918C33.8067 34.7252 34.2489 33.9736 34.314 33.1299L34.7629 27.3183C37.7224 24.8268 39.9658 21.9219 41.2091 19.518C44.0016 14.1193 44.105 8.41425 43.9591 5.73863C43.909 4.82178 43.1782 4.09094 42.2613 4.0409C39.5857 3.89502 33.8807 3.99845 28.482 6.7909C26.0784 8.03408 23.1741 10.277 20.6828 13.2359L14.8703 13.6849C14.0266 13.7501 13.2752 14.1922 12.8084 14.8981L9.31142 20.1873C8.95752 20.7226 8.90119 21.3925 9.16082 21.9793C9.42038 22.5661 9.95394 22.975 10.588 23.0732L15.392 23.8173C15.3639 23.9639 15.3374 24.1111 15.3127 24.2589C15.2176 24.8281 15.4084 25.408 15.8166 25.8161L22.1839 32.1835ZM29.3504 18.6485C30.35 19.6481 31.6626 20.1479 32.9757 20.1478C34.2884 20.1477 35.6016 19.6479 36.601 18.6485C37.5694 17.6802 38.1027 16.3926 38.1027 15.0232C38.1027 13.6537 37.5694 12.3661 36.601 11.3978C34.6019 9.39874 31.3493 9.39881 29.3504 11.3978C28.382 12.3661 27.8486 13.6537 27.8486 15.0232C27.8486 16.3926 28.382 17.6802 29.3504 18.6485Z"
      fill="currentColor"
    />
  </SvgIcon>
)
