import {
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
} from '@mui/material'
// Allow only once and only here
// eslint-disable-next-line import/no-internal-modules
import { CSSProperties } from '@mui/styles'
import includes from 'lodash/includes'
import { Config as WebFontConfig } from 'webfontloader'

/**
 * String representing a box-shadow value
 */
export type CSSShadowValue = string

/**
 * String representing some value that is either '0', 'Xrem', 'X.Yrem'
 */
export type CSSValueInRem = string

/**
 * String representing a valid CSS color value
 */
export type CSSColorValue = string

/**
 * Union type that defines each of the valid scale values.
 *
 * 1 is the smallest, 7 is the largest.
 *
 * The relative differences between n and n+1 are dependent
 * on what is being scaled.
 */
export type Scale = 1 | 2 | 3 | 4 | 5 | 6 | 7

/**
 * A union of each supported breakpoint.
 */
export type Breakpoint = keyof MuiTheme['breakpoints']['values']

/**
 * JSS object that describes a font at a Scale value
 */
export interface FontScale {
  fontSize: string
  lineHeight: string
}

/**
 * Dictionary mapping Scale values to JSS object that describes
 * a font at that Scale value
 */
export interface FontScaleMap {
  0: FontScale
  1: FontScale
  2: FontScale
  3: FontScale
  4: FontScale
  5: FontScale
  6: FontScale
  7: FontScale
  inherit: FontScale
}

/**
 * Declarative map of scale value to CSS REM value
 */
export interface SpacingScaleMap {
  1: CSSValueInRem
  2: CSSValueInRem
  3: CSSValueInRem
  4: CSSValueInRem
  5: CSSValueInRem
  6: CSSValueInRem
  7: CSSValueInRem
}

/**
 * A palette must dependably define all the color types
 */
export interface Palette {
  readonly contrast: CSSColorValue
  readonly dark: CSSColorValue
  readonly light: CSSColorValue
  readonly main: CSSColorValue
}

type AvailableMuiPalettes = 'primary' | 'secondary' | 'tertiary' | 'common'
interface ExtensionPalettes {
  readonly accentOne: Palette
  readonly accentTwo: Palette
  readonly grey: Palette
  readonly background: Palette
}

/**
 * Map defining the desired global page margin at each breakpoint.
 */
export type GutterBreakpointMap = { [key in Breakpoint]: CSSValueInRem }

/**
 * Declarative and serializable configuration for a theme.
 * The `createTheme` function takes this object and returns
 * the `Theme` object that is used in React Components.
 *
 * @deprecated Import from @mui
 */
export interface ThemeOptions extends MuiThemeOptions {
  mrExtensions: {
    fontScale?: FontScaleMap
    fontLoaderConfig?: WebFontConfig
    palette: ExtensionPalettes
    paper?: {
      baseShadow?: CSSShadowValue
      plusShadow?: CSSShadowValue
    }
    spacingScale?: SpacingScaleMap
    pageGutterBreakpointMap?: GutterBreakpointMap
  }
}

type ColorName = keyof Palette | 'white' | 'black'
type ExtensionPaletteName = keyof ExtensionPalettes
type PaletteName = AvailableMuiPalettes | ExtensionPaletteName

/**
 * Type Guard for checking whether palette is one of our extensions
 *
 * @param paletteName Name of the palette to check
 */
export const isExtensionPaletteName = (
  paletteName: PaletteName,
): paletteName is ExtensionPaletteName =>
  includes(['accentOne', 'accentTwo', 'grey', 'background'], paletteName)

/**
 * In the theme, colors are chosen by palette and color type.
 * The color type is optional and generally defaults to `main`
 */
export type ColorCoordinates = Readonly<{
  paletteName: PaletteName
  colorName?: ColorName
}>

/**
 * Given coordinates for a color, return the value
 *
 * The `colorName` defaults to `main` for convenience
 */
export type ColorByCoordsSelector = (
  colorCoordinates: ColorCoordinates,
) => CSSColorValue

/**
 * Set of media query blocks that contain CSS for each query
 */
export type MediaQueryBlock = {
  [mediaQuery: string]: { [cssKey: string]: CSSValueInRem }
}

/**
 * Gutters may be implemented with different spacing types
 */
export type GutterType = 'margin' | 'padding'

// Workaround for csstype version mismatch between React and current Mui version
type WorkaroundTypography = Omit<
  MuiTheme['typography'],
  | 'fontWeightLight'
  | 'fontWeightRegular'
  | 'fontWeightMedium'
  | 'fontWeightBold'
> & {
  fontWeightLight: CSSProperties['fontWeight']
  fontWeightRegular: CSSProperties['fontWeight']
  fontWeightMedium: CSSProperties['fontWeight']
  fontWeightBold: CSSProperties['fontWeight']
}
/**
 * The `Theme` object provides easy access to css values
 * specific to the current theme.  The theme should be used
 * anywhere possible.  It is provided to the function when
 * using `makeStyles`
 *
 * @deprecated Import from @mui
 */
export interface Theme extends MuiTheme {
  typography: WorkaroundTypography
  select: Readonly<{
    /**
     * Given coordinates for a color, return the value
     *
     * The `colorName` defaults to `main` for convenience
     */
    colorByCoords: ColorByCoordsSelector

    /**
     * Given Scale receive fontSize/lineHeight. Default: inherit
     */
    fontScale: (scale?: Scale) => FontScale

    /**
     * Defines the global gutters by breakpoint to be used by
     * any component that needs to fill the page from
     * left to right.
     *
     * A given component might use this to set:
     *
     * 1. marginLeft and marginRight
     *    if it wanted to create space between its content and the edge of the page.
     *
     * 2. paddingLeft and paddingRight if it wanted part of its content to stretch
     *    to the edge. Padding is useful if you have colors that should
     *    spread to the edge for example.
     */
    gutters: Readonly<{
      page: (gutterType: GutterType) => MediaQueryBlock
    }>

    /**
     * boxShadow CSS property value for Paper component
     */
    paperShadow: CSSShadowValue

    /**
     * boxShadow CSS property value for PaperPlus component
     */
    paperPlusShadow: CSSShadowValue

    /**
     * Given a Scale 1-7, returns a CSS spacing value
     */
    spacingScale: (scale: Scale) => CSSValueInRem
  }>
}

/**
 * @deprecated Only use this to old components. New components should be using the newer theme and don't need explicit declaration
 */
export type OldTheme = Theme & ThemeOptions
