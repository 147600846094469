import React from 'react'
import { CheckRounded as Check } from '@mui/icons-material'
import { StepIconProps } from '@mui/material'

import { Text } from '../text'

import {
  StepContainer,
  StyledStep as Step,
  StyledStepConnector as StepConnector,
  StyledStepLabel as StepLabel,
  StyledStepper as Stepper,
} from './styles'

const StepIcon = (props: StepIconProps): JSX.Element => {
  const { active, completed, icon } = props

  return (
    <StepContainer completed={completed} active={active}>
      {completed ? <Check fontSize="small" /> : icon}
    </StepContainer>
  )
}

interface ProgressBarProps {
  steps?: ReadonlyArray<string>
  activeStep: number
}

interface IProgressBar {
  (props: ProgressBarProps): JSX.Element
}

const getDefaultSteps = (): ReadonlyArray<string> => {
  return ['Start', 'Vehicle', 'Details', 'Review']
}

/**
 * Progress Bar component
 *
 * Flexible progress component for n number of steps
 * Defaults to set of steps for consumer site
 * Start → Vehicle → Details → Review
 *
 * @example
   <ProgressBar activeStep={activeStep} />

   <ProgressBar
     steps={['first', 'second', 'third']
     activeStep={activeStep}
   />
 */
export const ProgressBar: IProgressBar = ({
  steps = getDefaultSteps(),
  activeStep = 0,
}) => {
  return (
    <Stepper activeStep={activeStep} connector={<StepConnector />}>
      {steps.map((label: string, index: number) => (
        <Step key={label} disabled={index > activeStep + 1}>
          <StepLabel
            StepIconComponent={StepIcon}
            StepIconProps={{
              icon: (
                <Text
                  color={activeStep === index ? 'base.greenLeaf' : undefined}
                  use={[
                    'span',
                    { xs: 'subheadings.14sb', sm: 'subheadings.16sb' },
                  ]}
                  align="center"
                >
                  {index + 1}
                </Text>
              ),
            }}
          >
            <Text
              color={index <= activeStep ? 'text.softBlack' : 'text.bodyGray'}
              use={['span', { xs: 'subheadings.14sb', sm: 'subheadings.16sb' }]}
            >
              {label}
            </Text>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
