import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CopyDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M12 16.25C12 11.885 15.5517 8.33333 19.9167 8.33333H35.1383C34.59 6.415 32.8417 5 30.75 5H11.5833C9.055 5 7 7.055 7 9.58333V35.4167C7 37.945 9.055 40 11.5833 40H12V16.25Z"
      fill="currentColor"
    />
    <path
      d="M38.082 10.6667H18.9154C16.387 10.6667 14.332 12.7217 14.332 15.2501V39.4167C14.332 41.9451 16.387 44.0001 18.9154 44.0001H38.082C40.6104 44.0001 42.6654 41.9451 42.6654 39.4167V15.2501C42.6654 12.7217 40.6104 10.6667 38.082 10.6667ZM34.7487 39.0001H22.2487C21.5587 39.0001 20.9987 38.4401 20.9987 37.7501C20.9987 37.0601 21.5587 36.5001 22.2487 36.5001H34.7487C35.4387 36.5001 35.9987 37.0601 35.9987 37.7501C35.9987 38.4401 35.4387 39.0001 34.7487 39.0001ZM34.7487 32.3334H22.2487C21.5587 32.3334 20.9987 31.7734 20.9987 31.0834C20.9987 30.3934 21.5587 29.8334 22.2487 29.8334H34.7487C35.4387 29.8334 35.9987 30.3934 35.9987 31.0834C35.9987 31.7734 35.4387 32.3334 34.7487 32.3334ZM34.7487 26.5001H22.2487C21.5587 26.5001 20.9987 25.9401 20.9987 25.2501C20.9987 24.5601 21.5587 24.0001 22.2487 24.0001H34.7487C35.4387 24.0001 35.9987 24.5601 35.9987 25.2501C35.9987 25.9401 35.4387 26.5001 34.7487 26.5001ZM34.7487 19.8334H22.2487C21.5587 19.8334 20.9987 19.2734 20.9987 18.5834C20.9987 17.8934 21.5587 17.3334 22.2487 17.3334H34.7487C35.4387 17.3334 35.9987 17.8934 35.9987 18.5834C35.9987 19.2734 35.4387 19.8334 34.7487 19.8334Z"
      fill="currentColor"
    />
  </SvgIcon>
)
