import { Component, ErrorInfo } from 'react'

import { getDatadogLogs } from '@common/telemetry'
import GenericErrorPage from '@src/pages/generic-error'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    getDatadogLogs().logger.error('ErrorBoundary Encountered', errorInfo, error)
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <GenericErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
