import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PagesDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M28.0657 43.9969H8.93979C7.38426 43.9922 6.12464 42.7326 6.12024 41.1774V13.4797C6.12464 11.9245 7.38426 10.6649 8.93979 10.6602H21.3741V15.4817C21.3748 18.0765 23.4781 20.1802 26.0733 20.1809H30.8945V41.1774C30.8857 42.7344 29.6227 43.9929 28.0657 43.9969ZM29.5787 18.3012H26.0733C24.5178 18.2968 23.2582 17.0372 23.2538 15.4817V11.9855L29.5787 18.3012Z"
      fill="currentColor"
    />
    <g opacity="0.24">
      <path
        d="M40.519 11.6411L34.1937 5.32534V8.82151C34.1981 10.3767 35.4581 11.6363 37.0133 11.6411H40.519Z"
        fill="currentColor"
      />
      <path
        d="M32.7652 37.3364H39.0152C40.5704 37.332 41.83 36.0724 41.8348 34.5169V13.5208H37.0228C34.4276 13.5197 32.3243 11.4164 32.3235 8.82151V4H19.8893C18.3337 4.00441 17.0741 5.26439 17.0697 6.81955V8.78369H22.3141C22.5686 8.78112 22.8134 8.88282 22.9908 9.06565L32.4924 18.5581C32.6657 18.7377 32.7634 18.9763 32.7652 19.2256V37.3364Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)
