import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EyeOffIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 7C11.7168 7 11.4352 7.02323 11.1556 7.06728C10.61 7.1532 10.0981 6.7806 10.0122 6.23504C9.92625 5.68948 10.2989 5.17756 10.8444 5.09163C11.2214 5.03226 11.6071 5 11.9999 5C14.1099 5 16.0058 5.92747 17.5088 7.00885C19.0198 8.0959 20.22 9.40025 20.9598 10.2985C21.7805 11.2948 21.7805 12.7052 20.9598 13.7015C20.6286 14.1037 20.2083 14.5833 19.7122 15.0866C19.3246 15.4799 18.6914 15.4845 18.298 15.0968C17.9047 14.7092 17.9001 14.076 18.2878 13.6827C18.7353 13.2286 19.1158 12.7945 19.4161 12.43C19.6284 12.1722 19.6284 11.8278 19.4161 11.57C18.7359 10.7442 17.6599 9.58138 16.3408 8.63233C15.0138 7.6776 13.5257 7 11.9999 7ZM7.90746 8.45823C6.47656 9.43461 5.30766 10.6912 4.58379 11.57C4.37149 11.8278 4.37149 12.1722 4.58379 12.43C5.26401 13.2558 6.33994 14.4186 7.65906 15.3677C8.98608 16.3224 10.4741 17 11.9999 17C13.317 17 14.6078 16.4956 15.7973 15.7366L16.873 17.4227C15.4907 18.3046 13.8263 19 11.9999 19C9.89 19 7.99408 18.0725 6.49104 16.9912C4.9801 15.9041 3.77988 14.5998 3.04001 13.7015C2.21941 12.7052 2.21941 11.2948 3.04002 10.2985C3.82506 9.34539 5.1316 7.9311 6.78018 6.80619L7.90746 8.45823Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.84348 11.8105C9.37695 11.6676 9.92528 11.9842 10.0682 12.5176C10.1591 12.8567 10.3376 13.166 10.5859 13.4142C10.8341 13.6625 11.1433 13.841 11.4824 13.9318C12.0159 14.0748 12.3325 14.6231 12.1895 15.1566C12.0466 15.6901 11.4983 16.0066 10.9648 15.8637C10.2866 15.682 9.66814 15.3249 9.17165 14.8284C8.67516 14.3319 8.3181 13.7135 8.13637 13.0353C7.99343 12.5018 8.31001 11.9535 8.84348 11.8105Z"
      fill="currentColor"
    />
  </SvgIcon>
)
