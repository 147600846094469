import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
  </SvgIcon>
)
