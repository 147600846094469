import isNil from 'lodash/isNil'

import {
  EmploymentInfo,
  EmploymentStatus,
  EmploymentStatusKeys,
} from '@common/types'
import { pickNonNil } from '@src/utils'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

export type Employment = {
  id?: number
  employmentStatus?: EmploymentStatusKeys
  monthlyGrossIncome?: number
  departureDate?: string
}

export type RefiEmployment = {
  id?: number
  employmentStatus?: RefiEmploymentStatusKeys
  monthlyGrossIncome?: number
  departureDate?: string
}

export const RefiEmploymentStatus = {
  Employed: 'employed',
  Retired: 'retired',
  SelfEmployed: 'self-employed',
  Student: 'student',
  Unemployed: 'unemployed',
} as const

export type RefiEmploymentStatusKeys =
  (typeof RefiEmploymentStatus)[keyof typeof RefiEmploymentStatus]

const fromRefiEmploymentStatusMap: Record<
  RefiEmploymentStatusKeys,
  EmploymentStatusKeys
> = {
  [RefiEmploymentStatus.Employed]: EmploymentStatus.Employed,
  [RefiEmploymentStatus.Retired]: EmploymentStatus.Retired,
  [RefiEmploymentStatus.SelfEmployed]: EmploymentStatus.SelfEmployed,
  [RefiEmploymentStatus.Student]: EmploymentStatus.Student,
  [RefiEmploymentStatus.Unemployed]: EmploymentStatus.Unemployed,
}

const toRefiEmploymentStatusMap: Record<
  EmploymentStatusKeys,
  RefiEmploymentStatusKeys
> = {
  [EmploymentStatus.Employed]: RefiEmploymentStatus.Employed,
  [EmploymentStatus.Retired]: RefiEmploymentStatus.Retired,
  [EmploymentStatus.SelfEmployed]: RefiEmploymentStatus.SelfEmployed,
  [EmploymentStatus.Student]: RefiEmploymentStatus.Student,
  [EmploymentStatus.Unemployed]: RefiEmploymentStatus.Unemployed,
}

export const submitEmployment = async (
  payload: EmploymentInfo,
): Promise<{ resumeUrl: string }> => {
  const body = {
    ...pickNonNil(payload),
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/customer/${getRequestId()}/employment${getLeadSourceQueryParams()}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const getCurrentEmployment = async (
  primaryCustomerUuid: string,
): Promise<Employment | undefined> => {
  const response = await fetch(
    `${refiApiUrl}/services/v2/customer/${primaryCustomerUuid}/employments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData?.message)
  }
  const data: { employments: Array<RefiEmployment> } = await response.json()

  const employments = data.employments
  if (!Array.isArray(employments)) {
    return undefined
  }

  const currentEmployment = employments.find((employment) =>
    isNil(employment?.departureDate),
  )
  if (!currentEmployment) {
    return undefined
  }
  return {
    ...currentEmployment,
    employmentStatus:
      currentEmployment?.employmentStatus === null
        ? undefined
        : fromRefiEmploymentStatusMap[currentEmployment?.employmentStatus],
  }
}
export const refiUpsertCurrentEmployment = async (
  uuid: string,
  employment: Employment,
): Promise<void> => {
  const response = await fetch(`${refiApiUrl}/services/v1/employment_detail`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({
      ...employment,
      customerUuid: uuid,
      employmentStatus: toRefiEmploymentStatusMap[employment.employmentStatus],
    }),
  })
  if (!response.ok) {
    throw new Error('Unable to upsert current employment for customer: ${uuid}')
  }
}
