/* eslint-disable import/no-internal-modules */
import { useCallback, useRef } from 'react'
import { FieldValues, Resolver, ResolverResult } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SchemaOf } from 'yup'

import { firstValueFrom, Subject } from '../utils'

type Validated<TFieldValues extends FieldValues> = {
  count: number
  validating: false
  result: ResolverResult<TFieldValues>
}
type Validating = {
  count: number
  validating: true
}
type ValidationState<TFieldValues extends FieldValues> =
  | Validated<TFieldValues>
  | Validating

export const useResolver = <TFieldValues extends FieldValues>(
  schema: SchemaOf<TFieldValues>,
): Resolver<TFieldValues> => {
  const nextValidation$Ref = useRef(new Subject<ResolverResult<TFieldValues>>())
  const validationState = useRef<ValidationState<TFieldValues>>({
    count: 0,
    validating: true,
  })

  // TODO: potential for performance improvement.
  // Instead of using yupResolver, we can write our own resolver that calls
  // .validateAt instead of .validate every time there's a field change
  const resolver = useCallback(yupResolver(schema), [
    schema,
  ]) as Resolver<TFieldValues>

  const validate: Resolver<TFieldValues> = useCallback(
    async (values, context, options) => {
      const testCount = validationState.current.count + 1
      validationState.current = { count: testCount, validating: true }

      const result = await resolver(values, context, options)
      const isLatestValidation = testCount === validationState.current.count
      if (isLatestValidation) {
        nextValidation$Ref.current.next(result)
        validationState.current = {
          count: testCount,
          validating: false,
          result,
        }
        return result
      }

      const latestValidationState =
        validationState.current as ValidationState<TFieldValues>
      const latestResult =
        latestValidationState.validating === true
          ? // if newer validation is in progress -> subscribe to it
            await firstValueFrom(nextValidation$Ref.current)
          : // if newer validation resolved -> return it
            latestValidationState.result

      return latestResult
    },
    [resolver],
  )
  return validate
}
