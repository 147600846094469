import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HeartDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      opacity="0.24"
      d="M39.2627 22.1799C36.418 22.0444 34.0617 24.2288 34.0012 26.966C34.0011 26.972 33.9989 26.972 33.9988 26.966C33.9385 24.2287 31.5821 22.0444 28.7373 22.1799C25.613 22.3287 23.5394 25.3162 24.088 28.3269C25.0107 33.3905 31.3851 36.8769 33.3946 37.86C33.776 38.0466 34.224 38.0466 34.6054 37.86C36.6149 36.8769 42.9893 33.3905 43.912 28.3269C44.4607 25.3162 42.3871 22.3287 39.2627 22.1799V22.1799Z"
      fill="currentColor"
    />
    <path
      d="M11.6734 9.51074L11.6734 9.51074C6.23854 9.78042 2.71759 15.1678 3.65044 20.4991L4.10618 20.4193L3.65044 20.4991C4.42954 24.9514 7.4966 28.6639 10.7153 31.447C13.9434 34.2382 17.3888 36.1504 19.0393 36.9913L19.0393 36.9913C19.8017 37.3797 20.6984 37.3797 21.4608 36.9913L21.4608 36.9913C23.1112 36.1504 26.5567 34.2382 29.7847 31.447C33.0034 28.6639 36.0705 24.9514 36.8495 20.4991C37.7825 15.1677 34.2614 9.78042 28.8266 9.51074L28.8018 10.0101L28.8266 9.51074C24.8801 9.31494 21.4875 11.8098 20.25 15.339C19.0126 11.8098 15.6199 9.315 11.6734 9.51074ZM20.2481 18.1098C20.2481 18.1097 20.2481 18.1097 20.2481 18.1097C20.2446 17.9446 20.2364 17.7806 20.2235 17.618L20.2481 18.1098Z"
      fill="currentColor"
      stroke="white"
    />
  </SvgIcon>
)
