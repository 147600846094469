import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PiggybankDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M16.9883 24.1416C22.5033 24.1416 26.9766 19.6333 26.9766 14.075C26.9766 8.51665 22.5033 4 16.9883 4C11.4732 4 7 8.50831 7 14.0666C7 19.6249 11.4732 24.1416 16.9883 24.1416ZM13.499 18.0499C13.59 17.7166 13.6809 17.3916 13.7801 17.0583C13.8959 16.6749 14.0034 16.6333 14.3507 16.8166C14.946 17.1249 15.5744 17.2999 16.2359 17.3833C16.6575 17.4333 17.0792 17.3916 17.4679 17.2166C18.2037 16.8916 18.3195 16.0333 17.6994 15.5166C17.4844 15.3416 17.2446 15.2083 16.9966 15.1C16.3516 14.8166 15.6819 14.6 15.07 14.2333C14.0861 13.6416 13.4577 12.8166 13.5321 11.6083C13.6148 10.2416 14.3837 9.38331 15.624 8.93331C16.1366 8.74165 16.1366 8.74998 16.1449 8.20831C16.1449 8.02498 16.1449 7.84165 16.1449 7.65832C16.1532 7.24999 16.2276 7.18332 16.6245 7.16665C16.7485 7.16665 16.8725 7.16665 16.9966 7.16665C17.8565 7.16665 17.8565 7.16665 17.8565 8.03332C17.8565 8.64165 17.8565 8.64998 18.4601 8.74165C18.9231 8.81665 19.3613 8.94998 19.7913 9.14164C20.0311 9.24998 20.1138 9.41664 20.0476 9.66664C19.9401 10.0416 19.8409 10.4166 19.7169 10.7833C19.6011 11.1333 19.4936 11.1833 19.1629 11.025C18.4931 10.7 17.7903 10.5583 17.0462 10.6C16.8477 10.6083 16.6658 10.6333 16.4839 10.7166C15.8472 11 15.7397 11.7083 16.2855 12.15C16.5583 12.375 16.8725 12.5333 17.2033 12.6666C17.7655 12.9 18.336 13.1333 18.8818 13.4333C20.5933 14.3916 21.0564 16.5583 19.8492 18.0333C19.4109 18.5666 18.8487 18.9249 18.1789 19.1083C17.8895 19.1916 17.7572 19.3416 17.7738 19.6416C17.7903 19.9416 17.7738 20.2333 17.7738 20.5333C17.7738 20.7999 17.6415 20.9416 17.3769 20.9499C17.0627 20.9583 16.7402 20.9583 16.426 20.9499C16.1449 20.9416 16.0126 20.7833 16.0126 20.5083C16.0126 20.2916 16.0126 20.0749 16.0043 19.8583C15.9961 19.3833 15.9878 19.3583 15.5248 19.2833C14.9377 19.1916 14.3672 19.0583 13.8297 18.7916C13.4246 18.5999 13.3832 18.4916 13.499 18.0499Z"
      fill="currentColor"
    />
    <path
      opacity="0.26"
      d="M39.3959 24.9834H38.2301C37.4611 22.6084 35.9562 20.5001 33.947 18.8668C33.947 18.7918 33.9305 18.7085 33.9139 18.6335C33.4922 17.1418 34.0132 15.7835 34.6167 14.8085C35.0384 14.1251 34.4927 13.2501 33.6989 13.3501C31.9212 13.5751 30.6975 14.2335 29.8541 14.9751C29.2753 15.4835 28.8619 16.1668 28.68 16.9251C27.4067 22.2418 22.6523 26.2001 16.9884 26.2001C14.235 26.2001 11.7049 25.2668 9.67911 23.7001C8.9763 25.2334 8.59595 26.9084 8.59595 28.6667C8.59595 29.6167 8.71171 30.5417 8.92669 31.4334C8.92669 31.4334 8.92668 31.4501 8.93495 31.4751C9.1582 32.4001 9.49721 33.2917 9.92717 34.1334C10.4977 35.375 11.3245 36.825 12.4656 38C14.111 39.7084 14.3921 41.825 14.4335 42.7417V43.1333C14.4335 43.15 14.4335 43.1667 14.4335 43.1833C14.4335 43.1917 14.4335 43.1917 14.4335 43.1917C14.4665 43.6417 14.8386 44 15.2934 44H20.519C20.9903 44 21.379 43.6167 21.379 43.1333V41.8333C22.1397 41.9333 22.9169 41.9917 23.7024 41.9917C24.3225 41.9917 24.9261 41.9583 25.5297 41.8917V43.1333C25.5297 43.6083 25.9101 44 26.3896 44H31.6318C32.1031 44 32.4918 43.6167 32.4918 43.1333V41.8333C32.5 41.4 32.6489 39.675 34.2695 38.2C34.3274 38.15 34.3852 38.1 34.4431 38.05C34.4596 38.0334 34.4762 38.025 34.4927 38.0084C36.2952 36.3917 37.6265 34.375 38.3127 32.1251H39.4124C40.2889 32.1251 41 31.4084 41 30.5251V26.5834C40.9835 25.7001 40.2724 24.9834 39.3959 24.9834ZM32.8556 25.2668C32.1362 25.2668 31.5574 24.6834 31.5574 23.9584C31.5574 23.2334 32.1362 22.6501 32.8556 22.6501C33.5749 22.6501 34.1537 23.2334 34.1537 23.9584C34.1537 24.6834 33.5749 25.2668 32.8556 25.2668Z"
      fill="currentColor"
    />
  </SvgIcon>
)
