import { useEffect, useMemo, useState } from 'react'

type Subscriber<T> = (value: T) => unknown
type Unsubscribe = () => void

export class Subject<T> {
  private subscribers: ReadonlyArray<Subscriber<T>> = []

  public next(value: T): void {
    for (const fn of this.subscribers) {
      fn(value)
    }
  }

  public subscribe(callback: Subscriber<T>): Unsubscribe {
    this.subscribers = [...this.subscribers, callback]
    return () => {
      this.subscribers = this.subscribers.filter((fn) => fn !== callback)
    }
  }
}

export const firstValueFrom = <T>(subject: Subject<T>): Promise<T> => {
  return new Promise((resolve) => {
    const unsubscribe = subject.subscribe((value) => {
      unsubscribe()
      resolve(value)
    })
  })
}

export const useIsInViewport = (
  ref: React.RefObject<HTMLElement>,
  opts?: IntersectionObserverInit,
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]: Array<IntersectionObserverEntry>) => {
        setIsIntersecting(entry.isIntersecting)
      }, opts),
    [opts],
  )

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}
