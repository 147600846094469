import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

type AccountForm = {
  resumeUrl: string
}

type SubmitCreateAccountFormParams = {
  terms: boolean
  activeDuty: boolean
  ssn: string
  coborrowerSsn?: string
  coborrowerActiveDuty?: boolean
}

export const submitCreateAccountForm = async ({
  terms: tosAcceptance,
  activeDuty: active,
  ssn,
  coborrowerSsn,
  coborrowerActiveDuty,
}: SubmitCreateAccountFormParams): Promise<AccountForm> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/create-account${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify({
        tosAcceptance,
        active,
        ssn,
        coborrowerSsn,
        coborrowerActiveDuty,
      }),
    },
  )

  return await response.json()
}
