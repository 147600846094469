import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const NavigationV2Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.462 2.9255C20.2228 2.13617 22.0266 3.93999 21.2373 5.70079L14.7427 20.1888C13.7865 22.3218 10.792 22.4133 9.70731 20.3426L8.1186 17.3096C7.83535 16.7689 7.39388 16.3274 6.85313 16.0442L3.82015 14.4555C1.74946 13.3708 1.84096 10.3763 3.97402 9.4201L18.462 2.9255ZM19.3383 4.73934C19.3316 4.73793 19.3156 4.73462 19.2801 4.75052L4.79213 11.2451C4.18268 11.5183 4.15654 12.3739 4.74817 12.6838L7.78114 14.2725C8.68239 14.7446 9.41818 15.4804 9.89027 16.3816L11.479 19.4146C11.7889 20.0062 12.6444 19.9801 12.9176 19.3706L19.4122 4.88268C19.4281 4.84721 19.4248 4.83112 19.4234 4.82448C19.4207 4.8117 19.4119 4.79123 19.3917 4.77106C19.3715 4.75089 19.3511 4.74204 19.3383 4.73934Z"
      fill="currentColor"
    />
  </SvgIcon>
)
