import React, { ReactElement } from 'react'

import { FormHelperText } from '@common/react-lib-base'
import { makeStyles, Theme } from '@modules/core-ui'

const useStyles = makeStyles((theme: Theme) => ({
  paragraph: {
    textAlign: 'left',
    paddingTop: theme.spacing(1),
    color: '#191C22',
  },
}))

const informationTexts = [
  {
    id: 1,
    preBoldStmt: 'If you are an ',
    boldStmt: 'employee',
    postBoldStmt:
      ' and changed jobs in the last year, you will need to show W-2s or multiple pay stubs from your last employer.',
  },
  {
    id: 2,
    preBoldStmt: 'If you are ',
    boldStmt: 'self-employed,',
    postBoldStmt:
      ' you must provide your 2 most recent years of tax returns showing self-employed income.',
  },
  {
    id: 3,
    preBoldStmt: 'If you are ',
    boldStmt: 'retired or receive benifits,',
    postBoldStmt:
      ' you must provide your most recent award letter documenting your monthly social security benifits, pension, or retirement deposits.',
  },
  {
    id: 4,
    preBoldStmt: 'If you have alternate income from ',
    boldStmt: 'alimony or child support,',
    postBoldStmt:
      ' you will need to provide a court-issued decree stating the monthly amount you receive. The decree must be valid for next 12 months.',
  },
]

export const InformationalText = (): ReactElement => {
  const classes = useStyles()

  return (
    <>
      {informationTexts.map((text) => (
        <FormHelperText key={text.id} className={classes.paragraph}>
          {text.preBoldStmt}
          <strong>{text.boldStmt}</strong>
          {text.postBoldStmt}
        </FormHelperText>
      ))}
    </>
  )
}
