/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useState } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import {
  Button as MuiButton,
  ClickAwayListener,
  FormControl,
  FormControlProps,
  FormHelperText,
  Grow,
  InputLabel,
  InputLabelProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import cx from 'classnames'
import isNil from 'lodash/isNil'
import map from 'lodash/map'

import { makeStyles, Theme } from '@modules/core-ui'

import { Box } from './content-elements'

export type SelectProps<
  TDispPropName extends string = 'displayName',
  TValPropName extends string = 'value',
> = {
  autoFocus?: MuiSelectProps['autoFocus']
  className?: MuiSelectProps['className']
  classes?: MuiSelectProps['classes']
  defaultValue?: MuiSelectProps['defaultValue']
  disabled?: MuiSelectProps['disabled']
  displayPropertyName: TDispPropName
  error?: boolean
  formControlClasses?: FormControlProps['classes']
  helperText?: React.ReactNode
  id?: MuiSelectProps['id']
  input?: MuiSelectProps['input']
  inputProps?: {}
  label?: string
  labelProps?: InputLabelProps
  menuItems: ReadonlyArray<
    {
      [T in TDispPropName]: string
    } & {
      [T in TValPropName]: string
    }
  >
  menuProps?: MuiSelectProps['MenuProps']
  multiple?: MuiSelectProps['multiple']
  name?: string
  onChange?: MuiSelectProps['onChange']
  value?: string
  valuePropertyName: TValPropName
  variant?: MuiSelectProps['variant']
  wrapperClasses?: string
}

const useSelectStyles = makeStyles(() => ({
  formControlRoot:
    // @css
    {
      margin: 'auto',
      verticalAlign: 'baseline',
      width: '100%',
    },
  fullWidth: {
    width: '-webkit-fill-available',
  },
}))

const useButtonSelectStyles = makeStyles((theme: Theme) => ({
  label:
    // @css
    {
      fontSize: '1.25rem !important',
      fontWeight: 'normal',
      lineHeight: 'normal !important',
      color: 'inherit !important',
      '&.disabled': {
        color: `${theme.select.colorByCoords({
          paletteName: 'grey',
          colorName: 'dark',
        })} !important`,
      },
    },
  labelOutlined:
    // @css
    {
      transform: 'translate(14px, 9px) scale(0.75) !important',
    },
  selectRoot: {
    '&.MuiSelect-root:not(.Mui-disabled)': {
      background: theme.palette.primary.main,
    },
  },
  inputRootDisabled:
    // @css
    {
      // Disabled background for menu button.
      background: theme.select.colorByCoords({
        paletteName: 'grey',
        colorName: 'light',
      }),
    },
  selectWrapper: {
    display: 'inline-block',
  },
  formControlRoot: {
    margin: 0,
    color: theme.palette.common.white,
    width: '100%',
  },
  menuList:
    // @css
    {
      fontSize: '0.8125rem',
      paddingTop: '0px',
      paddingBottom: '0px',
      '& li': {
        fontSize: '0.8125rem',
        lineHeight: '1rem',
      },
      '& .MuiListItem-button:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  buttonWrapper: {
    display: 'inline-flex',
    position: 'relative',
    background: 'none',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.10) !important', // Border color for button select when used in a toolbar.
  },
  buttonRoot: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: `inherit`,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    height: '1.625rem',
    padding: '5px 15px',
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '.825rem',
  },
  buttonGroupRoot: {
    borderWidth: '1px',
  },
  buttonSelectMenu: {
    '&.buttonSelectPopper': {
      minWidth: '100%',
      zIndex: '999999999',
    },
  },
}))

/**
 *
 * Base Select component wrapping the Mui TextField.
 *
 */

const SelectToForwardRef = <
  TDispPropName extends string,
  TValPropName extends string,
>(
  {
    classes,
    className,
    displayPropertyName,
    error = false,
    formControlClasses,
    helperText,
    label,
    labelProps,
    menuItems,
    menuProps,
    name,
    onChange,
    valuePropertyName,
    variant = 'outlined',
    wrapperClasses,
    defaultValue,
    ...props
  }: SelectProps<TDispPropName, TValPropName>,
  ref,
): JSX.Element => {
  const formControlStyles = useSelectStyles(props)

  const handleChange = useCallback(
    (event: SelectChangeEvent): void => {
      if (!isNil(onChange)) {
        onChange(event, void 0)
      }
    },
    [onChange],
  )

  return (
    <Box className={cx(wrapperClasses, className)}>
      <FormControl
        error={error}
        className={cx(formControlStyles.fullWidth)}
        classes={formControlClasses}
      >
        <InputLabel {...labelProps}>{label}</InputLabel>
        <MuiSelect
          ref={ref}
          classes={classes}
          MenuProps={menuProps}
          name={name}
          variant={variant}
          onChange={handleChange}
          defaultValue={defaultValue ?? ''}
          {...props}
        >
          {map(menuItems, (item) => {
            return (
              <MenuItem
                key={item[valuePropertyName]}
                value={item[valuePropertyName]}
              >
                {item[displayPropertyName]}
              </MenuItem>
            )
          })}
        </MuiSelect>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export const Select = React.forwardRef(SelectToForwardRef)

type ToolbarButtonSelectProps<
  TDispPropName extends string,
  TValPropName extends string,
> = {
  autoFocus?: MuiSelectProps['autoFocus']
  className?: MuiSelectProps['className']
  classes?: MuiSelectProps['classes']
  disabled?: MuiSelectProps['disabled']
  displayPropertyName: TDispPropName
  error?: boolean
  formControlClasses?: FormControlProps['classes']
  helperText?: React.ReactNode
  id?: MuiSelectProps['id']
  input?: MuiSelectProps['input']
  inputProps?: {}
  label?: string
  labelProps?: InputLabelProps
  menuItems: ReadonlyArray<
    {
      [T in TDispPropName]: string
    } & {
      [T in TValPropName]: string
    }
  >
  menuProps?: MuiSelectProps['MenuProps']
  multiple?: MuiSelectProps['multiple']
  name?: string
  onChange?: MenuItemProps['onChange']
  value?: string
  valuePropertyName: TValPropName
  variant?: MuiSelectProps['variant']
  wrapperClasses?: string
}

export const ToolbarButtonSelect = <
  TDispPropName extends string,
  TValPropName extends string,
>({
  classes,
  displayPropertyName,
  formControlClasses,
  helperText,
  label,
  labelProps,
  inputProps,
  menuItems,
  menuProps,
  name,
  onChange,
  valuePropertyName,
  wrapperClasses,
  ...props
}: ToolbarButtonSelectProps<TDispPropName, TValPropName>): JSX.Element => {
  const styles = useButtonSelectStyles()

  // The open/closed state of the the popup menu.
  const [open, setOpen] = useState(false)
  // This ref is used to align the popup menu with the bottom of the triggering button.
  const anchorRef: React.MutableRefObject<HTMLButtonElement> = React.useRef()

  const handleToggleMenu = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const handleCloseMenu = useCallback((event): void => {
    if (!isNil(anchorRef.current) && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }, [])

  const handleSelect = useCallback(
    (event) => {
      if (!isNil(onChange)) {
        // Get the value out of the element event 'value' attribute and put it into the event.target object.
        onChange({
          ...event,
          target: { value: event.target.getAttribute('value') },
        })
      }
      handleCloseMenu(event)
    },
    [handleCloseMenu, onChange],
  )

  return (
    <Box className={cx([props.className, styles.buttonWrapper])}>
      <MuiButton
        ref={anchorRef}
        size="small"
        onClick={handleToggleMenu}
        classes={{ root: cx(styles.buttonRoot, styles.buttonLabel) }}
        disabled={props.disabled}
      >
        {label} <ArrowDropDown />
      </MuiButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-start'}
        transition
        disablePortal
        className={cx(['buttonSelectPopper', styles.buttonSelectMenu])}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList id="button-menu" classes={{ root: styles.menuList }}>
                  {map(menuItems, (item) => (
                    <MenuItem
                      key={item[valuePropertyName]}
                      value={item[valuePropertyName]}
                      onClick={handleSelect}
                    >
                      {item[displayPropertyName]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
