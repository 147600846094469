export const Citizenship = {
  UsCitizen: 'US_CITIZEN',
  PermanentResident: 'PERMANENT_RESIDENT',
  NonPermanentResident: 'NON_PERMANENT_RESIDENT',
  NonResidentAlien: 'NON_RESIDENT_ALIEN',
} as const

export type CitizenshipKeys = (typeof Citizenship)[keyof typeof Citizenship]

export const citizenshipDisplay = {
  [Citizenship.UsCitizen]: 'US Citizen',
  [Citizenship.PermanentResident]: 'Permanent Resident (Green Card holder)',
  [Citizenship.NonPermanentResident]:
    'Non-Permanent Resident (Work Authorization/Political Asylum/DACA)',
  [Citizenship.NonResidentAlien]: 'Non-Resident Alien (ITIN Only)',
} as const
