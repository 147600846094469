/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Control, FieldValues, Path } from 'react-hook-form'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'

import { Falsy, SimpleFunctionComponent } from '@common/lib-types'

import { useComponentBuilder } from '../../hooks'
import { assert } from '../../utils'

const isElement = (value: unknown): value is TElement =>
  isPlainObject(value) &&
  has(value, ['Component']) &&
  has(value, ['props']) &&
  has(value, ['key'])

export type TElement<
  TProps extends {} = {},
  TBoundProps extends Partial<TProps> = {},
> = {
  Component: SimpleFunctionComponent<TProps>
  props: TBoundProps
  key?: string | number
}

type BuildElement = {
  <
    TProps extends {},
    TBoundProps extends Partial<TProps>,
    TFieldValues extends FieldValues = FieldValues,
  >(
    Component: SimpleFunctionComponent<TProps>,
    props: TProps extends { control: Control }
      ? // Field that expects "control" parameter overload
        Omit<TBoundProps, 'control' | 'name'> & {
          // cast FieldValues type to have typesafety for name parameter
          name: Path<TFieldValues>
          control: Control<TFieldValues>
        }
      : // Simple element overload
        TBoundProps,
    key?: string | number,
  ): TProps extends { control: Control }
    ? TElement<Omit<TProps, 'value' | 'control' | 'name' | keyof TBoundProps>>
    : TElement<TProps, TBoundProps>
}

type ElementsRecord = Record<
  string,
  | TElement
  | Array<TElement>
  // Nested modules
  | Falsy
  | Record<string, TElement | Array<TElement>>
>

type BuiltElement<
  TProps extends {} = {},
  TBoundProps extends Partial<TProps> = {},
> = SimpleFunctionComponent<Omit<TProps, keyof TBoundProps>>

type ExtractP1<T> = T extends TElement<infer P1, any> ? P1 : never
type ExtractP2<T> = T extends TElement<any, infer P2> ? P2 : never

type Module<T extends ElementsRecord> = {
  [K in keyof T]: T[K] extends boolean
    ? T[K]
    : T[K] extends TElement
    ? BuiltElement<ExtractP1<T[K]>, ExtractP2<T[K]>>
    : T[K] extends Array<TElement>
    ? Array<BuiltElement<ExtractP1<T[K]>, ExtractP2<T[K]>>>
    : T[K] extends Record<string, TElement | Array<TElement>>
    ? Module<T[K]>
    : never
}

const everyIsUnique = (keys: ReadonlyArray<string | number>): boolean => {
  const keysSet = new Set<string | number>()
  for (const key of keys) {
    if (key === undefined || keysSet.has(key)) {
      return false
    }
    keysSet.add(key)
  }
  return true
}

/**
 * This hook is a smart component builder.
 * It works in tandem with Element.
 * It maps through all the keys in the object and uses them
 * as displayName of the components it creates.
 *
 * It supports conditional logic and dynamic components mapped from
 * arrays (as long as you provide unique keys to identify them)
 *
 * @example
 * const useForm = () =>
 *  useModule({
 *    FirstName: Element(TextField, {onClick, name, value})
 *    borrower: {
 *      LastName: Element(TextField, {onClick, name, value})
 *    }
 *  })
 *
 * // Is equivalent to
 *
 * const useForm = () => {
 *  const build = useComponentBuilder()
 *  return {
 *    FirstName: build(TextField, {onClick, name, value}, 'FirstName'),
 *    borrower: {
 *      LastName: build(TextField, {onClick, name, value}, 'borrower.FirstName')
 *    }
 *  }
 * }
 *
 * @example
 * useModule({
 *  coborrower: hasCoborrower && {
 *    FirstName: Element(TextField, {onClick, name, value})
 *  },
 *  Options: options.map(option =>
 *    Element(RadioBox, {onClick, selected, label: option.label}, option.id)
 *  )
 * })
 */
export const useModule = <TElements extends ElementsRecord>(
  elements: TElements,
): Module<TElements> => {
  const buildComponent = useComponentBuilder()

  const buildWith = (prefix: string) => (elements: ElementsRecord) =>
    mapValues(elements, (value, name) => {
      if (isElement(value)) {
        return buildComponent(value.Component, value.props, prefix + name)
      }

      if (isArray(value)) {
        const elements = value
        assert(
          everyIsUnique(elements.map((subEl) => subEl.key)),
          `All Elements in an array under '${
            prefix + name
          }' must have a unique key`,
        )
        return elements.map((subEl) =>
          buildComponent(
            subEl.Component,
            subEl.props,
            `${prefix + name}-${subEl.key}`,
          ),
        )
      }

      if (isPlainObject(value)) {
        const elements = value as Record<string, TElement | Array<TElement>>
        return buildWith(`${name}.`)(elements)
      }

      return value
    }) as Module<TElements>

  return buildWith('')(elements)
}

/**
 * This is just a utility builder that creates an object of type TElement.
 * Most of its value comes from infering types for Typescript
 *
 * It accepts optional 'key' parameter that is only used when building
 * elements inside an array. (It's used to map array Elements to corresponding components)
 *
 * @example
 * useModule({
 *  FirstName: Element(TextField, {control, name}),
 *  Options: options.map(option =>
 *    Element(RadioBox, {onClick, selected, label: option.label}, option.id)
 *  )
 * })
 */
export const Element: BuildElement = (Component, props, key) =>
  ({
    Component,
    props,
    key,
  } as any)
