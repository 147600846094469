import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl } from '../config'

export type UserValidationResponse = {
  isRequestIdValid: boolean
  isAuthTokenValid: boolean
  isLeadSourceValid: boolean
}

type ValidateUserOptions = {
  token?: string
  requestId?: string
}
export const validate: (
  options: ValidateUserOptions,
) => Promise<UserValidationResponse> = async (options: ValidateUserOptions) => {
  const requestId = options?.requestId ?? getRequestId()
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/validate-user${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (options?.token ?? getAuthToken()),
      },
      body: JSON.stringify({ requestId }),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }

  return data
}
