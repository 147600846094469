import React, { createContext, useContext, useState } from 'react'

import { DatadogRum, getDatadogRum } from '@common/telemetry'

const DatadogContext = createContext<DatadogRum>({} as DatadogRum)

export const DatadogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [datadogRum] = useState(getDatadogRum)

  return (
    <DatadogContext.Provider value={datadogRum}>
      {children}
    </DatadogContext.Provider>
  )
}

export const useDatadogRum = (): DatadogRum => useContext(DatadogContext)
