import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

import {
  PartnerAuthCode,
  PartnerCustomerInfo,
  PartnerName,
  PartnerRewardsInfo,
  PartnerVehicleInfo,
} from '@common/types'

import { useAuthToken } from './use-auth-token'
import { useCustomerInfo } from './use-customer-info'
import { useRewardsInfo } from './use-rewards-info'
import { useVehicleInfo } from './use-vehicle-info'

type PrefillParamProps = {
  partner: PartnerName
  code: PartnerAuthCode
}

type PartnerPrefillHook = {
  customerInfo: PartnerCustomerInfo
  dataLoaded: boolean
  rewardsInfo: PartnerRewardsInfo
  vehicleInfo: PartnerVehicleInfo
}

const parseQueryParams = (params: ParsedUrlQuery): PrefillParamProps => {
  const partner = Array.isArray(params.partner)
    ? params.partner[0]
    : params.partner
  const code = Array.isArray(params.code) ? params.code[0] : params.code

  return {
    code,
    partner,
  }
}

export const usePartnerPrefill = (): PartnerPrefillHook => {
  const router = useRouter()
  const { code, partner } = parseQueryParams(router.query)
  const { token } = useAuthToken(partner, code)
  const { customerInfo, customerInfoLoaded } = useCustomerInfo(partner, token)
  const { rewardsInfo } = useRewardsInfo(partner, token)
  const { vehicleInfo, vehicleInfoLoaded } = useVehicleInfo(partner, token)

  const mergedInfo = rewardsInfo
    ? {
        ...rewardsInfo,
        partner,
        partnerUserId: customerInfo?.partnerUserId,
      }
    : {}

  return {
    customerInfo,
    dataLoaded: customerInfoLoaded && vehicleInfoLoaded,
    rewardsInfo: mergedInfo,
    vehicleInfo,
  }
}
