import { EmploymentStatusKeys } from './common'
import { StateKeys } from './states'

export const ResidenceStatus = {
  rent: 'RENT',
  own: 'OWN',
  livesWithFamily: 'LIVES_WITH_FAMILY',
  militaryHousing: 'MILITARY_HOUSING',
  ownsFreeAndClear: 'OWNS_FREE_AND_CLEAR',
  other: 'OTHER',
} as const

export type ResidenceStatusKeys =
  (typeof ResidenceStatus)[keyof typeof ResidenceStatus]

export type ResidenceAndEmploymentSubmissionInfo = {
  address: string
  apartment?: string
  city: string
  state: StateKeys
  zip: string
  livedInAddress: {
    years: number
    months: number
  }

  rentOrOwn: ResidenceStatusKeys
  monthlyPayment: number
  employmentStatus: EmploymentStatusKeys
  yearlyIncome: number
}

export type ResidenceAndEmploymentInfo = {
  hasCoborrower: boolean
  address: string
  addressUuid?: string
  residenceUuid?: string
  apartment?: string
  city: string
  state: StateKeys
  zip: string
  livedInAddress: {
    years: number
    months: number
  }
  livedOutAddress?: {
    years: number
    months: number
  }

  rentOrOwn: ResidenceStatusKeys
  residenceStatus?: ResidenceStatusKeys
  monthlyPayment?: number
  employmentStatus: EmploymentStatusKeys
  yearlyIncome: number
  coborrowerResidence?: boolean
  coaddress?: string
  coapartment?: string
  cocity?: string
  costate?: StateKeys
  cozip?: string
  colivedInAddress?: {
    years: number
    months: number
  }

  corentOrOwn?: ResidenceStatusKeys
  comonthlyPayment?: number
  coemploymentStatus?: EmploymentStatusKeys
  coyearlyIncome?: number
}
