import { UseFormReturn } from 'react-hook-form'

import { TextFieldProps } from '@common/react-lib-base'

import { useEffectIf } from '../../../hooks'
import { trackCoBorrowerEmailWasUsed } from '../../../tracking'
import { emailIsInUseMessage, PersonalInfoForm } from '../../schemas'
import { Element, NewTextField as TextField, TElement } from '../../utils'

export const useCoborrowerEmail = (
  staticProps: Pick<TextFieldProps, 'label'>,
  {
    formState: { dirtyFields, errors },
    getValues,
    control,
    setValue,
    trigger,
  }: UseFormReturn<PersonalInfoForm>,
): TElement<Pick<TextFieldProps, 'className'>> => {
  const coborrowerEmailIsUsedShown =
    errors.coemailAddress?.message === emailIsInUseMessage
  useEffectIf(coborrowerEmailIsUsedShown, () => {
    void trackCoBorrowerEmailWasUsed({ email: getValues('coemailAddress') })
  })

  return Element(TextField, {
    ...staticProps,
    control,
    name: 'coemailAddress',
    'data-testid': 'coemailAddress',
    onChange: () => {
      if (dirtyFields.coconfirmEmailAddress) {
        void trigger('coconfirmEmailAddress')
      }
    },
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
      setValue('coemailAddress', e.target.value.trim(), {
        shouldValidate: true,
      })
      if (dirtyFields.coconfirmEmailAddress) {
        void trigger('coconfirmEmailAddress')
      }
    },
  })
}
