import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PieChartV3Icon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6.0718C8.97057 6.22039 7.98279 6.59721 7.11101 7.17972C5.95987 7.94889 5.06266 9.04214 4.53285 10.3212C4.00303 11.6003 3.86441 13.0078 4.13451 14.3656C4.4046 15.7235 5.07129 16.9708 6.05026 17.9498C7.02922 18.9287 8.2765 19.5954 9.63437 19.8655C10.9922 20.1356 12.3997 19.997 13.6788 19.4672C14.9579 18.9373 16.0511 18.0401 16.8203 16.889C17.4028 16.0172 17.7796 15.0294 17.9282 14H12C10.8954 14 10 13.1046 10 12V6.0718ZM9.8779 4.07022C11.1074 3.91572 12 4.93442 12 6.00001V12H18C19.0656 12 20.0843 12.8926 19.9298 14.1221C19.7561 15.5045 19.263 16.8331 18.4832 18.0001C17.4943 19.4802 16.0887 20.6337 14.4442 21.3149C12.7996 21.9961 10.99 22.1743 9.24419 21.8271C7.49836 21.4798 5.89472 20.6226 4.63604 19.364C3.37737 18.1053 2.5202 16.5016 2.17294 14.7558C1.82567 13.01 2.0039 11.2004 2.68509 9.55586C3.36628 7.91132 4.51983 6.50571 5.99987 5.51678C7.16686 4.73702 8.49549 4.24394 9.8779 4.07022Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3.99999C13 2.94111 13.8925 1.90219 15.139 2.08148C15.7978 2.17625 16.4438 2.35309 17.0615 2.60895C18.0321 3.01099 18.914 3.60027 19.6569 4.34313C20.3997 5.086 20.989 5.96792 21.391 6.93852C21.6469 7.55623 21.8237 8.20216 21.9185 8.86101C22.0978 10.1075 21.0589 11 20 11L14.1 11C13.4925 11 13 10.5075 13 9.89999V3.99999ZM19.9161 8.99999C19.8411 8.55636 19.7163 8.12152 19.5433 7.70389C19.2417 6.97593 18.7998 6.3145 18.2426 5.75735C17.6855 5.2002 17.0241 4.75824 16.2961 4.45671C15.8785 4.28372 15.4436 4.1589 15 4.08391V8.99999L19.9161 8.99999Z"
      fill="currentColor"
    />
  </SvgIcon>
)
