import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PlayCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.268C9 8.72835 10.6667 7.7661 12 8.5359L15 10.268C16.3333 11.0378 16.3333 12.9623 15 13.7321L12 15.4641C10.6667 16.2339 9 15.2717 9 13.7321L9 10.268ZM14 12L11 10.268L11 13.7321L14 12Z"
      fill="currentColor"
    />
  </SvgIcon>
)
