import React, { FC, useCallback, useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material'

import { Text } from '../../reskin'

import { CarLoanInfo, CarLoanInfoProps } from './offer-selections-components'

const MobileInfoDivider = styled('div')<{
  margin: [number, number?, number?, number?]
}>(({ theme, margin }) => ({
  width: '100%',
  height: '1px',
  background: theme.extension.colors.divider.lightGray,
  margin: theme.spacing(...margin),
}))

const LoanDropdown = styled(Accordion)(() => ({
  elevation: 0,
  border: 'none',
  background: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0px',
  },
}))

const LoanDropdownTitle = styled(AccordionSummary)(() => ({
  display: 'flex',
  height: '24px',
  minHeight: '0px',
  paddingTop: '16px',
  boxSizing: 'content-box',

  '&.Mui-expanded': {
    minHeight: '0px',
  },
}))

const LoanDropdownDetails = styled(AccordionDetails)(() => ({
  flexDirection: 'column',
  padding: '16px 0px 0px 0px',
}))

export const CarLoanInfoDropdown: FC<CarLoanInfoProps> = ({
  outstandingAmount,
  remainingTerm,
  estimatedRate,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpanded = useCallback(
    () => setExpanded((v) => !v),
    [setExpanded],
  )

  return (
    <>
      <MobileInfoDivider margin={[2, 0, 0]} />
      <LoanDropdown expanded={expanded}>
        {/* This is needed for accordion expand/hide to work */}
        <AccordionSummary style={{ display: 'none' }} />

        <LoanDropdownDetails>
          <CarLoanInfo
            outstandingAmount={outstandingAmount}
            remainingTerm={remainingTerm}
            estimatedRate={estimatedRate}
          />
        </LoanDropdownDetails>
      </LoanDropdown>
      {/* No padding here to keep animation smooth on show*/}
      {expanded && <MobileInfoDivider margin={[0]} />}
      <LoanDropdownTitle
        aria-expanded={expanded}
        expandIcon={
          expanded ? (
            <ExpandLess color="inherit" />
          ) : (
            <ExpandMore color="inherit" />
          )
        }
        onClick={toggleExpanded}
      >
        <Text
          use={['p', { xs: 'subheadings.14m' }]}
          color="text.softBlack"
          margin={0}
        >
          {expanded ? `Hide your loan details` : `See your loan's details`}
        </Text>
      </LoanDropdownTitle>
    </>
  )
}
