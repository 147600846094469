import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const TimeDuotoneIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#00C696',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M34.1608 23.9999C34.1608 23.3527 34.6854 22.828 35.3326 22.828H37.8004C37.236 16.1238 31.8761 10.7639 25.1718 10.1995V12.6673C25.1718 13.3145 24.6472 13.8392 24 13.8392C23.3528 13.8392 22.8281 13.3145 22.8281 12.6673V10.1995C16.1239 10.7639 10.764 16.1238 10.1996 22.828H12.6673C13.3145 22.828 13.8392 23.3527 13.8392 23.9999C13.8392 24.6471 13.3145 25.1718 12.6673 25.1718H10.1996C10.764 31.876 16.1239 37.2359 22.8281 37.8003V35.3325C22.8281 34.6853 23.3528 34.1606 24 34.1606C24.6472 34.1606 25.1718 34.6853 25.1718 35.3325V37.8003C31.8761 37.2359 37.236 31.876 37.8004 25.1718H35.3326C34.6854 25.1718 34.1608 24.6471 34.1608 23.9999ZM31.0774 18.4039L24.8401 24.8169C24.4058 25.2634 23.698 25.2924 23.2287 24.8822L18.5726 20.8118C18.0854 20.3859 18.0357 19.6455 18.4617 19.1583C18.8876 18.671 19.6279 18.6212 20.1153 19.0473L23.9347 22.3862L29.3973 16.7698C29.8486 16.3059 30.5905 16.2956 31.0544 16.7467C31.5183 17.1981 31.5286 17.94 31.0774 18.4039V18.4039Z"
      fill="currentColor"
    />
    <path
      opacity="0.24"
      d="M24 4C12.972 4 4 12.972 4 24C4 35.028 12.972 44 24 44C35.028 44 44 35.028 44 24C44 12.972 35.028 4 24 4ZM24 40.1942C15.0705 40.1942 7.80578 32.9295 7.80578 24C7.80578 15.0705 15.0705 7.80578 24 7.80578C32.9295 7.80578 40.1942 15.0705 40.1942 24C40.1942 32.9295 32.9295 40.1942 24 40.1942Z"
      fill="currentColor"
    />
  </SvgIcon>
)
