import React, { FC } from 'react'
import { styled } from '@mui/material'

import { ButtonFineTextProps, ContinueButtonProps } from '@common/types'

import { LockDuotoneIcon } from './icons'
import { Text } from './text'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: 64,
  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    height: 'auto',
    flexDirection: 'column',
  },
}))

const ContinueButtonContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 64,
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    flexDirection: 'column',
  },
}))

const IconContainer = styled(Text)(({ theme }) => ({
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const FineTextContainer = styled('div')(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2),
  padding: theme.spacing(0, 3),
  backgroundColor: theme.extension.colors.surface.cloudGray,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,

  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 264,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const ButtonFineText: FC<ButtonFineTextProps> = (props) => {
  const { button: Button, fineText, className } = props

  return (
    <Container {...{ className }}>
      {fineText && (
        <FineTextContainer>
          <IconContainer
            use={['span', 'paragraphs.16']}
            color="base.freshMint"
            mr={2}
          >
            <LockDuotoneIcon />
          </IconContainer>
          <Text
            use={['span', 'paragraphs.16']}
            color="text.softBlack"
            mr={1.75}
          >
            {fineText}
          </Text>
        </FineTextContainer>
      )}

      <ButtonContainer>
        <Button sx={{ width: '100%', minWidth: 264 }} />
      </ButtonContainer>
    </Container>
  )
}

export const ContinueButton: FC<ContinueButtonProps> = (props) => {
  const { button: Button, className } = props

  return (
    <ContinueButtonContainer {...{ className }}>
      <ButtonContainer>
        <Button sx={{ width: '100%', minWidth: 264 }} />
      </ButtonContainer>
    </ContinueButtonContainer>
  )
}
