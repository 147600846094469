import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const IosSmartphoneActiveIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12V6C18 4.89543 17.1046 4 16 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H8C5.79086 22 4 20.2091 4 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H8.11111C9.35367 2 10.3977 2.84985 10.6938 4H13.3062C13.6023 2.84985 14.6463 2 15.8889 2H16V4H15.8889C15.5207 4 15.2222 4.29848 15.2222 4.66667C15.2222 5.40305 14.6253 6 13.8889 6H10.1111C9.37473 6 8.77778 5.40305 8.77778 4.66667C8.77778 4.29848 8.4793 4 8.11111 4H8V2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C14.8954 16 14 16.8954 14 18C14 19.1046 14.8954 20 16 20C17.1046 20 18 19.1046 18 18C18 16.8954 17.1046 16 16 16ZM12 18C12 15.7909 13.7909 14 16 14C18.2091 14 20 15.7909 20 18C20 20.2091 18.2091 22 16 22C13.7909 22 12 20.2091 12 18Z"
      fill="currentColor"
    />
  </SvgIcon>
)
