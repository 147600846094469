import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MicOffSolidIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 6.41421V12C7 14.2091 8.79086 16 11 16H13C13.9991 16 14.9127 15.6337 15.6138 15.028L7 6.41421ZM16.3224 15.7366C15.4391 16.5225 14.2753 17 13 17H12H11C8.23858 17 6 14.7614 6 12V11C6 10.4477 5.55228 10 5 10C4.44772 10 4 10.4477 4 11V12C4 15.866 7.13401 19 11 19L11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V19C14.8276 19 16.4917 18.2996 17.7383 17.1525L16.3224 15.7366ZM19.3119 15.0302C19.1709 15.3234 18.7852 15.371 18.5552 15.141L17.779 14.3648C17.6302 14.216 17.5928 13.9908 17.6684 13.7943C17.8826 13.2374 18 12.6324 18 12V11C18 10.4477 18.4477 10 19 10C19.5523 10 20 10.4477 20 11V12C20 13.0855 19.7529 14.1133 19.3119 15.0302ZM16.9571 12.5878C16.9037 12.9506 16.4675 13.0533 16.2082 12.794L7.75687 4.34266C7.59966 4.18544 7.56253 3.94135 7.68734 3.75735C8.40671 2.69683 9.62198 2 11 2H13C15.2091 2 17 3.79086 17 6V12C17 12.1997 16.9854 12.396 16.9571 12.5878Z"
      fill="currentColor"
    />
  </SvgIcon>
)
