import React, { FC } from 'react'

import type { TTradeline } from '@common/types'

import { TradelineCard } from './card'

type Props = {
  tradelines: ReadonlyArray<TTradeline>
  selected?: number
  handleSelect: (id: number) => void
}

export const TradelineCards: FC<Props> = ({
  tradelines,
  handleSelect,
  selected,
}) => (
  <>
    {tradelines.map((tradeline) => (
      <TradelineCard
        {...tradeline}
        key={tradeline.id}
        handleSelect={handleSelect}
        selected={selected}
      />
    ))}
  </>
)
