import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FolderRightIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7H13C12.0557 7 11.1666 6.55542 10.6 5.8L9.55 4.4C9.36115 4.14819 9.06476 4 8.75 4H6ZM2 6C2 3.79086 3.79086 2 6 2H8.75C9.69427 2 10.5834 2.44458 11.15 3.2L12.2 4.6C12.3889 4.85181 12.6852 5 13 5H18C20.2091 5 22 6.79086 22 9V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L15.7071 13.2929C15.8946 13.4804 16 13.7348 16 14C16 14.2652 15.8946 14.5196 15.7071 14.7071L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C10.9024 17.3166 10.9024 16.6834 11.2929 16.2929L12.5858 15L9 15C8.44772 15 8 14.5523 8 14C8 13.4477 8.44772 13 9 13L12.5858 13L11.2929 11.7071C10.9024 11.3166 10.9024 10.6834 11.2929 10.2929Z"
      fill="currentColor"
    />
  </SvgIcon>
)
