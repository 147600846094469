/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'

import { assert, Subject } from '../utils'

/**
 * This is here as a bug patch. It triggers rerenders if it wasn't automatically triggered by React.
 * This bug was observed on the personal info page with the Submit button (not other fields).
 * This doesn't happen on vehicle-options page or select-tradelines.
 *
 * We have ran into similar issues with helperText on personal-info page without using this approach
 * of creating components inside hooks, so it's unclear why this happens and if it's related to hook components
 *
 * This patch works quite well and DOES NOT trigger unnecessary rerenders on components that work as expected
 **/

const useForceRerender = () => {
  const [, setRerenderCount] = useState(0)
  return () => setRerenderCount((v) => v + 1)
}

const useRerenderPatch = (
  rerender$: Subject<void>,
  getBoundProps: () => any,
) => {
  const forceRerender = useForceRerender()

  const lastRenderBoundProps = useRef()
  lastRenderBoundProps.current = getBoundProps()

  useEffect(() => {
    const unsubscribe = rerender$.subscribe(() => {
      const propsSynced = getBoundProps() === lastRenderBoundProps.current
      if (!propsSynced) {
        forceRerender()
      }
    })
    return () => unsubscribe()
  }, [])
}

export const useComponentBuilder = () => {
  const { current: hookComponents } = useRef({})
  const { current: components } = useRef({})
  const { current: boundPropsRecord } = useRef({})

  const usedNamesThisRender = new Set()

  // Keep track of rerenders
  const { current: rerender$ } = useRef(new Subject<void>())
  useEffect(() => rerender$.next())

  return <TProps extends {} = any, TBoundProps extends TProps = any>(
    /** Will only use the very first component passed in. Doesn't support conditional logic */
    Component: React.FC<TProps>,
    propsToBind: TBoundProps,
    name: string,
  ): React.FC<Omit<TProps, keyof TBoundProps>> => {
    // safe guards
    assert(name, 'Name was not provided')
    console.assert(
      !usedNamesThisRender.has(name),
      `Name '${name}'' was already used during this render. Make sure you use unique name for each element`,
    )
    usedNamesThisRender.add(name)

    // keep track of bound props
    boundPropsRecord[name] = propsToBind
    // recreate component if new base component was passed in
    if (components[name] !== Component) {
      const getBoundProps = () => boundPropsRecord[name]
      const HookComponent = (otherProps: {}) => {
        useRerenderPatch(rerender$, getBoundProps)
        // always get latest bound props on every rerender
        const boundProps = getBoundProps()
        return <Component {...boundProps} {...otherProps} />
      }
      // update component display name
      HookComponent.displayName = name
      hookComponents[name] = HookComponent
      components[name] = Component
    }

    return hookComponents[name]
  }
}

export const useComponent = <
  TProps extends {} = any,
  TBoundProps extends TProps = any,
>(
  Component: React.FC<TProps>,
  props: TBoundProps,
): React.FC<Omit<TProps, keyof TBoundProps>> =>
  useComponentBuilder()(Component, props, 'UseComponent')
