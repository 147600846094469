import { firstValueFrom, Subject } from '../../utils'

const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time))

/**
 * Debounce for async functions.
 * Avoids race conditions
 *
 * @warning
 * 'Canceled' promises will be resolved with the latest value.
 */
export const asyncDebounce = <TArgs extends ReadonlyArray<unknown>, TReturn>(
  func: (...args: TArgs) => Promise<TReturn>,
  debounceTime: number,
): ((...args: TArgs) => Promise<TReturn | never>) => {
  let stateCount = 0
  let inProgress = false
  let lastValue: TReturn
  const nextValue$ = new Subject<TReturn>()

  return async (...args: TArgs): Promise<TReturn | never> => {
    stateCount++
    inProgress = true

    const newProcessStarted = (() => {
      const thisStateCount = stateCount
      return () => thisStateCount !== stateCount
    })()
    await wait(debounceTime)
    if (newProcessStarted()) {
      return firstValueFrom(nextValue$)
    }
    const value = await func(...args)
    if (newProcessStarted()) {
      return inProgress ? firstValueFrom(nextValue$) : lastValue
    }

    lastValue = value
    inProgress = false
    nextValue$.next(value)
    return value
  }
}
