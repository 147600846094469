import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BusinessIcon: React.FC<SvgIconProps> = ({
  htmlColor = '#191C22',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.125 4.25C5.0587 4.25 4.99511 4.27634 4.94822 4.32322C4.90134 4.37011 4.875 4.4337 4.875 4.5V19.75H7.875V18.25C7.875 17.6977 8.32272 17.25 8.875 17.25C9.42728 17.25 9.875 17.6977 9.875 18.25V19.75H12.875V10.125V4.5C12.875 4.4337 12.8487 4.37011 12.8018 4.32322C12.7549 4.27634 12.6913 4.25 12.625 4.25H5.125ZM14.875 8.5V4.5C14.875 3.90326 14.6379 3.33097 14.216 2.90901C13.794 2.48705 13.2217 2.25 12.625 2.25H5.125C4.52826 2.25 3.95597 2.48705 3.53401 2.90901C3.11205 3.33097 2.875 3.90326 2.875 4.5V20.75C2.875 21.3023 3.32272 21.75 3.875 21.75H8.875H13.7188C13.7457 21.75 13.7726 21.7491 13.7994 21.7472C13.8244 21.7491 13.8496 21.75 13.875 21.75H20.125C20.6773 21.75 21.125 21.3023 21.125 20.75V10.75C21.125 10.1533 20.8879 9.58097 20.466 9.15901C20.044 8.73705 19.4717 8.5 18.875 8.5H14.875ZM14.875 10.5V19.75H19.125V10.75C19.125 10.6837 19.0987 10.6201 19.0518 10.5732C19.0049 10.5263 18.9413 10.5 18.875 10.5H14.875Z"
      fill="currentColor"
    />
  </SvgIcon>
)
