import React from 'react'

import { Text } from '../../reskin'

export const NewHeading = (): JSX.Element => {
  return (
    <>
      <Text
        use={['h2', { xs: 'headings.32', md: 'headings.48' }]}
        color="text.softBlack"
        mt={{ xs: 4, md: 6 }}
        mb={{ xs: 1, md: 4 }}
      >
        Let’s review your information
      </Text>
    </>
  )
}
